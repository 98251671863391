.sitemap-kp {
  &__section {
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 64px;
    .title {
      display: block;
      margin: 0 0 24px;
      font-weight: 600; }
    &> ul {
      display: flex;
      flex-wrap: wrap;
      list-style: none;
      margin-left: -12px;
      margin-right: -12px;
      &> li {
        display: block;
        max-width: 50%;
        flex: 0 0 50%;
        padding-left: 12px;
        padding-right: 12px;
        margin-bottom: 16px;
        &> a {
          display: block;
          color: #626B79;
          text-decoration: underline; } } }
    &:last-child {
      padding-bottom: 0; } }
  &__nav {
    max-height: calc(100vh - 138px);
    overflow: auto;
    padding-right: 8px;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px; }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); }
    &::-webkit-scrollbar-thumb {
      background-color: #8B95A5;
      outline: 1px solid none;
      border-radius: 2.5px; }
    &> li {
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0; }
      &> a {
        color: #232933;
        display: block;
        padding: 8px 52px 8px 16px;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 20px;
          height: 20px;
          top: calc(50% - 10px);
          right: 16px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><g id="arrow"><path id="Vector" d="M12.0248 4.94165L17.0832 9.99998L12.0248 15.0583M2.9165 9.99998H16.9415" stroke="%23626B79" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></g></svg>');
          background-size: contain;
          background-position: center;
          transition: all .3s ease; }
        &.active {
          &::after {
            background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><g id="arrow"><rect x="0.5" y="0.5" width="19" height="19" stroke="%23626B79"/><path id="Vector" d="M12.0248 4.94165L17.0832 9.99998L12.0248 15.0583M2.9165 9.99998H16.9415" stroke="%23232933" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></g></svg>'); } } } } }

  &__sticky {
    position: sticky;
    top: 84px; }
  &__sidebar {
    max-width: 304px;
    flex: 0 0 304px;
    padding: 0 12px;
    .sidebar_title {
      font-size: 14px;
      line-height: 20px;
      color: 626B79;
      margin: 0 0 8px; } }
  &__content {
    position: relative;
    max-width: calc(100% - 304px);
    flex: 0 0 calc(100% - 304px); }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include maxtablet() {
    &__sidebar {
      max-width: 240px;
      flex: 0 0 240px;
      padding: 0 12px; }
    &__content {
      position: relative;
      max-width: calc(100% - 240px);
      flex: 0 0 calc(100% - 240px); } }

  @include mobile() {
    &__section {
      padding-top: 16px;
      padding-bottom: 16px;
      &> ul {
        &> li {
          max-width: 100%;
          flex: 0 0 100%; } } }
    &__nav {
      max-height: 0;
      opacity: 0;
      visibility: hidden; }
    &__sticky {
      position: relative;
      top: auto; }
    &__sidebar {
      position: relative;
      z-index: 200;
      max-width: 100%;
      flex: 0 0 100%;
      position: sticky;
      top: 56px;
      background: #F2F4F7;
      .sidebar_title {
        padding: 12px 40px 12px 4px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        position: relative;
        &::after {
          content: "";
          display: block;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 14px;
          right: 4px;
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="Caret"><path id="Path" d="M18 15L12 9L6 15L18 15Z" fill="%23626B79"/></g></svg>');
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          transition: all .3s ease;
          transform: rotate(180deg); } }
      &.open {
        .sitemap-kp__nav {
          max-height: calc(100vh - 148px);
          opacity: 1;
          visibility: visible;
          margin-top: 12px;
          margin-bottom: 32px;
          background: #FFF;
          padding: 8px;
          &> li {
            margin-bottom: 12px;
            &> a {
              padding: 0;
              &::after {
                display: none; }
              &.active {
                color: $link; } } } } } }
    &__content {
      max-width: 100%;
      flex: 0 0 100%; }
    &__wrap {
      padding-top: 0;
      padding-bottom: 32px;
      padding-left: 12px;
      padding-right: 12px; } } }
