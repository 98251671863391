@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content; }

  @-moz-keyframes #{$animation-name} {
    @content; }

  @-ms-keyframes #{$animation-name} {
    @content; }

  @-o-keyframes #{$animation-name} {
    @content; }

  @keyframes #{$animation-name} {
    @content; } }

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str}; }

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args; }

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity = $opacity-ie); }

@mixin transform($translate3d) {
  transform: $translate3d;
  -webkit-transform: $translate3d;
  -moz-transform: $translate3d;
  -o-transform: $translate3d;
  -ms-transform: $translate3d; }

@mixin background($path,$size: auto,$position:0 0,$repeat: no-repeat) {
  background: transparent url(#{$path}) $repeat $position;
  background-size: $size;
  -webkit-background-size: $size;
  -moz-background-size: $size; }
@mixin bg-cover {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; }
@mixin backgroundSize($val) {
  background-size: $val;
  -webkit-background-size: $val;
  -moz-background-size: $val; }
@mixin filter($value) {
  -webkit-filter : $value;
  filter: $value; }
@mixin center() {
  align-items: center;
  display: flex;
  display: -webkit-flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -webkit-box-pack: center;
  box-align: center; }

@mixin responsive($max:0,$min: 0) {
  @if $min == 0 {
    @media(max-width: $max) {
      @content; } }
  @else if $max == 0 {
    @media(min-width: $min) {
      @content; } }
  @else {
    @media(max-width: $max) and  (min-width: $min) {
      @content; } } }
@mixin mobilexs() {
  @include responsive(320px) {
    @content; } }
@mixin mobilesm() {
  @include responsive(375px) {
    @content; } }
@mixin mobilelg() {
  @include responsive(479px) {
    @content; } }
@mixin mobile() {
  @include responsive(768px) {
    @content; } }
@mixin maxtablet() {
  @include responsive(991px) {
    @content; } }
@mixin maxtablet-l() {
  @include responsive(1025px) {
    @content; } }
@mixin mintablet() {
  @include responsive(0,768px) {
    @content; } }
@mixin minltablet() {
  @include responsive(0,992px) {
    @content; } }
@mixin tablet() {
  @include responsive(992px,768px) {
    @content; } }
@mixin tablets() {
  @include responsive(1200px,768px) {
    @content; } }
@mixin ltablet() {
  @include responsive(1200px,992px) {
    @content; } }
@mixin sdekstop() {
  @include responsive(1200px,1024px) {
    @content; } }
@mixin xsdesktop() {
  @include responsive(1024px) {
    @content; } }
@mixin maxlarge() {
  @include responsive(1200px,0) {
    @content; } }
@mixin large() {
  @include responsive(0,1200px) {
    @content; } }
@mixin mac14() {
  @include responsive(1600px,1300px) {
    @content; } }
@mixin imac() {
  @include responsive(1700px,1600px) {
    @content; } }
@mixin imac19() {
  @include responsive(0,1700px) {
    @content; } }
//IE8

%clearfix {
  *zoom: 1;
  &:before, &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

%visuallyhidden {
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip: rect(0, 0, 0, 0);
  position: absolute; }

@mixin bgcenter {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f9f9f9; }

@mixin imgBg($padding) {
  background-size: cover;
  background-position: center;
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  display: block;
  &:before {
    content: "";
    display: block;
    padding: $padding; } }

@mixin absfull() {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

@mixin clear {
  &:after {
    content: "";
    display: table;
    clear: both; } }

@mixin fontGenerator($name,$weight,$family) {
  @for $i from 1 through length($family) {
    $src: #{"../fonts/"}#{nth($family, $i)};
    @font-face {
      font-family : $name;
      font-display: swap;
      src         : local($name);
      src         : url("#{$src}.woff2");
      src         : url("#{$src}.woff2") format("woff2");
      font-style  : normal;
      font-weight : nth($weight, $i); } } }

@mixin bounce() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce2;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounce2 {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(-4px); }
  50% {
    transform: scale(1.05,.95) translateX(6px); }
  57% {
    transform: scale(1,1) translateX(-2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@mixin dash($duration) {
  stroke-dasharray: 1000;
  animation: dash $duration linear; }
@keyframes dash {
  from {
    stroke-dashoffset: 1000; }
  to {
    stroke-dashoffset: 0; } }

@mixin dash2($duration) {
  stroke-dasharray: 1500;
  animation: dash2 $duration linear; }
@keyframes dash2 {
  from {
    stroke-dashoffset: 1500; }
  to {
    stroke-dashoffset: 0; } }
