.recom-bundle--alt {
  padding-top: 48px;
  padding-bottom: 48px;
  h2 {
    text-align: center;
    margin-bottom: 48px; }
  .recom-bundle__nav {
    .nav-item {
      width: 100%; }
    .item {
      position: relative;
      padding: 20px 60px 20px 32px;
      background: #FFF;
      border: 1px solid $border;
      border-radius: 4px;
      margin-bottom: 12px;
      transition: all .3s ease;
      &[data-toggle] {
        cursor: pointer; }
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/ic-chevron-right-round.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 12px);
        right: 30px;
        opacity: 0;
        transition: all .3s ease; }

      &__logo {
        position: relative;
        margin-bottom: 12px;
        img {
          display: block;
          height: 26px;
          width: auto; } }
      &__title {
        font-size: 18px;
        line-height: 28px;
        @include inter-600(); }
      &.active {
        border-color: $primary;
        background: #FDEDD9!important;
        &:after {
          opacity: 1;
          right: 24px; } }
      &:not(.active):hover {
        background: #F2F4F7; } } }
  .tab-content {
    height: 100%;
    padding-bottom: 12px; }
  .tab-pane {
    padding: 32px;
    border: 1px solid $border;
    border-radius: 4px;
    height: 100%;
    &__sm {
      display: none; }
    h3 {
      margin-bottom: 16px; }
    span.cat {
      color: #2B2A35;
      font-size: 16px;
      line-height: 24px;
      @include inter-500();
      display: block;
      margin-bottom: 8px; }
    ul.custom-list {
      &> li {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0; } } }
    .item__title {
      font-size: 24px;
      line-height: 32px; }
    .price {
      &:not(:last-child) {
        margin-bottom: 24px; }
      .ps {
        font-size: 14px;
        line-height: 20px;
        color: #777;
        display: block; }
      .pn {
        font-size: 40px;
        line-height: 48px;
        @include inter-500();
        color: #2B2A35; }
      .pt {
        font-size: 14px;
        line-height: 24px;
        @include inter-400();
        color: #545465; } }
    .tab-action {
      padding-top: 32px; } }
  @include maxtablet() {
    &__nav {
      display: none; }
    .tab-pane {
      margin-bottom: 24px;
      display: block;
      height: auto;
      opacity: 1;
      &__sm {
        display: block;
        .item__logo {
          margin-bottom: 12px; }
        .item__desc {
          margin-bottom: 24px; } }
      .hide-sm {
        display: none; }
      &:last-child {
        margin-bottom: 0; }
      .item__title {
        font-size: 21px;
        line-height: 32px; }
      .price {
        .pn {
          font-size: 26px;
          line-height: 32px; } } } } }

.pricing-main--alt {
  padding-bottom: 0;
  .pricing-items {
    .owl-dots {
      margin-top: 0;
      .owl-dot {
        width: 32px;
        height: 4px;
        border-radius: 4px;
        background: #8B95A5;
        border: none;
        &.active {
          background: #F96D01; } } } }
  .pricing-main__wrap {
    padding-bottom: 64px; }
  .pricing-main__goto {
    position: sticky;
    z-index: 50;
    left: 0;
    bottom: 24px;
    width: 100%;
    text-align: center;
    padding-bottom: 16px;
    .btn {
      background: #FFF;
      &::after {
        content: "";
        display: inline-block;
        position: relative;
        top: -2px;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M15.5581 12.0251L10.4997 17.0834L5.44141 12.0251M10.4997 2.91675V16.9417" stroke="%234B61DD" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        margin-left: 8px; }
      &:hover {
        color: #FFF;
        background: $button;
        &::after {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M15.5581 12.0251L10.4997 17.0834L5.44141 12.0251M10.4997 2.91675V16.9417" stroke="%23FFFFFF" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>'); } } } }

  .item--alt {
    .badge {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      border-radius: 10px;
      background: $border;
      color: #232933;
      &.yellow {
        background: #FBBF24; }
      &.addon {
        color: #FFF;
        background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%); } }
    .item__head {
      position: relative;
      h2 {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .badge {
          color: #FFF;
          background: #F96D01;
          font-size: 12px;
          line-height: 16px;
          font-weight: 600;
          padding: 2px 12px;
          border-radius: 6px; } }
      p {
        display: block;
        min-height: 120px;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
        color: #232933; }
      .price {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin-bottom: 24px;
        .strike {
          font-size: 14px;
          line-height: 20px;
          color: #626B79;
          text-decoration: line-through; }
        .pr {
          @include inter-600();
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 0;
          small {
            font-size: 14px;
            line-height: 20px;
            color: #626B79;
            margin-bottom: 0;
            margin-left: 0; }
          .disc {
            color: #4B61DD;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            padding: 0 6px;
            border-radius: 10px;
            border: 1px solid #4B61DD;
            background: #FFF;
            margin-left: 8px; } }
        .snk {
          font-size: 12px;
          line-height: 16px;
          color: #626B79; } } }
    .item__main {
      .custom-list {
        font-size: 14px;
        line-height: 20px;
        &> li {
          margin: 0;
          padding-top: 4px;
          padding-bottom: 4px;
          &::before {
            top: 4px; } } }
      .item__toggle {
        @include inter-600();
        color: #4B61DD;
        &::after {
          background: url(../images/ic-chev-up-blue.svg) no-repeat center;
          background-size: contain; } }
      .item__collapse-wrap {
        .cat {
          @include inter-600();
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 8px;
          position: relative;
          padding-right: 25px;
          .badge.red {
            background: #DA473F;
            padding: 2px 4px;
            font-size: 12px;
            line-height: 15px;
            color: #FFF;
            position: absolute;
            right: -13px;
            top: 3px; } } }
      &--sm {
        display: none; } } }

  @media (max-width: 1024px) {
    .item--alt .item__head h2 .badge {
      position: absolute;
      top: -10px;
      left: 16px; } }

  @media (max-width: 768px) {
    .pricing-main__wrap {
      overflow: auto;
      padding-top: 10px;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
      margin-bottom: 40px; }
    .pricing-items {
      position: relative;
      flex-direction: row;
      width: 1024px;
      .item--alt {
        max-width: 25%;
        flex: 0 0 25%;
        margin-bottom: 24px; } } }

  @media (max-width: 540px) {
    .pricing-main__wrap {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 10px; }
    .pricing-items {
      width: auto;
      flex-direction: column-reverse;
      .item--alt {
        max-width: 100%;
        flex: 0 0 100%; } }
    .pricing-main__goto {
      display: none; }
    .item--alt {
      .item__main {
        .custom-list {
          &> li {
            padding-top: 8px;
            padding-bottom: 8px;
            font-size: 16px;
            line-height: 24px;
            &::before {
              top: 8px;
              width: 24px;
              height: 24px;
              background-size: 20px; } } }
        .item__toggle {
          color: #232933;
          font-size: 16px;
          line-height: 24px;
          &::after {
            filter: grayscale(100) contrast(100); } } }
      .item__main:not(.item__main--sm) {
        border-radius: 0;
        border-bottom: 0; }
      .item__main--sm {
        display: block;
        .custom-list {
          &> li {
            display: flex;
            align-items: flex-start;
            span {
              display: block;
              &:first-child {
                padding-right: 8px; } }
            .badge {
              margin-left: auto; }
            &.cross::before {
              background: url(../images/ic-pricing-cross.svg) no-repeat center/contain; } } } } } } }


.package-detail {
  padding: 32px 0;
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    text-align: center;
    .accr {
      width: 25px;
      height: 25px;
      display: inline-block;
      margin-left: 10px;
      background: url(../images/ic-chevron-down.svg) no-repeat center;
      background-size: contain;
      cursor: pointer;
      &.active {
        transform: rotateZ(180deg); } } }
  &__desc {
    p {
      text-align: center;
      margin-bottom: 24px; } }
  &__main {
    display: none;
    visibility: hidden;
    opacity: 0;
    transition: all linear .3s;
    &.active {
      visibility: visible;
      opacity: 1;
      transition: all linear .3s; } }
  .items {
    .item {
      border: 1px solid #D0D6DD;
      border-radius: 6px;
      overflow: hidden;
      margin-bottom: 40px;
      &__head {
        background: #EDF0F2;
        border-right: 1px solid #D0D6DD;
        padding: 16px;
        h3 {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0;
          @include inter-600(); } }
      &__main {
        padding: 16px;
        ul {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          width: 100%;
          li {
            display: flex;
            flex-direction: column;
            flex: 50%; } } } } }
  @include maxtablet() {
    padding: 32px 10px;
    &__title {
      font-size: 20px;
      line-height: 28px;
      .accr {
        width: 25px;
        height: 25px;
        margin-bottom: -5px; } }
    .items {
      .item {
        margin-bottom: 20px;
        &__head {
          border-right: 0;
          border-bottom: 1px solid #D0D6DD;
          p {
            margin-bottom: 0; } }
        &__main {
          ul {
            li {
              flex: 100%; } } } } } } }

.our-customer--alt {
  padding: 32px 0;
  &__action {
    margin-bottom: 0; } }

.tab-accr {
  padding-top: 64px;
  &__head {
    display: none; }
  &__subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    @include inter-600();
    text-align: center; }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
    text-align: center;
    @include maxtablet() {
      font-size: 28px;
      line-height: 36px; } }
  .nav-container {
    .nav-tabs {
      border-bottom: 0;
      .nav-item {
        .nav-link {
          position: relative;
          border: 0;
          text-align: center;
          opacity: 1;
          color: #2B2A35;
          background: #FFFFFF;
          padding: 1.25rem 0.5rem 3.5rem;
          overflow: hidden;
          img.icon {
            display: block;
            object-fit: contain;
            object-position: 50% 50%;
            margin: 0 auto 1rem;
            filter: grayscale(100%); }
          span {
            display: block;
            color: #232933; }
          &.active {
            img.icon {
              filter: grayscale(0); }
            span {
              @include inter-600(); }
            &::after {
              content: "";
              display: block;
              width: 82px;
              height: 82px;
              border-radius: 41px;
              background-color: #F2F4F7;
              position: absolute;
              top: calc(100% - 41px);
              left: calc(50% - 41px);
              background-image: url(../images/ic-chev-down-orange.svg);
              background-repeat: no-repeat;
              background-position: center 30%; } }
          &:hover {
            cursor: pointer; } } } }
    @include maxtablet() {
      display: none;
      .nav-item {
        width: 50%; } } }

  .tab-pane {
    background: #F2F4F7;
    padding: 32px 12px;
    @include maxtablet() {
      display: block;
      opacity: 1;
      visibility: visible;
      padding: 0 16px;
      background: #FFF;
      .tab-accr {
        &__head {
          display: block;
          @include inter-600();
          font-size: 20px;
          line-height: 28px;
          color: #626B79;
          padding: 12px 0 20px;
          border-bottom: 1px solid #D0D6DD;
          position: relative;
          &::after {
            content: '';
            width: 24px;
            height: 24px;
            background: url('../images/ic-caret-down.svg') no-repeat center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 15px; } }
        &__main {
          display: none;
          opacity: 0;
          visibility: hidden;
          background: #F2F4F7;
          padding: 32px 0; } }
      &.active.show {
        .tab-accr {
          &__head {
            color: #232933;
            border-color: #F96D01;
            &::after {
              transform: rotateZ(180deg); } }
          &__main {
            display: flex;
            opacity: 1;
            visibility: visible; } } } } }

  @include maxtablet() {
    padding-top: 32px; } }

.addons-accr {
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
    @include maxtablet() {
      font-size: 16px;
      line-height: 23px;
      margin-bottom: 12px; } }
  &__img {
    @include maxtablet() {
      display: none; } }
  &__desc {
    p {
      margin-bottom: 24px; } }
  &__action {
    margin-bottom: 24px; }

  #accaddons {
    .card {
      &__header {
        border-bottom: 0;
        padding: 16px;
        &::after {
          background: url('../images/ic-caret-down.svg') no-repeat center;
          background-size: contain;
          top: 17px; } }
      &__title {
        font-size: 16px;
        line-height: 24px; }
      &__body {
        margin-top: 0;
        padding: 0 16px 16px;
        p {
          margin-bottom: 16px; }
        .price {
          margin-bottom: 24px;
          .pr {
            @include inter-600();
            font-size: 20px;
            line-height: 32px;
            small {
              font-size: 14px;
              line-height: 20px;
              color: #232933; } } } } } } }

.addons-pricing {
  padding: 32px 12px;
  &__title {
    @include inter-600();
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
    @include maxtablet() {
      font-size: 16px;
      line-height: 23px; } }
  &__desc {
    p {
      margin-bottom: 24px; } }
  &__price {
    margin-bottom: 20px;
    .start {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 0;
      color: #626B79; }
    .pr {
      display: block;
      font-size: 40px;
      line-height: 48px;
      @include inter-600();
      small {
        font-size: 14px;
        line-height: 20px; } } }
  &__list {
    margin-bottom: 32px; }
  &__action {
    margin-bottom: 18px; } }

.fea-block {
  padding: 32px 12px;
  &__title {
    @include inter-600();
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 12px;
    @include maxtablet() {
      font-size: 16px;
      line-height: 23px; } }
  &__desc {
    p {
      margin-bottom: 24px; } }
  &__img {
    @include maxtablet() {
      display: none; } } }

.pricing-table {
  .badge {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    border-radius: 10px;
    background: $border;
    color: #232933;
    &.yellow {
      background: #FBBF24; }
    &.addon {
      color: #FFF;
      background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%); } }
  span.checked {
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-check.svg) no-repeat center/contain; }
  span.cross {
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-pricing-cross.svg) no-repeat center/contain; }
  span.lock {
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-pricing-lock.svg) no-repeat center/contain; }
  .block-question {
    display: flex;
    width: 100%;
    max-width: 24px;
    flex: 0 0 24px;
    margin-left: 4px;
    &::after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../images/ic-question.svg) no-repeat center/contain; } }
  .block-label {
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 16px;
    color: #626B79;
    span.badge {
      display: inline-block;
      margin-left: 4px; } }
  .block-head {
    width: 100%;
    background: #F1F5F9;
    border: 1px solid #D0D6DD;
    padding: 16px 14px;
    border-radius: 8px 8px 0 0;
    &.popular {
      background-color: #FBF3DD;
      border-color: #F96D01; } }
  .block-body {
    padding: 16px;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background: #F1F5F9;
    border-left: 1px solid #F1F5F9;
    border-right: 1px solid #F1F5F9;
    &.popular {
      background-color: #FBF3DD;
      border-color: #F96D01; } }
  .tr-head {
    .block-label {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      color: #232933; }
    .block-body {
      display: none; } }
  .tr-body {
    .pricing-table__tr:first-child > .pricing-table__td > .block-body {
      border-radius: 8px 8px 0 0;
      border-top: 1px solid #F1F5F9;
      &.popular {
        background-color: #FBF3DD;
        border-color: #F96D01; } }
    .pricing-table__tr:last-child > .pricing-table__td > .block-body {
      border-radius: 0 0 8px 8px;
      border-bottom: 1px solid #F1F5F9;
      &.popular {
        background-color: #FBF3DD;
        border-color: #F96D01; } }
    .pricing-table__tr {
      position: relative;
      &::after {
        content: "";
        z-index: 2;
        display: block;
        width: calc(100% - 16px);
        height: 100%;
        position: absolute;
        top: 0;
        left: 8px;
        background: #8B95A5;
        border-radius: 8px;
        opacity: 0;
        pointer-events: none;
        transition: all .3s ease; }
      &:hover {
        &::after {
          opacity: 0.3; } } } }
  &__tr {
    display: flex;
    margin-left: -8px;
    margin-right: -8px; }
  &__td,
  &__th {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
    &.td-label,
    &.th-label {
      max-width: 290px;
      flex: 0 0 290px; } }
  &__th {
    .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      text-align: center;
      margin: 0 0 24px; } }
  &__thead {
    z-index: 10;
    display: block;
    position: sticky;
    top: 59px;
    padding-top: 16px;
    background: #FFF; }
  &__title {
    text-align: center;
    margin-bottom: 24px; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @media (max-width: 1024px) {
    .pricing-table__container {
      max-width: 100%;
      overflow: auto;
      padding-bottom: 24px;
      .pricing-table__thead,
      .pricing-table__tbody {
        width: 1224px;
        top: auto; } } }
  @media (max-width: 540px) {
    display: none; } }

body.tbs-show {
  .pricing-table__thead {
    top: 119px; } }

body.tbs-show-top {
  .pricing-table__thead {
    top: 100px; } }
