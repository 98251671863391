.modal-success--alt {
  .close {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
    opacity: 1;
    transition: all .3s ease-in-out; }
  .modal-dialog {
    margin: 0 auto;
    padding: 0 !important;
    background: transparent!important;
    box-shadow: none!important;
    max-width: 100%;

    .modal-body {
      padding: 0; }

    .modal-content {
      text-align: center;
      padding: 32px;
      max-width: 632px;
      width: 100%;
      background: #FFF;
      box-shadow: 0 4px 8px 0 rgba(119,119,119,0.1), 0 12px 20px 0 rgba(119,119,119,0.2);
      margin: 64px auto;
      border-radius: 8px;
      position: relative;
      @include maxtablet() {
        padding: 16px; } }

    .modal-icon {
      margin-bottom: 16px;
      img {
        object-fit: contain; } }

    .modal-title {
      @include inter-600();
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 16px;
      color: #232933;
      @include maxtablet() {
        font-size: 28px;
        line-height: 36px; } }

    .modal-desc {
      font-size: 16px;
      line-height: 24px;
      color: #232933; } } }
