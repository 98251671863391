.microsite {
  padding-top: 64px;
  &__banner {
    margin: 0 0 24px;
    border-radius: 32px;
    img {
      border-radius: 32px;
      display: block;
      width: 100%;
      height: auto; }
    .swiper-wrapper {
      padding-bottom: 0 !important; }
    .swiper-pagination {
      text-align: right;
      padding: 12px 28px;
      .swiper-pagination-bullet {
        width: 44px;
        height: 8px;
        background: #8B95A5;
        opacity: 0.4;
        &-active {
          opacity: 1; } } } }
  &__head {
    padding-bottom: 40px;
    .box {
      display: block;
      border-radius: 32px;
      background: #FFF;
      border: 1px solid #D0D6DD;
      padding: 24px;
      margin-bottom: 24px;
      box-shadow: 0px 4px 12px -2px rgba(0, 0, 0, 0.05), 0px 10px 30px -3px rgba(0, 0, 0, 0.05);
      background-repeat: no-repeat;
      background-size: auto 100%;
      background-position: bottom right;
      &__icon {
        width: 100%;
        max-width: 32px;
        flex: 0 0 32px; }
      &__title {
        width: 100%;
        padding: 0 8px;
        color: $text-dark;
        font-weight: 600; }
      &__action {
        white-space: nowrap; }
      &__head {
        display: flex;
        align-items: center;
        margin: 0 0 16px; }
      &__content {
        font-size: 14px;
        line-height: 20px;
        color: #232933;
        margin: 0 0 16px; }
      &--spotify {
        background: #F7F4FF;
        .box__spotify {
          iframe {
            width: 100%;
            height: 80px;
            display: block;
            margin: 0 0 16px;
            &:last-child {
              margin-bottom: 0; } } } }
      &--yt {
        .box__video {
          display: block;
          position: relative;
          .btn-play {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: calc(50% - 30px);
            left: calc(50% - 30px);
            width: 60px;
            height: 60px;
            border-radius: 30px;
            background: #4B61DD;
            color: #fff;
            text-align: center;
            @include pulseCustom(255,255,255,'pulse-white');
            .icon {
              width: 16px;
              height: 16px;
              background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='play-video'%3E%3Cpath id='Vector' fill-rule='evenodd' clip-rule='evenodd' d='M10.7723 4.66807L10.7455 4.65254C9.56002 3.93978 8.63574 3.38464 7.88333 3.02206C7.12863 2.65947 6.46254 2.44595 5.80714 2.51117C5.29865 2.56186 4.80642 2.72112 4.36268 2.97855C3.91893 3.23598 3.53368 3.58576 3.23214 4.005C2.84333 4.54461 2.68445 5.23718 2.60883 6.08192C2.53244 6.92434 2.53244 8.01598 2.53244 9.41587V12.2498C2.53244 13.6497 2.53244 14.7413 2.60883 15.5837C2.68521 16.4285 2.84333 17.121 3.23214 17.6607C3.5336 18.0798 3.91873 18.4295 4.36233 18.687C4.80594 18.9444 5.29802 19.1037 5.80638 19.1545C6.46178 19.2197 7.12863 19.007 7.8841 18.6428C8.63574 18.281 9.56002 17.7259 10.7455 17.0131L10.7723 16.9976L13.0792 15.6125L13.1059 15.5969V15.5962C14.2555 14.9051 15.1531 14.3663 15.81 13.8842C16.4707 13.4004 16.9642 12.9214 17.2247 12.3189C17.4276 11.8505 17.5324 11.3443 17.5324 10.8324C17.5324 10.3206 17.4276 9.8144 17.2247 9.34599C16.965 8.74504 16.4707 8.26521 15.81 7.78073C15.1531 7.29935 14.2555 6.75974 13.1059 6.0695L13.0792 6.05397L10.7723 4.66807Z' fill='%23FFFFFF'/%3E%3C/g%3E%3C/svg%3E");
              background-repeat: no-repeat;
              background-size: contain;
              background-position: center; }
            span {
              font-size: 10px;
              line-height: 16px; } }

          img {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 16px;
            object-fit: cover;
            object-position: center; }
          a {
            display: block;
            width: 100%;
            position: relative;
            padding-top: 53.8%; } } }
      &--ig {
        background: #FDECEE;
        .box__imgs {
          display: flex;
          margin-left: -8px;
          margin-right: -8px;
          .item {
            position: relative;
            display: block;
            padding-left: 8px;
            padding-right: 8px;
            width: 100%;
            max-width: 33.33%;
            flex: 0 0 33.33%;
            a {
              position: relative;
              display: block;
              width: 100%;
              padding-top: 100%; }
            img {
              display: block;
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              object-fit: cover;
              object-position: center;
              border-radius: 16px; } } } }
      &--url {
        display: flex;
        align-items: center;
        font-weight: 600;
        color: $text-dark;
        text-decoration: none;
        .icon {
          width: 100%;
          max-width: 32px;
          flex: 0 0 32px;
          img {
            width: 32px;
            height: 32px;
            object-fit: contain;
            object-position: center; } }
        .text {
          display: block;
          padding-left: 8px;
          width: 100%;
          max-width: calc(100% - 32px);
          flex: 0 0 calc(100% - 32px); }
        &:hover {
          color: $primary;
          text-decoration: none; }
        &.box--url-ig {
          background: #FDECEE; } }
      &--img {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        min-height: 184px;
        .icon {
          width: 32px;
          height: 32px; }
        .text {
          display: block;
          width: calc(100% - 32px);
          padding-left: 8px;
          padding-top: 4px;
          font-weight: 600;
          color: #232933; }
        .content {
          padding-top: 16px;
          color: #232933; }
        .box-bg {
          position: absolute;
          width: 55%;
          height: calc( 100% - 8px );
          bottom: 1px;
          right: 0;
          object-fit: contain;
          object-position: right bottom; }
        &:hover {
          text-decoration: none; } }
      &--desc {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        min-height: 184px;
        .icon {
          width: 32px;
          height: 32px; }
        .text {
          display: block;
          width: calc(100% - 32px);
          padding-left: 8px;
          padding-top: 4px;
          font-weight: 600;
          color: #232933; }
        .content {
          padding-top: 16px;
          color: #232933; }
        .action {
          padding-top: 24px;
          display: block;
          width: 100%;
          .btn {
            display: block; } } } } }
  &__sidebar {
    position: relative;
    max-width: 365px;
    flex: 0 0 365px;
    .sidebar-sticky {
      display: flex;
      flex-wrap: wrap;
      position: sticky;
      top: 24px; }
    .logo {
      display: block;
      max-width: 64px;
      flex: 0 0 64px;
      margin: 0 0 12px;
      img {
        display: block;
        height: 64px;
        width: 64px;
        object-fit: contain;
        object-position: center; } }
    .title {
      display: block;
      max-width: calc(100% - 64px);
      flex: 0 0 calc(100% - 64px);
      padding-left: 12px;
      margin: 0 0 12px; }
    .content {
      display: block;
      max-width: 100%;
      flex: 0 0 100%; }
    h1 {
      font-size: 32px;
      line-height: 48px;
      margin: 0; }
    .subtitle {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      color: $text-dark;
      margin: 0; }
    p {
      margin: 0 0 12px;
      font-size: 16px;
      line-height: 24px; }
    .location {
      display: flex;
      color: $text;
      transition: all .3s ease;
      &::before {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="location"><g id="Vector"><path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="%23626B79" stroke-width="1.5"/><path d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2768 7.19 8.87995 8.58687 8.87995 10.31C8.87995 12.0331 10.2768 13.43 12 13.43Z" stroke="%23626B79" stroke-width="1.5"/></g></g></svg>');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-right: 8px;
        transition: all .3s ease; }
      &:hover {
        color: $primary;
        &::before {
          background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><g id="location"><g id="Vector"><path d="M3.61995 8.49C5.58995 -0.169998 18.42 -0.159997 20.38 8.5C21.53 13.58 18.37 17.88 15.6 20.54C13.59 22.48 10.41 22.48 8.38995 20.54C5.62995 17.88 2.46995 13.57 3.61995 8.49Z" stroke="%234B61DD" stroke-width="1.5"/><path d="M12 13.43C13.7231 13.43 15.12 12.0331 15.12 10.31C15.12 8.58687 13.7231 7.19 12 7.19C10.2768 7.19 8.87995 8.58687 8.87995 10.31C8.87995 12.0331 10.2768 13.43 12 13.43Z" stroke="%234B61DD" stroke-width="1.5"/></g></g></svg>'); } } } }
  &__main {
    max-width: calc(100% - 365px);
    flex: 0 0 calc(100% - 365px);
    padding-bottom: 64px;
    padding-left: 24px; }
  &__product {
    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 24px; }
    .item {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      border-radius: 24px;
      padding-left: 12px;
      padding-right: 12px;
      margin-bottom: 24px;
      &__title,
      &__desc {
        position: relative;
        z-index: 2;
        color: #FFF;
        margin: 0 0 16px; }
      &__bg {
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 32px;
        &.bg-talenta {
          background: linear-gradient(340deg, #BF000A 9.7%, #F22929 47.12%, #FB5B58 84.53%); }
        &.bg-jurnal {
          background: linear-gradient(340deg, #0087D9 9.7%, #00A8FD 47.12%, #66CFFF 84.53%); }
        &.bg-qontak {
          background: linear-gradient(340deg, #2E65EC 9.7%, #2979FF 47.12%, #26A9FF 84.53%); }
        &.bg-klikpajak {
          background: linear-gradient(146deg, #FF7C1F 5.21%, #FF9100 47.89%, #FFC333 90.57%); }
        &.bg-flex {
          background: linear-gradient(340deg, #643ECC 9.7%, #7C4DFF 47.12%, #A380FF 84.53%); }
        &.bg-expense {
          background: linear-gradient(340deg, #01579B 24.67%, #075FA2 38.89%, #1978B7 62.08%, #2F95D1 84.53%); }
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: bottom right;
          border-radius: 32px; } }
      &__wrap {
        display: block;
        text-decoration: none;
        border-radius: 32px;
        overflow: hidden;
        padding: 24px;
        min-height: 300px;
        position: relative;
        &:hover {
          text-decoration: none; } } }
    .items {
      display: flex;
      flex-wrap: wrap;
      margin-left: -12px;
      margin-right: -12px; } }
  &__footer {
    padding: 32px 24px;
    text-align: center;
    color: #626B79; }

  @media (max-width: 1024px) {
    padding-top: 32px;
    &__head {
      .box {
        &__title {
          max-width: calc(100% - 32px);
          flex: 0 0 calc(100% - 32px); }
        &__action {
          max-width: 100%;
          flex: 0 0 100%;
          padding-top: 16px;
          .btn {
            display: block; } }
        &__head {
          flex-wrap: wrap; } } }
    &__sidebar {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 24px;
      .sidebar-sticky {
        position: relative;
        top: auto; } }
    &__main {
      max-width: 100%;
      flex: 0 0 100%;
      padding-left: 16px;
      padding-right: 16px; } }

  @media (max-width: 580px) {
    &__product {
      .item {
        max-width: 100%;
        flex: 0 0 100%; } } } }
