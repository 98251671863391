.section-contents {
  &__title {
    text-align: center;
    margin: 0 0 24px; }
  .item {
    padding-top: 32px;
    padding-bottom: 32px;
    &__img {
      img {
        display: block;
        max-width: 100%;
        height: auto;
        width: auto; } }
    &__title {
      margin: 0 0 16px; }
    &__desc {
      .custom-list {
        li:last-child {
          margin: 0; } } }
    &__action {
      padding-top: 32px;
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin-left: 8px;
        margin-right: 8px; } } }
  &__items {
    .item {
      flex-direction: row;
      &:first-child {
        padding-top: 0; }
      &:last-child {
        padding-bottom: 0; }
      &:nth-child(even) {
        flex-direction: row-reverse; } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__title {
      br {
        display: none; } }
    .item {
      padding-top: 32px;
      padding-bottom: 32px;
      flex-direction: column-reverse;
      &:nth-child(even) {
        flex-direction: column-reverse; }
      &__img {
        padding-top: 24px; }
      &__action {
        margin-left: 0;
        margin-right: 0;
        .btn {
          display: block;
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }
