// Margin & Padding
$mg : 20 30 40 50 60 80 90 100 110 120 150 180;
$mg-xs : 10 15 20 25 30 40 45 50 55 60 75 90;

@mixin layout($prefix:'') {
  @for $i from 1 through length($mg) {

    .#{$prefix}mtd-#{nth($mg, $i)} {
      margin-top: #{nth($mg, $i)}px;
      margin-bottom: #{nth($mg, $i)}px;
      @include mobile {
        margin-top: #{nth($mg-xs, $i)}px;
        margin-bottom: #{nth($mg-xs, $i)}px; } }

    .#{$prefix}mb-#{nth($mg, $i)} {
      margin-bottom: #{nth($mg, $i)}px;
      @include mobile {
        margin-bottom: #{nth($mg-xs, $i)}px; } }
    .#{$prefix}mt-#{nth($mg, $i)} {
      margin-top: #{nth($mg, $i)}px;
      @include mobile {
        margin-top: #{nth($mg-xs, $i)}px; } }

    .#{$prefix}ptd-#{nth($mg, $i)} {
      padding-top: #{nth($mg, $i)}px;
      padding-bottom: #{nth($mg, $i)}px;
      @include mobile {
        padding-top: #{nth($mg-xs, $i)}px;
        padding-bottom: #{nth($mg-xs, $i)}px; } }

    .#{$prefix}pt-#{nth($mg, $i)} {
      padding-top: #{nth($mg, $i)}px;
      @include mobile {
        padding-top: #{nth($mg-xs, $i)}px; } }

    .#{$prefix}pb-#{nth($mg, $i)} {
      padding-bottom: #{nth($mg, $i)}px;
      @include mobile {
        padding-bottom: #{nth($mg-xs, $i)}px; } } } }

@include layout();


@mixin layout2($prefix:'') {
  @for $i from 1 through length($mg) {
    %#{$prefix}mb-#{nth($mg, $i)} {
      margin-bottom: #{nth($mg, $i)}px;
      @include mobile {
        margin-bottom: #{nth($mg-xs, $i)}px; } }
    %#{$prefix}mt-#{nth($mg, $i)} {
      margin-top: #{nth($mg, $i)}px;
      @include mobile {
        margin-top: #{nth($mg-xs, $i)}px; } }

    %#{$prefix}ptd-#{nth($mg, $i)} {
      padding-top: #{nth($mg, $i)}px;
      padding-bottom: #{nth($mg, $i)}px;
      @include mobile {
        padding-top: #{nth($mg-xs, $i)}px;
        padding-bottom: #{nth($mg-xs, $i)}px; } }

    %#{$prefix}pt-#{nth($mg, $i)} {
      padding-top: #{nth($mg, $i)}px;
      @include mobile {
        padding-top: #{nth($mg-xs, $i)}px; } }

    %#{$prefix}pb-#{nth($mg, $i)} {
      padding-bottom: #{nth($mg, $i)}px;
      @include mobile {
        padding-bottom: #{nth($mg-xs, $i)}px; } } } }

@include layout2();
