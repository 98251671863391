.block-table--alt {
  .section__title {
    margin-bottom: 24px; }
  .item {
    &__head {
      display: flex;
      align-items: center;
      justify-content: flex-start; }
    &__icon {
      width: 40px;
      height: 40px;
      flex: 0 0 40px;
      margin-right: 8px;
      img {
        width: 40px;
        height: 40px;
        object-fit: contain; } } } }
