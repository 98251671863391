.ebook-tab {
  .nav-container {
    .nav-tabs {
      border-bottom: 0;
      .nav-item {
        .nav-link {
          position: relative;
          border: 0;
          text-align: center;
          opacity: 1;
          color: #2B2A35;
          background: #FFFFFF;
          padding: 1.25rem 0.5rem 3.5rem;
          overflow: hidden;
          img.icon {
            display: block;
            object-fit: contain;
            object-position: 50% 50%;
            margin: 0 auto 1rem;
            filter: grayscale(100%); }
          span {
            display: block;
            color: #232933; }
          &.active {
            img.icon {
              filter: grayscale(0); }
            span {
              @include inter-600(); }
            &::after {
              content: "";
              display: block;
              width: 82px;
              height: 82px;
              border-radius: 41px;
              background-color: #FFF6E0;
              position: absolute;
              top: calc(100% - 41px);
              left: calc(50% - 41px);
              background-image: url(../images/ic-chev-down-orange.svg);
              background-repeat: no-repeat;
              background-position: center 30%; } }
          &:hover {
            cursor: pointer; } } } }
    @include maxtablet() {
      .nav-item {
        width: 50%; } } }


  &__filter {
    background: #FFF6E0;
    padding: 64px 0;
    @include maxtablet() {
      padding: 48px 8px; } }
  &__title {
    text-align: center; }

  .tab-content {
    .tab-pane {
      .item {
        display: block;
        height: 100%;
        padding: 12px;
        &__wrapper {
          padding: 64px 0; }
        &__img {
          display: inline-block;
          width: 177px;
          height: 100%;
          vertical-align: top;
          margin-right: 30px; }
        &__box {
          display: inline-block;
          width: calc(100% - 212px);
          height: 100%;
          vertical-align: top;
          padding: 36px 0; }
        &__cat {
          font-size: 12px;
          line-height: 16px;
          margin-bottom: 4px; }
        &__title {
          @include inter-600();
          font-size: 20px;
          line-height: 28px;
          margin-bottom: 4px;
          &:hover {
            text-decoration: underline; } }
        &__action {
          opacity: 0;
          visibility: hidden; }
        &:hover {
          box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          .item__action {
            opacity: 1;
            visibility: visible; } } } }
    @include maxtablet() {
      .tab-pane {
        .item {
          border: 1px solid #D0D6DD;
          border-radius: 4px;
          margin-bottom: 12px;
          &__wrapper {
            padding: 48px 8px; }
          &__img {
            display: none; }
          &__box {
            width: 100%;
            padding: 0; }
          &__desc {
            p {
              margin-bottom: 14px; } }
          &__action {
            opacity: 1;
            visibility: visible; }
          &:last-child {
            margin-bottom: 0; } } } } } }


.ebook-detail {
  background: #F2F4F7;
  padding: 64px 20px;
  position: relative;
  &__title {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    &.hide {
      position: absolute;
      opacity: 0;
      visibility: hidden; } }
  &__subtitle {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    &.hide {
      position: absolute;
      opacity: 0;
      visibility: hidden; } }
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: '';
    display: block;
    width: 100%;
    height: 300px;
    background: linear-gradient(360deg, #EDF0F2 -28.09%, rgba(237, 240, 242, 0) 87.66%); }
  #ebookviewer {
    max-width: 868px;
    width: 100%;
    height: calc(100vh - 115px);
    overflow-x: hidden;
    overflow-y: auto;
    margin: 0 auto;
    position: relative;
    .canvas-wrapper {
      margin: 0 auto;
      max-width: 868px;
      width: 100%;
      canvas {
        width: 100%; } } }

  &__popup {
    position: absolute;
    bottom: 102px;
    left: calc(50% - 225px);
    z-index: 3;
    background: #FFF;
    border: 1px solid #F96D01;
    border-radius: 8px;
    padding: 24px;
    text-align: center;
    .popup__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px;
      @include inter-600(); }
    .popup__desc {
      margin-bottom: 24px; } }
  @include maxtablet() {
    padding: 0;
    &__popup {
      left: unset;
      bottom: 84px;
      margin-left: 13px;
      margin-right: 13px; } } }



.filter-rules-nav,
.filter-tips-nav {
  width: 100%;
  margin: 15px 0;
  padding: 0 12px;
  .page-link {
    visibility: collapse;
    opacity: 0;
    &.enable {
      visibility: visible;
      opacity: 1;
      &.active {
        color: $primary;
        @include inter-500(); } } } }
