/* ------------------------------------------------------------------------------------------------ */
/* Common */
/* ------------------------------------------------------------------------------------------------ */

*, *:after, *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

* {
  outline: none !important;
  &:hover,
  &:focus,
  &:focus-within,
  &:visited {
    outline: none !important; } }

img {
  max-width: 100%;
  height: auto; }

/* Common */


hr {
  border: 0;
  border-top: 1px solid #e2e2e2; }


.clearfix {
  display: block;
  width: 100%;
  &:before,
  &:after {
    content: " ";
    display: table; }
  &:after {
    clear: both; } }

/* ------------------------------------------------------------------------------------------------ */
/* Typography */
/* ------------------------------------------------------------------------------------------------ */

a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  outline: none !important; }


p {
  margin: 0 0 24px;
  padding: 0;
  font-weight: 400;
  strong,b {
    font-weightL bold {} }
  em,i {
    font-style: italic; }
  u {
    text-decoration: underline; } }

h1, h2, h3, h4 {
  margin: 0 0 10px;
  line-height: normal;
  font-weight: 500; }

h1 {
  @include inter-700;
  font-size: 48px;
  line-height: 56px;
  color: $black;
  margin: 0 0 24px;
  @include mobile {
    font-size: 2rem;
    line-height: 2.5rem; } }

h2 {
  @include inter-600;
  font-size: 32px;
  line-height: 48px;
  color: $black;
  margin: 0 0 24px;
  @include mobile {
    font-size: 28px;
    line-height: 36px; } }

h3 {
  @include inter-600;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 24px;
  @include mobile {
    font-size: 1.5rem;
    line-height: 2rem; } }

h4 {
  @include inter-600;
  font-size: 20px;
  line-height: 32px;
  color: $black;
  margin: 0 0 24px;
  @include mobile {
    font-size: 1.25rem;
    line-height: 2rem; } }

article {
  h1,h2,h3,h4 {
    margin-bottom: 20px; }

  .indent p {
    margin: 0;
    + p {
      text-indent: 2em; } }
  p {
    @extend .clearfix;
    word-break: break-word;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    margin-bottom: 30px;
    &:last-child {
      margin-bottom: 0; } }
  a {
    &:visited {
      text-decoration: underline; }
    &:hover {
      text-decoration: none; } }
  blockquote {
    font-style: italic;
    &:before {
      content: "\201C";
      display: inline-block;
      padding-right: 0.4em; } }
  li {
    ul, ol {
      //margin: 0 1.5em
      padding-left: 20px; } }
  ul, ol {
    margin: 0;
    margin-bottom: 20px;
    padding-left: 20px; }
  ul {
    list-style-type: disc; }
  ol {
    list-style-type: decimal;
    ol {
      //list-style: upper-alpha
      ol {
        //list-style: lower-roman
        ol {
 } } } }          //list-style: lower-alpha
  li {
    margin-bottom: 10px; } }


ul {
  padding: 0;
  margin: 0; }
.list-inline > li {
  padding: 0; }

label, .label {
  font-weight: normal; }


.text-regular {
  font-weight: 400;
  // font-family: "Helvetica Neue", sans-serif;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI", Roboto, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"; }



/* ------------------------------------------------------------------------------------------------ */
/* Bootstrap */
/* ------------------------------------------------------------------------------------------------ */
.form-control:hover,
.form-control:focus {
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -moz-transition: none;
  -webkit-transition: none;
  border-color: #e2e2e2; }

html, body {
  @extend .text-regular;
  font-size: 16px;
  line-height: 24px;
  @include inter-400();
  // font-weight: 400
  background: #FFF;
  margin: 0px;
  color: $secondary;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  @include maxtablet; }

hr {
  margin: 20px 15px;
  @include mobile() {
    margin: 10px 0; } }


@include mobile {
  .hidden-xs {
    display: none !important; }
  // [class*="pt-"]
  //   padding-top: 60px
  // [class*="pb-"]
  //   padding-bottom: 60px
  // [class*="ptd-"]
  //   padding: 50px 0
  // [class*="mt-"]
  //   margin-top: 30px
  // [class*="mb-"]
 }  //   margin-bottom: 30px


@include responsive(1200px) {
  .visible-lg {
    display: none !important; } }

@include responsive(0,1200px) {
  .hidden-lg {
    display: none !important; } }

@include mintablet {
  .visible-xs {
    display: none !important; } }


