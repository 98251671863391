
.mastheadrvmp--ptrdetail {
  text-align: center;
  background: #F2F4F7;
  .mastheadrvmp__desc {
    margin-bottom: 0; }
  .mastheadrvmp__wrap {
    padding-top: 64px;
    padding-bottom: 64px; } }

.ptrdetail-integrator {
  .row {
    margin-left: -12px;
    margin-right: -12px;
    &> [class*="col-"] {
      padding-left: 12px;
      padding-right: 12px; } }
  .item {
    margin-bottom: 24px;
    &__wrap {
      border: 1px solid #E3E3E8;
      border-radius: 4px;
      padding: 24px; }
    &__icon {
      margin-bottom: 16px;
      img {
        display: block;
        height: 56px;
        width: auto; } }
    &__title {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 12px; }
    &__desc {
      p {
        &:last-child {
          margin-bottom: 0; } } } }
  &__filter {
    padding-top: 48px;
    padding-right: 12px; }
  &__sort {
    display: flex;
    margin-bottom: 24px;
    justify-content: space-between;
    align-items: center;
    .sort-select {
      display: inline-block;
      width: 130px;
      div.dropdown.bootstrap-select:not(.select-btn) .dropdown-toggle {
        border: 0;
        color: #005FBF;
        &:after {
          background: none;
          top: calc(50% - 3px);
          width: 0;
          height: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 6px solid #005FBF; } }
      .dropdown-item.active, .dropdown-item:active {
        color: #2B2A35; } } }
  &__wrap {
    padding-top: 52px;
    padding-bottom: 52px; }

  @include maxtablet() {
    .item {
      &__icon {
        position: absolute;
        left: 16px;
        img {
          height: 48px;
          width: 48px; } }
      &__wrap {
        position: relative;
        padding: 16px 16px 16px 80px; } }
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__filter {
      padding-top: 0;
      padding-right: 0; }
    &__sort {
      flex-wrap: wrap;
      padding-top: 10px;
      .sort-left,
      .sort-right {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 5px 0; }
      .sort-left {
        order: 2; }
      .sort-right {
        order: 1; } }
    &__wrap {
      padding: 48px 24px; } } }

