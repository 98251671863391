.header--shadow {
  @include maxtablet {
    .header__left {
      top: 0; } } }

main.shadow {
  @include maxtablet {
    margin-top: 75px; } }

.mastheadrvmp--shadow {
  @include inter-400;
  .mastheadrvmp__sub {
    color: #626B79;
    margin-bottom: 8px; }
  .mastheadrvmp__title {
    @include inter-700;
    margin-bottom: 24px; }
  .mastheadrvmp__desc {
    margin-bottom: 32px; }
  .mastheadrvmp__media {
    .media {
      display: block;
      position: relative;
      width: 100%;
      &--video {
        padding-top: 83.333333%;
        position: relative; }
      &--img {
        // padding-left: 40px
        img {
          display: block;
          width: 100%;
          height: auto; } }
      &__cover {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover; }
      &__play {
        display: block;
        width: 80px;
        height: 80px;
        border-radius: 40px;
        background: url(../images/ic-play-circle-alt.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px);
        @include pulse(); } } }
  @include maxtablet {
    //text-align: left
    .mastheadrvmp__title {
      br {
        display: none; } } } }

.benefits--shadow {
  @include inter-400;
  padding-top: 80px;
  padding-bottom: 80px;
  background: #FCFCFC;
  .section-head {
    text-align: center;
    margin-bottom: 80px;
    &__sub {
      @include inter-600;
      color: #626B79;
      margin-bottom: 12px;
      font-size: 12px;
      line-height: 20px; }
    &__title {
      @include inter-600;
      font-size: 32px;
      line-height: 40px; } }
  .item {
    &__icon {
      height: 40px;
      width: auto;
      margin-bottom: 12px; }
    &__title {
      @include inter-600;
      margin-bottom: 12px; } }
  @include maxtablet {
    padding-top: 64px;
    padding-bottom: 64px;
    .section-head {
      margin-bottom: 46px;
      &__title {
        font-size: 24px;
        line-height: 32px;
        br {
          display: none; } } }
    .item {
      margin-bottom: 36px; } } }

.features--shadow {
  .promo-benefit {
    padding: 64px 0 80px 0;
    &__content {
      padding-right: 60px; }
    &__title {
      @include inter-600;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px; }
    &__action {
      margin-top: 30px;
      &-title {
        @include inter-600;
        margin-bottom: 16px; }
      a {
        margin-right: 16px;
        &:last-child {
          margin-right: 0; } } }
    &__img {
      img {
        object-fit: contain; }
      .video {
        video {
          width: 100%;
          height: auto;
          pointer-events: none; } } }
    @include maxtablet {
      padding: 24px 0;
      &__wrap {
        color: #2B2A35;
        .row {
          flex-direction: column-reverse; } }
      &__content {
        padding-right: 0;
        .custom-list {
          margin-bottom: 36px; } }
      &__title {
        font-size: 26px;
        line-height: 32px;
        text-align: center; }
      &__action {
        a {
          width: 100%;
          display: block;
          margin-bottom: 16px;
          margin-right: 0;
          &:last-child {
            margin-bottom: 0; } } }
      &__img {
        margin-bottom: 30px;
        img {
          height: auto; } } } }

  .block-cta-alt {
    text-align: center;
    position: relative;
    .container {
      padding-top: 80px;
      padding-bottom: 80px; }

    &__icon {
      margin-bottom: 8px;
      img {
        display: block;
        height: 72px;
        width: auto;
        margin: 0 auto; } }
    &__title {
      @include inter-600;
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 20px;
      &.small {
        font-size: 24px;
        line-height: 32px; } }
    &__desc {
      max-width: 600px;
      margin: 0 auto 32px; }
    hr.separator {
      max-width: 1224px;
      margin: 0 auto; }
    &--trial {
      background-color: #002A38;
      background-image: url('../images/pattern-cta.svg');
      color: #FFF;
      .block-cta-alt__title {
        color: #FFF; }
      .block-cta-alt__desc {
        color: #FFF; } }
    &--simple {
      .container {
        padding-top: 0; }
      .block-cta-alt__buttons {
        padding-top: 20px;
        a {
          margin-right: 16px;
          margin-bottom: 0;
          &:last-child {
            margin-right: 0; } } }
      .block-cta-alt__title {
        position: relative;
        padding-top: 32px;
        &::before {
          content: '';
          display: inline-block;
          width: 48px;
          height: 6px;
          background: $button;
          top: 0;
          left: calc(50% - 24px);
          position: absolute; } } }

    &.bg-snow {
      background: #F2F4F7; }
    @include maxtablet() {
      .container {
        padding-top: 64px;
        padding-bottom: 64px;
        padding-left: 12px;
        padding-right: 12px; }
      &--simple {
        .block-cta-alt__buttons {
          a {
            margin-right: 0;
            margin-bottom: 16px;
            width: 100%;
            margin-bottom: 14px;
            &:last-child {
              margin-right: 0;
              margin-bottom: 16px; } } } } } }

  .section-tabs {
    margin-bottom: 80px;
    &__header {
      .nav-tabs {
        background: #F2F4F7;
        border-radius: 100px;
        display: inline-flex;
        padding: 4px;
        overflow: hidden;
        .nav-item {
          cursor: pointer;
          border: 0;
          .nav-link {
            padding: 8px 20px!important;
            height: auto;
            position: relative;
            border: 0;
            cursor: pointer;
            background: transparent;
            display: inline-block;
            .nav-item__title {
              label {
                @include inter-600; } }
            &.active {
              background: $blue;
              border-radius: 100px;
              box-shadow: none;
              .nav-item__title {
                label {
                  color: #FFF;
                  margin-bottom: 0; } }
              &:hover {
                background: $blue;
                box-shadow: none;
                .nav-item__title {
                  label {
                    color: #FFF; } } } }
            &.alt {
              &.active {
                background: #FFF;
                color: $button;
                border-radius: 100px;
                box-shadow: none;
                border: 1px solid $button;
                .nav-item__title {
                  label {
                    color: $button;
                    margin-bottom: 0; } }
                &:hover {
                  background: #FFF;
                  color: #e1eefc;
                  border-radius: 100px;
                  box-shadow: none;
                  border: 1px solid #005FBF;
                  .nav-item__title {
                    label {
                      color: #005FBF;
                      margin-bottom: 0; } } } } }
            &:hover {
              background: transparent;
              cursor: pointer;
              box-shadow: none;
              .nav-item__title {
                label {
                  color: #232933; } } }
            .nav-item__title {
              margin-bottom: 0;
              cursor: pointer;
              @include inter-600;
              label {
                color: #9090A2;
                margin-bottom: 0; }
              cursor: pointer;
              @include inter-600; } } } } }
    &__body {
      padding-top: 40px; }
    @include maxtablet {
      margin-bottom: 64px;
      &__header {
        position: relative;
        text-align: center;
        .nav-tabs {
          position: relative;
          height: auto;
          top: inherit;
          left: inherit;
          background: #FFF; } } } }

  .accordion--section {
    @include inter-400;
    .card {
      padding-top: 80px;
      border: 0;
      &>.container {
        border-bottom: 1px solid #D0D6DD; }
      &:last-child {
        &>.container {
          border-bottom: 0; } }
      &:nth-child(even) {
        .promo-benefit__wrap {
          .row {
            flex-direction: row-reverse; } } }

      .card__header {
        border: 0;
        text-align: center;
        .card__title {
          @include inter-600;
          font-size: 32px;
          line-height: 40px;
          margin-bottom: 16px; }
        .card__desc {
          margin-bottom: 32px; }
        &::after {
          display: none; } }
      .collapse {
        display: block; } }
    .testimonial-small__wrap {
      padding-top: 0;
      padding-bottom: 48px; }
    .testimonial-small {
      .item__box {
        background: #F8F9FA;
        color: #232933; } } }
  &--alt {
    .promo-benefit__img {
      img {
        display: block;
        max-width: 100%;
        width: auto;
        height: auto;
        margin: 0 auto; } }
    .features-accr {
      &__head {
        padding-top: 80px;
        padding-bottom: 24px;
        text-align: center; } }
    .section-tabs__body {
      padding-top: 0;
      padding-bottom: 40px; }
    // .accordion--section .card:nth-child(even)
    //   background: #FFF
    .accordion--section .card:nth-child(1) {
      padding-top: 30px; } }

  @include maxtablet {
    .accordion--section {
      padding-left: 0;
      padding-right: 0;

      .card {
        padding-top: 40px;
        padding-bottom: 14px;
        .card__header {
          position: relative;
          .card__title {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 24px; }
          .card__toggle {
            margin-bottom: 40px;
            .link-show {
              display: show; }
            .link-close {
              display: none; } }
          .card__desc {
            display: none; } }
        .collapse {
          display: none;
          .section-tabs {
            ul.nav-tabs {
              background: #F2F4F7; }
            .tab-content {
              padding-left: 12px;
              padding-right: 12px; } }
          .promo-benefit__wrap {
            margin-bottom: 30px; }
          .promo-benefit__title {
            font-size: 24px;
            line-height: 32px;
            text-align: left;
            br {
              display: none; } }
          .testimonial-small__wrap {
            padding: 15px 0 60px; }
          .block-cta-alt {
            .container {
              padding-top: 0;
              padding-bottom: 30px;
              .block-cta-alt__title {
                &.small {
                  font-size: 24px;
                  line-height: 32px; } } } }
          .promo-benefit__wrap {
            .row {
              flex-direction: row-reverse; } }
          .testimonial-small {
            .item__img {
              position: absolute;
              width: 208px;
              height: 208px;
              left: calc(50% - 104px);
              top: 0; }
            .item__box {
              padding: 140px 24px 24px 24px;
              margin-top: 104px; } } }
        &.open {
          .card__header {
            .card__desc {
              display: block; }
            .card__toggle {
              .link-show {
                display: none; }
              .link-close {
                display: block; } } }
          .collapse {
            display: block!important; } }
        &>.container {
          padding-left: 0;
          padding-right: 0; } } }
    &--alt {
      .promo-benefit__img {
        display: block;
        img {
          display: block;
          max-width: 80%;
          height: auto;
          margin: 0 auto; }
        .video {
          max-width: 80%;
          height: auto;
          margin: 0 auto; } }
      .block-cta-alt .container {
        padding-top: 0; } } } }


.table-features {
  @include inter-400;
  padding-top: 80px;
  padding-bottom: 80px;
  .section-head {
    text-align: center;
    margin-bottom: 56px; }
  .item {
    &__wrapper {
      background: #FFFFFF;
      box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      padding-top: 14px;
      padding-bottom: 14px; }
    &__head {
      background: #626B79;
      padding: 32px; }
    &__title {
      @include inter-600;
      font-size: 24px;
      line-height: 32px;
      color: #FFF; }
    &__body {
      background: #F6F7F9;
      padding: 28px; } }
  @include maxtablet {
    padding-top: 64px;
    padding-bottom: 64px;
    .item {
      margin-bottom: 18px;
      &__wrapper {
        box-shadow: none; }
      &__title {} } } }



.faq-block.alt {
  @include inter-400;
  .section-head {
    &__title {
      @include inter-600; } }
  .card.open {
    border-color: $button; } }

.shadow-cta {
  @include inter-400;
  background: #EDF0F2;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: center;
  &__icon {
    margin-bottom: 24px; }
  &__title {
    @include inter-600;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 40px; }
  @include maxtablet {
    padding-top: 64px;
    padding-bottom: 64px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      br {
        display: none; } }
    a.btn {
      width: 100%;
      margin-bottom: 14px; } } }


.block-table--shadow {
  @include inter-400;
  font-size: 16px;
  line-height: 24px;
  .block-table {
    &__sub {
      font-size: 14px;
      line-height: 20px;
      @include inter-600;
      margin-bottom: 12px; }
    &__title {
      @include inter-600;
      font-size: 32px;
      line-height: 40px;
      text-align: center; }
    &__action {
      margin-bottom: 22px;
      a {
        margin-right: 14px;
        &:last-child {
          margin-right: 0; } } } }
  .item {
    &__title {
      @include inter-600; } }
  @include maxtablet {
    .block-table {
      &__action {
        a {
          margin-right: 0;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0; } } } } } }

.form-contact--shadow {
  @include inter-400;
  padding-top: 80px;
  padding-bottom: 80px;
  .form-contact {
    &__title {
      @include inter-500;
      margin-bottom: 18px!important; }
    &__desc {
      text-align: center;
      margin-bottom: 40px; } } }
