/* Privacy */
.privacy-page {
  padding-top: 64px;
  padding-bottom: 64px;
  margin-top: 58px;
  .sidebar-menu {
    .item {
      position: relative;
      border-bottom: 1px solid $border;
      &__link {
        display: block;
        color: $text;
        padding: 12px 32px 12px 0;
        &.active {
          color: $blue;
          background: #FFF;
          @include inter-500();
          &+ .sub-item {
            display: block; } }
        &:hover {
          color: $blue;
          text-decoration: none; } }
      &:first-child {
        border-top: 1px solid $border; } }
    .sub-item {
      padding: 4px 0;
      display: none;
      &__link {
        display: block;
        position: relative;
        color: $text;
        padding: 0 32px;
        margin-bottom: 20px;
        &:after {
          content: "";
          display: none;
          position: absolute;
          width: 20px;
          height: 20px;
          right: 0;
          top: 2px;
          background: url(../images/ic-caret-right.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: contain; }
        &.active {
          color: $blue;
          @include inter-500();
          &::after {
            display: block; } }
        &:hover {
          color: $blue;
          text-decoration: none; } } } }

  &__article {
    padding-left: 24px;
    article {
      h1 {
        display: block;
        font-size: 40px;
        line-height: 56px;
        margin-bottom: 24px; }
      h2 {
        display: block;
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px; }
      h3 {
        display: block;
        font-size: 18px;
        line-height: 27px;
        margin-bottom: 8px; }
      p {
        color: #2B2A35;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } } } }

  @include maxtablet() {
    padding-top: 32px;
    padding-bottom: 32px;
    margin-top: 30px;
    &__sidebar {
      margin-bottom: 32px;
      margin-top: 32px;
      max-width: 100%;
      flex: 100%; }
    &__article {
      padding-left: 0;
      article {
        h1 {
          font-size: 32px;
          line-height: 40px; } } } } }

