.card-list {
  padding: 64px 0;
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    text-align: center; }
  .card {
    border: 1px solid #D0D6DD;
    border-radius: 4px;
    overflow: hidden;
    &__head {
      padding: 24px;
      text-align: center;
      border-bottom: 1px solid #D0D6DD; }
    &__icon {
      width: 52px;
      height: 52px;
      object-fit: contain;
      margin: 0 auto 8px auto; }
    &__title {
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0; }
    &__body {
      padding: 32px 24px; } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px; }
    .card {
      &__head {
        display: flex;
        padding: 12px 10px;
        align-items: center;
        justify-content: center; }
      &__icon {
        margin: 0; }
      &__title {
        text-align: left; }
      &__body {
        padding: 12px; }
      &__wrapper {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } } } } }


.pricing-main--integration {
  padding: 64px 0;

  .pricing-main__title {
    @include inter-600();
    text-align: center;
    margin-bottom: 24px;
    br {
      display: none; } }
  .item {
    &__head {
      p {
        min-height: 60px; }
      .price {
        min-height: unset;
        .badge.discount {
          font-size: 14px;
          line-height: 20px;
          @include inter-400();
          border: 1px solid #4B61DD;
          color: #4B61DD;
          border-radius: 24px;
          background: #FFF;
          margin-left: 8px;
          padding: 4px 12px; } } }
    &.is-highlight {
      .item__head {
        background: #EDFAFF;
        border-color: #009BDE; }
      .item__main {
        border-color: #009BDE; } }
    &:hover {
      .item__wrap {
        box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04); } } }

  @include maxtablet() {
    .pricing-main__wrap {
      padding-left: 0;
      padding-right: 0;
      .item {
        height: 100%; } }
    .pricing-main__title {
      padding: 0 15px; } } }

.tab-integration {
  padding: 64px 0;
  &__title {
    text-align: center;
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px; }
  &__list {
    text-align: center;
    display: block;
    width: 100%;
    .nav-item {
      display: inline-block;
      .nav-link {
        padding: 24px 56px;
        border-top: 0;
        border-left: 0;
        border-right: 0;
        border-bottom: 2px solid trnasparent;
        img {
          height: 40px;
          width: auto;
          object-fit: contain;
          filter: grayscale(1); }
        &.active,
        &:hover {
          border-bottom: 2px solid #0031BE;
          img {
            filter: grayscale(0); } } } } }
  .item {
    &__wrap {
      padding: 24px 0 32px 0;
      border-bottom: 1px solid #EDF0F2;
      &:last-child {
        border-bottom: 0;
        padding: 24px 0 0 0; } }
    &__title {
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 24px; }
    &__icon {
      margin-bottom: 24px; }
    &__desc {
      margin-bottom: 24px;
      p {
        margin-bottom: 24px; } }
    &__list {
      margin-bottom: 24px;
      .list {
        display: flex;
        align-items: start;
        margin-bottom: 16px;
        .icon {
          margin-right: 16px;
          width: 24px;
          height: 24px;
          object-fit: contain; }
        .title {
          font-size: 16px;
          line-height: 24px;
          @include inter-600();
          margin-bottom: 0; }
        .desc {
          p {
            margin-bottom: 0; } } } }
    &__clients {
      overflow: hidden;
      mask-image: linear-gradient(to right,hsla(0, 0%, 0%, 0),hsla(0, 0%, 0%, 1) 20%,hsla(0, 0%, 0%, 1) 80%,hsla(0, 0%, 0%, 0));
      .marquee-container {
        height: auto;
        .our-customer__slider {
          margin-bottom: 24px;
          .item {
            img {
              filter: grayscale(1); }
            &:hover {
              img {
                filter: grayscale(0); } } } } } } }
  .tab-container {
    .tab-pane {
      &.fade {
        visibility: hidden;
        height: 0;
        &.active,
        &.show {
          visibility: visible;
          height: auto; } } } }
  @include maxtablet() {
    padding: 32px 10px;
    &__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } }
    &__accr {
      padding: 12px 0;
      border-bottom: 2px solid #D0D6DD;
      position: relative;
      img {
        height: 40px;
        width: auto;
        object-fit: contain;
        filter: grayscale(1); }
      &:after {
        content: '';
        display: inline-block;
        width: 24px;
        height: 24px;
        background: url(../images/ic-caret-down.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 20px; } }
    .tab-container {
      .tab-pane {
        &.fade {
          visibility: visible;
          height: auto;
          opacity: 1;
          .tab-integration__content {
            visibility: hidden;
            opacity: 0;
            height: 0; }
          &.active,
          &.show {
            .tab-integration__accr {
              img {
                filter: grayscale(0);
                border-bottom-color: #0031BE; }
              &::after {
                transform: rotate(180deg); } }
            .tab-integration__content {
              visibility: visible;
              opacity: 1;
              height: auto; }
            .item__wrap {
              margin-bottom: 24px; } } } } } } }
