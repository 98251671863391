.wrap.in-app {

  .masthead,
  .section-masthead {
    &__wrap {
      padding-top: 40px;
      padding-bottom: 40px; }
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 8px;
      font-weight: 600; }
    &__footer {
      padding-top: 24px;
      .title {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        color: #626B79;
        margin: 0 0 8px; }
      .logos {
        margin-left: -8px;
        margin-right: -8px; }
      .logo {
        display: inline-block;
        vertical-align: top;
        padding-left: 8px;
        padding-right: 8px;
        img {
          display: block;
          max-height: 56px;
          width: auto;
          height: auto; } } }
    &.bg-gray {
      background: #EDF0F2; }

    @include maxtablet() {
      &__action {
        padding-top: 24px; }
      &__media {
        padding-top: 40px; } } }

  .accr-steps {
    padding-top: 40px;
    padding-bottom: 40px; }

  .block-banner {
    &__title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin: 0 0 8px; }
    &__wrap {
      padding-top: 40px;
      padding-bottom: 40px; } } }
