.masthead--hero {
  background: #FFF;
  padding: 64px 0;
  .masthead__left {
    padding-right: 50px; }
  .masthead__meta,
  .masthead__subtitle {
    @include inter-600();
    font-size: 16px;
    line-height: 24px;
    &.orange {
      color: #F96D01; } }
  .masthead__title {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 16px; }
  .masthead__desc {
    margin-bottom: 24px;
    @include inter-400();
    p {
      font-size: 16px;
      line-height: 24px; } }
  .masthead__action {
    a {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  @include maxtablet() {
    padding: 32px 0;
    .masthead__left {
      padding-right: 12px; }
    .masthead__title {
      font-size: 36px;
      line-height: 44px; }
    .masthead__action {
      &.show-sm {
        margin-top: 18px; }
      a {
        display: block;
        width: 100%;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0; } } }
    .masthead__desc {
      margin-top: 18px;
      margin-bottom: 0;
      .custom-list {
        li {
          &:last-child {
            margin-bottom: 0; } } } } } }

.items-block {
  &--alt {
    padding: 64px 0;
    .items-block__title {
      margin-bottom: 24px;
      text-align: center; }
    .items-block__wrap {
      margin-bottom: 32px;
      display: flex;
      justify-content: center;
      align-items: start;
      .item {
        width: 236px;
        margin: 0 26px;
        &__icon {
          height: 236px;
          width: 236px;
          margin-bottom: 12px;
          img {
            width: auto;
            height: 100%; } }
        &__title {
          text-align: center;
          display: block; }
        &__desc {
          text-align: center;
          margin-bottom: 24px; }
        &__action {
          text-align: center; } } }
    .items-block__action {
      text-align: center;
      a {
        margin-right: 14px;
        &:last-child {
          margin-right: 0; } } } }
  &--home {
    .items-block__wrap {
      .item {
        padding: 0;
        border: none;
        &__title {
          @include inter-400();
          font-size: 16px;
          line-height: 24px; } } } }
  @include maxtablet() {
    &--alt {
      padding: 48px 0;
      .items-block__wrap {
        .item {
          margin: 0 auto; } }
      .items-block__title {
        font-size: 28px;
        line-height: 36px; }
      .items-block__action {
        text-align: center;
        a {
          display: block;
          width: 100%;
          margin-bottom: 14px;
          &.link-more {
            display: inline-block;
            width: auto; }
          &:last-child {
            margin-bottom: 0; } } } } } }

.tab-feature {
  padding: 64px 0;
  background: #F2F4F7;
  &__head {
    text-align: center;
    margin-bottom: 24px; }
  &__nav {
    display: flex;
    align-items: stretch;
    justify-content: space-evenly;
    padding: 0;
    margin-bottom: 27px;
    .nav-item {
      .nav-link {
        padding: 12px;
        margin: 0 6px;
        color: #626B79;
        display: flex;
        font-size: 20px;
        line-height: 28px;
        border-width: 0;
        border-bottom: 1px solid transparent;
        @include inter-600();
        &:hover,
        &:focus,
        &:active,
        &.active {
          color: #232933;
          background: transparent;
          border-width: 0;
          border-bottom: 1px solid #F96D00; } } } }
  &__toggle {
    display: none; }
  &__meta {
    .tab-feature__img {
      display: none; }
    .tab-feature__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
      @include inter-600(); } }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 24px; }
  &__action {
    display: flex;
    align-items: center;
    a {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  @include maxtablet() {
    padding: 48px 0;
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__content {
      .row {
        flex-direction: column-reverse; } }
    &__img {
      margin-bottom: 24px;
      display: none; }
    &__nav {
      display: none; }
    &__meta {
      .tab-feature__title {
        display: none; } }
    &__toggle {
      display: block;
      position: relative;
      &::after {
        content: '';
        width: 24px;
        height: 24px;
        position: absolute;
        right: 10px;
        top: 15px;
        background: url('../images/ic-caret-down.svg') no-repeat center;
        background-size: contain;
        transform: rotateZ(0deg);
        transition: all linear .3s; }
      .tab-feature__title {
        padding: 12px 40px 12px 0;
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 0;
        color: #626B79;
        @include inter-600(); } }
    &__meta {
      margin-top: 12px;
      height: 0;
      opacity: 0;
      visibility: collapse;
      transition: all linear .3s; }
    .tab-pane {
      display: block;
      opacity: 1;
      visibility: visible;
      &.active.show {
        .tab-feature__toggle {
          &::after {
            transform: rotateZ(180deg);
            transition: all linear .3s; }
          .tab-feature__title {
            color: #232933;
            border-bottom: 1px solid #F96D00; } }
        .tab-feature__meta {
          height: 100%;
          opacity: 1;
          visibility: visible;
          transition: all linear .3s;
          .tab-feature__img {
            display: block;
            margin: 16px auto; } } } } } }

.customer-story {
  padding: 64px 0;
  &__title {
    text-align: center; }
  &__content {
    box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.75);
    border-radius: 6px;
    overflow: hidden;
    .col-md-7 {
      padding-right: 0; }
    .col-md-5 {
      padding-left: 0; } }
  &__testimonial {
    background: #F96D01;
    padding: 44px 32px;
    height: 100%;
    color: #FFF; }
  &__quote {
    margin: 0 0 16px 0;
    font-size: 20px;
    line-height: 28px;
    @include inter-600(); }
  &__info {
    display: flex;
    align-items: center;
    width: 100%;
    &-left,
    &-right {
      width: 50%; }
    &-right {
      text-align: right; } }
  &__img {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 16px;
    vertical-align: middle;
    overflow: hidden;
    img {
      height: 48px;
      width: auto;
      border-radius: 50%; } }
  &__contact {
    display: inline-block;
    width: calc(100% - 69px);
    vertical-align: middle; }
  &__name {
    @include inter-600(); }
  &__logo {
    display: inline-block;
    vertical-align: middle;
    img {
      height: 48px;
      width: auto; } }
  &__content {
    display: inline-flex; }
  &__stats {
    background: #EDF0F2;
    height: 100%;
    color: #232933;
    padding: 32px 24px; }
  &__items {
    display: flex;
    align-items: stretch;
    .item {
      &__label {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px;
        display: block;
        @include inter-500(); }
      &__number {
        font-size: 32px;
        line-height: 48px;
        display: block;
        color: #F96D01;
        @include inter-600(); }
      &__desc {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 26px;
        display: block;
        @include inter-500(); } } }
  @include maxtablet() {
    padding: 48px 0;
    &__nav {
      overflow-x: auto;
      overflow-y: hidden; }
    &__content {
      .col-md-7 {
        padding-right: 12px; }
      .col-md-5 {
        padding-left: 12px; } }
    &__quote {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 24px; }
    &__info {
      display: block;
      &-left,
      &-right {
        width: 100%;
        text-align: left; }
      &-left {
        margin-bottom: 16px; } }
    &__stats,
    &__items {
      display: block;
      .item {
        margin-bottom: 32px;
        &:last-child {
          margin-bottom: 0; } } } } }

.faq-block--alt {
  background: #F2F4F7;
  padding: 64px 0;
  .card {
    &__body {
      ul {
        padding-left: 20px;
        list-style: disc;
        li {
          margin-bottom: 4px; } }
      ol {
        padding-left: 20px;
        list-style: decimal;
        li {
          margin-bottom: 4px; } } } }

  @include maxtablet() {
    padding: 48px 0; } }


.statistic {
  padding: 60px 0;
  .container {
    position: relative; }
  .owl-dots {
    text-align: left; }
  &__item {
    &> p {
      margin: 0 0 20px;
      font-size: 20px;
      line-height: 28px;
      @include inter-600(); } }
  &_author {
    p {
      margin: 0 0 4px;
      font-size: 16px;
      line-height: 24px;
      @include inter-400(); }
    img {
      width: auto!important;
      max-height: 45px; } }
  &__stat {
    min-height: 104px;
    padding-left: 152px;
    position: relative;
    p {
      font-size: 21px; }
    .radial-progress {
      position: absolute;
      top: 0;
      left: 0;
      background: #FBF3DD;
      .circle {
        .mask {
          .fill {
            background-color: #F96D01; } } }
      &.green {
        background: #4FB262;
        .circle {
          .mask {
            .fill {
              background-color: #139908; } } } }
      &.blue {
        background: #B4D3F2;
        .circle {
          .mask {
            .fill {
              background-color: #009BDE; } } } }
      .inset .percentage .numbers span {
        @include inter-600(); } }
    &:not(:last-child) {
      margin: 0 0 48px; } }
  &__action {
    a {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  &__slider {
    margin-bottom: 16px; }
  @include maxtablet() {
    padding: 48px 0;
    &__action {
      margin-bottom: 70px;
      a {
        display: block;
        width: 100%;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0; } } }
    &__stat {
      p {
        font-size: 14px;
        line-height: 23px; } } } }


.item-box {
  margin: 32px 0;
  background: #FFF;
  box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  &.alt {
    margin: 64px 0 32px 0; }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 16px;
    @include inter-600(); }
  &__action {
    .link-more {
      color: #4B61DD!important; } }
  &__img {
    text-align: center;
    img {
      margin: 0 auto;
      height: 100%;
      width: auto;
      object-fit: contain; } }
  .col-md-7 {
    padding: 36px 48px; }
  @include maxtablet() {
    .row {
      flex-direction: column-reverse; }
    &__img {
      margin-bottom: 16px; }
    &__title {
      font-size: 28px;
      line-height: 36px; }
    .col-md-7 {
      padding: 0 40px 40px; } } }

