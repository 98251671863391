@import 'cores/mixin';
// @import url('https://fonts.googleapis.com/css?family=Fira+Mono|Fira+Sans');


// Color
$primary    : #F58232;
$secondary  : #232933;
$red        : #ed3626;
$border     : #DFDFDF;
$link       : #4A26C4;
$blue       : #4B61DD;
$black      : #2B2A35;
$button     : #4B61DD;
$text       : #2B2A35;
$text-light : #545465;
$text-dark  : #2B2A35;

/* ------------------------------------------------------------------------------------------------ */
/* Core style */
/* ------------------------------------------------------------------------------------------------ */

@import 'cores/fonts';
@import 'cores/base';


/* ------------------------------------------------------------------------------------------------ */
/* Component style */
/* ------------------------------------------------------------------------------------------------ */

@import 'components/animation';
@import 'components/general';
@import 'components/header-revamp';
@import 'components/form';
@import 'components/footer';
@import 'components/content';
@import 'components/modal';
@import 'cores/layout';

/* Pages style */
@import 'pages/pages-api';
@import 'pages/pages-partner';
@import 'pages/pages-partner-detail';
@import 'pages/inapp-jurnal';
@import 'pages/pages-solution';
@import 'pages/homepage';
@import 'pages/pricing';
@import 'pages/shadow';
@import 'pages/home';
@import 'pages/event-webinar';
@import 'pages/ebook';
@import 'pages/referral';
@import 'pages/klikpajak-api';
@import 'pages/form-embeded';
@import 'pages/integrasi';
@import 'pages/why';
@import 'pages/client';
@import 'pages/pages-casestudy';
@import 'pages/calendar';
@import 'pages/update-pajak';
@import 'pages/kantor-pajak';
@import 'pages/pages-npwp-val';
@import 'pages/form-only';
@import 'pages/privacy-policy';
@import 'pages/404';
@import 'pages/blog';
@import 'pages/microsite';

/* Sections style */
@import 'sections/section-masthead';
@import 'sections/section-value';
@import 'sections/section-contents';
@import 'sections/section-testimonial';
@import 'sections/section-tab';
@import 'sections/section-contact-headquarter';
@import 'sections/items-block';
@import 'sections/accr-steps';
@import 'sections/block-table';
@import 'sections/block-middle';
@import 'sections/banner-block';
@import 'sections/value-props';
@import 'sections/compliance';
@import 'sections/top-banner';
@import 'sections/propose';
@import 'sections/insight';
@import 'sections/t-promo';
@import 'sections/block-banner';
@import 'sections/style-inapp';
@import 'sections/sitemap-kp';



