.blog__header {
  font-size: 14px;
  line-height: 20px;
  margin-top: 111px;
  .container {
    position: relative;
    padding-top: 64px;
    padding-bottom: 40px;
    &::after {
      content: '';
      width: calc(100% - 24px);
      height: 1px;
      left: 12px;
      bottom: 0;
      background: #D0D6DE;
      position: absolute; } }
  .blog__meta {
    display: flex;
    margin-bottom: 4px;
    .expert-mark-sm {
      display: none; }
    .blog__cat {
      a {
        @include inter-600();
        color: #232933;
        text-decoration: none;
        transition: all linear .3s;
        &:hover {
          color: darken(#232933, 30%);
          text-decoration: underline;
          transition: all linear .3s; } } }
    .blog__read {
      position: relative;
      margin-left: 20px;
      &::before {
        content: '\2022';
        color: #232933;
        height: 4px;
        width: 4px;
        position: absolute;
        left: -13px;
        top: 0; } }
    .blog__author {
      a {
        display: inline-flex;
        align-items: center;
        @include inter-600();
        color: #4B61DD;
        text-decoration: none;
        transition: all linear .3s;
        position: relative;
        &::before {
          content: '';
          display: inline-block;
          width: 20px;
          height: 20px;
          background: url(../images/ic-employee.svg) no-repeat center;
          background-size: contain;
          position: relative;
          margin-right: 4px; }
        &:hover {
          color: darken(#4B61DD, 15%);
          text-decoration: underline;
          transition: all linear .3s; } } }
    .blog__date {
      display: inline-block;
      position: relative;
      margin-left: 20px;
      &::before {
        content: '\2022';
        color: #232933;
        height: 4px;
        width: 4px;
        position: absolute;
        left: -13px;
        top: 0; } } }
  .blog__shares {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    display: none;
    &> span {
      @include inter-600();
      color: #232933;
      margin-right: 18px; }
    a {
      display: inline-flex;
      margin-right: 18px;
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
      img {
        width: 24px;
        height: 24px;
        object-fit: contain;
        filter: grayscale(.8);
        transition: all linear .3s; }
      span {
        position: absolute;
        color: transparent;
        font-size: 0;
        line-height: 0; }
      &:last-child {
        margin-right: 0; }
      &:hover {
        background: #F7FBFF;
        img {
          filter: grayscale(0);
          transition: all linear .3s; } } } }

  .blog__title {
    @include inter-700();
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 18px; }

  @include maxtablet() {
    margin-top: 145px;
    .container {
      padding-top: 34px;
      padding-bottom: 32px; }

    .blog__title {
      font-size: 32px;
      line-height: 40px; }

    .blog__meta {
      display: block;
      .expert-mark {
        display: none; }
      .expert-mark-sm {
        display: inline-block;
        margin-left: 6px;
        vertical-align: middle; }

      .blog__cat {
        display: inline-block; }

      .blog__read {
        display: inline-block;
        &::before {
          left: -15px; } }

      .blog__author {
        display: block;
        width: 100%;
        margin-bottom: 8px;
        &+ svg {
          display: none; } }

      .blog__date {
        display: block;
        width: 100%;
        margin-left: 0;
        margin-bottom: 22px;
        &::before {
          display: none; } } }

    .blog__shares {
      justify-content: flex-start;
      &> span {
        margin-right: 17px; } } } }

.blog__content {
  padding: 64px 0 40px 0;
  position: relative;

  .blog__wrap {
    .col-lg-3 {
      align-items: flex-start; } }

  .blog__sidebar {
    position: relative;
    height: auto;
    max-width: 100%;

    &.left {
      padding-right: 16px; }
    &.right {
      padding: 0 12px 0 16px; }

    &.stick-it {
      position: fixed;
      top: 105px;
      overflow-x: hidden;
      overflow-y: auto;
      transition: all linear .3s;
      height: auto;

      &.show-top {
        top: 120px!important;
        transition: all linear .3s; }

      .blog__box {
        img {
          height: 0;
          opacity: 0;
          position: absolute;
          transition: all linear .3s; } } }

    &.stick-bottom {
      position: absolute;
      bottom: 0;
      top: unset;
      height: auto;

      .blog__box {
        img {
          height: auto;
          opacity: 1;
          position: relative;
          transition: all linear .3s; } } }

    .blog__search {
      position: relative;
      margin-bottom: 24px;
      form {
        position: relative;
        input[type="text"] {
          height: 40px;
          padding: 10px 62px 10px 12px;
          font-size: 14px;
          line-height: 20px;
          border-radius: 6px; }
        button {
          background: #4B61DD;
          border-color: #4B61DD;
          font-size: 16px;
          line-height: 24px;
          padding: 5px 8px;
          @include inter-600();
          position: absolute;
          right: 2px;
          top: 2px;
          border-radius: 6px;
          transition: all linear .3s;
          &:hover {
            background: darken(#4B61DD, 10%);
            border-color: darken(#4B61DD, 10%);
            transition: all linear .3s; } } } }

    .blog__toc {
      padding-bottom: 30px;
      b {
        font-size: 16px;
        line-height: 24px;
        @include inter-600();
        color: #232933;
        margin-bottom: 24px;
        display: block; }
      ul {
        list-style: none;
        li {
          a {
            display: block;
            padding: 4px 8px 4px 0;
            border-right: 1px solid #D0D6DE;
            font-size: 14px;
            line-height: 20px;
            color: #626B79;
            text-decoration: none;
            transition: all linear .3s;
            &:hover {
              text-decoration: underline;
              transition: all linear .3s; }
            &.active {
              color: #0031BE;
              border-right-color: #0031BE; } } } } }

    .blog__box {
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 24px;
      position: relative;
      img {
        transition: all linear .3s; } }

    strong, b {
      font-size: 14px;
      line-height: 20px;
      @include inter-600();
      margin-bottom: 20px;
      display: inline-block; }

    .blog__subscribe {
      padding: 16px;
      border-top: 1px solid #E2E2E2;
      border-bottom: 1px solid #E2E2E2;
      .btn {
        display: block;
        width: 100%; } }

    .blog__sosmed {
      padding: 34px 16px 28px 16px;
      ul {
        list-style: none;
        display: inline-flex;
        li {
          width: 24px;
          margin-right: 16px;
          margin-bottom: 8px;
          a {
            display: inline-block;
            position: relative;
            img {
              width: 24px;
              height: 24px;
              max-width: 24px;
              max-height: 24px;
              object-fit: contain;
              filter: grayscale(.8); }
            span {
              position: absolute;
              font-size: 0;
              line-height: 0;
              color: transparent; }
            &:last-child {
              margin-right: 0; }
            &:hover {
              img {
                filter: grayscale(0); } } } } } } }

  @include maxtablet() {
    padding: 32px 0 0 0;

    .blog__box {
      img {
        width: 100%;
        height: auto; } }

    .blog__sidebar {
      height: auto; }

    .blog__sosmed {
      strong {
        display: block; } } }

  .blog__article {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 0.008em;

    h2 {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 24px;
      @include inter-600(); }

    h3 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
      @include inter-600(); }

    h4 {
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 16px;
      @include inter-600(); }

    b,
    strong {
      @include inter-600(); }

    p {
      margin-bottom: 24px; }

    ul {
      list-style: disc;
      &:last-child {
        margin-bottom: 24px; } }

    ol {
      list-style: decimal;
      &:last-child {
        margin-bottom: 24px; } }

    a {
      color: #4B61DD;
      text-decoration: underline;
      transition: .3s linear all;
      &:hover {
        color: darken(#4B61DD, 15%);
        text-decoration: none;
        transition: .3s linear all; }
      &.btn-primary,
      &.btn-blue {
        color: #FFF;
        &:hover {
          color: #FFF; } }
      &.btn {
        text-decoration: none; } }

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
      margin-bottom: 24px; }

    figure {
      margin-bottom: 24px;
      width: 100%!important;
      height: auto;
      img {
        margin-bottom: 0;
        border-radius: 16px; } }
    figcaption {
      margin: 10px 0;
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      color: #232933; }

    .media--video {
      margin-bottom: 24px;
      img {
        margin-bottom: 0; } }

    blockquote {
      padding: 24px 24px 24px 60px;
      background: #F1F5F9;
      border-left: 1px solid #8B95A5;
      border-radius: 0;
      display: block;
      width: 100%;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 24px;
      position: relative;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      cite {
        font-size: 12px;
        line-height: 16px;
        font-style: normal; }
      &::before {
        content: '';
        width: 16px;
        height: 24px;
        background: url('../images/ic-left-quote.webp') no-repeat center;
        background-size: contain;
        position: absolute;
        left: 20px;
        top: 25px; }
      b {
        margin-bottom: 8px;
        display: block;
        @include inter-600(); }
      p:last-child {
        margin-bottom: 0; } }

    code {
      font-size: 16px;
      line-height: 24px;
      @include inter-600();
      padding: 0 0 0 16px;
      border-left: 4px solid #4B61DD;
      margin-bottom: 24px;
      display: block;
      width: 100%;
      color: #2B2A35;
      a {
        text-decoration: none;
        &:hover {
          text-decoration: underline; } } }

    .table-responsive {
      overflow-x: auto;
      margin-bottom: 24px;

      .table {
        border-collapse: collapse;
        width: 100%;
        font-size: 16px;
        line-height: 24px;
        white-space: nowrap;

        thead {
          background-color: #D0D6DD;
          color: #232933;

          th {
            padding: 8px 16px;
            border: 1px solid #EED8DA;
            @include inter-600();
            &:first-child {
              border-left-color: transparent; }
            &:last-child {
              border-right-color: transparent; } } }

        tbody {

          td {
            padding: 8px 16px;
            vertical-align: top;
            border: 1px solid #EED8DA;
            @include inter-400();
            &:first-child {
              border-left-color: transparent; }
            &:last-child {
              border-right-color: transparent; } } } } }

    iframe {
      width: 100%;
      min-height: auto; }

    mark {
      display: block;
      background: #EDF6FB;
      border: 2px solid #4B61DD;
      border-radius: 4px;
      margin-bottom: 24px;
      position: relative;
      padding: 12px;
      a {
        color: #4B61DD;
        text-decoration: none;
        &:hover {
          text-decoration: underline; } } }

    @include maxtablet() {

      h2 {
        font-size: 28px;
        line-height: 36px; } } }

  .blog__cats {
    margin-bottom: 24px;
    b {
      color: #626B79;
      @include inter-600();
      font-size: 14px;
      line-height: 20px;
      display: inline-block;
      margin-right: 8px;
      margin-bottom: 8px; }
    a {
      display: inline-block;
      font-size: 14px;
      line-height: 20px;
      padding: 4px 8px;
      background: #EDF0F2;
      border-radius: 4px;
      color: #626B79;
      margin-right: 8px;
      margin-bottom: 8px;
      &:last-child {
        margin-right: 0; }
      &:hover {
        background: darken(#EDF0F2, 10%);
        color: darken(#626B79, 10%); } }

    @include maxtablet() {
      b {
        display: block;
        width: 100%;
        margin-bottom: 8px; } } }

  .blog__navs {
    a {
      font-size: 16px;
      line-height: 24px;
      color: #4B61DD;
      text-decoration: none;
      &:hover {
        text-decoration: underline; }
      &.link-btn {
        display: inline-block;
        position: relative;
        &.with-arrow {
          &::after {
            background: url(../images/ic-arrow-r.svg) no-repeat center;
            background-size: contain;
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            top: 5px;
            left: 8px;
            position: relative; }
          &:hover {
            &::after {
              @include bounceRevert(); } } }
        &.with-arrow-invert {
          &::before {
            background: url(../images/ic-arrow-l.svg) no-repeat center;
            background-size: contain;
            content: '';
            width: 20px;
            height: 20px;
            display: inline-block;
            top: 5px;
            right: 8px;
            position: relative; }
          &:hover {
            &::before {
              @include bounce(); } } } } }
    @include maxtablet() {
      margin-bottom: 32px;
      padding-left: 7px;
      padding-right: 7px; } } }

.blog__footer {

  .blog__author-detail {
    margin: 16px 0;
    border-radius: 16px;
    background: #f1f5f9;
    padding: 24px;
    display: flex;
    max-width: 100%;
    align-items: flex-start;
    gap: 24px;
    .img {
      width: 80px;
      height: 80px;
      object-fit: cover;
      object-position: center;
      border-radius: 50%;
      flex: 0 0 80px; }
    .meta {
      flex: 0 0 calc(100% - 104px);
      .name {
        margin-bottom: 12px;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        color: #4B61DD;
        a {
          font-size: 16px;
          line-height: 24px;
          display: inline-block;
          color: #4B61DD;
          @include inter-600();
          &:hover {
            text-decoration: underline; } }
        .badge {
          display: inline-block;
          vertical-align: middle;
          margin-left: 6px;
          font-size: 12px;
          border-radius: 10px;
          background: rgba(35, 41, 51, .8);
          font-weight: 400; }
        .expert-mark {
          display: inline-block;
          vertical-align: middle; } }
      p {
        font-size: 16px;
        line-height: 24px;
        &:last-child {
          margin-bottom: 0; } } } }

  .blog__related {
    margin-top: 24px;
    h2,h3 {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      @include inter-600(); }

    .blog__item {
      display: flex;
      padding-bottom: 24px;
      margin-bottom: 32px;
      border-bottom: 1px solid #E2E2E2;
      height: calc(100% - 32px);
      &> a {
        display: inline-block;
        margin-right: 16px;
        width: 120px;
        flex: 0 0 120px;
        .img {
          width: 120px;
          height: 120px;
          object-fit: cover;
          border-radius: 6px; } }
      .meta {
        display: grid;
        .cat {
          a {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            color: #4B61DD;
            margin-bottom: 4px;
            &:last-child {
              margin-right: 0; }
            &:hover {
              text-decoration: underline; } } }
        .title {
          a {
            font-size: 16px;
            line-height: 24px;
            color: #232933;
            @include inter-600();
            margin-bottom: 16px;
            display: inline-block;
            &:hover {
              text-decoration: underline; } } }
        .author {
          font-size: 14px;
          line-height: 20px;
          align-self: flex-end;
          a,b,span {
            color: #626B79;
            display: inline-flex;
            align-items: center;
            position: relative;
            &::before {
              content: '';
              display: inline-block;
              width: 20px;
              height: 20px;
              background: url(../images/ic-employee.svg) no-repeat center;
              background-size: contain;
              position: relative;
              margin-right: 8px; }
            &:hover {
              text-decoration: none; } } } } } }

  @include maxtablet() {

    .blog__author-detail {
      flex-direction: column;
      padding: 24px; }

    .blog__related {
      padding: 32px 0 0 0;
      .col-lg-6.col-12 {
        &:last-child {
          .blog__item {
            margin-bottom: 0;
            border-bottom: 0; } } } } } }

@include maxtablet() {
  .blog__sticky {
    position: fixed;
    width: 100%;
    top: 96px;
    left: 0;
    background: #F1F5F9;
    padding: 0;
    z-index: 101;
    transition: all linear .3s;
    .blog__toc {
      cursor: pointer; }

    b {
      display: block;
      padding: 12px 0;
      font-size: 16px;
      line-height: 24px;
      color: #4B61DD;
      border-bottom: 0;
      @include inter-600();
      position: relative;
      cursor: pointer;

      &::after {
        content: '';
        width: 24px;
        height: 24px;
        background: url(../images/ic-caret-down.svg) no-repeat center;
        display: inline-block;
        position: absolute;
        top: 12px;
        right: 0;
        transform: rotate(0deg);
        transition: all linear .3s; } }

    ul {
      background: #FFF;
      overflow-x: hidden;
      overflow-y: auto;
      max-height: 0;
      margin-bottom: 0;
      transition: all linear .3s;
      li {

        a {
          color: #626B79;
          font-size: 14px;
          line-height: 20px;
          padding: 8px 34px 24px 8px;
          display: block;
          &.active {
            color: #4B61DD; } }

        ul {
          padding: 0;
          margin: 0;
          max-height: 100%; } } }

    &.active {
      b {
        border-bottom: 1px solid #D0D6DD;
        &::after {
          transform: rotate(180deg);
          transition: all linear .3s; } }
      ul {
        max-height: calc(50vh - 50px);
        margin-bottom: 32px;
        transition: all linear .3s;

        li {
          ul {
            margin-bottom: 0;
            max-height: 100%; } } } } }

  .blog__sticky-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    background: #F8F9FB;
    padding: 24px 4px;
    display: block;
    width: 100%;
    z-index: 8;

    span.close {
      position: absolute;
      width: 20px;
      height: 20px;
      background: url(../images/ic-close.svg) no-repeat center;
      background-size: contain;
      top: -15px;
      right: 0; }

    strong,
    b {
      @include inter-600();
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
      display: block; }
    a {
      &:last-child {
        margin-bottom: 0; } } }

  .menu-open {
    .blog__sticky {
      z-index: 7; } } }

.modal-subs {
  .modal-dialog {
    margin: 50px auto;
    max-width: 371px;
    border-radius: 4px;
    .modal-content {
      padding: 32px 20px;
      background: #F2F4F7;
      position: relative;
      .modal-body {
        padding: 0; }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        img {
          width: 20px; } }

      strong,
      b {
        @include inter-600();
        font-size: 16px;
        line-height: 24px;
        display: block;
        margin-bottom: 32px;
        text-align: center; }

      .form-group {
        margin-bottom: 20px;
        label {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 4px; }
        .form-control {
          font-size: 14px;
          line-height: 20px; }
        .custom-checkbox {
          label {
            font-size: 12px;
            line-height: 16px; } } } } } }

.modal-subs-success {
  .modal-dialog {
    margin: 50px auto;
    max-width: 371px;
    border-radius: 4px;
    .modal-content {
      padding: 32px 20px;
      background: #F2F4F7;
      position: relative;

      .modal-body {
        padding: 0; }

      .close {
        position: absolute;
        top: 10px;
        right: 10px;
        img {
          width: 20px; } }

      .icon {
        margin-bottom: 16px;
        text-align: center;
        img {
          height: 240px;
          width: auto;
          margin: 0 auto; } }

      strong,
      b {
        @include inter-600();
        font-size: 20px;
        line-height: 28px;
        display: block;
        margin-bottom: 16px;
        text-align: center; }
      p {
        font-size: 16px;
        line-height: 24px;
        text-align: center; } } } }

.highlight-blocks {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  .heading {
    color: #FFF;
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    border-radius: 8px 8px 0px 0px;
    border-bottom: 2px solid #F96D01;
    background: #F96D01;
    display: flex;
    padding: 16px 24px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch; }

  .content {
    display: flex;
    padding: 24px;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    border-radius: 0px 0px 8px 8px;
    background: #F1F5F9;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    color: #343A40;
    ul {
      margin-left: 0px;
      gap: 0px;
      &:last-child {
        margin-bottom: 0px; } } } }

form {
  #captcha {
    .preview {
      color: #555;
      width: 100%;
      text-align: center;
      height: 36px;
      line-height: 36px;
      letter-spacing: 8px;
      border: 1px solid #e2e2e2;
      background: #F2F2F3;
      border-radius: 4px 4px 0 0;
      margin-bottom: 0;
      border-bottom: 0; }

    .alert-error {
      color: #DA473F;
      font-size: 14px;
      line-height: 20px;
      padding-top: 4px;
      display: none; }

    .preview span {
      display: inline-block;
      user-select: none; } }

  .captcha_form {
    position: relative;

    .field {
      padding-right: 40px;
      border-radius: 0 0 4px 4px; }

    .captcha_refersh {
      position: absolute;
      top: 0;
      right: 16px;
      line-height: 36px;
      border: 0;
      background: transparent;
      color: $text;
      padding: 0;
      box-shadow: none; } } }

.blog__progress {
  z-index: 7;
  display: none;
  height: 4px;
  background: #4B61DD;
  position: fixed;
  top: 56px;
  left: 0;
  transition: all .4s ease;
  transition: all linear .3s;
  &.fixed {
    display: block; }
  @include maxtablet() {
    top: 104px;
    transition: all linear .3s; } }

body.header-fixed {
  @include maxtablet() {
    .blog__sticky {
      top: 56px;
      transition: all linear .3s; } } }

body.header-sticky:not(.header-fixed) {
  @include maxtablet() {
    .blog__sticky {
      top: 96px;
      transition: all linear .3s; } } }
