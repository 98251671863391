.steps {
  position: relative;
  display: block;
  .section-head {
    margin-bottom: 0;
    &__title {
      @include inter-600();
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 16px;
      @include maxtablet {
        font-size: 24px;
        line-height: 32px; } }
    &__content, &__content p {
      margin-bottom: 20px; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  &__img {
    padding-left: 24px;
    z-index: 2;
    img {
      display: block;
      width: 100%;
      height: auto; }
    &.fixed {
      position: fixed; } }
  &__list {
    list-style: none;
    &.alt {
      &> li {
        padding-bottom: 200px; } }
    &> li {
      position: relative;
      padding-left: 32px;
      padding-bottom: 120px;
      opacity: .75;
      transition: all .3s ease-in-out;

      .img {
        display: none;
        padding: 0 24px;
        text-align: center;
        img {
          max-width: 100%;
          display: block;
          margin: 0 auto 16px; } }
      h3 {
        @include inter-600();
        font-size: 16px;
        line-height: 23px;
        margin-bottom: 8px; }
      p {
        margin-bottom: 0; }
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        background: #E2E2E2;
        border: 4px solid transparent;
        box-shadow: 0px 2px 4px rgba(0,0,0,0.25);
        top: 3px;
        left: -7px;
        z-index: 2;
        border-radius: 50%; }
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 1px;
        height: 100%;
        background: #E2E2E2;
        top: 18px;
        left: 0;
        z-index: 1; }
      &:last-child {
        padding-bottom: 0!important;
        &::after {
          display: none; } }
      &.active {
        opacity: 1;
        &:before {
          background: #F96D01;
          border: 4px solid #FFF; } } } }


  &--promo {
    .steps__list {
      &> li {
        padding-bottom: 16px;
        &:hover {
          cursor: pointer; }
        @include maxtablet() {
          padding-left: 32px;
          h3, p {
            color: #626B79; }
          &.active {
            h3, p {
              color: inherit; }
            &::before {
              background: #F96D01;
              border: 4px solid #FFF;
              box-shadow: 0px 2px 4px rgba(0,0,0,.25); } }
          &::after {
            width: 4px; }
          &::before {
            content: '';
            display: block;
            position: absolute;
            width: 16px;
            height: 16px;
            background: #E2E2E2;
            border: 4px solid transparent;
            top: 3px;
            left: -6px;
            z-index: 2;
            border-radius: 50%;
            box-shadow: none; } } } }
    .steps__img.show-sm {
      padding-left: 0;
      margin-bottom: 16px; } }

  @include maxtablet {
    .section-head__content,
    .section-head__content p {
      margin-bottom: 12px; }
    &__wrap {
      padding-top: 40px;
      padding-bottom: 60px; }
    &__img {
      display: none; }
    &__list {
      li {
        padding: 0;
        opacity: 1;
        text-align: left;
        .img {
          display: block;
          margin-bottom: 32px; }

        &:before {
          display: none; } }
      .owl-dots {
        text-align: left;
        .owl-dot {
          background: #E2E2E2;
          width: 32px;
          height: 4px;
          border-radius: 2px; } } } } }

.benefits--alt {
  padding-top: 64px;
  padding-bottom: 64px;
  @include maxtablet() {
    padding-top: 32px;
    padding-bottom: 32px; }
  &.bg-gray,
  &.bg-grey {
    .section-head__title {
      margin-bottom: 8px; } }
  .section-head {
    margin-bottom: 0;
    &__title {
      @include inter-600();
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 24px;
      text-align: center;
      @include maxtablet {
        font-size: 24px;
        line-height: 32px; } }
    &__desc {
      text-align: center;
      margin-bottom: 24px; } }
  .item {
    padding: 36px 32px;
    position: relative;
    border-radius: 4px;
    background: #FFF;
    height: 100%;
    @include maxtablet() {
      padding: 16px 20px 16px 55px;
      margin-bottom: 24px;
      border-radius: 4px;
      height: calc(100% - 24px);
      &__desc {
        p {
          margin-bottom: 0; } } }
    &__icon {
      width: 48px;
      height: 48px;
      margin-bottom: 8px;
      @include maxtablet() {
        position: absolute;
        width: 24px;
        height: 24px;
        left: 20px;
        top: 16px; } }
    &__title {
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px; } } }

.form-contact--alt {
  padding: 64px 0;
  background-color: #F2F4F7;
  background-repeat: no-repeat;
  background-position: 100px center;
  background-size: 35%;
  @include maxtablet() {
    padding: 42px 10px;
    background-image: none!important; }
  .form-contact__img {
    img {
      max-width: 100%;
      margin: 0 auto;
      width: unset; } }
  .form-contact__wrap {
    background: transparent;
    box-shadow: none;
    margin-bottom: 0;
    padding: 0; }
  .form-contact__title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 24px; }
  .form-group {
    label {
      @include inter-600();
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 4px;
      display: inline-block; }
    small {
      color: #626B79;
      font-size: 12px;
      line-height: 16px; }
    span.info {
      width: 20px;
      height: 20px;
      background: url('../images/ic-info.svg') no-repeat center;
      background-size: contain;
      display: inline-block;
      vertical-align: middle;
      margin-left: 5px;
      cursor: help; }

    .form-control {
      border: 1px solid #D0D6DD;
      border-radius: 6px;
      &:active,
      &:hover,
      &:focus {
        border-color: #314AD8;
        box-shadow: none; } }

    input[type="file"] {
      font-size: 13px; }

    label.cb-label {
      @include inter-400();
      font-size: 14px;
      line-height: 20px;
      color: #232933;
      margin-bottom: 24px;
      a {
        color: #4B61DD;
        text-decoration: underline;
        &:hover {
          color: darken(#4B61DD, 20%);
          text-decoration: none; } } }

    @include maxtablet() {
      .col-lg-6 {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0; } }
      label.cb-label {
        display: inline-block;
        width: calc(100% - 30px);
        vertical-align: top; } } } }


.tooltip-inner {
  background-color: #8B95A5; }

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #8B95A5; }


.block-sections {
  .block-item__desc {
    margin-bottom: 24px;
    p {
      margin-bottom: 24px; }
    ul {
      li {
        p {
          margin-bottom: 0; } } } }
  .block-item__buttons {
    a,.btn {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  @include maxtablet() {
    &__wrap {
      padding: 42px 12px; }
    .block-item__title {
      text-align: center; }
    .block-item__desc {
      margin-bottom: 30px; }
    .block-item__img.show-sm {
      margin-bottom: 32px; }
    .block-item__buttons {
      a,
      .btn {
        width: 100%;
        display: block;
        margin-right: 0;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0; } } } } }
