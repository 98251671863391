
/* ------------------------------------------------------------------------------------------------ */
/* Header */
/* ------------------------------------------------------------------------------------------------ */

@include responsive(0,1025px) {
  .header {
    z-index: 100;
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #FFF;
    font-size: 16px;
    line-height: 24px;
    color: #2B2A35;
    border-bottom: 1px solid #FFF;
    transition: all .4s ease;
    .show-sm {
      display: none!important; }
    .sm-only {
      display: none; }
    &__back {
      display: none; }
    .video-thumb {
      position: relative;
      a.play {
        display: block;
        position: absolute !important;
        z-index: 3 !important;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        img {
          display: block;
          width: 40px;
          height: 40px; } }
      .cover {
        position: relative;
        width: 100%;
        padding-top: 56%;
        z-index: 2 !important;
        img {
          display: block;
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%; } } }
    a:not(.btn):not(.link-more) {
      color: #2B2A35;
      text-decoration: none;
      &:hover {
        color: #005FBF;
        text-decoration: none; } }
    &__logo {
      display: block;
      width: 100%;
      padding: 4px 0;
      img {
        display: block;
        height: 32px;
        width: auto; } }
    .main-menu {
      display: flex;
      margin-bottom: 0;
      &__item {
        display: block;
        padding: 8px 20px;
        &.has-child {
          display: flex;
          align-items: center;
          &:after {
            content: "";
            display: block;
            width: 16px;
            height: 16px;
            margin-left: 4px;
            background: url(../images/ic-dropdown-header.svg); }
          &> a {
            display: block;
            position: relative; }
          &> a:before,
          &> a:after {
            display: none; }
          &:hover {
            &> a:before,
            &> a:after {
              opacity: 1;
              top: calc(100% + 13px); }
            &> a:before, {
              top: calc(100% + 14px); }
            .main-menu__sub {
              top: calc(100% - 10px);
              opacity: 1;
              visibility: visible;
              display: block; } } }
        &:hover {
          .main-menu__link {
            border-bottom-color: $primary;
            color: #232933; } }
        &.sm-only {
          display: none; } }
      &__link {
        display: block;
        position: relative;
        border-bottom: 2px solid transparent; } }
    .main-menu__sub {
      position: absolute;
      width: 1240px;
      background: #FFF;
      top: calc(100% + 22px);
      left: calc(50% - 652px);
      border: 1px solid $border;
      // box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.16)
      box-shadow: 0px 24px 48px rgba(209, 209, 209, 0.75), 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
      font-size: 14px;
      line-height: 20px;
      border-radius: 4px;
      padding: 32px 24px 0;
      opacity: 0;
      visibility: hidden;
      display: none;
      // transition: all .4s ease
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 30px;
        top: -22px;
        left: 0;
        background: transparent;
 }        // background: red
      &.alt {
        padding: 24px 40px; }
      &.sol {
        min-height: 526px; }
      &.lg {
        max-width: 1088px;
        left: calc(50% - 544px); }
      &.md {
        max-width: 995px;
        left: calc(50% - 498px);
        height: 450px;
        .sub-menu__sidebar {
          height: calc(100% + 116px); } }
      &.sm {
        max-width: 634px;
        left: calc(50% - 317px);
        padding-bottom: 32px; }
      &.xs {
        max-width: 620px;
        left: calc(50% - 490px); }
      &.xxs {
        max-width: 350px;
        left: calc(50% - 300px); }
      .row {
        margin-left: -8px;
        margin-right: -8px;
        &> .col,
        &> [class*="col-"] {
          padding-left: 8px;
          padding-right: 8px; } }
      .sub-menu {
        &.bg-gray {
          position: relative;
          padding-left: 24px;
          background: transparent;
          &:before {
            content: "";
            z-index: 1;
            display: block;
            position: absolute;
            width: calc(100% + 9px);
            height: calc(100% + 32px);
            background: #F2F4F7;
            top: -31px;
            left: 8px;
            border-radius: 0 4px 4px 0; }
          * {
            position: relative;
            z-index: 2; }
          &.left {
            padding-left: 0;
            padding-right: 48px;
            // padding-bottom: 48px
            &::before {
              left: -32px; }
            .link-more {
              position: absolute;
              left: 0;
              bottom: 20px; } }
          .sub-menu__title {
            margin-bottom: 0;
            color: #232933;
            @include inter-600(); }
          .sub-menu__item {
            p {
              font-size: 14px;
              line-height: 20px;
              margin-bottom: 20px; } } }
        &__title {
          display: block;
          font-size: 12px;
          line-height: 16px;
          @include inter-600();
          color: #626B79;
          margin-bottom: 16px;
          text-transform: uppercase; }
        &__item > a {
          display: block;
          position: relative;
          padding: 8px;
          border-radius: 6px;
          transition: all .4s ease;
          color: #232933;
          background: #FFF;
          b {
            display: block;
            font-size: 16px;
            line-height: 24px;
            @include inter-500();
            margin-bottom: 4px; }
          p {
            display: block;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 0;
            color: #626B79; }
          &:hover {
            color: #232933;
            background: #F7FBFF;
            p {
              color: #626B79; } } }
        &__item {
          margin-bottom: 16px;
          display: inline-block;
          width: 100%;
          position: relative;
          .badge {
            display: inline-block;
            vertical-align: middle;
            color: #FFF;
            font-size: 12px;
            line-height: 20px;
            padding: 0 4px;
            background: #DA473F;
            margin-left: 6px;
            border-radius: 6px;
            position: relative;
            top: -1px; }
          &.has-icon {
            &> a {
              padding-left: 40px;
              .icon {
                display: block;
                position: absolute;
                top: 8px;
                left: 8px;
                width: 24px;
                height: 24px;
                object-fit: contain;
                object-position: 50% 50%;
                filter: grayscale(100);
                opacity: 0.7;
                transition: all .3s ease-in-out; }
              &:hover {
                .icon {
                  filter: grayscale(0);
                  opacity: 1; } } } } }
        &__2-col {
          column-count: 2; }
        &__3-col {
          column-count: 3; }
        &__item-action {
          padding-top: 24px;
          padding-left: 16px;
          padding-right: 16px; }
        &__media {
          padding-top: 24px;
          .sub-menu__title {
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 4px; }
          .video-thumb {
            width: 87.224%;
            .cover {
              padding-top: 57.25%; } } }
        &__banner {
          position: relative;
          display: block;
          border-radius: 4px;
          width: 100%;
          padding-top: 0!important;
          img {
            position: relative!important;
            display: block;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-position: center;
            border-radius: 4px; }
          label {
            position: absolute;
            color: transparent;
            top: 0;
            left: 0; } }
        &__action {
          text-align: right;
          padding: 24px 100px 0 0;
          &.alt {
            width: 100%;
            text-align: center;
            border-top: 1px solid $border; } }
        &__bot {
          width: 100%;
          position: relative;
          &::before {
            content: "";
            display: block;
            width: calc(100% + 17px);
            height: 100%;
            position: absolute;
            top: 0;
            left: -16px;
            background: #FBFCFC;
            border-radius: 0 0 4px 4px; }
          .sub-menu-col__bot {
            display: flex;
            width: 100%;
            padding-top: 32px;
            padding-bottom: 32px;
            .sub-menu__item {
              max-width: 33.333333%;
              padding: 0;
              margin-bottom: 0;
              a {
                background: transparent; }
              &:hover {
                a {
                  background: darken(#FBFCFC, 4%); } } } } }
        &__sidebar {
          position: absolute;
          top: -32px;
          right: -16px;
          height: calc(100% + 32px);
          background:#F2F4F7 {}
          padding: 32px 24px;
          &.alt {
            height: calc(100% + 48px); }
          .sub-menu__title {
            font-size: 14px;
            line-height: 20px;
            color: #232933;
            margin-bottom: 0;
            text-transform: none;
            @include inter-600(); }
          &> p {
            font-size: 14px;
            line-height: 20px;
            color: #626B79;
            margin-bottom: 20px;
            @include inter-400(); }
          .sub-menu__img {
            margin-bottom: 24px; }
          .btn {
            margin-bottom: 24px; }
          .video-thumb {
            margin-bottom: 20px; }
          .btn-header {
            margin-bottom: 8px;
            padding: 8px;
            border-radius: 4px;
            display: block;
            b {
              font-size: 16px;
              line-height: 24px;
              color: #232933;
              display: block; }
            img {
              margin: 8px auto; }
            &:hover {
              background: #FFF; } } } } }

    .right-menu {
      display: flex;
      align-items: center;
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: 0;
      a:not(.btn):not(.link-more) {
        color: $button;
        font-weight: 500;
        // +inter-500()
        &:hover {
          color: $button;
          text-decoration: underline; } }
      &__item {
        display: block;
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 20px;
          top: calc(50% - 10px);
          left: 0;
          background: #E2E2E2; }
        &:last-child {
          padding-left: 0; }
        &:first-child,
        &:last-child {
          &:after {
            display: none; } } }
      &__link {
        display: block;
        &.btn {
          background: $button;
          color: #FFF;
          padding: 7px 23px;
          &:hover {
            background: darken($button, 10%);
            color: #FFF;
            text-decoration: none; }
          &-blue-border {
            background: transparent;
            color: $blue;
            border: 1px solid $button;
            border-radius: 6px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            box-shadow: none;
            &:hover {
              color: #FFF;
              background: $button;
              border-color: $button;
              text-decoration: none; } }
          &-mekari {
            background: $button;
            &:hover {
              background: darken($button, 10%); } } } } }

    .right-menu-md {
      display: none; }

    &__top {
      padding: 10px 0;
      background: #1C1D1E;
      .container {
        max-width: 1248px; }
      .djp-logo {
        margin-left: auto;
        color: #FFF;
        font-size:  14px;
        line-height: 20px;
        text-align: right;
        font-weight: 500;
        img {
          display: inline-block;
          height: 20px;
          width: auto;
          margin-right: 10px; } } }

    &__wrap {
      z-index: 2;
      position: relative;
      display: block;
      padding: 16px 12px;
      margin: 0 auto;
      max-width: 1248px;
      transition: all .4s ease;
      &:after {
        content: "";
        display: block;
        width: 100%;
        clear: both; } }
    &__left {
      display: block;
      float: left;
      width: 180px; }
    &__main {
      display: inline-block;
      vertical-align: middle; }
    &__right {
      display: block;
      float: right; }

    &__sticky {
      z-index: 1;
      background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%);
      .container {
        position: relative;
        .close {
          position: absolute;
          width: 20px;
          height: 20px;
          background: url('../images/ic-close.png') no-repeat center;
          background-size: contain;
          top: calc(50% - 7.5px);
          right: -50px; } }
      .hs {
        display: flex;
        color: #FFF!important;
        position: relative;
        &__wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%; }
        &__img {
          img {
            max-height: 140px;
            width: auto; } }
        &__content {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          flex-grow: 3;
          padding: 16px;
          &>label {
            font-size: 20px;
            line-height: 28px;
            @include inter-600();
            display: block;
            width: 100%;
            text-align: center;
            margin: 0 auto 4px; } }
        &__action {
          display: flex;
          flex-grow: 0; }
        .countdown {
          display: flex;
          &__box {
            display: block;
            margin: 0 6px;
            span {
              display: block;
              background: #FFF;
              color: #232933;
              font-size: 32px;
              line-height: 38px;
              padding: 4px 8px;
              border-radius: 4px;
              @include inter-700();
              letter-spacing: -2px;
              border: 1px solid #8B95A5;
              position: relative;
              min-width: 60px;
              text-align: center;
              // &:after
              //   content: ""
              //   position: absolute
              //   top: 50%
              //   left: 0
              //   display: block
              //   height: 1px
              //   width: 100%
              //   background: #8B95A5
 }              //   opacity: 0.7
            label {
              display: block;
              margin: 2px auto 0;
              text-align: center;
              font-size: 12px;
              line-height: 16px;
              @include inter-400();
              text-transform: uppercase; } } } } }

    &.fixed {
      top: -42px;
      border-bottom: 1px solid #E2E2E2;
      .header__wrap {
        padding-top: 8px;
        padding-bottom: 8px; }
      .main-menu__item.has-child:hover > a:before {
        top: calc(100% + 5px); }
      .main-menu__item.has-child:hover > a:after {
        top: calc(100% + 4px); }
      .main-menu__sub:before {
        top: -15px; }
      .header__sticky {
        background: #F96D01;
        .hs {
          &__img {
            display: none; }
          &__content {
            padding: 10px 0;
            &>label {
              max-width: 40%;
              flex: 0 0 40%;
              text-align: left;
              font-size: 14px;
              line-height: 20px;
              margin: 0; } }
          &__action {
            .btn-white-arrow {
              color: #FFF;
              background: transparent;
              padding: 0;
              &::after {
                background: url(../images/ic-arrow-right-w.svg) no-repeat center; } } }
          .countdown {
            max-width: 60%;
            flex: 0 0 60%;
            &__box {
              display: flex;
              align-items: center;
              span {
                background: transparent;
                border: 0;
                color: #FFF;
                font-size: 14px;
                line-height: 20px;
                @include inter-600();
                padding: 0 4px 0 0;
                letter-spacing: 0;
                min-width: auto;
                &::after {
                  display: none; } }
              label {
                text-transform: initial; } } } } } } } }

@include responsive(1024px) {
  .header {
    z-index: 100;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    border-bottom: 1px solid transparent;
    .video-thumb {
      position: relative;
      a.play {
        display: block;
        position: absolute !important;
        z-index: 3 !important;
        top: calc(50% - 20px);
        left: calc(50% - 20px);
        img {
          display: block;
          width: 40px;
          height: 40px; } }
      .cover {
        position: relative;
        width: 100%;
        padding-top: 56%;
        z-index: 2 !important;
        img {
          display: block;
          position: absolute !important;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%; } } }
    &__back {
      display: none;
      span {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: #4B61DD;
        position: relative;
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          display: inline-block;
          background: url('../images/ic-arrow-back.svg') no-repeat center center;
          background-size: contain;
          vertical-align: middle;
          margin-top: -3px;
          margin-right: 8px; } } }
    .hide-sm {
      display: none; }
    .show-sm {
      display: block!important; }
    &__logo {
      margin-right: auto;
      img {
        display: block;
        height: 32px;
        width: auto; } }
    .main-menu {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      margin-bottom: 160px;
      &__item {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid $border;
        &.has-child {
          align-items: center;
          &:after {
            content: "";
            display: block;
            position: absolute;
            top: 19px;
            right: 16px;
            width: 20px;
            height: 20px;
            margin-left: auto;
            background: url(../images/ic-chevron-right.svg);
            background-size: cover;
            background-position: 50% 50%;
            transform: none;
            transition: all .3s ease; }
          &.expand {
            .main-menu__link {
              background: #F2F4F7;
              color: #4B61DD; }
            .main-menu__sub {
              display: block;
              transform: translateX(0);
              transition: all .3s ease-in;
              // opacity: 1
              // max-height: max-content
              // height: auto
              // visibility: visible
              // padding: 8px 16px
              &-back {
                display: block;
                position: fixed;

                span {
                  color: #4B61DD;
                  font-size: 16px;
                  line-height: 24px;
                  position: relative;
                  display: inline-block;
                  vertical-align: middle;
                  &::before {
                    content: '';
                    width: 24px;
                    height: 24px;
                    display: inline-block;
                    background: url('../images/ic-arrow-back.svg') no-repeat center center;
                    background-size: contain;
                    vertical-align: middle;
                    margin-top: -3px;
                    margin-right: 8px; } } } }

            &:after {
              transform: rotate(180deg); } } } }
      &__link {
        display: block;
        width: 100%;
        padding: 16px 16px 20px;
        font-size: 20px;
        line-height: 28px;
        color: #2B2A35;
        @include inter-500();
        &:hover {
          text-decoration: none; } }
      &__sub {
        display: none;
        opacity: 1;
        visibility: visible;
        position: fixed;
        padding: 20px;
        top: 95px;
        left: 0;
        width: 100%;
        height: calc(100vh - 236px);
        background: #FFF;
        z-index: 2;
        overflow-y: auto;
        overflow-x: hidden;
        transform: translateX(-100%);
        transition: all .3s ease-out;
        .sub-menu {
          &__title {
            display: block;
            padding: 16px 0;
            border-bottom: 1px solid $border;
            color: #2B2A35;
            font-size: 16px;
            line-height: 24px;
            margin-bottom: 8px; }
          &__item {
            position: relative;
            margin-bottom: 8px;
            .badge {
              display: inline-block;
              vertical-align: middle;
              color: #FFF;
              font-size: 12px;
              line-height: 20px;
              padding: 0 4px;
              background: #DA473F;
              margin-left: 6px;
              border-radius: 6px;
              position: relative;
              top: -1px; }
            a {
              display: block;
              padding: 8px 16px;
              color: #2B2A35; }
            b {
              display: block;
              font-size: 16px;
              line-height: 24px;
              margin-bottom: 4px; }
            p {
              display: block;
              font-size: 14px;
              line-height: 20px; }
            &.has-icon {
              .icon {
                position: absolute;
                top: 8px;
                left: 16px;
                width: 24px;
                height: 24px;
                display: block;
                object-fit: contain;
                object-position: 50% 50%; }
              a {
                padding-left: 48px; } } }
          &__action {
            padding: 24px 16px 40px;
            width: 100%; }
          &__item-action {
            padding: 24px 16px; }
          &__media {
            padding-bottom: 24px;
            position: relative;
            max-width: 80%; }
          &__banner {
            display: none; }
          &.bg-gray {
            background: #F2F4F7;
            padding: 24px 16px;
            margin-bottom: -8px;
            .sub-menu__title {
              padding: 0;
              display: block;
              border: 0;
              margin: 0 0 4px; } } } }
      .menu-tab {
        display: none;
        &__block {
          flex-direction: column-reverse;
          .bg-gray.left {
            background: #FFF;
            display: none; } } } }
    .sub-menu__bot {
      .sub-menu-col__bot {
        padding: 32px 6px;
        margin: 16px -20px 0;
        background: #FBFCFC; } }
    .cta-sm {
      display: flex;
      padding: 0 16px 12px;
      margin: 0 -8px;
      li {
        display: block;
        max-width: 50%;
        flex: 0 0 50%;
        padding: 0 8px; }
      .btn {
        font-size: 14px;
        line-height: 24px;
        padding: 7px 16px; } }

    .right-menu {
      padding: 12px 16px 20px;
      list-style: none;
      display: none;
      &__item {
        padding: 8px 0; }
      &__link {
        display: block;
        width: 100%;
        padding: 7px 16px;
        background: #fffc;
        border: 1px solid #005FBF;
        border-radius: 4px;
        color: #005FBF;
        font-weight: 500;
        // +inter-500()
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        &:hover {
          text-decoration: none; }
        &.btn {
          background: $button;
          color: #FFF;
          &-blue-border {
            background: transparent;
            color: $button;
            border: 1px solid $button;
            border-radius: 6px;
            font-size: 14px;
            line-height: 24px;
            font-weight: 500;
            box-shadow: none;
            &:hover {
              color: #FFF;
              background: $button;
              border-color: $button;
              text-decoration: none; } } } } }
    .right-menu-md {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-bottom: 0;
      a:not(.btn):not(.link-more) {
        color: $button;
        font-weight: 500;
        // +inter-500()
        &:hover {
          color: $button;
          text-decoration: underline; } }
      &__item {
        display: block;
        position: relative;
        padding-left: 12px;
        padding-right: 12px;
        &:last-child {
          padding-right: 0; }
        &:after {
          content: "";
          display: block;
          position: absolute;
          width: 1px;
          height: 20px;
          top: calc(50% - 10px);
          left: 0;
          background: #E2E2E2; }
        &:last-child {
          padding-left: 0; }
        &:first-child,
        &:last-child {
          &:after {
            display: none; } } }
      &__link {
        display: block;
        &.btn {
          background: $button;
          color: #FFF;
          padding-left: 23px;
          padding-right: 23px;
          &:hover {
            background: darken($button, 10%);
            color: #FFF;
            text-decoration: none; }
          &-blue-border {
            background: transparent;
            color: $blue;
            border: 1px solid $blue;
            border-radius: 6px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 500;
            box-shadow: none;
            &:hover {
              color: #FFF;
              background: $blue;
              border-color: $blue;
              text-decoration: none; } } } } }

    .btn-sales {
      display: none;
      margin-left: auto;
      a {
        display: inline-block;
        padding: 3px 12px;
        text-align: center;
        background: #fff;
        border: 1px solid $button;
        border-radius: 6px;
        color: $button;
        font-size: 14px;
        line-height: 20px;
        &:hover {
          text-decoration: none; } } }
    .burger-menu {
      display: block;
      position: relative;
      margin-left: 14px;
      width: 20px;
      height: 14px;
      span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background: $primary;
        left: 0;
        transition: all .3s ease;
        &:nth-child(1) {
          top: 0; }
        &:nth-child(2) {
          top: 6px; }
        &:nth-child(3) {
          top: 12px; } } }

    &__top {
      z-index: 2;
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      padding: 10px 0;
      background: #1C1D1E;
      transition: top .3s ease;
      .container {
        max-width: 1248px; }
      .djp-logo {
        margin-left: auto;
        color: #FFF;
        font-size:  14px;
        line-height: 20px;
        font-weight: 500;
        text-align: center;
        img {
          display: inline-block;
          height: 20px;
          width: auto;
          margin-right: 10px; } } }

    &__left {
      z-index: 2;
      display: flex;
      position: fixed;
      top: 40px;
      left: 0;
      width: 100%;
      align-items: center;
      padding: 12px 16px;
      background-color: #fff;
      transition: top .2s ease; }
    &__main {
      z-index: 1;
      margin-top: 96px;
      display: block;
      background-color: #fff;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease;
      overflow: hidden; }
    &__right {
      z-index: 2;
      position: fixed;
      width: 100%;
      bottom: 0;
      left: 0;
      display: block;
      background-color: #fff;
      max-height: 0;
      opacity: 0;
      visibility: hidden;
      transition: all .3s ease; }
    &.fixed {
      .header__sticky {
        background: #F96D01; }
      .header__top {
        top: -100%; }
      .header__left {
        top: 0;
        box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.2); }
      .header__main {
        margin-top: 70px; }
      .main-menu__sub {
        top: 58px;
        height: calc(100vh - 195px); } }
    &__sticky {
      background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%);
      position: fixed;
      width: 100%;
      padding: 12px;
      transition: all .3s ease;
      .container {
        position: relative;
        .close {
          position: absolute;
          width: 20px;
          height: 20px;
          background: url('../images/ic-close.png') no-repeat center;
          background-size: contain;
          top: 5px;
          right: 3px; } }
      .hs {
        display: flex;
        color: #FFF!important;
        &__img {
          display: none; }
        &__wrapper {
          display: block;
          width: 100%; }
        &__content {
          display: block;
          text-align: center;
          &>label {
            font-size: 14px;
            line-height: 20px;
            @include inter-600();
            margin: 0 0 4px; } }
        &__action {
          text-align: center;
          .btn-white-arrow {
            color: #FFF;
            background: transparent;
            padding: 0;
            @include inter-400();
            &::after {
              background: url(../images/ic-arrow-right-w.svg) no-repeat center; } } }
        .countdown {
          display: flex;
          justify-content: center;
          margin-bottom: 8px;
          &__box {
            display: flex;
            align-items: center;
            margin-right: 0;
            &::before {
              content: ":";
              display: inline-block;
              margin: 0 6px; }
            &:first-child::before {
              display: none; }
            &:last-child {
              display: none; }
            span {
              font-size: 20px;
              line-height: 28px;
              @include inter-600(); }
            label {
              font-size: 20px;
              line-height: 28px;
              @include inter-600();
              margin-bottom: 0;
              padding-left: 4px; } } } } }
    // &.sb
    //   &.fixed
    //     .header__sticky
    //       top: 0
    //       transition: all linear .3s
    //     .header__wrap
    //       .header__left
    //         top: 133px
    //         transition: all linear .3s
    //   .header__sticky
    //     top: 40px
    //     transition: all linear .3s
    //   .header__wrap
    //     .header__left
    //       top: 172px
 }    //       transition: all linear .3s


  body.menu-open {
    overflow: hidden;
    .header {
      height: 100%;
      background: #FFF;
      &.sub-open {
        overflow-x: hidden;
        overflow-y: auto;
        .header__back {
          display: inline-block;
          position: absolute;
          width: 100%;
          background: #FFF;
          padding: 16px;
          top: 0;
          left: 0;
          box-shadow: 0px 2px 8px rgba(119, 119, 119, 0.2); } }
      .burger-menu {
        span {
          background: #777777;
          &:nth-child(1) {
            top: 6px;
            transform: rotate(135deg); }
          &:nth-child(2) {
            top: 6px;
            opacity: 0; }
          &:nth-child(3) {
            top: 6px;
            transform: rotate(45deg); } } }
      &__main,
      &__right {
        opacity: 1;
        max-height: max-content;
        visibility: visible; } }

    &.sb {
      .header,
      .header.sticky {
        .header__wrap {
          position: fixed;
          width: 100%;
          z-index: 2;
          .header__left {
            top: 41px;
            transition: all linear .3s; }
          .header__main {
            margin-top: 95px;
            transition: all linear .3s; } } }
      .header.fixed {
        .header__wrap {
          position: fixed;
          z-index: 2;
          width: 100%;
          .header__left {
            top: 0px;
            transition: all linear .3s; }
          .header__main {
            margin-top: 56px;
            transition: all linear .3s; } } } } } }

@include sdekstop() {
  .header__left {
    max-width: 180px; } }

@include xsdesktop() {
  .header__left {
    max-width: 100%; } }

@include mobile {
  .header {
    &__left {
      max-width: 100%; }
    .right-menu {
      display: block; }
    .right-menu-md {
      display: none; }
    .btn-sales {
      display: inline-block; } } }


/* STICKY BANNER */
.sticky-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #4B61DD;
  color: #FFF;
  padding: 0 24px;
  text-align: center;
  transition: all .4s ease;
  font-size: 16px;
  line-height: 24px;
  min-height: 48px;
  &:hover {
    color: #FFF;
    text-decoration: none; }
  .block-link {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1; }
  .text-sm {
    display: none;
    color: #FFF; }
  .text-lg {
    position: relative;
    z-index: 2;
    color: #FFF;
    span {
      @include inter-500(); } }
  .more {
    color: #FFF;
    text-decoration: underline;
    opacity: 1;
    transition: all .4s ease;
    &:hover {
      opacity: .5; } }
  .close {
    display: block;
    position: absolute;
    right: 20px;
    top: calc(50% - 12px);
    transition: all .4s ease;
    cursor: pointer;
    color: #FFF;
    font-size: 26px; }
  .more {
    font-size: 14px;
    line-height: 24px;
    margin-left: 16px; }

  &.sb-alt {
    background: #E0EEFF;
    color: #232933;
    .img {
      display: inline-block;
      vertical-align: bottom;
      margin-top: -12px;
      margin-bottom: -12px;
      transition: all .4s ease; }
    .btn {
      margin-left: 24px; } }
  @include maxtablet() {
    display: block;
    font-size: 14px;
    line-height: 20px;
    padding: 8px 24px;
    background: #4B61DD;
    text-align: center;
    color: #FFF;
    transform: none;
    visibility: visible;
    transition: all .4s ease;
    opacity: 1;
    &:hover {
      color: #FFF;
      text-decoration: none; }
    .text-lg {
      display: none; }
    .text-sm {
      display: block;
      span {
        display: block;
        @include inter-500(); } }
    a {
      color: #FFF;
      text-decoration: underline; }
    .more {
      font-size: 12px; }

    .close {
      font-size: 20px;
      line-height: 20px;
      display: block;
      position: absolute;
      right: 8px;
      top: 8px;
      transition: all .4s ease;
      cursor: pointer; }
    &.sb-alt {
      background: #E0EEFF;
      color: #232933;
      .img {
        display: none; }
      .more {
        color: $primary; } } } }

body.has-sb {
  main {
    padding-top: 0!important; }
  header {
    .header__top {
      transition: all .4s ease;
      opacity: 1;
      visibility: visible; }
    &.fixed {
      transform: translateY(0);
      top: 0;
      .sticky-banner {
        padding-top: 8px;
        padding-bottom: 8px;
        min-height: 48px;
        &.sb-alt .img {
          margin-top: -8px;
          margin-bottom: -8px; } }
      .header__top {
        height: 0;
        opacity: 0;
        padding: 0;
        visibility: hidden; } } }
  @include maxtablet() {
    main {
      padding-top: 0!important; }
    .header.fixed {
      .header__sticky {
        padding: 8px;
        .hs__content > label {
          font-size: 12px;
          line-height: 16px; }
        .hs .countdown {
          margin-bottom: 4px; }
        .hs .countdown__box {
          span, label, &::before {
            font-size: 12px;
            line-height: 16px; } }
        .hs__action .btn-white-arrow {
          font-size: 14px;
          line-height: 20px; } } }

    &.menu-open {
      .sticky-banner {
        height: 0;
        padding: 0 24px;
        opacity: 0;
        visibility: hidden; }
      header {
        .header__left {
          top: 0; }
        .header__main {
          margin-top: 0; } } } } }


