.insight-slider {
  padding: 64px 0;
  .insight {
    &__nav {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 100%;
      margin: 0 0 24px; }
    &__next,
    &__prev {
      display: block;
      width: 56px;
      height: 56px;
      margin: 0 8px;
      background: url(../images/ic-nav.svg) no-repeat center;
      cursor: pointer;
      transition: all .3s ease;
      &:hover {
        background: url(../images/ic-nav-hover.svg) no-repeat center; }
      &.swiper-button-disabled {
        opacity: 0.5;
        filter: grayscale(100);
        pointer-events: none; }
      &.swiper-button-lock {
        display: none; } }
    &__prev {
      transform: scaleX(-1); }
    &__pagination {
      position: relative!important;
      z-index: 1;
      display: flex;
      justify-content: center;
      gap: 12px;
      top: 0 !important;
      padding: 8px 0;
      .swiper-pagination-bullet {
        width: 32px;
        height: 6p;
        margin: 0 !important;
        background: #8B95A5;
        position: relative;
        opacity: 1;
        transition: all .3s ease;
        transition-delay: .5s;
        border-radius: 4px;
        &.active {
          background: #4B61DD; } }
      .swiper-pagination-bullet-active {
        background: #4B61DD; } } }
  .card {
    margin-bottom: 24px;
    height: calc(100% - 24px);
    border: none;
    &__wrap {
      margin: 24px 0 0 0;
      .swiper-wrapper {
        align-items: stretch; } }
    &__img {
      margin-bottom: 24px;
      img {
        height: 219px;
        width: 100%;
        object-fit: cover;
        border-radius: 4px; } }
    &__title {
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px; }
    &__desc {
      margin-bottom: 24px; }
    &__action {
      margin-top: auto;
      a {
        margin-right: 16px;
        &:last-child {
          margin-right: 0; } } } }
  .swiper {
    .swiper-wrapper {
      padding-bottom: 0; } }
  @include mobilelg() {
    padding: 32px 0;
    .insight {
      &__nav {
        display: none; }
      &__pagination {
        .swiper-pagination-bullet {
          width: 32px;
          height: 6px; } }
      &__action {
        a {
          margin-right: 0;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } } } } }
