.form-embeded {
  overflow-x: hidden;
  @include inter-400();
  &__light,
  &__dark {
    padding: 32px; }
  &__light {
    background: #FFF;
    max-width: 45%;
    flex: 0 0 45%; }
  &__dark {
    background: #F2F4F7;
    max-width: 55%;
    flex: 0 0 55%; }
  &__title,
  &__clients-title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
    text-align: center;
    @include inter-600(); }
  &__desc {
    margin: 0 0 16px; }
  &__img {
    margin: 0 0 16px;
    text-align: center;
    img {
      display: block;
      max-width: 100%;
      width: auto;
      height: auto;
      margin: 0 auto; } }
  &__clients {
    list-style: none;
    text-align: center;
    margin-left: -16px;
    margin-right: -16px;
    margin-bottom: 16px;
    li {
      display: inline-block;
      max-width: 90px;
      width: 100%;
      height: auto;
      padding: 0 16px;
      img {
        width: auto;
        height: 28px;
        object-fit: contain;
        object-position: center; } } }
  &__header {
    margin-bottom: 24px;
    img {
      width: 150px;
      height: auto;
      object-fit: contain;
      display: inline-block;
      vertical-align: middle; }
    p {
      display: inline-block;
      width: calc(100% - 160px);
      vertical-align: middle;
      margin-bottom: 0; } }
  &__testimonial {
    border: 1px solid #D0D6DD;
    border-radius: 6px;
    padding: 16px;
    .testimonial {
      &__title {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 12px;
        @include inter-600(); }
      &__quote {
        margin-bottom: 24px; }
      &__author {
        display: inline-block;
        // width: calc(100% - 146px)
        width: 100%;
        vertical-align: middle;
        img {
          width: 48px;
          height: 48px;
          border-radius: 50%;
          object-fit: contain;
          margin-right: 16px;
          display: inline-block;
          vertical-align: middle; } }
      &__info {
        display: inline-block;
        width: calc(100% - 90px);
        vertical-align: middle;
        b {
          display: block;
          width: 100%;
          inter-600() {} } }

      &__logo {
        display: inline-block;
        width: 141px;
        vertical-align: middle;
        img {
          width: 100%;
          height: auto;
          object-fit: contain; } } } } }



