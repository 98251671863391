
.formpage {
  display: block;
  max-width: 600px;
  margin: 64px auto;
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 8px;
    text-align: center; }
  &__desc {
    margin: 0 0 24px; }
  &__img {
    border-radius: 6px 6px 0 0;
    overflow: hidden;
    margin: 0 0 8px;
    img {
      display: block;
      width: 100%;
      height: auto;
      position: relative; } }
  &__form {
    border-radius: 6px;
    background: #FFF;
    box-shadow: 0 4px 8px 0 rgba(119, 119, 119, 0.1), 0 12px 20px 0 rgba(119, 119, 119, 0.2);
    padding: 48px; }
  .form-action {
    text-align: center; }
  @include maxtablet {
    margin: 48px 20px;
    &__title {
      font-size: 20px;
      line-height: 28px; }
    &__desc {
      font-size: 14px;
      line-height: 20px;
      margin: 0 0 24px; }
    &__form {
      padding: 24px 24px 32px; } } }
