/* ------------------------------------------------------------------------------------------------ */
/* Content */
/* ------------------------------------------------------------------------------------------------ */

.masthead {
  background: #F9FAFB;
  position: relative;
  .shape {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    display: block;
    width: 100%;
    height: 40px;
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: -6%;
      width: 112%;
      height: 140px;
      background: #FFF;
      border-radius: 100% 100% 0 0; } }
  .content {
    @extend %ptd-80;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    z-index: 2; }
  .img {
    max-width: 900px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    img {
      width: 100%;
      height: auto; } }
  .sup {
    text-transform: uppercase;
    font-size: 15px;
    line-height: 18px;
    font-weight: 500;
    color: #777;
    display: block;
    margin-bottom: 10px; }
  .subtitle {
    display: block;
    font-size: 15px;
    line-height: 18px;
    margin-bottom: 8px;
    color: #777; }
  .masthead__form {
    background: #FFF;
    padding: 32px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    .title {
      display: block;
      font-size: 20px;
      line-height: 28px;
      @include inter-600();
      margin: 0 0 16px; }
    @include maxtablet() {
      margin-top: 32px; } }
  .masthead__success-msg {
    display: block;
    padding: 32px;
    background: #EAECFB;
    border-radius: 8px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    text-align: center;
    margin-left: 48px;
    .title {
      margin: 0 0 16px; }
    .img {
      margin: 0 0 16px;
      img {
        display: block;
        width: 237px;
        height: auto;
        margin: 0 auto; } }
    .desc {
      p {
        font-size: 16px;
        line-height: 24px;
        @include inter-400(); } }
    .action {
      .btn {
        display: block; } }
    @include maxtablet() {
      margin-left: 0;
      margin-top: 32px;
      padding: 24px; } }
  h1 {
    font-size: 48px;
    line-height: 59px;
    margin-bottom: 30px;
    font-weight: bold;
    // &:last-child
 }    //   margin-bottom: 0
  p {
    font-size: 21px;
    line-height: 36px;
    margin-bottom: 30px; }
  .box-action {
    padding: 0 15px;
    .btn + .btn {
      margin-left: 10px; }
    .btn-link {
      color: #777777;
      transition: all .3s;
      &:hover {
        color: $primary; } } }
  &--alt {
    background: #FFF;
    p {
      font-size: 15px;
      line-height: 26px;
      max-width: 600px;
      margin: 0 auto 40px; }
    .btn.btn-lg {
      min-width: 150px; } }
  &.masthead-dark {
    background: #1C1D1E;
    color: #FFF;
    .content {
      @extend %pt-100, %pb-60;
      padding-left: 15px;
      padding-right: 15px; }
    h1,
    p {
      color: #FFF; }
    span.category {
      font-size: 15px;
      line-height: 24px; } }
  &.inapp {
    padding-left: 104px;
    padding-right: 104px; }
  &.masthead--contact {}

  @include maxtablet {
    .img {
      max-width: 80%; }

    h1 {
      font-size: 36px;
      line-height: 48px; } }

  @include mobile {
    h1 {
      font-size: 28px;
      line-height: 42px; }
    p {
      font-size: 16px;
      line-height: 24px; } } }

.masthead + .block-content {
  @extend %pt-90; }

.masthead-featured {
  @extend %ptd-60;
  background: #F9FAFB;
  .item {
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
    .img {
      margin-bottom: 20px;
      img {
        height: 70px;
        margin: 0 auto; } }
    .title {
      font-size: 18px;
      line-height: 26px;
      font-weight: 500;
      margin-bottom: 10px; }
    .content {
      a {
        color: $link;
        text-decoration: underline;
        opacity: 1;
        transition: all .3s;
        &:hover {
          color: $link;
          text-decoration: underline;
          opacity: .5; } }
      p:last-child {
        margin-bottom: 0; } } }

  @include maxtablet {
    .item {
      padding-left: 0;
      padding-right: 0; } }
  @include mobile {
    .slider-xs:not(.owl-loaded) {
      .col-md-4:not(:first-child) {
        display: none; } }
    .slider-xs {
      padding-bottom: 54px; }
    .owl-dots {
      position: absolute;
      bottom: 0;
      width: 100%;
      margin: 0; } } }


.masthead-footer {
  text-align: center;
  padding: 48px 0 64px; }

.featured {
  padding-top: 64px;
  padding-bottom: 64px;
  &__images {
    position: relative;
    max-width: 900px;
    margin: 0 auto 48px;
    .item {
      position: relative;
      display: block;
      position: relative;
      width: 100%;
      padding-top: 62%;
      margin: 0 auto;
      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        object-fit: contain; } }
    @include maxtablet {
      margin-bottom: 12px;
      .item {
        width: calc(100% - 72px); } } }

  &__thumbs {
    position: relative;
    .owl-nav {
      display: none; }
    .nav {
      display: flex;
      justify-content: center;
      align-items: stretch;
      margin-left: -12px;
      margin-right: -12px; }
    .nav-tabs {
      border: 0; }
    .item {
      display: block;
      width: 100%;
      max-width: 33%;
      flex: 0 0 33%;
      padding-left: 12px;
      padding-right: 12px;
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: $black; } }
    @include maxtablet() {
      margin-left: 20px;
      margin-right: 20px;
      padding-left: 15px;
      padding-right: 15px;
      align-items: center;
      .owl-nav {
        display: block; }
      .item {
        max-width: 100%;
        flex: 0 0 100%;
        &:not(.active) {
          display: none; } } }
    .nav-link {
      display: flex;
      position: relative;
      height: 100%;
      flex-direction: column;
      position: relative;
      padding: 20px;
      border: 1px solid $border;
      border-radius: 4px;
      cursor: pointer;
      min-height: 144px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      transition: all .3s ease-in-out;
      p:last-child {
        margin-bottom: 0; }
      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }
      &:after,
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        top: 0;
        left: 0;
        border-radius: 2px 2px 0 0;
        background: #F96D01;
        opacity: 0;
        transition: all .4s ease; }

      &.active {
        border: 1px solid $border;
        &:after {
          opacity: 1;
          @include progressBar(10s); }
        &:before {
         opacity: 0.2; } } } }

  &__slider {
    position: relative;
    max-width: 800px;
    margin: 0 auto 48px;
    .owl-stage-outer {
      overflow: visible;
      .owl-item {
        opacity: 1;
        transform: scale(1);
        transition: all .75s ease-in-out; }
      .owl-item:not(.active) {
        opacity: 0;
        transform: scale(.5); } }
    .item {
      position: relative;
      display: block;
      position: relative;
      width: 100%;
      padding-top: 62%;
      margin: 0 auto;
      img,
      video {
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 0;
        object-fit: contain; }
      @include mobile {
        width: 100%;
        height: 62%; }
      @include mobilesm {
        padding-top: 40%; } } }
  &__tab {
    position: relative;
    max-width: 1224px;
    margin: 0 auto -16px;
    .owl-item {
      padding: 0 12px;
      &:first-child {
        padding-left: 0; }
      &:last-child {
        padding-right: 0; } }
    .item {
      position: relative;
      padding: 20px;
      border: 1px solid $border;
      border-radius: 4px;
      cursor: pointer;
      min-height: 144px;
      margin-bottom: 16px;
      box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
      transition: all .3s ease-in-out;
      &:hover {
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }
      .progress {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0; }
      &__title {
        display: inline-block;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        color: $black; } }
    .current .item {
      box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
      .progress {
        opacity: 1;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
        path,
        rect {
          @include dash(9s); } } } } }


.block-slider {
  .item {
    padding: 0 10px; } }

.block-content {
  .container {
    position: relative;
    .bg-shape {
      position: absolute;
      bottom: 0;
      right: -230px;
      img {
        position: relative;
        bottom: -130px;
        right: 0; } } }

  .box {
    background-color: #EDF6FA;
    border: 1px solid #A0D4EB;
    padding: 30px;
    border-radius: 6px;
    @include mobile {
      padding: 15px; }
    &.p-60 {
      padding: 60px;
      @include mobile {
        padding: 30px 15px; } }
    > .title {
      font-size: 21px;
      line-height: 36px;
      margin-bottom: 10px;
      font-weight: bold; }
    ol,
    ul {
      padding-left: 30px;
      margin-bottom: 0;
      li {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0; } } }
    ol {
      counter-reset: ol-counter;
      list-style: none;
      > li {
        position: relative; }
      > li:before {
        counter-increment: ol-counter;
        content: counter(ol-counter)".";
        position: absolute;
        top: 0;
        left: -25px; } } }

  .item {
    &.item-card {
      display: flex;
      flex-direction: column;
      height: 100%; }
    .img {
      margin-bottom: 20px;
      .icon {
        height: 70px;
        width: auto;
        display: block;
        margin: 0 auto; } }
    .title {
      font-size: 21px;
      line-height: 36px;
      font-weight: normal; }
    .content {
      @extend %mb-30;
      p:last-child {
        margin-bottom: 0; } }
    .action {
      margin-top: auto;
      a:not(.btn) {
        color: $link;
        text-decoration: underline;
        &:hover {
          color: lighten($link, 15%);
          text-decoration: underline; } } } }

  &.featured {
    .item {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 80px;
      .block__action {
        align-items: center; }
      @include maxtablet {
        padding-bottom: 20px; } }
    .col-md-4 {
      &:nth-last-child(-n+3) {
        .item {
          padding-bottom: 0; } } } }

  &.bg-dark-blue {
    background: #143A72;
    color: #FFF;
    .title,
    p,
    span {
      color: #FFF; }
    .item {
      padding-right: 40px;
      padding-left: 40px;
      border-right: 1px solid rgba(255,255,255,0.1);
      &:last-of-type {
        border-right: 0; } } }

  @include maxtablet {
    &.featured {
      .item {
        padding-left: 0;
        padding-right: 0; } }

    &.bg-dark-blue {
      .item {
        padding-right: 10px;
        padding-left: 10px; } } }

  @include mobile {
    .item {
      &.item-card {
        display: block;
        height: auto; }
      .action {
        margin-top: 0; } } } }

.block-quote {
  font-size: 26px;
  line-height: 36px;
  padding: 0;
  @include mobile {
    font-size: 24px;
    line-height: 34px; } }

.vertical-slider {
  max-width: 570px;
  margin: 0 auto 60px;
  position: relative;
  .slick-list {
    max-height: 375px;
    position: relative; }
  .slick-sliderd {
    overflow: hidden; }
  .slick-sliderd:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 9px;
    box-shadow: 2px 2px 10px 0 rgba(15,41,48,0.1);
    z-index: -1;
    transform: scale(.95); }
  .item {
    padding: 30px;
    height: 350px;
    background: #ffffff;
    box-shadow: 2px 2px 10px 0 rgba(15,41,48,0.1);
    position: relative;
    transform: scale(.95);
    transition: all .6s ease;
    &:hover,
    &:focus,
    &:active,
    &:focus-within {
      outline: none !important; }
    // &.slick-active
    //   @for $item from 1 through 3
    //     &:nth-child(#{$item})
    //       -webkit-transition-delay: #{$item*300}ms
    //       -moz-transition-delay: #{$item*300}ms
    //       -o-transition-delay: #{$item*300}ms
    //       -ms-transition-delay: #{$item*300}ms
    //       transition-delay: #{$item*300}ms
    //   & + .slick-active
    //     margin-top: -325px
    //   z-index: 0
    & + .slick-active + .slick-active {
      transform: scale(.95);
      margin-top: -60px;
      z-index: -1;
      box-shadow: none; }
    &.slick-current.slick-active {
      transform: scale(1);
      margin-top: 0;
      z-index: 5; }
    .quote {
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
      // font-size: 26px
 }      // line-height: 36px
    .account {
      width: 100%;
      padding: 30px;
      position: absolute;
      left: 0;
      bottom: 0;
      .pp-acc {
        width: 50px;
        height: 50px;
        display: inline-block;
        vertical-align: middle;
        .img {
          padding-top: 100%; }
        img {
          max-width: 50px; } }
      .namepos {
        display: inline-block;
        vertical-align: middle;
        margin-left: 20px;
        h4 {
          margin-bottom: 0;
          font-weight: 600; } } } }
  .slick-prev {
    position: absolute;
    top: -20px;
    left: calc(50% - 9px);
    z-index: 2;
    background: transparent;
    border: 0;
    color: transparent;
    cursor: pointer;
    width: 18px;
    padding: 0;
    margin: 0;
    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background-image: url(../images/arrow-up.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; } }
  .slick-next {
    position: absolute;
    top: auto;
    bottom: -40px;
    right: calc(50% - 9px);
    z-index: 2;
    background: transparent;
    border: 0;
    color: transparent;
    cursor: pointer;
    width: 18px;
    padding: 0;
    margin: 0;
    &:before {
      content: "";
      display: block;
      width: 18px;
      height: 18px;
      background-image: url(../images/arrow-down.png);
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center; } }

  @include mobile {
    .slick-list {
      max-height: 450px; }
    .item {
      padding: 15px;
      height: 430px;
      &.slick-active {
        & + .slick-active {
          margin-top: -410px;
          z-index: 0; }
        & + .slick-active + .slick-active {
          transform: scale(.89);
          margin-top: -410px;
          z-index: -1; } }
      .account {
        padding: 20px;
        .namepos {
          max-width: calc(100% - 80px); } } } } }

.blog-wrap {
  .item {
    @extend %pb-60;
    .img-block {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
      overflow: hidden;
      .img {
        display: block;
        width: 100%;
        padding-top: 60%;
        transform: none;
        transition: all .3s linear;
        @include bg-cover; }
      &:hover {
        .img {
          transform: scale(1.025); } } }
    .category {
      display: block;
      position: relative;
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 10px;
      span,
      a {
        display: inline-block;
        color: #777;
        padding: 0 10px;
        transition: all .3s; }
      a:hover {
        color: $primary; } }
    .title {
      font-size: 16px;
      line-height: 24px;
      color: #444;
      transition: all .3s;
      &:last-child {
        margin-bottom: 0; }
      &:hover {
        color: $primary; } } }


  .featured-post {
    @extend %mb-30;
    border-bottom: 1px solid #e2e2e2;
    .item {
      .title {
        font-size: 16px;
        line-height: 24px;
        &:not(:hover) {
          color: #1C1D1E; } } }
    @include mobile {
      .banner-up {
        transition-delay: unset; } } }

  .recommended-post {
    .item {
      .title {
        font-size: 26px;
        line-height: 36px;
        &:not(:hover) {
          color: #1C1D1E; } }
      .info {
        p:last-child {
          margin-bottom: 0; } } } } }

.single-wrap {
  article {
    p {
      margin-bottom: 30px; }

    .block-img {
      @extend %ptd-30, %mb-30;
      img {
        width: 100%; }
      &.img-offset {
        margin-left: -16%;
        margin-right: -16%;
        width: 132%;
        @include maxtablet {
          margin-left: 0;
          margin-right: 0;
          width: 100%; } }
      &:first-child {
        padding-top: 0; }
      &:last-child {
        padding-bottom: 0; } } }

  .share-block {
    .title {
      font-size: 21px;
      line-height: 30px;
      font-weight: normal;
      margin-bottom: 10px; }
    ul {
      padding: 0;
      margin: 0 -10px 30px;
      list-style: none;
      > li {
        display: inline-block;
        padding: 0 10px;
        a {
          font-size: 30px;
          line-height: 30px;
          color: #777;
          display: block;
          opacity: 1;
          transition: all .5s; }
        &:hover a {
          opacity: .5; } } } }


  .related-blog {
    .title {
      font-size: 36px;
      line-height: 48px;
      margin-bottom: 10px; }

    .item {
      display: flex;
      border-bottom: 1px solid #e2e2e2;
      padding: 30px 0;
      background-color: #FFF;
      transition: all .5s;
      .date {
        color: #777;
        min-width: 150px; }
      &:hover {
        padding: 30px 0;
        .content {
          color: $primary; } } }
    @include mobile {
      .item {
        padding: 15px 0; } } } }

.ebook-wrap {
  @extend .pt-90;
  .item {
    padding-bottom: 110px;
    > a {
      display: block; }
    .img {
      box-shadow: 0;
      img {
        display: block;
        // height: 310px
        width: 70%;
        box-shadow: 0px 30px 10px -12px rgba(0, 0, 0, 0.15);
        margin: 0 auto 30px;
        transition: all .5s; } }
    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 0;
      text-align: center; }
    .action {
      text-align: center;
      position: absolute;
      padding-top: 30px;
      width: 100%;
      left: 0;
      bottom: 0;
      opacity: 0;
      transition: all .5s;
      .btn {
        min-width: 60%;
 } }        // width: 100%
    &:hover {
      .img img {
        box-shadow: 0 5px 50px 0 rgba(0,0,0,0.5); }
      .action {
        opacity: 1;
        bottom: 50px; } } }
  @include maxtablet {
    .item {
      padding-bottom: 50px;
      .action {
        position: relative;
        bottom: 0;
        opacity: 1; } } }
  @include mobile {
    .banner-up {
      transition-delay: unset; }
    .item .title {
      font-size: 14px;
      line-height: 20px; } } }

.ebook {
  img {
    display: block;
    height: 350px;
    width: auto;
    box-shadow: 0px 30px 10px -12px rgba(0, 0, 0, 0.15);
    margin: 0 auto 30px;
    transition: all .5s; } }

.tags-block {
  a:hover {
    text-decoration: underline; } }

.small-img {
  padding: 0 15px;
  .item {
    justify-content: space-between;
    &:nth-child(even) {
      flex-direction: row-reverse; }
    + .item {
      @extend %pt-60; } } }

.pricing-wrap .pricing_box {
  .item {
    position: relative;
    padding: 16%;
    margin: 0 -10px;
    border: 1px solid #C9CBCD;
    border-radius: 4px;
    background-color: #F8F9FA;

    .pricing-badge {
      position: absolute;
      top: 30px;
      left: 0;
      text-transform: uppercase;
      color: #FFF;
      background-color: #17A085;
      font-size: 15px;
      font-weight: bold;
      padding: 2px 15px;
      border-radius: 0 5px 5px 0; }

    .title {
      font-size: 36px;
      line-height: 48px;
      font-weight: bold; }

    .price {
      font-size: 18px; }

    a.price {
      color: #F58232;
      text-decoration: underline;
      cursor: pointer;
      margin-bottom: 30px;

      &:hover {
        color: #d87935;
        text-decoration: none; } }

    ul {
      margin: 30px 0px;

      li {
        position: relative;
        color: #545465;
        font-size: 15px;
        line-height: 26px;
        list-style: none;
        padding-left: 25px;

        &::before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 0;
          top: 4px;
          background: transparent url(../images/ic-check.svg) no-repeat center;
          background-size: contain; }

        &.off {
          opacity: 0.5;

          &::before {
            color: #DADADA; } } } } }

  &:nth-child(2) .item {
    position: relative;
    z-index: 2;
    -webkit-transform: scale(1.2);
    transform: scale(1.2);
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
    background-color: #FFF;
    border-top: 5px solid #F58232;
    padding-top: 80px; } }

@media (max-width: 425px) {
  .pricing-wrap .pricing_box .item .pricing-badge {
    display: none; } }

.pricing-desc {
  padding: 60px 0;
  border-bottom: 1px solid #C9CBCD;

  &:last-child {
    border-bottom: 0px; } }

.form-download {
  input[type="text"],input[type="email"] {
    padding: 8px 16px;
    border-radius: 4px;
    border: 1px solid #DFDFDF;
    &:focus {
      border-color: $primary; } }
  .gform_wrapper.gravity-theme .gfield select {
    padding: 8px 4px;
    height: 42px;
    border-radius: 4px;
    border: 1px solid #DFDFDF; }
  .gform_footer {
    text-align: center;
    input[type="submit"] {
      position: relative;
      font-size: 15px;
      line-height: 26px;
      font-weight: 500;
      padding: 7px 20px;
      border: 0;
      border-radius: 4px;
      text-align: center;
      transition: all .3s ease;
      background: $primary;
      color: #FFF;
      box-shadow: 0 16px 4px 0 rgba(103,110,144,0.05), 0 8px 8px 0 rgba(103,110,144,0.05), 0 4px 4px 0 rgba(103,110,144,0.05), 0 2px 2px 0 rgba(103,110,144,0.05);
      &:hover,
      &:active,
      &.active {
        background: darken($primary, 10%); } } }
  .gform_confirmation_wrapper {
    .gform_confirmation_message {
      text-align: center;
      font-size: 18px;
      line-height: 26px;
      a {
        color: $primary;
        text-decoration: underline;
        &:hover {
          color: darken($primary, 10%); } } } } }

.masthead-tax-calculator {
  margin-top: 80px;
  padding: 80px 0;
  h5 {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #777777;
    margin-bottom: 8px;
    text-transform: uppercase; }
  h1 {
    font-weight: bold;
    font-size: 36px;
    line-height: 48px;
    color: #1C1D1E;
    margin-bottom: 15px; }
  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 26px;
    color: #1C1D1E; }
  img {
    max-width: none !important;
    width: 1440px;
    height: 900px;
    margin: -130px 0 0 -450px;
    @include maxtablet {
      margin: -90px 0 0 -150px;
      width: 1000px;
      height: 650px; }
    @include mobile {
      margin: -90px 0 0 -220px;
      width: 750px;
      height: 450px; } } }

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #C8E6C9;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards; }

.checkmark {
  width: 96px;
  height: 96px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #4CAF50;
  stroke-miterlimit: 10;
  margin: 25px auto;
  box-shadow: inset 0px 0px 0px #C8E6C9;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; }

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards; }

@keyframes stroke {
  100% {
    stroke-dashoffset: 0; } }

@keyframes scale {
  0%, 100% {
    transform: none; }

  50% {
    transform: scale3d(1.1, 1.1, 1); } }

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #C8E6C9; } }

.pricing-ao {
  .section-head {
    padding: 40px 0;
    text-align: center;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0; } }
  .item {
    background: #FFF;
    border: 1px solid #e2e2e2;
    padding: 32px 16px;
    border-radius: 4px;
    &__left {
      padding: 0 32px; }
    &__right {
      padding: 32px 32px 0 0; }
    &__icon {
      img {
        display: block;
        height: 40px;
        width: auto;
        margin: 0 0 12px; } }
    &__title {
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 24px; }
    &__desc {
      color: #000000; }
    small {
      display: block;
      font-size: 12px;
      line-height: 18px;
      color: #777;
      margin-bottom: 0; }
    span.big {
      display: block;
      margin-bottom: 16px;
      color: #000000;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500; }
    .pricing-list {
      color: #1C1D1E;
      &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 12px; }
      &__item {
        display: block;
        position: relative;
        margin-bottom: 12px;
        padding-left: 32px;
        &:last-child {
          margin-bottom: 0; }
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 16px;
          height: 16px;
          left: 0;
          top: 4px;
          background: transparent url(../images/ic-check.svg) no-repeat center;
          background-size: contain; } } } }

  &__cta {
    text-align: right;
    .btn {
      min-width: 156px;
      margin-bottom: 24px;
      box-shadow: none;
      .svg {
        display: inline-block;
        vertical-align: middle;
        margin-right: 12px;
        height: 16px;
        width: auto;
        position: relative;
        top: -1px;
        path {
          transition: all .3s ease; } }
      &:hover {
        .svg path {
          fill: #FFF; } } } } }

.small-img--pricing {
  .item {
    justify-content: center;
    align-items: center;
    .title {
      font-size: 26px;
      line-height: 36px;
      font-weight: bold;
      margin-bottom: 16px; } } }

.block-tc {
  .row {
    margin-left: -14px;
    margin-right: -14px;
    &> .col,
    &> [class*="col-"] {
      padding-right: 14px;
      padding-left: 14px; } } }

.tc-card {
  display: flex;
  height: 100%;
  flex-direction: column;
  box-shadow: none;
  transition: all .3s ease;
  &:hover {
    box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75); }
  &__img {
    .img {
      display: block;
      position: relative;
      padding: 62% 0 0;
      overflow: hidden;
      border-radius: 4px 4px 0 0;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; } } }
  &__main {
    padding: 24px;
    background: #FFF;
    border: 1px solid #e2e2e2;
    border-radius: 0 0 4px 4px;
    height: 100%;
    display: flex;
    flex-direction: column; }
  &__title {
    font-size: 21px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 12px;
    a {
      color: #000;
      transition: all .3s ease;
      &:hover {
        color: $primary; } } }
  &__content {
    padding-bottom: 24px;
    p {
      font-size: 15px;
      line-height: 26px;
      margin-bottom: 18px;
      &:last-child {
        margin-bottom: 0; } } }
  &__action {
    margin-top: auto;
    .btn-more {
      color: #1357FF; } } }

.tc-1 {
  background: #F9FAFB;
  padding: 80px 0 54px;
  .section-title {
    margin-bottom: 48px; } }

.tc-2 {
  background: #FFF;
  padding: 80px 0 50px;
  .item {
    text-align: center;
    padding: 30px;
    margin-bottom: 30px;
    border: 1px solid #e2e2e2;
    border-radius: 4px;
    display: block;
    box-shadow: none;
    transition: all .3s ease-in-out;
    &:hover {
      box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75); }
    &__icon {
      margin-bottom: 10px;
      img {
        display: block;
        width: 70px;
        height: 70px;
        margin: 0 auto; } }
    &__title {
      margin-bottom: 0;
      font-weight: normal; } } }

.tc-3 {
  padding: 0;
  &__wrap {
    border-top: 1px solid #e2e2e2; }
  .section-title {
    margin-bottom: 0;
    padding: 80px 0; }
  &__img {
    display: flex;
    height: 100%;
    position: relative;
    img {
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: auto; } } }

.tc-4 {
  padding: 0 0 80px;
  background: #F9FAFB;
  .section-title:last-child {
    margin-bottom: 0; } }

.tc-form {
  padding: 88px 0 120px;
  background: #F9FAFB;
  &__wrap {
    padding: 64px;
    background: #FFF;
    border: 1px solid #e2e2e2; }

  @include mobile {
    padding: 60px 0 80px;
    &__wrap {
      padding: 24px; } } }

.tc-result {
  padding: 88px 0 120px;
  background: #F9FAFB;
  &__wrap {
    background: #FFF;
    border: 1px solid #e2e2e2; }

  .section-title {
    padding: 64px 64px 32px;
    margin-bottom: 0;
    border-bottom: 1px solid #e2e2e2;
    p:last-child {
      margin-bottom: 0; } }

  &__main {
    padding: 0 64px;
    display: flex;
    flex-wrap: wrap;
    label {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #777;
      margin-bottom: 2px; }
    span.text {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: #212121; }
    span.text-lg {
      display: block;
      font-size: 32px;
      line-height: 40px;
      font-weight: 700;
      color: $primary; }
    h3 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
      font-weight: 500; }
    .item-group {
      padding-bottom: 16px;
      &:last-child {
        padding-bottom: 0; } }

    .block-gaji {
      padding: 24px 0;
      border-bottom: 1px solid #e2e2e2;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%; }
    .block-tunjangan {
      padding: 24px 0;
      width: 100%;
      max-width: 35%;
      flex: 0 0 35%; }
    .block-potongan {
      padding: 24px 0;
      width: 100%;
      max-width: 65%;
      flex: 0 0 65%;
      border-left: 1px solid #e2e2e2;
      padding-left: 60px; }
    .block-pay {
      padding: 24px 0 40px;
      border-top: 1px solid #e2e2e2;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%; } } }

.tc-main {
  background: #F9FAFB;
  padding-bottom: 120px;
  &__wrap {
    background: #FFF;
    border: 1px solid #e2e2e2; }

  .field {
    padding: 40px 64px 16px;
    border-bottom: 1px solid #e2e2e2;
    &:last-child {
      border-bottom: 0; }

    h3 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px; }

    label {
      font-size: 14px;
      line-height: 20px;
      display: block;
      min-height: 20px;
      width: 100%;
      position: relative;
      a {
        color: #1357FF;
        display: inline-block;
        margin-left: auto;
        font-weight: 400;
        float: right;
        &:hover {
          color: darken(#1357FF, 15%);
          text-decoration: underline; } }
      span.required {
        color: $red; } }

    .form-control {
      box-shadow: none; }

    .btn-link {
      text-decoration: underline;
      color: #1357FF;
      &:hover {
        color: darken(#1357FF, 15%); } }

    .bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
      width: 100%; }
    div.dropdown.bootstrap-select:not(.select-btn) .dropdown-toggle {
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      color: #777;
      padding-left: 16px;
      &:after {
        right: 16px;
        width: 12px;
        height: 12px;
        top: calc(50% - 6px); } }
    .dropdown-item.active, .dropdown-item:active {
      background: $primary; }

    .form-action {
      text-align: right;
      padding-bottom: 16px;
      .btn-link {
        color: #777;
        text-decoration: none;
        &:hover {
          color: #1357FF; } }
      .btn-blue {
        min-width: 120px; } } } }

.form-tax-calculator {
  ul {
    li {
      border: 0!important;
      padding: 0!important;
      margin-bottom: 50px;
      label {
        font-style: 15px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 5px;
        display: block; }
      input {
        width: 100%;
        padding: 10px 15px;
        height: 40px;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.1);
        &:hover {
          box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.1);
          border-color: #e2e2e2; } } } }
  .gform_footer {
    input[type="submit"] {
      background: #005FBF;
      border: 1px solid #005FBF;
      box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75);
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFF;
      width: 100%;
      padding: 10px;
      &:hover {
        background: #00468c; } } } }

.list-block {
  &__wrap {
    padding-top: 5rem;
    padding-bottom: 5rem; }
  .custom-list {
    margin-left: 1.5rem; }
  @include maxtablet {
    [class*="col-"]:first-child {
      margin-bottom: 2rem; }
    .custom-list {
      margin-left: 0; } } }

.oppst-block-title {
  text-align: center;
  padding-top: 80px;
  &__title {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 24px; }
  p:last-child {
    margin-bottom: 0; } }
.oppst-block {
  .row {
    padding-top: 64px;
    padding-bottom: 64px;
    &:nth-child(even) {
      flex-direction: row-reverse; } }
  &.oppst-reverse {
    .row {
      &:nth-child(even) {
        flex-direction: row; }
      &:nth-child(odd) {
        flex-direction: row-reverse; } } }

  @include maxtablet {
    [class*="col-"]:first-child {
      margin-bottom: 2rem; }
    .row:nth-child(even) {
      flex-direction: row; } } }

.testimonial-big {
  padding-top: 80px;
  margin-bottom: 32px;
  &__wrap {
    display: flex;
    background: #FFF;
    box-shadow: 0px 2px 8px rgba(180, 188, 208, 0.4);
    border-radius: 4px;
    padding: 60px 60px 48px 80px; }
  &__img {
    position: relative;
    display: block;
    width: 100%;
    max-width: 36.163%;
    flex: 0 0 36.163%;
    .block-img {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 81.632%; }
    .img {
      position: relative;
      z-index: 1;
      display: block;
      width: 100%;
      height: auto;
      position: absolute;
      left: 0;
      bottom: 0; }
    .play {
      display: block;
      position: absolute;
      right: -10%;
      bottom: -5%;
      z-index: 2;
      width: 28.5%;
      border-radius: 50%;
      .svg,
      img {
        width: 100%;
        height: auto;
        display: block; } } }
  &__content {
    padding-left: 90px; }
  &__category {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    text-transform: uppercase;
    position: relative;
    padding: 2px 0 2px 28px;
    margin-bottom: 28px;
    .icon {
      display: inline-block;
      height: 24px;
      width: 24px;
      position: absolute;
      left: 0;
      top: 0; } }
  &__blockquote {
    display: block;
    margin: 0 0 24px;
    font-size: 20px;
    line-height: 32px;
    color: $black; }
  &__footer {
    position: relative;
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    img {
      display: block;
      max-height: 56px;
      width: auto; } }
  &__author {
    padding: 16px 24px;
    font-size: 14px;
    line-height: 20px;
    &:not(:first-child) {
      border-left: 1px solid $border;
      margin-left: 24px; }
    span {
      display: block;
      &+ span {
        margin-top: 4px; } }
    .author__name {
      text-transform: uppercase;
      color: $black;
      font-weight: 500; } }

  @include mobile {
    padding-top: 60px;
    &__wrap {
      padding: 30px 30px 24px 40px; }
    &__content {
      padding-left: 45px; } }
  @include mobilesm {
    &__wrap {
      flex-wrap: wrap;
      padding: 24px; }
    &__img {
      max-width: 80%;
      flex: 0 0 80%;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 32px; }
    &__content {
      padding-left: 0; }
    &__blockquote {
      font-size: 18px;
      line-height: 26px; }
    &__footer {
      flex-wrap: wrap; }
    &__author {
      padding: 0;
      width: 100%;
      &:not(:first-child) {
        border-left: 0;
        margin-top: 24px;
        margin-left: 0; } } } }

.custom-form {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  .container-full {
    height: 100%;
    &> .row {
      height: 100%; } }

  &__meta {
    background: #F2F4F7;
    padding: 120px 5vw 24px;
    height: 100%;
    &-logo {
      margin-bottom: 64px;
      display: none;
      @include mobile {
        display: none; } }

    &-illustration {
      text-align: center;
      margin-bottom: 64px;
      img {
        display: block;
        max-width: 100%;
        height: auto; } }

    &-desc {
      ul {
        margin-bottom: 30px;
        padding-left: 30px; }
      p {
        a {
          text-decoration: underline;
          &:hover {
            text-decoration: underline;
            color: $primary; } } } }

    .testimonial__wrap {
      background: transparent;
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 80px;
      .testimonial-big__content {
        padding: 0;
        .testimonial-big__footer {
          img {
            width: auto!important; } } }
      .owl-dots {
        padding: 0;
        text-align: left;
        button.owl-dot {
          background: #E2E2E2;
          &.active {
            background: $primary; } } } }

    &-footer {
      position: relative;
      bottom: 0;
      label {
        font-weight: 500;
        display: block;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 15px; }
      @include mobile {
        position: relative; } }
    &-copyright {
      display: none;
      @include mobile {
        display: block; } }
    &-icon {
      margin-bottom: 48px;
      a {
        margin-right: 25px; } }
    @include mobile {
      padding: 60px 16px 30px; } }

  &__form {
    padding: 48px 5vw 24px 15vw;
    &-logo {
      display: block;
      margin-bottom: 50px; }
    &-title {
      font-weight: 700;
      font-size: 2rem;
      line-height: 2.5rem;
      margin-bottom: 1rem; }
    &-subtitle {
      font-size: 1rem;
      line-height: 1.5rem;
      margin-bottom: 4px; }
    &-desc {
      margin-bottom: 1.5rem;
      .custom-list {
        position: relative;
        margin-bottom: 32px;
        li {
          position: relative;
          display: inline-block;
          padding-left: 36px;
          margin-bottom: 16px;
          margin-right: 16px;
          &:before {
            content: "";
            display: block;
            width: 20px;
            height: 20px;
            background: url(../images/ic-check.svg) no-repeat center;
            background-size: contain;
            position: absolute;
            top: 0;
            left: 0; }
          &:last-child {
            margin-bottom: 0; } } } }
    &-copyright {
      margin-top: 30px;
      display: block;
      text-align: left;
      p {
        margin-bottom: 0; }
      @include mobile {
        display: none; } }
    @include mobile {
      padding: 40px 16px 30px;
      &-logo {
        margin-bottom: 40px;
        img {
          height: 32px;
          width: auto; } }
      &-title {
        font-size: 26px;
        line-height: 36px; } } }

  &__clients {
    .block-clients__wrap {
      padding-top: 48px; }
    .block-clients__title {
      text-align: left;
      font-size: 15px;
      line-height: 26px;
      font-weight: 700; }
    .block-clients__items {
      justify-content: flex-start;
      .item {
        max-width: 33.333333%;
        flex: 0 0 33.333333%; } } } }

.sidebar {
  &__wrap {
    position: relative; }
  &__data {
    padding: 24px 0;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    display: flex;
    flex-wrap: wrap;
    h4,
    span {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      margin: 0; }
    span {
      margin-left: auto;
      text-align: right; }
    p {
      display: block;
      width: 100%;
      margin: 8px 0 0; } } }

// .cs-detail
//   margin-top: 16px
//   padding-bottom: 80px
//   .block-append
//     background: #F9FBFD
//     border-radius: 4px
//     padding: 30px 40px
//     margin-bottom: 64px
//     h3
//       position: relative
//       font-size: 32px
//       line-height: 48px
//       margin: 0
//       small
//         color: $blue
//         display: inline-block
//         vertical-align: top
//         font-size: 20px
//         line-height: 32px
//         margin: 0 4px
//         position: relative
//         top: -2px
//     p
//       font-size: 14px
//       line-height: 20px
//     .row
//       margin: 0 -30px
//     .item
//       padding: 10px 30px
//       position: relative
//       &:not(:last-child):after
//         content: ""
//         display: block
//         height: calc(100% - 28px)
//         width: 1px
//         background: $border
//         position: absolute
//         right: 0
//         top: 14px

//   article
//     color: $secondary
//     .text-category
//       display: block
//       font-size: 16px
//       line-height: 24px
//       font-weight: 400
//       margin-bottom: 4px
//     h1
//       display: block
//       font-size: 48px
//       line-height: 56px
//       font-weight: 700
//       color: $black
//       margin-bottom: 16px
//     h2
//       display: block
//       font-size: 32px
//       line-height: 48px
//       font-weight: 600
//       color: $black
//       margin-bottom: 16px
//     h3
//       display: block
//       font-size: 20px
//       line-height: 32px
//       font-weight: 600
//       color: $black
//       margin-bottom: 8px
//     p
//       margin-bottom: 40px
//       &:last-child
//         margin-bottom: 0
//     a
//       color: $link
//     blockquote
//       font-size: 1.25em
//       font-weight: 600!important
//       line-height: 1.5
//       position: relative
//       clear: both
//       margin-bottom: 1em
//       padding: 1em 50px
//       background-color: #fafafa
//       font-family: gdsherpa, Helvetica Neue, Helvetica, Arial, sans-serif!important
//       positin: relative
//       font-style: normal
//       margin-left: 0
//       margin-right: 0
//       span
//         font-weight: 600!important
//       &::before
//         content: ""
//         top: 10px
//         left: 15px
//         width: 25px
//         height: 25px
//         font-size: 64px
//         position: absolute
//         display: block
//         color: #e6e6e6
//         background: url(../images/quote-left.svg) no-repeat center
//         background-size: contain
//         filter: invert(.7)
//       &::after
//         content: ""
//         right: 15px
//         bottom: 15px
//         width: 25px
//         height: 25px
//         font-size: 64px
//         position: absolute
//         display: block
//         color: #e6e6e6
//         background: url(../images/quote-right.svg) no-repeat center
//         background-size: contain
//         filter: invert(.7)
//     code
//       display: inline-block
//       font-style: normal
//       font-family: gd-sage, Helvetica Neue, Helvetica, Arial, sans-serif!important
//       font-weight: 600!important
//       font-size: 22px
//       line-height: 1.5
//       position: relative
//       clear: both
//       margin-bottom: 24px
//       padding: 5px 30px
//       color: $black
//       &::before
//         content: ''
//         display: block
//         position: absolute
//         top: 0
//         left: 0
//         width: 6px
//         height: 100%
//         background: $red
//     mark
//       display: block
//       background: #EDF6FB
//       border: 2px solid #005FBF
//       border-radius: 4px
//       margin-bottom: 24px
//       position: relative
//       padding: 24px
//       a
//         color: #005FBF
//         text-decoration: none
//         &:hover
//           text-decoration: underline

//   &__sidebar
//     .sidebar
//       margin-top: -200px
//       &__wrap
//         background: #F58232
//         border-radius: 8px
//         padding: 0 24px
//         color: #FFF
//       &__data
//         span
//           font-weight: 400

//   &.animate
//     transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1)
//     opacity: 0
//     transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1)
//   &.animate-in
//     transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1)
//     opacity: 1

//   +mobile
//     &__sidebar .sidebar
//       margin-top: 48px

.our-customer,
.case-studies {
  .marquee-container {
    height: 90px; }
  &__title {
    max-width: 650px;
    margin: 0 auto 16px;
    text-align: center; }
  &__content {
    max-width: 600px;
    margin: 0 auto 48px;
    text-align: center; }
  &__slider {
    white-space: nowrap;
    display: flex;
    margin-bottom: 32px;
    opacity: 1;
    transition: all .3s ease-in-out;
    .item {
      display: inline-flex;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 56px;
      position: relative;
      padding: 0 20px;
      img {
        display: block;
        width: auto;
        height: auto;
        max-height: 56px; } }
    .owl-stage-outer {
      overflow: visible; } }
  &__action {
    text-align: center;
    margin-bottom: 64px; }
  &__toolbar {
    padding-top: 10px;
    padding-bottom: 56px;
    position: relative;
    z-index: 2;
    .dropdown.bootstrap-select {
      width: auto;
      display: inline-block;
      .bttn {
        width: auto;
        min-width: 100px;
        padding: 0 30px 0 12px;
        font-size: 14px;
        line-height: 34px;
        &:after {
          top: 0;
          line-height: 36px; } }
      .dropdown-menu {
        min-width: 220px !important;
        .inner {
          overflow-x: hidden; } } }
    .slider-category {
      position: relative;
      overflow: hidden;
      &__nav {
        z-index: 2;
        position: absolute;
        right: 0;
        top: 0;
        display: flex;
        width: 100%;
        height: 0; }
      &__wrap {
        z-index: 1;
        position: relative;
        white-space: nowrap;
        display: flex;
        width: fit-content; }
      .item-nav {
        width: 60px;
        height: 36px;
        background: #FFF;
        background: linear-gradient(90deg, rgba(255,255,255,1) 70%, rgba(255,255,255,0) 100%);
        padding: 3px 0;
        cursor: pointer;
        .svg {
          display: block;
          path {
            fill: $secondary;
            transition: all .3s ease-in-out; } }
        &.prev {
          display: none; }
        &.next {
          margin-left: auto;
          background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 40%);
          .svg {
            margin-left: auto; } }
        &:hover {
          .svg path {
            fill: $black; } } }
      .item {
        display: inline-block;
        margin: 0 4px;
        padding: 6px 8px;
        border-radius: 4px;
        &__link {
          color: $secondary;
          &:hover {
            color: $blue; } }
        &.active {
          background: #F2F4F7;
          .item__link {
            color: $black;
            font-weight: 400; } } } } }
  &__posts {
    position: relative; }

  .slider {
    .owl-item {
      &> div {
        max-width: 100%; } } }

  .post {
    margin-bottom: 40px;
    &__img {
      position: relative;
      cursor: pointer;
      .play {
        position: absolute;
        z-index: 2;
        top: calc(50% - 24px);
        left: calc(50% - 24px);
        .icon {
          position: relative;
          width: 48px;
          height: 48px;
          display: block;
          z-index: 2; }
        &:after {
          z-index: 1;
          content: "";
          display: block;
          width: 64px;
          height: 64px;
          border-radius: 32px;
          background: $primary;
          opacity: .2;
          position: absolute;
          top: calc(50% - 32px);
          left: calc(50% - 32px);
          transform: scale(.5);
          transition: all .5s ease-in-out; } }
      .block-img {
        position: relative;
        display: block;
        width: 100%;
        padding-top: 56.25%;
        overflow: hidden;
        border-radius: 4px 4px 0 0;
        img {
          position: absolute;
          display: block;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          z-index: 1; } }
      &:hover {
        .play:after {
          transform: scale(1); } } }

    &__content {
      padding: 24px;
      border: 1px solid $border;
      border-top: 0;
      border-radius: 0 0 4px 4px; }
    &__title {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 4px;
      a {
        color: $black; }
      &:hover a {
        color: $blue; } }
    &__category {
      display: block;
      margin-bottom: 4px;
      a {
        color: $secondary; }
      &:hover a {
        color: $secondary;
        text-decoration: underline; } }
    p {
      display: block;
      padding-top: 8px;
      margin-bottom: 16px; } }
  &__cta {
    text-align: center; }

  &--home {
    position: relative;
    background: #F2F4F7;
    padding: 64px 0;
    .our-customer__title {
      font-size: 32px;
      line-height: 48px;
      max-width: 768px;
      margin-bottom: 24px;
      @include inter-600(); }
    .our-customer__action {
      margin-bottom: 0; } }


  &--m-overview {
    padding-top: 80px;
    padding-bottom: 80px;
    .our-customer__slider {
      margin-bottom: 0; } }

  &--pricing {
    padding-top: 80px;
    padding-bottom: 80px;
    .our-customer__slider {
      margin-top: 48px;
      margin-bottom: 0; } }

  &--solusi {
    padding-top: 80px;
    padding-bottom: 80px;
    .our-customer__title {
      margin-bottom: 32px; }
    .our-customer__slider {
      margin-bottom: 40px; } }


  @include maxtablet {
    &__title {
      font-size: 20px;
      line-height: 32px; }
    &__toolbar {
      .right {
        padding-top: 16px; } }
    &--home {
      padding-top: 48px;
      padding-bottom: 48px;
      .our-customer__title {
        font-size: 28px;
        line-height: 36px; } }
    &--m-overview,
    &--pricing {
      padding-top: 60px;
      padding-bottom: 60px; } } }

.case-studies {
  padding-bottom: 80px;
  .post {
    margin-bottom: 48px;
    &.animate {
      transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 0;
      transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
    &.animate-in {
      transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
      opacity: 1; } }
  &--related {
    padding: 80px 0;
    background: #F8FAFC;
    .post {
      margin-bottom: 0; }
    .section-head {
      position: relative;
      padding-bottom: 24px;
      text-align: left;
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin: 0;
        padding-right: 220px; }
      &__action {
        position: absolute;
        top: 0;
        right: 30px; } } }


  @include mobilesm {
    padding-bottom: 60px;
    .post {
      margin-bottom: 32px; } } }

.post {
  position: relative;
  padding-bottom: 48px;
  padding-left: 16px;
  padding-right: 16px;
  &__img {
    position: relative;
    z-index: 2;
    .block-img {
      position: relative;
      display: block;
      width: 100%;
      padding-top: 66.666667%;
      overflow: hidden;
      border-radius: 4px;
      margin-bottom: 20px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0; } }
    &> img {
      height: 220px;
      width: 392px;
      object-fit: cover;
      border-radius: 4px 4px 0 0; } }
  &__title {
    position: relative;
    z-index: 2;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 12px;
    font-weight: 600;
    padding-left: 30px;
    .icon {
      display: block;
      position: absolute;
      top: 3px;
      left: 0; } }
  &__content {
    position: relative;
    z-index: 2;
    color: $black;
    margin-bottom: 20px; }
  &__meta {
    margin-bottom: 12px;
    span {
      display: inline-block; }
    .name {
      font-weight: 600;
      color: $black;
      margin-right: 6px;
      font-size: 14px;
      line-height: 20px;
      &:after {
        content: ","; } } }
  &__logo {
    img {
      max-height: 56px;
      width: auto;
      display: block; } }
  &__footer {
    position: relative;
    z-index: 2; }
  .link-more {
    position: absolute;
    z-index: 4;
    left: 16px;
    bottom: 20px;
    opacity: 0;
    transition: all .3s ease-in-out; }
  &:before {
    content: "";
    z-index: 1;
    display: block;
    width: calc(100% + 32px);
    height: calc(100% + 48px);
    position: absolute;
    top: -24px;
    left: -16px;
    background: #FFF;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    opacity: 0;
    transform: scale(0.9);
    transition: all .3s ease-in-out; }
  &:hover {
    z-index: 2;
    .link-more {
      bottom: 0;
      opacity: 1; }
    &:before {
      opacity: 1;
      transform: none; } }


  &.animate {
    transform: translateX(0px) translateY(100px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 0;
    transition: 1000ms cubic-bezier(0.215, 0.61, 0.355, 1); }
  &.animate-in {
    transform: translateX(0px) translateY(0px) translateZ(0px) rotate(0deg) scale(1, 1);
    opacity: 1; } }

.case-studies {
  padding: 60px 0;
  .post {
    padding: 0;
    &__title {
      padding-left: 0; }
    &__content {
      margin-top: -20px; }
    .link-more {
      position: relative;
      left: 0;
      bottom: 0;
      opacity: 1;
      z-index: 0; }
    &::before {
      display: none; }
    &:hover {
      &::before {
        display: none; } } } }

.wrap.inapp {
  max-width: 100vw;
  overflow: hidden;
  @include robo-400();
  h1, h2, h3, h4 {
    font-family: "Roboto",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 600; }
  .btn {
    @include robo-500(); }
  .btn-blue {
    box-shadow: none; }
  .btn-white {
    background: #FFF;
    color: #777;
    &:hover {
      background: $blue;
      color: #FFF; } }
  .section-head {
    &__title {
      font-size: 24px;
      line-height: 32px;
      @include robo-500();
      margin: 0 0 24px; }
    &__action {
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin-left: 8px;
        margin-right: 8px; } } }

  .masthead {
    // background: linear-gradient(180.29deg, #FFF7ED 29.25%, #FFBE78 99.79%)
    background: #EDF0F2;
    ul.custom-list {
      &:last-child {
        margin-bottom: 0; }
      &> li {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0; } } }
    &__wrap {
      padding-top: 40px;
      padding-bottom: 24px; }
    &__logo {
      img {
        display: block;
        height: 40px;
        width: auto;
        margin: 0 0 32px; } }
    &__title {
      font-size: 32px;
      line-height: 40px;
      @include robo-600();
      max-width: 600px;
      margin: 0 0 16px; }
    &__desc {
      margin: 0 0 32px; }
    &__action {
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin-left: 8px;
        margin-right: 8px; } }
    &__footer {
      padding-top: 44px;
      .title {
        display: block;
        font-size: 12px;
        line-height: 16px;
        @include robo-400();
        margin: 0 0 8px; }
      .logos {
        margin-left: -6px;
        margin-right: -6px; }
      .logo {
        display: inline-block;
        vertical-align: top;
        padding-left: 6px;
        padding-right: 6px;
        img {
          display: block;
          max-height: 56px;
          width: auto;
          height: auto; } } }
    &__right {
      margin-top: -40px;
      margin-bottom: -24px;
      padding-right: 0;
      display: flex;
      width: 100%;
      align-items: stretch;
      justify-content: flex-end;
      .masthead__media {
        display: flex;
        position: relative;
        align-items: flex-end;
        justify-content: flex-end;
        img {
          display: block;
          width: 100%;
          height: auto; } } }
    @include maxtablet {
      .masthead__right {
        margin-top: 0; } } }

  .block-manfaat {
    padding: 64px 0;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 20px; }
    .accordion--images {
      .img {
        padding-right: 24px; } } }

  .cta-banner {
    background: #3D465A;
    color: #FFF;
    .btn-link {
      color: #fff;
      text-decoration: underline;
      &:hover {
        color: #FFF;
        text-decoration: underline;
        opacity: 0.5; } }
    &__wrap {
      padding-top: 60px; }
    &__img {
      position: relative;
      padding-left: 5%;
      img {
        display: block;
        width: 100%;
        height: auto; } }
    @include maxtablet {
      .cta-banner__img {
        padding: 32px 0 0; } } } }

.industry-wrap {
  padding: 64px 0 32px; }

.industry-items {
  .item {
    margin: 0 0 48px;
    &__wrap {
      position: relative; }
    &__icon {
      position: absolute;
      top: 0;
      left: 0; }
    &__img {
      position: relative;
      margin-bottom: 16px;
      width: 100%;
      padding-top: 56%;
      background-color: #C4C4C4;
      overflow: hidden;
      border-radius: 4px;
      img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center; } }
    &__title {
      margin-bottom: 12px;
      a {
        display: block;
        color: #000;
        font-size: 21px;
        line-height: 26px;
        font-weight: 500; } }
    &__desc {
      margin-bottom: 16px;
      p:last-child {
        margin-bottom: 0; } }
    &__content {
      padding-left: 32px;
      position: relative; } } }

.industry-cta {
  margin-bottom: 80px;
  &__box {
    background: #EDF6FA;
    border: 1px solid #A0D4EB;
    border-radius: 4px;
    padding: 64px 32px;
    text-align: center; }
  &__icon {
    margin-bottom: 16px; }
  &__title {
    color: #1C1D1E;
    margin-bottom: 12px;
    font-size: 26px;
    line-height: 36px;
    font-weight: bold; }
  &__desc {
    max-width: 727px;
    margin: 0 auto 28px;
    p:last-child {
      margin: 0; } }
  @include maxtablet {
    padding-left: 12px;
    padding-right: 12px;
    &__box {
      padding: 24px 16px; } } }

.clients-block {
  padding: 80px 0 64px;
  &__title {
    text-align: center;
    color: #1C1D1E;
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
    &:last-child {
      margin-bottom: 24px; } }
  &__desc {
    text-align: center;
    margin: 0 auto 48px;
    max-width: 600px;
    p:last-child {
      margin-bottom: 0; } }
  &__action {
    padding-top: 48px;
    text-align: center; }
  .item {
    &__title {
      font-weight: normal;
      a {
        display: block;
        font-size: 21px;
        line-height: 26px;
        font-weight: 500;
        margin-bottom: 4px; }
      span {
        display: block;
        font-size: 15px;
        line-height: 26px;
        font-weight: 400; } }
    &__desc {
      margin-bottom: 16px;
      p:last-child {
        margin: 0; } }
    &__content {
      padding: 24px;
      background: #FFF;
      border: 1px solid #E2E2E2;
      border-radius: 0 0 4px 4px; }
    &__img {
      border-radius: 4px 4px 0 0;
      overflow: hidden; } }
  &__slider {
    display: flex;
    align-items: center; }
  &__list {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include mobile {
      flex-wrap: wrap;
      .item {
        width: 100%;
        max-width: 33.333333%;
        flex: 0 0 33.333333%; } } } }

.list-block--industry {
  .section-head {
    margin-bottom: 48px;
    &__desc {
      p:last-child {
        margin: 0; } } } }

.industry-featured {
  padding: 80px 0;
  .item {
    &__icon {
      margin-bottom: 24px;
      img {
        display: block;
        height: 72px;
        width: auto; } }
    &__title {
      margin-bottom: 12px;
      font-size: 21px;
      line-height: 36px;
      font-weight: 400;
      color: #1C1D1E; }
    &__desc {
      p:last-child {
        margin-bottom: 0; } } }
  @include mobile {
    .item {
      margin-bottom: 48px;
      &:last-child {
        margin-bottom: 0; }
      &__wrap {
        position: relative;
        padding-left: 64px; }
      &__icon {
        position: absolute;
        top: 8px;
        left: 0;
        img {
          width: 48px;
          height: auto; } }
      &__title {
        font-size: 18px;
        line-height: 26px; } } } }

.faq-block {
  padding-top: 64px;
  padding-bottom: 64px;
  .section-head {
    max-width: 416px;
    &__subtitle {
      color: #232933;
      display: block;
      margin: 0 0 8px; }
    &__title {
      margin: 0 0 24px; } }
  .block__action {
    margin-bottom: 40px; }
  ul {
    padding-left: 20px;
    list-style: disc;
    li {
      margin-bottom: 4px; } }
  ol {
    padding-left: 20px;
    list-style: decimal;
    li {
      margin-bottom: 4px; } }
  @include maxtablet {
    padding: 32px 0;
    .section-head {
      margin-bottom: 24px;
      width: 100%;
      max-width: 100%;
      &__subtitle {
        font-size: 16px;
        line-height: 24px; }
      &__title {
        font-size: 28px;
        line-height: 36px; } } } }

.page-contact {
  padding-top: 80px;
  padding-bottom: 80px;
  .show-sm {
    display: none; }
  .hide-sm {
    display: flex; }
  &__masthead {
    text-align: center;
    max-width: 600px;
    margin: 0 auto;
    .sub {
      color: #2B2A35;
      font-size: 18px;
      line-height: 28px;
      display: block;
      margin-bottom: 8px; }
    .title {
      color: #2B2A35;
      font-size: 48px;
      line-height: 56px;
      font-weight: 700;
      margin-bottom: 24px; } }
  &__cards {
    padding-top: 40px;
    padding-bottom: 24px;
    .item {
      position: relative;
      &__icon {
        margin-bottom: 12px;
        img {
          display: block;
          height: 64px;
          width: auto;
          margin: 0 auto; } }
      &__title {
        color: #2B2A35;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 16px; }
      &__desc {
        p {
          color: #2B2A35; } }
      &__wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        background: #F2F4F7;
        padding: 32px;
        text-align: center;
        border-radius: 4px; }
      &--sm {
        margin-bottom: 12px;
        .item__icon {
          margin: 0;
          img {
            height: 40px;
            width: auto; } }
        .item__title {
          margin: 0;
          padding-left: 16px;
          font-size: 16px;
          line-height: 24px; }
        .item__wrap {
          padding: 8px 24px;
          flex-direction: row;
          align-items: center; } } } }

  @include mobile {
    padding-top: 48px;
    padding-bottom: 40px;
    .show-sm {
      display: flex; }
    .hide-sm {
      display: none; }
    &__masthead .title {
      font-size: 26px;
      line-height: 36px;
      max-width: 80%;
      margin: 0 auto 32px; }
    &__cards {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 12px;
      padding-right: 12px;
      .item {
        margin-bottom: 12px;
        &__icon img {
          height: 48px;
          width: auto; }
        &__title {
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 12px; } } } } }

.form-contact {
  background: #F8FAFC;
  padding: 64px 0 0;
  &__head {
    position: relative;
    display: block; }
  &__img {
    position: relative;
    img {
      display: block;
      max-width: 90%;
      width: 392px;
      height: auto;
      margin: 0 auto 48px; } }
  &__content {
    margin-bottom: 64px;
    p {
      font-size: 18px;
      line-height: 28px; }
    ul {
      padding-left: 24px;
      margin-top: -12px; } }
  &__wrap {
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    border-radius: 8px;
    padding: 40px;
    margin-bottom: 64px; }

  @include maxtablet {
    &__wrap {
      padding: 24px; } } }

.modal-success {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2000;
  background: rgba(0,0,0,0.6);
  display: none;
  align-items: center;
  justify-content: center;
  &__wrap {
    position: relative;
    padding: 64px 32px; }
  &__box {
    position: relative;
    text-align: center;
    padding: 48px;
    background: #FFF;
    border-radius: 4px;
    width: 600px;
    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      opacity: 1;
      transition: all .4s ease;
      &:hover {
        opacity: .5; } }
    .icon {
      margin-bottom: 24px;
      img {
        display: block;
        margin: 0 auto; } }
    h2 {
      font-size: 24px;
      line-height: 1.333em;
      font-weight: 600;
      margin-bottom: 24px; }
    p {
      margin: 0; } }

  @include mobile {
    &__wrap {
      padding: 0; }
    &__box {
      width: 300px;
      margin: 0;
      padding: 48px 24px;
      h2 {
        font-size: 20px;
        line-height: 28px; }
      .icon img {
        height: 56px;
        width: auto; } } } }


// ========================
//    NEW STYLE - REVAMP
// ========================

.mastheadrvmp {
  &__logo {
    margin-bottom: 32px;
    img {
      display: block;
      height: 40px;
      width: auto; } }
  &__title {
    display: block;
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 24px;
    letter-spacing: -1px; }
  &__sub {
    display: block;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 8px;
    &.theme {
      color: #F96D01;
      @include inter-600(); } }
  &__desc {
    margin-bottom: 40px;
    p {
      color: #545465;
      &:last-child {
        margin-bottom: 0; } } }
  &__buttons {
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 8px; } }
  &__img {
    img {
      display: block;
      width: 100%;
      height: auto;
      border-radius: 4px; } }
  &__wrap {
    padding-top: 30px;
    padding-bottom: 30px; }

  &__form {
    padding: 32px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    .mastheadrvmp__form-title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
      color: #232933;
      @include inter-600(); } }


  @include maxtablet() {
    &__title {
      font-size: 32px;
      line-height: 40px; }
    &__img {
      padding: 48px 24px 0; }
    &__buttons {
      a,.btn {
        display: block;
        width: 100%;
        margin-bottom: 14px;
        margin-left: 0;
        &:last-child {
          margin-bottom: 0; } } } } }

.block-clients {
  &__title {
    font-size: 32px;
    line-height: 40px;
    @include inter-600();
    text-align: center;
    margin-bottom: 57px; }
  &__buttons {
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 8px; } }
  &__items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -12px;
    margin-right: -12px;
    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 16px;
      padding-right: 16px;
      width: 100%;
      max-width: 16.666666%;
      flex: 0 0 16.666666%;
      img {
        display: block;
        height: auto;
        width: auto;
        max-height: 64px;
        max-width: 100%;
        margin: 0 auto; } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  &--contact {
    .block-clients__wrap {
      padding-top: 0;
      padding-bottom: 80px; }
    @include maxtablet() {
      .block-clients__title {
        font-size: 15px;
        line-height: 26px;
        font-weight: 700; } } }

  @include maxtablet() {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px;
      br {
        display: none; } }
    &__items {
      margin-left: -8px;
      margin-right: -8px;
      .item {
        max-width: 33.333333%;
        flex: 0 0 33.333333%;
        padding-left: 8px;
        padding-right: 8px; } } } }


.block-sections {
  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 24px; }
  &__desc {
    margin-bottom: 24px;
    text-align: center;
    p {
      color: #545465;
      &:last-child {
        margin-bottom: 0; } } }
  &__buttons {
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 8px; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }



  @include maxtablet() {
    &__title {
      font-size: 24px;
      line-height: 32px; }
    .media {
      margin-bottom: 24px; } } }

.block-sections__oppst {
  .block-item {
    &__title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 16px; }
    &__desc {
      margin-bottom: 24px;
      p {
        color: #545465;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } } }
    &__buttons {
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 8px; } }
    &__img {
      img {
        display: block;
        width: 100%;
        height: auto;
        margin: 0 auto; } }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px;
      align-items: center; } }
  .block-item:nth-child(even) {
    .block-item__wrap {
      flex-direction: row-reverse; } }

  @include maxtablet() {
    .block-item {
      &__title {
        font-size: 24px;
        line-height: 32px; }
      &__buttons {
        margin-left: 0;
        margin-right: 0;
        .btn {
          width: 100%;
          margin: 0 0 12px; } }
      &__img {
        margin-bottom: 18px; }
      &__wrap {
        padding-top: 32px;
        padding-bottom: 32px; } } } }

.testimoni-block {
  position: relative;
  padding: 64px 0;
  &:before {
    content: "";
    display: block;
    width: 75vw;
    height: 100%;
    background: #F2F4F7;
    position: absolute;
    top: 0;
    right: 0; }
  &.reverse:before {
    right: auto;
    left: 0; }
  &__img {
    display: block;
    position: relative;
    width: 100%;
    padding-top: 100%;
    img {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: contain;
      object-position: 50% 50%; } }
  &__title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 500;
    margin-bottom: 24px; }
  &__quote {
    margin-bottom: 24px;
    p {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      &:last-child {
        margin-bottom: 0; } } }
  &__quote-footer {
    display: flex;
    &:not(:last-child) {
      margin-bottom: 42px; }
    .img {
      display: block;
      img {
        display: block;
        max-height: 64px;
        width: auto; } }
    .content {
      margin-left: 16px;
      padding-left: 16px;
      border-left: 1px solid #c4c4c4;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        font-size: 15px;
        line-height: 24px; }
      .name {
        font-weight: 500; } } }
  @include maxtablet() {
    padding: 48px 0;
    &:before {
      width: 100%; }
    &__quote {
      p {
        font-size: 18px;
        line-height: 28px; } }
    &__img {
      margin: 32px auto; } } }

.block-table {
  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 16px;
    text-align: center; }
  &__desc {
    margin-bottom: 32px;
    text-align: center;
    p {
      color: #545465;
      &:last-child {
        margin-bottom: 0; } } }
  &__buttons {
    padding-top: 48px;
    text-align: center;
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px;
      margin-bottom: 8px; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  .items {
    margin-left: 0;
    margin-right: 0;
    box-shadow: 0px 32px 40px rgba(50, 50, 71, 0.1); }
  .item {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
    ul.list-check {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 26px;
      li {
        margin-bottom: 12px;
        &::before {
          width: 20px;
          height: 20px;
          top: 0; } } }
    &__icon {
      img {
        display: block;
        height: 40px;
        width: auto;
        margin-bottom: 8px; } }
    &__title {
      color: #FFF;
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 0; }
    &__buttons {
      margin-top: auto; }
    &__head {
      background: $primary;
      padding: 16px;
      border-radius: 4px;
      margin-bottom: 4px; }
    &__body {
      display: flex;
      flex-direction: column;
      background: #F2F4F7;
      padding: 16px;
      border-radius: 4px;
      height: 100%; }
    &__wrap {
      background: #FFF;
      padding: 4px;
      border-radius: 4px;
      margin-left: -2px;
      margin-right: -2px;
      display: flex;
      height: 100%;
      flex-direction: column; } }

  @include maxtablet() {
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; }
    .items {
      box-shadow: none; }
    .item {
      margin-bottom: 16px;
      box-shadow: 0px 32px 40px rgba(50, 50, 71, 0.1); } } }

// tax invoice
.mastheadrvmp.mastheadrvmp--efrf {
  background: linear-gradient(180.29deg, #FFF7ED 29.25%, #FFBE78 99.79%);
  .mastheadrvmp {
    &__logo {
      margin-bottom: 12px;
      img {
        display: block;
        height: 48px;
        width: auto; } }
    &__title {
      font-size: 32px;
      line-height: 40px; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 84px; } } }

.block-sections.block-sections--efrf {
  .block-sections {
    &__wrap {
      padding-top: 16px;
      padding-bottom: 16px; } } }

.form-contact.form-contact--efrf {
  background: #F2F4F7;
  .form-contact {
    &__title {
      text-align: center;
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 40px; } } }

.block-clients.block-clients--efrf {
  background: #F2F4F7;
  .block-clients {
    // &__items
    //   .item
    //     max-width: 16.666667%
    //     flex: 0 0 16.666667%
    //     img
    //       width: auto
    //       height: auto
    //       max-width: 100%
    //       max-height: 60px
    &__wrap {
      padding-top: 0;
      padding-bottom: 80px; }
    &__title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 32px; } } }

.footer--blank {
  padding-top: 24px;
  padding-bottom: 24px;

  .registered-label {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    list-style-type: she;
    margin-right: 4px; }
  .logos {
    a {
      display: inline-block;
      vertical-align: middle;
      padding-left: 16px; }
    img {
      display: block;
      max-height: 72px;
      max-width: 90px;
      width: auto;
      height: auto; } } }

// CUSTOM FORM REVAMP
.form-custom {
  background: #F8FAFC;
  padding: 64px 0 0;
  &__logo {
    margin-bottom: 40px; }
  &__head {
    position: relative;
    display: block; }
  &__title {
    margin-bottom: 24px; }
  &__img {
    position: relative;
    img {
      display: block;
      max-width: 90%;
      width: 392px;
      height: auto;
      margin: 0 auto 48px; } }
  &__content {
    margin-bottom: 64px;
    p {
      font-size: 18px;
      line-height: 28px; }
    ul {
      padding-left: 24px;
      margin-top: -12px; } }
  &__djp {
    margin-bottom: 64px;
    .title {
      display: block;
      font-weight: 500;
      margin-bottom: 24px; }
    .items {
      display: flex;
      align-items: center;
      margin-left: -16px;
      margin-right: -16px;
      .item {
        display: block;
        padding-left: 16px;
        padding-right: 16px;
        img {
          max-height: 80px;
          max-width: 120px;
          width: auto;
          height: auto; } } } }
  &__wrap {
    background: #FFF;
    box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
    border-radius: 8px;
    padding: 40px;
    margin-bottom: 64px; } }

// UPDATE PAJAK
.mastheadrvmp--upjk {
  background: #FFF1D6; }

.block-info {
  &__title {
    font-size: 18px;
    list-style: 28px;
    margin-bottom: 0; }
  &__items {
    display: grid;
    grid-template-columns: auto auto auto;
    .item {
      display: block;
      position: relative;
      &__icon {
        img {
          display: block;
          height: 24px;
          width: auto; } }
      &__text {
        font-weight: 600;
        margin-left: 8px; }
      &__wrap {
        padding-left: 40px;
        padding-right: 40px;
        display: flex;
        align-items: center; }
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 1px;
        height: 100%;
        left: 0;
        top: 0;
        background: #E3E3E8; }
      &:first-child::before {
        display: none; } } }

  &__wrap {
    padding-top: 60px;
    padding-bottom: 60px; }

  @include maxtablet() {
    &__title {
      margin-bottom: 24px; }
    &__items {
      display: block;
      .item {
        &__wrap {
          padding: 0 0 12px; }
        &:before {
          display: none; } } }
    &__wrap {
      padding: 32px 24px; } } }

.block-djp {
  &__img {
    display: block;
    width: 100%;
    max-width: 35%;
    flex: 0 0 35%;
    padding: 24px;
    text-align: right;
    img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%; } }
  &__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 16px; }
  &__desc {
    margin-bottom: 20px;
    p:last-child() {
      margin-bottom: 0; } }
  &__content {
    display: block;
    width: 100%;
    max-width: 65%;
    flex: 0 0 65%;
    padding: 24px 24px 24px 8px; }
  &__box {
    display: flex;
    background: #F3F3F3;
    border-radius: 16px;
    align-items: center; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include maxtablet() {
    // padding-left: 24px
    // padding-right: 24px
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__img {
      max-width: 100%;
      padding: 24px;
      img {
        display: block;
        // height: 120px
        height: auto;
        width: auto; } }
    &__buttons {
      text-align: center; }
    &__content {
      padding: 0;
      max-width: 100%;
      padding: 8px 24px 24px 24px; }
    &__box {
      display: block; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }


.block-webminar {
  &__title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 28px;
    text-align: center; }
  &__desc {
    margin-bottom: 56px;
    text-align: center;
    p:last-child() {
      margin-bottom: 0; } }
  &__sectitle {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px; }
  .item {
    margin-bottom: 32px;
    &__img {
      display: block;
      position: relative;
      .img {
        position: relative;
        padding-top: 60%;
        overflow: hidden;
        border-radius: 4px 4px 0 0; }
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center;
        transform: none;
        transition: all .4s ease-in-out; }
      .btn-play {
        display: block;
        position: absolute;
        z-index: 2;
        top: calc(50% - 30px);
        left: calc(50% - 30px);
        width: 60px;
        height: 60px;
        border-radius: 30px;
        background: url('../images/ic-play-dark.svg') no-repeat center;
        background-color: #FFF; }
      &:hover {
        img {
          transform: scale(1.05); }
        .btn-play {
          @include pulseCustom(255,255,255,'pulse-white'); } } }

    &__title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      margin-bottom: 8px;
      &:last-child {
        margin-bottom: 0; }
      a {
        display: block;
        transition: all .3s ease; }
      a:hover {
        color: $primary; } }
    &__meta {
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } }
    &__desc {
      p:last-child() {
        margin-bottom: 0; } }
    &__buttons {
      padding-top: 40px; }
    &__content {
      padding: 24px; }
    &__wrap {
      border-radius: 4px;
      border: 1px solid #E3E3E8;
      display: flex;
      flex-direction: column;
      height: 100%;
      box-shadow: 0 0 0 rgba(119, 119, 119, 0.1), 0 0 0 rgba(119, 119, 119, 0.2);
      transition: all .4s ease-in-out; }
    &:hover {
      .item__wrap {
        border: 1px solid $blue;
        box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); } } }
  &__now {
    padding-bottom: 32px;
    .item {
      &__img {
        display: block;
        width: 100%;
        max-width: 48%;
        flex: 0 0 48%;
        .img {
          border-radius: 4px 0 0 4px; } }
      &__title {
        order: 2;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 28px; }
      &__meta {
        order: 1;
        margin-bottom: 8px; }
      &__desc {
        order: 3; }
      &__buttons {
        order: 4;
        display: block; }
      &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 52%;
        flex: 0 0 52%; }
      &__wrap {
        display: flex;
        align-items: center;
        flex-direction: row; } } }
  &__next {
    padding-bottom: 32px;
    .row {
      margin-left: -12px;
      margin-right: -12px;
      .col-md-4 {
        padding-left: 12px;
        padding-right: 12px; } } }
  &__rewatch {
    .item {
      &__desc {
        display: none; } }
    .row {
      margin-left: -12px;
      margin-right: -12px;
      .col-md-4 {
        padding-left: 12px;
        padding-right: 12px; } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 48px; }
  @include maxtablet() {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px; }
    &__desc {
      margin-bottom: 24px; }
    &__now {
      .item {
        &__img {
          max-width: 100%; }
        &__title {
          font-size: 18px;
          line-height: 28px;
          margin-bottom: 8px; }
        &__buttons {
          padding-top: 24px;
          .btn {
            width: 100%;
            &+ .btn {
              margin-top: 8px; } } }
        &__content {
          max-width: 100%; }
        &__wrap {
          display: block; } } }
    &__wrap {
      padding: 64px 24px 48px; } } }

.block-blogs {
  background: #F2F4F7;
  margin-bottom: 80px;
  &__title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 28px;
    text-align: center; }
  &__desc {
    text-align: center;
    margin-bottom: 28px;
    p:last-child() {
      margin-bottom: 0; } }
  &__buttons {
    text-align: center; }
  &__featured {
    padding-top: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #E3E3E8;
    .item {
      &__img {
        position: relative;
        width: 100%;
        max-width: 29.125%;
        flex: 0 0 29.125%;
        .img {
          display: block;
          position: relative;
          width: 100%;
          padding-top: 60.12%;
          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 4px; } } }
      &__meta {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px; }
      &__title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 16px;
        a {
          display: block; }
        a:hover {
          color: $primary; }
        &:last-child {
          margin-bottom: 0; } }
      &__desc {
        margin-bottom: 16px;
        p:last-child {
          margin-bottom: 0; } }
      &__content {
        padding-left: 16px; }
      &__wrap {
        display: flex;
        padding-top: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid #E3E3E8; }
      &:last-child {
        .item__wrap {
          border-bottom: 0;
          padding-bottom: 0; } }
      &--large {
        .item__img {
          max-width: 100%;
          flex: 0 0 100%;
          margin-bottom: 20px; }
        .item__title {
          font-size: 24px;
          line-height: 32px; }
        .item__content {
          padding-left: 0; }
        .item__wrap {
          flex-direction: column; } } } }
  &__main {
    padding-top: 40px;
    .item {
      margin-bottom: 32px;
      &__img {
        position: relative;
        // width: 100%
        // max-width: 100%
        // flex: 0 0 100%
        .img {
          display: block;
          position: relative;
          width: 100%;
          padding-top: 60.12%;
          img {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            border-radius: 4px 4px 0 0; } } }
      &__meta {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 8px; }
      &__title {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 16px;
        a {
          display: block; }
        a:hover {
          color: $primary; }
        &:last-child {
          margin-bottom: 0; } }
      &__desc {
        margin-bottom: 16px;
        p:last-child {
          margin-bottom: 0; } }
      &__content {
        padding: 24px; }
      &__wrap {
        display: flex;
        flex-direction: column;
        border-radius: 4px;
        background: #FFF;
        border: 1px solid #E3E3E8;
        height: 100%; } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 48px; }
  @include maxtablet() {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px; }
    &__desc {
      margin-bottom: 16px; }
    &__featured {
      padding-top: 24px;
      padding-bottom: 24px;
      .item {
        &--large {
          padding-bottom: 32px; } } }
    &__main {
      .item__wrap {
        height: auto; } }
    &__wrap {
      padding: 64px 24px 48px; } } }

.block-qna {
  &__wrap {
    padding-top: 24px;
    padding-bottom: 80px; } }

// INTEGRASI KP X JURNAL
.block-points {
  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600; }
  .items {
    display: grid;
    grid-template-columns: auto auto; }
  .item {
    position: relative;
    margin-bottom: 32px;
    p {
      margin-bottom: 0;
      font-size: 18px;
      line-height: 28px; }
    &__icon {
      img {
        max-width: 100%;
        height: auto; } }
    &__text {
      padding-left: 12px; }
    &__wrap {
      display: flex;
      align-items: center;
      padding-left: 42px; } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 48px; }

  @include maxtablet {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
      br {
        display: none; } }
    .items {
      display: block; }
    .item {
      &__wrap {
        padding-left: 0; } } } }

.block-accor {
  &__title {
    font-size: 32px;
    line-height: 40px;
    font-weight: 600;
    margin-bottom: 40px; }
  .accordion {
    margin-bottom: 0;
    .card {
      border: 0;
      margin-bottom: 0;
      overflow: hidden;
      &__himage {
        display: none;
        img {
          display: block;
          width: 90%;
          height: auto;
          margin: 0 auto; } }
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background: $primary;
        opacity: 0;
        transition: all .3s ease; }
      &.open {
        margin-top: 8px;
        margin-bottom: 8px;
        &::before {
          opacity: 1; } } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px; }
    &__img {
      display: none; }
    .accordion {
      .card {
        &__himage {
          display: block; } } } } }

.block-pricing-alt {
  &__sub {
    display: block;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    margin: 0 0 12px; }
  &__title {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    margin: 0 0 48px; }
  .item {
    margin-bottom: 24px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      font-weight: 600;
      display: block;
      margin-bottom: 16px; }
    &__desc {
      margin-bottom: 20px;
      p:last-child() {
        margin-bottom: 0; } }
    &__price {
      display: flex;
      align-items: center;
      color: #005FBF;
      small {
        display: block;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin-right: 8px; }
      span {
        display: block;
        font-size: 56px;
        line-height: 64px;
        font-weight: 600; } }
    &__head {
      padding-bottom: 20px; }
    &__main {
      padding-bottom: 20px;
      ul.list-check {
        &> li {
          margin-bottom: 8px; } } }
    &__wrap {
      border: 1px solid #E3E3E8;
      border-radius: 4px;
      padding: 24px;
      box-shadow: 0 0 0 rgba(119, 119, 119, 0.1);
      transition: all .3s ease; }
    &:hover {
      .item__wrap {
        box-shadow: 0px 12px 20px rgba(119, 119, 119, 0.2); } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 0; }
  @include maxtablet {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 40px;
      br {
        display: none; } }
    .item {
      &__title {
        font-size: 20px;
        line-height: 28px; } }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 40px; } } }

// PRICING
.mastheadrvmp--pricing {
  .mastheadrvmp__wrap {
    padding-top: 80px;
    padding-bottom: 48px; }
  .mastheadrvmp__title {
    text-align: center;
    &:last-child {
      margin-bottom: 0; } }
  @include maxtablet() {
    .mastheadrvmp__title {
      br {
        display: none; } } } }

.pricing-main {
  padding-bottom: 72px;
  .pricing-items {
    margin-left: -8px;
    margin-right: -8px;
    .row {
      margin-left: -8px;
      margin-right: -8px; }
    [class^="col-"] {
      padding-left: 8px;
      padding-right: 8px; } }
  .tnc {
    position: absolute;
    top: 100%;
    right: 12px;
    display: block;
    padding-top: 16px;
    text-align: right; }
  .item {
    &__wrap {
      display: flex;
      flex-direction: column;
      height: 100%;
      border-radius: 8px; }
    &__head {
      display: block;
      padding: 16px;
      border: 1px solid $border;
      background: #F2F4F7;
      border-radius: 8px 8px 0 0;
      h3 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 12px;
        .mp {
          display: inline-block;
          vertical-align: middle;
          margin-left: 12px;
          font-size: 12px;
          line-height: 16px;
          padding: 4px 8px;
          border-radius: 4px;
          background: #19A184;
          font-weight: 500;
          color: #FFF; } }
      p {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px; }
      .price {
        display: block;
        &:not(:last-child) {
          margin-bottom: 20px; }
        .sf {
          display: block;
          min-height: 20px;
          margin-bottom: 4px;
          font-size: 16px;
          line-height: 20px; }
        .pr {
          display: block;
          color: #2B2A35;
          font-size: 28px;
          line-height: 32px;
          font-weight: 500;
          small {
            display: inline-block;
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            margin-left: 6px;
            color: #545465; }
          &.sm {
            font-size: 24px;
            line-height: 32px; } } } }
    &__toggle {
      position: relative;
      padding: 16px 48px 16px 16px;
      color: $primary;
      cursor: pointer;
      font-weight: 500;
      &:hover {
        color: darken($primary, 10%); }
      &::after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/ic-chevron-up-orange.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 12px);
        right: 16px;
        transform: rotate(0);
        transition: all .3s ease; }
      &.collapsed {
        &::after {
          transform: rotate(0);
          transform: rotate(180deg); } } }
    &__collapse-wrap {
      padding: 8px 16px 16px; }
    &__main {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 0;
      border: 1px solid $border;
      border-top: 0;
      background: #FFF;
      border-radius: 0 0 8px 8px;
      color: #545465;
      span.cat {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #2B2A35;
        margin-bottom: 4px;
        font-weight: 600; } }

    &--highlight {
      .item__wrap {
        box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2); }
      .item__head {
        border-color: #F96D01;
        background: #FBF3DD; }
      .item__main {
        border-color: #F96D01; } } }
  .pricing-main__buttons {
    text-align: center;
    padding-top: 48px; }
  @include maxtablet {
    padding-bottom: 48px;
    .item {
      margin-bottom: 32px; } }
  @include mobile {
    &__wrap {
      &> .row {
        flex-direction: column-reverse; } }
    &__buttons {
      display: none; } } }

.recom-bundle {
  padding-top: 80px;
  padding-bottom: 80px;
  &__sub {
    text-align: center;
    font-size: 14px;
    line-height: 20px;
    display: block;
    margin-bottom: 12px;
    color: #545465; }
  &__title {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin: 0 auto 40px;
    font-weight: 600;
    max-width: 808px; }
  &__nav {
    .item {
      position: relative;
      padding: 20px 60px 20px 32px;
      background: #FFF;
      border: 1px solid $border;
      border-radius: 4px;
      margin-bottom: 12px;
      transition: all .3s ease;
      &[data-toggle] {
        cursor: pointer; }
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url("../images/ic-chevron-right-round.svg") no-repeat center;
        background-size: contain;
        position: absolute;
        top: calc(50% - 12px);
        right: 30px;
        opacity: 0;
        transition: all .3s ease; }
      &__logo {
        position: relative;
        margin-bottom: 12px;
        img {
          display: block;
          height: 26px;
          width: auto; } }
      &.active {
        border-color: $primary;
        background: #F9E2DE80;
        &:after {
          opacity: 1;
          right: 24px; } }
      &:not(.active):hover {
        background: #F2F4F7; } } }
  .tab-pane {
    padding: 32px;
    border: 1px solid $border;
    border-radius: 4px;
    &__sm {
      display: none; }
    h3 {
      margin-bottom: 16px; }
    span.cat {
      color: #2B2A35;
      font-size: 16px;
      line-height: 24px;
      font-weight: 500;
      display: block;
      margin-bottom: 8px; }
    ul.custom-list {
      &> li {
        margin-bottom: 8px;
        &:last-child {
          margin-bottom: 0; } } }
    .price {
      &:not(:last-child) {
        margin-bottom: 24px; }
      .ps {
        font-size: 14px;
        line-height: 20px;
        color: #777; }
      .pn {
        font-size: 28px;
        line-height: 48px;
        font-weight: 500;
        color: #2B2A35; }
      .pt {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #2B2A35; } }
    .tab-action {
      padding-top: 32px; } }
  .tnc {
    display: block;
    padding-top: 16px;
    font-size: 12px;
    line-height: 16px;
    color: #545465;
    text-align: right; }
  .bundle {
    display: flex;
    &__logo {
      img {
        display: block;
        height: 48px;
        width: auto; } }
    &__price {
      margin-top: auto;
      margin-bottom: 12px;
      .sf {
        display: block;
        margin-block-end: 8px; }
      .pr {
        display: block;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: -0.5px;
        font-weight: 600;
        small {
          display: inline-block;
          font-size: 18px;
          line-height: 28px;
          margin-left: 4px;
          color: #545465; } } }
    &__action {
      .btn {
        width: 100%; }
      .btn-link {
        color: #1357FF;
        line-height: 24px;
        padding: 0; }
      .btn + .btn {
        margin-top: 16px; } }
    span.cat {
      display: block;
      font-size: 18px;
      line-height: 28px;
      color: #2B2A35;
      margin-bottom: 12px;
      font-weight: 500; }
    &__head {
      padding: 32px;
      background: #F9FAFB;
      border: 1px solid #009BDE;
      border-radius: 4px 0 0 4px;
      width: 100%;
      max-width: 38%;
      flex: 0 0 38%;
      display: flex;
      flex-direction: column; }
    &__main {
      padding: 32px;
      background: #FFF;
      border: 1px solid $border;
      border-left: 0;
      border-radius: 0 4px 4px 0;
      width: 100%;
      max-width: 62%;
      flex:  0 0 62%; }

    @include maxtablet() {
      flex-wrap: wrap;
      &__logo {
        img {
          height: 32px;
          width: auto; } }
      &__price {
        padding-top: 32px;
        .sf {
          margin-bottom: 4px; }
        .pr {
          font-size: 28px;
          line-height: 36px;
          small {
            font-size: 16px;
            line-height: 24px; } } }
      &__head {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 24px 24px 16px;
        border-radius: 4px 4px 0 0; }
      &__main {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 24px;
        border-radius: 0 0 4px 4px;
        border-left: 1px solid $border;
        border-top: 0; } } }
  @include maxtablet() {
    padding-top: 64px;
    padding-bottom: 64px;
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__nav {
      display: none; }
    .tab-pane {
      margin-bottom: 24px;
      display: block;
      &__sm {
        display: block;
        .item__logo {
          margin-bottom: 12px; }
        .item__desc {
          margin-bottom: 24px; } }
      .hide-sm {
        display: none; }
      &:last-child {
        margin-bottom: 0; } } }
  @include mobile {
    padding-left: 12px;
    padding-right: 12px; } }

.testimoni-big {
  width: 100%;
  overflow: hidden;
  margin-bottom: 24px;
  .link-more {
    color: #F9A564; }
  .link-more:after {
    background-image: url('../images/ic-arrow-right-long-orange.svg'); }
  &__img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    img {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      object-position: center; } }
  &__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px; }
  &__quote {
    margin-bottom: 24px; }
  &__author {
    display: flex;
    align-items: center;
    .author-logo {
      padding-right: 16px;
      border-right: 1px solid #FFF;
      img {
        display: block;
        width: auto;
        height: 60px; } }
    .author-content {
      padding-left: 16px;
      span {
        display: block; }
      .name {
        font-weight: 600; } } }
  &__buttons {
    padding-top: 40px; }
  &__wrap {
    position: relative;
    padding-top: 48px;
    padding-bottom: 48px;
    color: #FFF;
    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 16.666%;
      background: #011C40;
      border-radius: 8px; } }
  @include maxtablet() {
    padding-left: 24px;
    padding-right: 24px;
    &__img {
      max-width: 85%;
      padding-top: 85%;
      margin: 0 auto 40px; }
    &__content {
      padding-left: 16px;
      padding-right: 16px; }
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__author {
      flex-direction: column;
      align-items: flex-start;
      .author-logo {
        padding-right: 0;
        margin-bottom: 16px;
        border: 0;
        img {
          height: 48px; } }
      .author-content {
        padding-left: 0; } }
    &__wrap {
      &:before {
        left: 0;
        top: auto;
        bottom: 0;
        height: 82%; } } } }

.modal--pricing {
  span.yes {
    display: inline-block;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin: 0 auto;
    background: url("../images/ic-check.svg") no-repeat center;
    background-size: contain; }
  .btn-collapse {
    position: relative;
    display: inline-block;
    color: #1357FF;
    padding-left: 26px;
    cursor: pointer;
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 0;
      top: 2px;
      width: 20px;
      height: 20px;
      background: url("../images/ic-minus.svg") no-repeat center;
      background-size: contain; }
    &.collapsed::before {
      background-image: url("../images/ic-plus.svg"); } }
  .group-title {
    display: block;
    position: relative;
    width: 100%;
    padding: 16px 24px 16px 56px;
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    color: #2B2A35;
    border: 1px solid #E3E3E8;
    border-width: 0 1px 1px 0;
    &::before {
      content: "";
      position: absolute;
      display: block;
      left: 24px;
      top: 20px;
      width: 20px;
      height: 20px;
      background: url("../images/ic-minus.svg") no-repeat center;
      background-size: contain;
      filter: grayscale(100%); }
    &.collapsed::before {
      background-image: url("../images/ic-plus.svg"); } }
  .pricing-detail {
    .group-row {
      display: grid;
      grid-template-columns: 26% 18.5% 18.5% 18.5% 18.5%; }
    .cell {
      padding: 16px 24px;
      color: #545465;
      border: 1px solid #E3E3E8;
      border-width: 0 1px 1px 0;
      display: flex;
      align-items: center; }
    &__action {
      padding: 16px 24px; }
    &__heading {
      border: 1px solid #E3E3E8;
      border-width: 1px 0 0 1px;
      text-align: center;
      .title {
        display: block;
        width: 100%;
        font-size: 24px;
        line-height: 32px;
        font-weight: 600;
        margin-bottom: 12px;
        color: #2B2A35; }
      p {
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 0; }
      .cell {
        flex-direction: column;
        padding: 32px 24px;
        &:first-child {
          .title,
          p {
            text-align: left; } } } }
    &__group {
      border: 1px solid #E3E3E8;
      border-width: 1px 0 0 1px;
      margin-top: -1px;
      .cell {
        background: #F2F4F7; } } }



  .modal-title {
    display: block;
    width: 100%;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px; }
  .modal-header {
    padding: 24px 24px 0;
    text-align: center;
    border: 0; }
  .modal-body {
    padding: 28px; }
  .modal-content {
    padding-top: 56px;
    border-radius: 4px; }
  .modal-dialog {
    max-width: 1280px; } }

.testimonial-small {
  &__wrap {
    padding: 80px 0; }
  .item {
    &__quote {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 16px;
      @include robo-500; }
    &__author {
      .author-logo {
        display: inline-block;
        vertical-align: middle;
        padding-right: 24px;
        img {
          height: 32px;
          width: auto;
          display: block; } }
      .auhtor-content {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 24px;
        border-left: 1px solid $border;
        span {
          display: block; }
        .name {
          @include robo-500(); } } }
    &__img {
      position: absolute;
      top: 0;
      left: 12px;
      width: 20%;
      padding: 36px 0;
      .img {
        display: block;
        width: 100%;
        padding-top: 100%;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
          border-radius: 8px;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } } }
    &__box {
      margin-left: 10%;
      background: #10253E;
      color: #FFF;
      padding: 48px 40px 48px 14%;
      box-shadow: 0px 4px 8px rgba(119, 119, 119, 0.1), 0px 12px 20px rgba(119, 119, 119, 0.2);
      border-radius: 6px; } }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    .item {
      &__box {
        margin-left: 0;
        padding: 24px; }
      &__img {
        position: relative;
        left: 0;
        padding: 0;
        width: 100%;
        margin-bottom: 32px; }
      &__quote {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 32px; }
      &__author {
        .author-logo {
          display: block;
          margin-bottom: 16px; }
        .auhtor-content {
          display: block;
          padding: 0;
          border-left: 0; } } } } }

.page-tabs {
  position: relative;
  &__header {
    padding-top: 4rem; }

  &__title {
    display: block;
    width: 100%;
    text-align: center;
    color: #212121;
    max-width: 600px;
    margin: 0 auto 28px;
    font-size: 40px;
    line-height: 48px;
    @include maxtablet {
      font-size: 28px;
      line-height: 36px; } }
  &__desc {
    max-width: 600px;
    margin: 0 auto 64px;
    @include maxtablet {
      margin: 0 auto 40px; } }
  .nav-tabs {
    .nav-link {
      display: block;
      border: 1px solid $border;
      border-radius: 0.25rem;
      padding: 1.5rem;
      text-align: center;
      color: #212121;
      background: #FFFFFF;
      transition: all .3s ease;
      height: 100%;
      .icon {
        display: block;
        height: 48px;
        width: 48px;
        margin: 0 auto 12px; }
      span {
        display: block;
        font-size: 0.875rem;
        line-height: 1.5em;
        color: #212121; }
      h3 {
        display: block;
        font-size: 1.5rem;
        line-height: 1.333em;
        margin: 0;
        color: #212121; }
      &:hover {
        text-decoration: none;
        background: #F2F4F7;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08); }
      &.active {
        text-decoration: none;
        background: #F2F4F7;
        border-color: $blue;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
        .icon {
          path {
            fill: #005FBF; } } } } }

  @media (min-width: 1600px) {
    .container.container--nav-tabs {
      max-width: 1600px; } }

  &--alt {
    padding-top: 80px;
    @include maxtablet {
      padding-top: 46px; }
    .page-tabs__header {
      @include mobile {
        padding: 4rem 0 6rem 0; } }
    .page-tabs__title {
      @include maxtablet {
        padding-top: 30px; } }
    .page-tabs__body {
      .mobile-section {
        display: none;
        // text-align: center
        @include mobile {
          display: block;
          .img {
            margin-bottom: 16px;
            max-height: 48px;
            width: auto; }
          h3 {
            margin-bottom: 12px; }
          h4 {
            margin-bottom: 12px; } } } }

    .page-tabs__dropdown {
      position: relative;
      @include mobile {
        margin-top: -10px; }
      .selected {
        display: none;
        width: 100%;
        height: 48px;
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px 35px 10px 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #2B2A35;
        font-size: 16px;
        line-height: 1.85rem;
        @include robo-500;
        &::after {
          content: '';
          background: url(../images/arrow-down.svg) no-repeat center;
          width: 24px;
          height: 24px;
          display: inline-block;
          position: absolute;
          bottom: 12px;
          right: 10px; }
        @include mobile {
          height: 48px;
          width: 100%;
          background: #FFFFFF;
          border: 1px solid #E2E2E2;
          border-radius: 4px;
          display: block; } }
      &.active {
        .nav-tabs {
          // overflow-x: hidden
          // overflow-y: scroll
          height: auto; } } }

    .nav-tabs {
      .nav-item {
        &__title {
          display: flex;
          align-items: center;
          text-align: left;
          margin-bottom: 0.75rem;
          @include maxtablet {
            margin-bottom: 0; }
          h3 {
            font-size: 16px;
            line-height: 24px;
            @include mobile {
              font-size: 1rem;
              line-height: 1.5rem; } }
          .ic {
            max-width: 40px;
            min-width: 40px;
            margin-right: 1rem; } }
        .nav-link {
          background: #FFF;
          border: 0;
          position: relative;
          padding: 2rem;
          text-align: left;

          &:hover,
          &.active {
            box-shadow: none;
            background: #F2F4F7;
            border-radius: 8px 8px 0 0;
            &::after {
              content: '';
              background: url(../images/arrow-down.svg) no-repeat center;
              width: 24px;
              height: 24px;
              display: inline-block;
              position: absolute;
              bottom: 0.75rem;
              left: calc(50% - 12px); }
            @include mobile {
              &::after {
                display: none; } } }
          @include mobile {
            border-radius: 0!important;
            border: 1px solid #E2E2E2;
            padding: 12px;
            height: auto;
            .ic {
              display: none; } } } }
      &.slider-tabs {
        .owl-stage-outer {
          overflow: visible; }
        .owl-stage {
          display: flex;
          flex-wrap: nowrap;
          &::after {
            display: none; } }
        .owl-item {
          position: relative;
          width: 100% !important;
          padding: 0 12px; }
        .nav-item {
          display: flex;
          height: 100%; }

        @include maxtablet() {
          width: 100%;
          .nav-item {
            width: 100%;
            display: block; } } } }

    .page-tabs {
      &__section-head {
        padding-top: 80px;
        padding-bottom: 48px; }
      &__title {
        margin-bottom: 16px;
        text-align: center; }
      &__desc {
        text-align: center; }
      &__body {
        background: #F2F4F7; } }

    .page-tabs__wrap.sticky-me {
      z-index: 20;
      .page-tabs__header {
        transition: all .4s ease;
        padding-top: 0; }
      .nav-tabs .nav-item {
        &__title {
          transition: all .4s ease;
          .ic {
            transition: all .4s ease; } }
        .nav-link {
          transition: all .4s ease; } }
      &.stick {
        background: #FFF;
        bottom: auto !important;
        border-bottom: 1px solid $border;
        .page-tabs__header {
          padding-top: 56px; }
        .nav-tabs .nav-item {
          &__title {
            margin: 0;
            .ic {
              height: 30px;
              width: 30px;
              min-width: 30px;
              margin-right: 12px; }
            h3 {
              font-size: 16px;
              line-height: 24px; } }
          .nav-link {
            display: flex;
            align-items: center;
            border-radius: 0;
            padding-top: 12px;
            padding-bottom: 12px;
            p {
              display: none; }
            &::after {
              display: none; } } } }
      &.show-top.stick {
        .page-tabs__header {
          padding-top: 97px; } } }

    @include maxtablet {
      background: #F2F4F7;
      .nav-tabs {
        position: absolute;
        z-index: 2;
        height: 0;
        overflow: hidden;
        top: 49px;
        left: 0;
        .nav-link {
          .nav-item__title {
            h3 {
              margin-bottom: 0; } }
          p {
            display: none; } } } } } }


// STICKY BANNER BIG
.top-banner {
  background: linear-gradient(90deg, #3A00A9 1.56%, #7B3CEF 44.12%, #3A00A9 68.21%, #7B3CEF 92.31%);
  margin-top: 120px;
  display: block;
  a.container {
    display: block; }
  &__img {
    img {
      display: inline-flex;
      width: auto;
      height: auto;
      max-height: 64px; } }
  &__meta {
    color: #FFF;
    font-size: 18px;
    line-height: 1.5em;
    @include inter-500(); }
  &__title {
    color: #FFF;
    font-size: 28px;
    line-height: 1.5em;
    @include inter-600(); }
  &__desc {
    color: #FFF;
    p {
      font-size: 14px;
      line-height: 20px; }
    span.link {
      font-size: 15px;
      line-height: 22px;
      font-weight: 500;
      text-decoration: underline;
      &:hover {
        color: darken(#FFF, 10%); } } }
  &__content {
    padding-top: 14px;
    padding-bottom: 14px;
    text-align: center; }

  &__buttons {
    padding-top: 20px;
    padding-bottom: 20px;
    .btn {
      padding: 11px 24px;
      font-size: 16px;
      line-height: 24px; }
    .btn-white {
      border-color: #FFF; } }
  @include maxtablet {
    margin-top: 0;
    &__img {
      display: none; }
    &__meta {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px; }
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 12px; }
    &__content {
      padding-top: 28px; }
    &__buttons {
      text-align: center;
      padding-top: 0;
      padding-bottom: 28px;
      .btn {
        display: inline-block;
        width: auto;
        margin-bottom: 0; } } } }

.tbs {
  z-index: 20;
  display: block;
  background: linear-gradient(90deg, #3A00A9 1.56%, #7B3CEF 44.12%, #3A00A9 68.21%, #7B3CEF 92.31%);
  color: #FFF;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  transition: all .4s ease;
  opacity: 0;
  &-bot {
    display: block;
    background: url(../images/sticky-speakers.svg) no-repeat;
    background-position: center right;
    background-size: contain;
    .row {
      min-height: 120px; } }
  &:hover {
    color: darken(#FFF, 10%); }
  &.is-ready {
    opacity: 1; }
  &__wrap {
    padding: 4px 0;
    min-height: 36px;
    margin: 0 auto;
    text-align: center;
    align-items: center;
    display: flex;
    width: 100%; }
  &__img {
    display: inline-block;
    text-align: left;
    img {
      width: 100%;
      height: auto;
      margin-top: -5px; } }
  &__content {
    display: block;
    text-align: center;
    color: #FFF;
    span {
      display: inline-block;
      vertical-align: middle; }
    span.large {
      font-size: 18px;
      line-height: 1.5em;
      @include inter-600(); }
    br {
      display: none; } }
  &__cta {
    display: block;
    margin-left: auto;
    .link {
      text-decoration: underline;
      font-size: 13px;
      line-height: 1.5em;
      &:hover {
        color: darken(#FFF, 10%); } }
    .btn-dummy {
      color: #fff;
      position: relative;
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 20px;
        background: url(../images/ic-arrow-right-long-white.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: -32px;
        top: calc(50% - 10px); }
      &:hover {
        color: #fff;
        text-decoration: underline;
        &:after {
          @include bounce(); } } }
    // .btn-dummy
    //   display: inline-block
    //   font-size: 16px
    //   line-height: 34px
    //   background: #FFF
    //   border: 1px solid #FFF
    //   color: $primary
    //   border-radius: 4px
    //   padding: 0 24px
    //   margin: 2px
    //   +inter-500
    //   transition: all .3s ease
    //   &:hover
    //     background: $primary
 }    //     color: #FFF
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 64px;
    height: 30px;
    top: 0;
    right: 0;
    span {
      display: inline-block;
      height: 16px;
      width: 16px;
      border-radius: 8px;
      text-align: center;
      vertical-align: middle;
      background: #FFF;
      color: $text-dark;
      font-size: 14px;
      line-height: 1; } }
  &.fixed {
    top: 57px;
    opacity: 1;
    &.show-top {
      top: 97px; } }

  @include maxtablet {
    top: 0;
    opacity: 1;
    &-bot {
      display: none; }
    &> .container {
      &> .row {
        min-height: 50px; } }
    &__wrap {
      padding-left: 0;
      padding-right: 0; }
    &__content {
      span {
        display: block; }
      span.meta {
        font-size: 12px;
        line-height: 16px;
        display: block; }
      span.large {
        margin-left: 0;
        font-size: 14px;
        line-height: 1.25rem; } }
    &__cta {
      min-width: 200px; }
    &__close {
      align-items: flex-start;
      justify-content: flex-end;
      width: 40px;
      height: 40px;
      padding: 12px;
      span {
        height: 14px;
        width: 14px;
        font-size: 13px; } }
    &.fixed {
      top: 56px;
      &.show-top {
        top: 56px; } } }
  @include mobile {
    &__wrap {
      flex-wrap: wrap; }
    &__content {
      text-align: center;
      width: 100%; }
    &__cta {
      text-align: center;
      max-width: 100%;
      width: 100%; } } }

// body.has-tb
//   main
//     margin-top: 0
//   .masthead
//     padding-top: 0
//   &.single
//     .masthead
//       padding-top: 53px
//   +maxtablet
//     margin-top: 110px
//     main
//       margin-top: 35px
//     &.single
//       .masthead
//         padding-top: 123px
//     .header__top
//       top: 48px
//     .header__left
//       top: 89px
// body.has-tbs-top
//   &.single
//     .header
//       top: 64px
//   .tbs
//     top: 0
//     &.fixed.show-top
//       top: 0
//   .header.fixed
//     top: 36px
//     .header__top
//       max-height: 0
//       padding-top: 0
//       padding-bottom: 0
//       opacity: 0
//       transition: all .4s ease
//     &.show-top
//       top: 36px
//       .header__top
//         max-height: 40px
//         padding-top: 8px
//         padding-bottom: 8px
//         opacity: 1
//   +maxtablet
//     &.single
//       .tbs
//         top: 0
//       .header .header__left
//         top: 48px
//     .tbs
//       top: 0
//       &.fixed
//         top: 0
//     .header
//       top: 126px !important
//       .header__left
//         top: 89px
//         transition: all .4s ease
//       &.fixed
//         .header__left
//           top: 48px
//     &.menu-open
//       .header
//         top: 0 !important
//         .header__left
//           top: 0 !important
//       .tbs
//         display: none
//     &.tbs-top
//       .header .header__left
//         top: 48px

.cta-footer {
  text-align: center;
  background: #FFF;
  padding-top: 64px;
  padding-bottom: 64px;
  &__logomark {
    margin-bottom: 16px; }
  &__title {
    margin-bottom: 20px; }
  &__desc {
    margin: 0 auto 32px;
    max-width: 600px; }
  &__buttons {
    .btn {
      margin-left: 6px;
      margin-right: 6px; } }
  @include maxtablet() {
    padding: 42px 8px;
    &__buttons {
      a,.btn {
        width: 100%;
        display: block;
        margin-bottom: 14px;
        margin-left: 0;
        margin-right: 0;
        &:last-child {
          margin-bottom: 0; } } } } }


.thanks-single {
  width: 100%;
  &__wrap {
    height: 100vh;
    width: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px; }
  &__icon {
    width: auto;
    height: 153px;
    object-fit: contain;
    display: block;
    margin: 0 auto 16px auto; } }
