.accr-steps {
  padding: 64px 0;
  .section__meta {
    @include inter-600();
    text-align: center; }
  .section__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px;
    text-align: center; }
  .section__action {
    margin-bottom: 24px;
    text-align: center; }
  &__img {
    display: none;
    &.active {
      display: block; } }
  &__accr {
    .card {
      margin-bottom: 18px;
      &.open {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
        .card__header {
          padding-bottom: 8px; }
        .card__title {
          .badge {
            background: #F96D01; } } }
      &__header {
        padding: 24px;
        border-bottom: 0;
        &::after {
         display: none; } }
      &__title {
        .badge {
          background: #8B95A5;
          border-radius: 12px;
          color: #FFF;
          font-size: 14px;
          line-height: 20px;
          padding: 0 6px;
          display: inline-block;
          margin-bottom: 8px;
          @include inter-400(); }
        .title {
          @include inter-600();
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 0; } }
      &__body {
        padding: 0 24px 24px 24px;
        margin: 0;
        p {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } } } }
  @include maxtablet() {
    padding: 32px 0;
    .section__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } } } }
