/* ------------------------------------------------------------------------------------------------ */
/* Footer */
/* ------------------------------------------------------------------------------------------------ */
footer {
  background: #F2F4F7;

  .left-side {
    max-width: 220px;
    min-width: 220px;
    padding-top: 5px;
    padding-bottom: 5px; }
  .right-side {
    max-width: 110px; }


  .foottop {
    .container {
      padding: 60px 15px; }
    .logo {
      position: relative;
      img {
        height: 40px; } }
    .djp {
      // min-width: 400px
      // max-width: 412px
      &-qr {
        max-width: 135px;
        margin-right: 20px;
        margin-bottom: 15px; }
      a {
        display: inline-block;
        vertical-align: top;
        img {
          display: block;
          max-height: 94px;
          width: auto; } }
      .mitra {
        margin-bottom: 15px;
        .logodjp {
          display: inline-block;
          margin-right: 10px;
          img {
            height: 20px; } }
        span {
          display: inline-block;
          font-size: 15px;
          line-height: 24px;
          color: #1C1D1E;
          font-weight: 500;
          margin-bottom: 10px;
          display: block; } }
      p {
        font-size: 12px;
        line-height: 18px; } }
    .iso-logo {
      display: inline-block;
      vertical-align: middle;
      max-height: 60px!important; }
    .iso {
      position: relative;
      text-align: center;
      width: 100px;
      margin-left: auto;
      .logoiso {
        img {
          display: block;
          width: 100%; } }
      span {
        color: #777777;
        font-size: 12px;
        line-height: 18px; } }
    .item {
      .fhead {
        color: $secondary;
        font-weight: 500;
        margin-bottom: 10px;
        display: block; }
      ul {
        list-style: none;
        li {
          margin-bottom: 5px;
          a {
            display: inline-block;
            font-size: 15px;
            line-height: 24px;
            color: #777777;
            &:hover {
              text-decoration: underline; }
            &.active {
              text-decoration: underline; }
            + a {
              margin-left: 10px; } } } } } }

  .footmid {
    position: relative;
    z-index: 2;
    .container {
      border-top: 1px solid $border;
      padding: 30px 15px; }
    .socmed {
      font-size: 20px;
      a {
        img {
          height: 20px;
          width: auto;
          opacity: 1;
          transition: opacity .3s; }
        & + a {
          display: inline-block;
          margin-left: 20px; }
        i {
          color: #777; }
        &:hover {
          img {
            opacity: .5; }
          i {
            color: $primary; } } } }
    .text {
      font-size: 12px;
      line-height: 18px;
      color: #777; }
    .addr-list {
      li {
        display: flex;
        margin: 0 0 16px;
        span {
          color: #626B79; }
        span.city {
          display: block;
          width: 100%;
          max-width: 110px;
          flex: 0 0 110px;
          color: $text;
          font-weight: 500; }
        &:last-child {
          margin: 0; } } }
    .lang {
      text-align: right;
      .icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: 5px;
        vertical-align: middle;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center; }
      a {
        font-size: 15px;
        line-height: 26px;
        color: #777777; }

      .bootstrap-select {
        display: inline-block;
        width: 90px !important;
        .select-control {
          border: 0 !important;
          color: #777777 !important;
          padding: 0 !important;
 }          // font-size: 18px
        &.show {
          .select-control:after {
            transform: rotate(180deg); } } }
      .dropdown-menu {
        .active {
          background: $primary !important;
          a,
          span {
            color: #ffffff !important; } }
        li {
          &:hover,
          &.selected {
            background: $primary !important;
            color: #ffffff !important; } } } } }

  .footbott {
    background: #FFF;
    border-top: 1px solid $border;
    line-height: 30px;
    position: relative;
    z-index: 1;
    .container {
      padding: 35px 15px; }
    .copyright {
      font-weight: 400;
      span {
        position: relative;
        top: 3px; } }
    .mekari-logo {
      display: inline-block;
      margin-right: 30px;
      img {
        height: 30px; } }
    .link {
      text-align: right; } }

  @include maxtablet {
    .hidden-md-down {
      display: none; }
    .foottop {
      .left-side {
        max-width: 220px;
        min-width: 220px; }
      .djp {
        min-width: 50%; }
      .item {
        margin-bottom: 30px; } }
    .footmid {
      .left-side,
      .right-side {
        min-width: 50%;
        padding-top: 0;
        padding-bottom: 0;
 }        // margin-bottom: 20px
      .addr-list {
        padding-top: 24px;
        padding-bottom: 12px;
        li {
          display: block; } }
      .left-side {
        order: 1; }
      .right-side {
        order: 2; }
      .left-side + .col {
        order: 3; } } }

  @include mobile {
    .foottop {
      .logo {
        margin-bottom: 20px; }
      .container {
        padding: 40px 15px; }
      .col {
        min-width: 50%;
        max-width: 50%; }
      .left-side {
        min-width: 100%;
        max-width: 100%;
        margin-bottom: 30px; }
      .djp {
        min-width: 100%; } }
    .footmid {
      .container {
        padding: 20px 15px; }
      .socmed {
        position: relative;
        top: 5px; } }
    .footbott {
      text-align: center;
      .container {
        padding: 20px 15px; }
      .mekari-logo {
        display: block;
        margin: 0 0 15px;
        text-align: center; }
      .link {
        padding-top: 15px;
        text-align: center; } } } }

.footer-small {
  background: #FFF;
  border: 1px solid #DFDFDF;
  padding: 24px 0;
  text-align: center;
  span.copy {
    font-size: 14px;
    line-height: 20px; } }
