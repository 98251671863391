.logo-marquee {
  padding-top: 64px;
  padding-bottom: 64px;
  &__title {
    text-align: center;
    margin: 0 0 8px; }
  &__desc {
    margin-bottom: 24px;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__group {
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: 32px;
    position: relative;
    margin: 24px 0 0;
    &::before,
    &::after {
      content: "";
      z-index: 2;
      display: block;
      width: 64px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(89.8deg, #FFFFFF 49.02%, rgba(255, 255, 255, 0) 113.2%); }
    &::after {
      left: auto;
      right: 0;
      transform: matrix(-1, 0, 0, 1, 0, 0); }
    &.reverse .logo-marquee__list {
      animation-direction: reverse;
      animation-delay: -3s; } }
  &__list {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-around;
    min-width: 1720px;
    animation: scroll-x 60s linear infinite;
    .item {
      display: grid;
      place-items: center;
      width: 172px;
      height: 56px;
      padding-left: 24px;
      padding-right: 24px;
      img {
        display: block;
        max-width: 100%;
        max-height: 56px;
        width: auto;
        height: auto; } } }
  &__action {
    margin-top: 35px;
    text-align: center; }
  &.bg-gray {
    .logo-marquee__group {
      &::before,
      &::after {
        width: 24px;
        background: linear-gradient(89.8deg, #F2F4F7 49.02%, rgba(255, 255, 255, 0) 113.2%); } } }
  @include maxtablet {
    padding: 32px 0;
    &__title {
      br {
        display: none; } }
    &__list {
      animation-duration: 30s;
      min-width: 1560px;
      .item {
        width: 156px;
        padding-left: 4px;
        padding-right: 4px; } } } }



.compare-cards {
  padding: 64px 0;
  @include maxtablet() {
    padding: 32px 0; }
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    text-align: center;
    @include maxtablet() {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      br {
        display: none; } } }
  &__desc {
    margin-bottom: 24px;
    text-align: center;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__action {
    text-align: center;
    margin-bottom: 24px;
    a,.btn {
      margin-right: 16px;
      @include maxtablet() {
        margin-right: 0;
        width: 100%;
        margin-bottom: 14px; } } }
  &__features {
    margin-bottom: 32px;
    .item {
      &__img {
        margin-bottom: 8px;
        img {
          display: block;
          width: 48px;
          height: 48px;
          object-fit: contain; } }
      &__title {
        font-size: 20px;
        line-height: 28px;
        @include inter-500();
        color: #232933;
        margin-bottom: 8px; }
      &__wrap {
        padding: 32px; } }
    @include maxtablet() {
      margin-bottom: 0;
      .item {
        padding-left: 32px;
        padding-right: 32px;
        padding-bottom: 24px;
        &__img {
          position: absolute;
          top: 0;
          left: 0;
          img {
            display: block;
            width: 24px;
            height: 24px; } }
        &__title {
          font-size: 16px;
          line-height: 24px; }
        &__wrap {
          position: relative;
          padding: 0 0 0 36px; } } } }

  &__compare {
    justify-content: center;

    .compare-nav {
      display: none; }
    .item {
      &__head {
        padding: 24px;
        background: #EDF0F2;
        text-align: center;
        border-bottom: 1px solid #626B79;
        .sub {
          display: block;
          color: #232933; }
        .title {
          font-size: 20px;
          line-height: 28px;
          color: #232933;
          @include inter-600(); } }
      &__body {
        padding: 24px;
        .title {
          font-size: 20px;
          line-height: 28px;
          color: #232933;
          @include inter-600();
          margin-bottom: 24px; }
        .custom-list {
          b {
            display: block;
            @include inter-600();
            color: #232933; }
          li {
            margin-bottom: 32px;
            @include inter-400();
            &:last-child {
              margin-bottom: 0; } } } }
      &__action {
        text-align: center;
        padding-top: 32px;
        padding-bottom: 8px; }
      &__wrap {
        border: 1px solid #626B79;
        border-radius: 12px;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column; }
      &--highlight {
        .item__head {
          background: #373F4C;
          border-bottom-color: #373F4C;
          .sub {
            color: #FFF; }
          .title {
            color: #FFF;
            font-size: 32px;
            line-height: 48px; } }
        .item__wrap {
          border-color: #373F4C;
          box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 3px 6px -4px rgba(0, 0, 0, 0.12); } } }
    @include maxtablet() {
      .compare-nav {
        display: block;
        position: relative;
        z-index: 2;
        &__wrap {
          display: flex;
          position: relative; }
        .nav-link {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          position: relative;
          width: 100%;
          text-align: center;
          border-radius: 12px 12px 0 0;
          background: #EDF0F2;
          padding: 12px 6px;
          border: 1px solid transparent;
          &.nav-link--highlight {
            &.active {
              border-color: #373F4C;
              .title {
                color: #373F4C; } } }
          &.active {
            border-color:#373F4C {}
            background: #FFF;
            // .title
            //   font-size: 20px
            //   line-height: 28px
            &::after {
              content: "";
              display: block;
              width: 100%;
              height: 2px;
              position: absolute;
              top: 100%;
              left: 0;
              background: #FFF; } }
          .sub {
            font-size: 14px;
            line-height: 23px; }
          .title {
            font-size: 16px;
            line-height: 24px;
            @include inter-600(); } } }
      .item {
        display: none;
        &.active {
          display: block; }
        &__head {
          display: none; }
        &__wrap {
          border-radius: 0 0 12px 12px; }
        &--highlight {
          .item__wrap {
            top: auto; } } } } }

  &__nav {
    max-width: 880px;
    margin: 0 auto 32px;
    position: relative;
    &::after {
      content: "";
      display: block;
      width: calc(100% + 24px);
      height: 1px;
      background: #CFCDC8;
      position: absolute;
      bottom: 0;
      left: -12px; }
    .nav-sm {
      display: none; }
    .nav {
      display: flex;
      justify-content: center;
      &-item {
        position: relative;
        width: 100%;
        padding-left: 12px;
        padding-right: 12px;
        // max-width: 16.666667%
 }        // flex: 0 0 16.666667%
      &-link {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 16px;
        height: 80px;
        border-bottom: 2px solid transparent;
        img {
          display: block;
          max-height: 40px;
          max-width: 100%;
          height: auto;
          width: auto;
          // filter: grayscale(100)
          filter: brightness(0);
          opacity: .5; }
        &.active {
          border-bottom-color: #0055A2;
          img {
            filter: none;
            opacity: 1; } } } }
    .owl-nav {
      position: absolute;
      width: 100%;
      height: 0;
      left: 0;
      top: 50%;
      .owl-prev,
      .owl-next {
        position: absolute;
        left: -56px;
        top: calc(100% - 28px);
        outline: none !important;
        span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
          background-image: url(../images/ic-page-left.svg);
          background-size: auto 32px;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          opacity: 0.4;
          transition: all .4s ease-in-out; }
        &.disabled {
          opacity: 0; }
        &:hover {
          span {
            opacity: 1; } } }
      .owl-next {
        left: auto;
        right: -56px;
        span {
          background-image: url(../images/ic-page-right.svg); } } }
    @include maxtablet() {
      &::after {
        display: none; }
      .nav-tabs {
        display: none; }
      .nav-sm {
        display: block;
        position: relative;
        z-index: 10;
        .nav {
          display: none;
          position: absolute;
          top: 60px;
          left: 0;
          width: 100%;
          background: #FFF;
          border: 1px solid $border;
          border-radius: 4px;
          padding: 6px 0; } }
      .nav-sm__selected {
        position: relative;
        display: block;
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        color: #232933;
        display: block;
        height: auto;
        min-height: 52px;
        padding: 12px 32px 12px 0;
        border-bottom: 1px solid #0055A2;
        &::after {
          content: "";
          display: block;
          width: 24px;
          height: 24px;
          position: absolute;
          top: 12px;
          right: 0;
          background: url(../images/ic-dropdown.svg) no-repeat center;
          background-size: contain; } }
      .nav-item {
        max-width: 100%;
        flex: 0 0 100%; }
      .nav-link {
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #232933;
        display: block;
        height: auto;
        min-height: 40px;
        padding: 6px 12px;
        border: 0;
        &:hover {
          text-decoration: none; } } } }

  &__wrap {
    padding-top: 32px;
    padding-bottom: 32px; } }


.block-four {
  padding: 64px 0;
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    text-align: center; }
  &__desc {
    margin-bottom: 24px;
    text-align: center;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__action {
    text-align: center;
    margin-bottom: 24px;
    a,.btn {
      margin-right: 16px;
      @include maxtablet() {
        margin-right: 0;
        width: 100%;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0; } } } }
  .item__wrap {
    margin-top: 10px;
    .item {
      padding: 24px;
      height: 100%;
      border: 1px solid #D0D6DD;
      border-radius: 4px;
      &__icon {
        height: 52px;
        width: auto;
        object-fit: contain;
        margin-bottom: 8px; }
      &__title {
        @include inter-600();
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 8px; }
      &__desc {
        p {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } } } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      br {
        display: none; } }
    .item__wrap {
      .col-12 {
        .item {
          padding: 12px;
          margin-bottom: 24px;
          height: calc(100% - 24px);
          &__icon {
            height: 32px; } }
        &:last-child {
          .item {
            margin-bottom: 0;
            height: 100%; } } } } } }


.tab-sidebar-fea {
  padding: 64px 0;
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    text-align: center; }
  &__desc {
    margin-bottom: 24px;
    text-align: center;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__action {
    text-align: center;
    margin-bottom: 24px;
    a,.btn {
      margin-right: 16px;
      @include maxtablet() {
        margin-right: 0;
        width: 100%;
        margin-bottom: 14px;
        &:last-child {
          margin-bottom: 0; } } } }
  &__list {
    display: block;
    border: 0;
    .nav-item {
      border: 0;
      .nav-link {
        margin-top: 1px;
        padding: 12px;
        display: block;
        width: 100%;
        border-bottom: 1px solid #D0D6DD;
        color: #626B79;
        &.active,&:hover {
          border-bottom: 2px solid #F96D01;
          color: #232933;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          @include inter-600(); } } } }
  &__content {
    background: #F2F4F7;
    padding: 32px;
    border-radius: 4px;
    .item {
      &__img {
        margin: 0 auto 28px auto;
        display: block; }
      &__desc {
        p {
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } }
      &__action {
        margin-top: 24px;
        a,.btn {
          margin-right: 16px;
          @include maxtablet() {
            margin-right: 0;
            width: 100%;
            margin-bottom: 14px;
            &:last-child {
              margin-bottom: 0; } } } } } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      br {
        display: none; } }
    .tab-content {
      .tab-pane {
        display: block;
        .tab-sidebar-fea__content {
          opacity: 0;
          visibility: hidden;
          height: 0;
          padding: 0;
          transition: all linear .3s; }
        .tab-sidebar-fea__accr {
          padding: 12px;
          display: block;
          width: 100%;
          border-bottom: 1px solid #D0D6DD;
          color: #626B79;
          position: relative;
          &::after {
            position: absolute;
            width: 24px;
            height: 24px;
            content: '';
            background: url('../images/ic-caret-down.svg') no-repeat center;
            background-size: contain;
            right: 10px;
            top: 13px;
            transition: all linear .3s; } }
        &.active {
          .tab-sidebar-fea__content {
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 24px;
            transition: all linear .3s; }
          .tab-sidebar-fea__accr {
            border-bottom: 2px solid #F96D01;
            color: #232933;
            border-top: 0;
            border-left: 0;
            border-right: 0;
            @include inter-600();
            &::after {
              transform: rotate(180deg);
              transition: all linear .3s; } } } } } } }
