/* ------------------------------------------------------------------------------------------------ */
/* Fonts */
/* ------------------------------------------------------------------------------------------------ */

@include fontGenerator("Helvetica Neue",(200, 400, 500, 800),(HelveticaNeueLight, HelveticaNeue, HelveticaNeueMedium, HelveticaNeueBold));

@include fontGenerator("Roboto Reguler",(400),(roboto-regular));
@include fontGenerator("Roboto Medium",(500),(roboto-medium));
@include fontGenerator("Roboto Semibold",(600),(roboto-semibold));

@include fontGenerator("Inter",(400),(Inter-Regular));
@include fontGenerator("Inter",(500),(Inter-Medium));
@include fontGenerator("Inter",(600),(Inter-SemiBold));
@include fontGenerator("Inter",(700),(Inter-Bold));

@mixin robo-400 {
  font-family: "Roboto Reguler", sans-serif;
  font-weight: 400; }

@mixin robo-500 {
  font-family: "Roboto Medium", sans-serif;
  font-weight: 500; }

@mixin robo-600 {
  font-family: "Roboto bold", sans-serif;
  font-weight: 600; }


@mixin inter-400 {
  font-family: "Inter", sans-serif;
  font-weight: 400; }

@mixin inter-500 {
  font-family: "Inter", sans-serif;
  font-weight: 500; }

@mixin inter-600 {
  font-family: "Inter", sans-serif;
  font-weight: 600; }

@mixin inter-700 {
  font-family: "Inter", sans-serif;
  font-weight: 700; }
