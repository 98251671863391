.banner-block {
  padding: 64px 0;
  &__wrapper {
    padding: 24px;
    background: #F1F5F9;
    border-radius: 8px; }
  &__meta {
    @include inter-600();
    span.badge {
      margin-left: 4px; } }
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    padding-right: 30px; }
  &__desc {
    margin-bottom: 32px;
    padding-right: 30px;
    p {
      &:last-child {
        margin-bottom: 0px; } } }
  &__img {
    text-align: center;
    img {
      display: inline-block;
      margin: 0 auto; } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      padding-right: 0; }
    &__desc {
      padding-right: 0; }
    &__img {
      margin-top: 24px; } }
  @include mobilelg() {
    &__wrapper {
      margin-left: 0;
      margin-right: 0;
      padding: 24px 12px; } } }


.banner-block--gray {
  padding: 64px 0;
  .banner-block {
    &__wrapper {
      padding: 32px 52px;
      background: #F1F5F9;
      border-radius: 8px; }
    &__meta {
      @include inter-600();
      span.badge {
        margin-left: 4px;
        background: #009BDE;
        max-width: 600px; } }
    &__title {
      @include inter-600();
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 8px;
      padding-right: 30px;
      max-width: 600px; }
    &__desc {
      margin-bottom: 24px;
      padding-right: 30px;
      max-width: 600px;
      p {
        &:last-child {
          margin-bottom: 0px; } } }
    &__img {
      text-align: center;
      img {
        display: inline-block;
        margin: 0 auto; } }
    &__action {
      max-width: 600px;
      a {
        margin-right: 16px;
        &:last-child {
          margin-right: 0; } } }
    @include maxtablet() {
      padding: 32px 0;
      &__title {
        font-size: 28px;
        line-height: 36px;
        padding-right: 0; }
      &__desc {
        padding-right: 0; }
      &__img {
        margin-top: 24px; } }
    @include mobilelg() {
      &__wrapper {
        margin-left: 0;
        margin-right: 0;
        padding: 24px 12px; }
      &__action {
        a {
          width: 100%;
          margin-right: 0;
          margin-bottom: 16px;
          &:last-child {
            margin-bottom: 0; } } } } } }
