.mastheadrvmp--partner {
  .mastheadrvmp__img {
    img {
      border-radius: 8px; } }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    .mastheadrvmp__buttons {
      margin-left: 0;
      margin-right: 0;
      .btn {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 12px;
        width: 100%; } } } }

.partner-feature {
  &__title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.5px;
    margin-bottom: 28px;
    font-weight: 600; }
  &__desc {
    margin-bottom: 48px;
    p:last-child {
      margin-bottom: 0; } }
  .item {
    margin-bottom: 24px;
    &__icon {
      margin-bottom: 12px;
      img {
        display: block;
        height: 56px;
        width: auto; } }
    &__title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 12px; }
    &__desc {
      p:last-child {
        margin-bottom: 0; } } }
  &__buttons {
    padding-top: 64px; }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__buttons {
      padding-top: 40px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }

.partner-oppst {
  background: #F2F4F7;
  .item {
    &__img {
      img {
        display: block;
        width: 100%;
        height: auto; } }
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 28px;
      font-weight: 600; }
    &__desc {
      margin-bottom: 32px;
      p:last-child() {
        margin-bottom: 0; } }
    &__buttons {
      margin-left: -8px;
      margin-right: -8px;
      .btn,
      .link-more {
        margin-left: 8px;
        padding-right: 8px; } }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px;
      align-items: center;
      justify-content: space-between;
      flex-direction: row; }
    &:nth-last-child(even) {
      .item__wrap {
        flex-direction: row-reverse; } } }
  @include maxtablet() {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 12px;
    padding-right: 12px;
    .item {
      flex-direction: column;
      &__img {
        margin-bottom: 24px; }
      &__title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 12px; }
      &__wrap {
        padding-top: 32px;
        padding-bottom: 32px; }
      &:nth-last-child(even) {
        .item__wrap {
          flex-direction: column; } } } } }

.partner-block-table {
  background: #F2F4F7;
  .block-table {
    &__sub {
      display: block;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px; }
    &__title {
      text-align: left;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.5px;
      margin-bottom: 28px;
      font-weight: 600; }
    &__desc {
      text-align: left;
      margin-bottom: 48px; } }
  @include maxtablet {
    padding-left: 12px;
    padding-right: 12px;
    .block-table {
      &__title {
        font-size: 24px;
        line-height: 32px; } } } }

.partner-form {
  background: #F2F4F7;
  &__title {
    font-size: 40px;
    line-height: 48px;
    font-weight: 600;
    margin-bottom: 16px; }
  &__desc {
    max-width: 600px;
    margin: 0 auto 32px;
    p:last-child {
      margin-bottom: 0; } }
  &__code {
    background: #FFF;
    border: 1px solid #E3E3E8;
    border-radius: 4px;
    padding: 40px; }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__wrap {
      padding-top: 64px;
      padding-top: 64px; } } }

.partner-integrator {
  .item {
    margin-bottom: 24px;
    &__wrap {
      border: 1px solid #E3E3E8;
      border-radius: 4px;
      padding: 24px; }
    &__icon {
      margin-bottom: 16px;
      img {
        display: block;
        height: 56px;
        width: auto; } }
    &__title {
      font-size: 18px;
      line-height: 20px;
      margin-bottom: 12px; }
    &__desc {
      p {
        &:last-child {
          margin-bottom: 0; } } } }
  &__title {
    text-align: center;
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 24px; }
  &__buttons {
    text-align: center;
    margin-bottom: 48px; }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    .item {
      &__icon {
        position: absolute;
        left: 16px;
        img {
          height: 48px;
          width: 48px; } }
      &__wrap {
        position: relative;
        padding: 16px 16px 16px 80px; } }
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__wrap {
      padding: 64px 24px; } } }

