.big-number {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -16px;
  margin-right: -16px;
  .item {
    width: 100%;
    max-width: 280px;
    flex: 0 0 280px;
    padding-right: 16px;
    padding-left: 16px;
    span.large {
      display: block;
      color: $text-dark;
      font-size: 48px;
      line-height: 56px;
      @include inter-600(); }
    p {
      color: $text-dark;
      @include inter-500(); } }
  @include mobilesm {
    .item {
      margin-bottom: 40px; } } }


.cs-detail {
  &__head {
    padding-top: 50px;
    padding-bottom: 80px; }
  &__body {
    padding-bottom: 64px;
    .free-trial-block {
      margin-bottom: 32px; } }

  .sp-block {
    margin-bottom: 80px;
    .img {
      margin-bottom: 16px; }
    .title {
      @include inter-600();
      margin-bottom: 12px; }
    &__wrap {
      display: flex;
      align-items: center;
      padding: 16px 0; }
    &__problem {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      padding: 32px 48px;
      background: #F2F4F7;
      border-radius: 8px 0 0 8px;
      .title {
        color: $text-dark; } }
    &__solution {
      display: block;
      position: relative;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%;
      padding: 32px 48px;
      color: #FFF;
      background: #2C9CA3;
      border-radius: 0 8px 8px 0;
      .img,
      .title,
      p {
        z-index: 2;
        position: relative; }
      &:before {
        content: "";
        z-index: 1;
        display: block;
        position: absolute;
        width: 34px;
        height: calc(100% + 32px);
        top: -16px;
        left: -32px;
        background: #2C9CA3;
        border-radius: 6px 0 0 6px;
        clip-path: polygon(100% 0%, 100% 50%, 100% 100%, 0% 100%, calc(100% - 2px) 50%, 0% 0%); }
      &:after {
        content: "";
        z-index: 1;
        display: block;
        position: absolute;
        width: 100%;
        height: calc(100% + 32px);
        top: -16px;
        left: 0;
        background: #2C9CA3;
        border-radius: 0 8px 8px 0; } }
    @include mobilesm {
      margin-bottom: 48px;
      .sp-block__wrap {
        flex-direction: column; }
      .sp-block__problem,
      .sp-block__solution {
        max-width: 100%;
        flex: 0 0 100%;
        padding: 24px; }
      .sp-block__problem {
        border-radius: 8px 8px 0 0; }
      .sp-block__solution {
        border-radius: 0 0 8px 8px;
        &:before,
        &:after {
          display: none; } } } }

  .img-full {
    margin-bottom: 24px; }

  .blockquote {
    &__author {
      display: flex;
      margin-left: 104px;
      .author__avatar {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 16px; }
      .author__text {
        display: {}
        width: 100%;
        max-width: calc(100% - 40px);
        // padding-left: 16px
        span {
          display: block;
          font-size: 14px;
          line-height: 20px; } }
      .author__name {
        color: #2B2A35;
        @include inter-600(); } } }

  blockquote {
    position: relative;
    font-style: normal;
    max-width: 600px;
    margin: 24px auto;
    p {
      color: #2B2A35;
      font-size: 16px;
      line-height: 24px;
      span {
        font-weight: 600 !important; } }
    &:before {
      content: "\201C";
      display: block;
      position: absolute;
      top: -16px;
      left: -36px;
      font-size: 76px;
      line-height: 90px;
      color: #F96D01;
      @include inter-600(); }
    @include mobilesm() {
      margin-left: 32px;
      blockquote {
        p {
          font-size: 20px;
          line-height: 28px; } } } }

  .featured-block {
    padding: 40px 0 80px;
    .item {
      &__img {
        margin-bottom: 8px; }
      &__title {
        display: block;
        color: #2B2A35;
        margin-bottom: 12px;
        @include inter-600(); } }
    @include mobilesm {
      padding-bottom: 48px;
      .item {
        margin-bottom: 32px; } } }

  .block-2-col {
    padding: 74px 0;
    .item {
      &:not(:last-child) {
        margin-bottom: 100px; }
      &:nth-child(even) {
        flex-direction: row-reverse; } }
    @include mobilesm {
      .item {
        flex-direction: column-reverse;
        img {
          margin-bottom: 24px; }
        &:nth-child(even) {
          flex-direction: column-reverse; } } } }

  article {
    @include inter-400();
    .btn {
      white-space: break-spaces; }
    p {
      margin: 0 0 24px;
      &:last-child {
        margin: 0; } }
    img {
      max-width: 100%;
      height: auto; }
    h2, h3, h4 {
      margin-bottom: 1rem; } } }

.cs-sidebar {
  &__wrap {
    max-width: 312px;
    margin-bottom: 48px;
    background: #F1F5F9;
    padding: 16px;
    border-radius: 4px; }
  .item {
    margin-bottom: 32px;
    .custom-list {
      &> li:before {
        width: 18px;
        height: 18px; }
      li {
        padding-left: 32px; } }
    &__title {
      font-size: 14px;
      line-height: 20px;
      color: $text-dark;
      margin-bottom: 8px;
      @include inter-600(); }
    &__desc {
      font-size: 14px;
      line-height: 20px;
      color: $text-dark;
      ul {
        li {
          margin-bottom: 8px;
          a {
            color: $text-dark;
            text-decoration: underline;
            opacity: 1;
            transition: all .4s ease;
            &:hover {
              opacity: .5; } } } } }
    &-action {
      .btn {
        display: block; } } }
  @include mobilesm {
    margin-bottom: 64px; } }

.cs-more,
.case-study {
  padding: 80px 0;
  background: #F2F4F7;
  &__filter {
    margin: 0 0 48px;
    .bootstrap-select > .dropdown-toggle {
      padding-top: 9px;
      padding-bottom: 9px; }
    form {
      display: flex;
      margin-left: -8px;
      margin-right: -8px; }
    .form-group,
    .form-action {
      display: block;
      margin: 0;
      padding: 0 8px;
      width: 100%;
      flex-basis: 0; }
    .form-group {
      flex-grow: 2; }
    .form-action {
      flex-grow: 1.2;
      .btn {
        display: block;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px; } }
    @include mobilesm {
      padding-left: 20%;
      padding-right: 20%;
      form {
        flex-direction: column; } } }

  .item {
    border-radius: 4px;
    margin-bottom: 48px;
    &__wrap {
      display: flex;
      position: relative;
      flex-direction: column;
      height: 100%; }
    &__img {
      display: block;
      position: relative;
      width: 100%;
      padding-top: 56%;
      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: 4px 4px 0 0; } }
    &__cta {
      margin-top: auto; }
    &__content {
      padding: 20px 23px 24px;
      background: #fff;
      border: 1px solid $border;
      border-radius: 0 0 4px 4px;
      height: 100%;
      display: flex;
      flex-direction: column; }
    &__title {
      margin-bottom: 8px;
      font-size: 20px;
      line-height: 32px;
      a {
        display: block;
        color: #2B2A35;
        text-decoration: none;
        &:hover {
          color: $link; } } }
    &__desc {
      margin-bottom: 16px; } } }

.cs-more {
  .section-head {
    width: 100%;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-end;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 0; }
    &__more {
      margin-bottom: 0;
      margin-left: auto;
      padding-right: 32px; } }
  .item {
    margin-bottom: 0; } }
