.event-speaker {
  padding: 40px 0;
  &__wrap {
    text-align: center; }
  &__title {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 40px; }
  &__box {
    display: inline-block;
    margin: 0 8px; }
  &__info {
    b,span {
      display: block;
      text-align: center; }
    b {
      font-size: 16px;
      line-height: 24px; }
    span {
      font-size: 14px;
      line-height: 20px;
      color: #232933; } }
  &__img {
    margin: 0 auto 8px auto;
    overflow: hidden;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    img {
      width: 100%;
      height: auto;
      object-fit: cover; } }
  @include maxtablet() {
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 32px; }
    &__box {
      display: inline-block;
      margin: 0 auto 18px auto;
      width: 55%; }
    &__img {
      width: 40px;
      height: 40px;
      display: inline-block;
      vertical-align: top;
      margin-right: 12px; }
    &__info {
      display: inline-block;
      width: calc(100% - 57px);
      vertical-align: top;
      b,span {
        text-align: left; } } } }

.event-agenda {
  padding: 60px 0;
  .event-activity {
    padding-right: 30px;
    &__subtitle {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px;
      color: #232933; }
    &__title {
      font-size: 32px;
      line-height: 48px;
      margin-bottom: 24px;
      @include inter-600(); }
    &__list-label {
      margin-bottom: 8px; }
    &__buttons {
      margin-top: 32px;
      margin-bottom: 16px; } }
  .event-timeline {
    &__day {
      display: block;
      color: #626B79;
      font-size: 20px;
      line-height: 28px;
      padding-bottom: 20px;
      border-bottom: 1px solid $border;
      margin-bottom: 24px;
      @include inter-600(); }
    &__list {
      list-style: none;
      li {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; }
        span {
          width: calc(100% - 244px);
          display: inline-block;
          vertical-align: top;
          b {
            font-size: 20px;
            line-height: 28px;
            display: block; }
          &.time {
            display: inline-block;
            vertical-align: top;
            max-width: 223px;
            width: 100%;
            margin-right: 16px; } } } } }
  @include maxtablet() {
    padding: 40px 0;
    .event-activity {
      padding-right: 0;
      &__title {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 16px; }
      &__buttons {
        a {
          display: block;
          width: 100%;
          margin-bottom: 14px;
          &:last-child {
            margin-bottom: 0; } } } }
    .event-timeline {
      margin-top: 48px;
      &__day {
        font-size: 20px;
        line-height: 28px; }
      &__list {
        span {
          width: calc(100% - 146px) !important;
          &.time {
            width: 130px!important;
            font-size: 14px!important;
            line-height: 23px!important; }
          b, p {
            font-size: 14px!important;
            line-height: 23px!important; } } } } } }

