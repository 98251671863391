.tb-up {
  position: relative;
  background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%);
  &__img {
    display: block;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 144px; }
  &__sub {
    color: #FFF;
    font-size: 16px;
    line-height: 24px;
    @include inter-600();
    text-align: center; }
  &__title {
    color: #FFF;
    font-size: 32px;
    line-height: 48px;
    @include inter-700();
    text-align: center; }
  &__desc {
    color: #FFF;
    font-size: 16px;
    line-height: 24px;
    @include inter-400();
    text-align: center; }
  &__action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  &__sm {
    display: none; }
  &__left {
    display: block;
    width: 100%;
    max-width: 20%;
    flex: 0 0 20%; }
  &__center {
    display: block;
    width: 100%;
    max-width: 60%;
    flex: 0 0 60%;
    padding: 20px; }
  &__right {
    display: block;
    width: 100%;
    max-width: 20%;
    flex: 0 0 20%;
    text-align: right; }
  &__wrap {
    display: flex;
    align-items: center; }
  @include maxtablet() {
    &__left,
    &__right,
    &__sub,
    &__desc,
    &__title {
      display: none; }
    &__center {
      max-width: 100%;
      flex: 0 0 100%; }
    &__sm {
      display: block;
      text-align: center;
      .title {
        font-size: 20px;
        line-height: 28px;
        color: #FFF;
        @include inter-600();
        margin: 0 0 12px; }
      .btn {
        width: 100%; } }
    &__title {
      font-size: 20px;
      line-height: 28px; } } }

.block-upwn {
  .item {
    &__img {
      position: relative;
      width: 100%;
      padding-top: 59.55%;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center; } }
    &__sub {
      @include inter-600(); }
    &__title {
      font-size: 32px;
      line-height: 48px;
      @include inter-600();
      margin: 0 0 12px; }
    &__img-sm {
      display: none; }
    &__desc {
      p {
        margin-bottom: 20px; } }
    &__action {
      display: flex;
      flex-wrap: wrap;
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin-left: 8px;
        margin-right: 8px; } }
    &__wrap {
      align-items: center; } }
  &__title {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 8px; }
  &__desc {
    text-align: center; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include maxtablet() {
    .item {
      padding-left: 12px;
      padding-right: 12px;
      &__title {
        font-size: 28px;
        line-height: 36px;
        margin: 0 0 12px; }
      &__img-sm {
        display: block;
        margin: 0 0 12px;
        position: relative;
        width: 100%;
        padding-top: 59.55%;
        img {
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center; } }
      &__action {
        margin-left: 0;
        margin-right: 0;
        .btn,
        .link-more {
          margin-left: 0;
          margin-right: 0;
          width: 100%;
          margin-top: 8px; } }
      &__wrap {
        border: 1px solid $border;
        border-radius: 4px;
        padding: 12px 0;
        margin: 0;
        &> .col-lg-7 {
          display: none; } } }
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__slider {
      margin-left: -12px;
      margin-right: -12px;
      width: calc(100% + 24px); }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.block-upposts {
  .item {
    position: relative;
    &__img {
      position: relative;
      margin: 0 0 24px;
      padding-top: 56.736%;
      overflow: hidden;
      border-radius: 4px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        position: absolute;
        top: 0;
        left: 0;
        transform: none;
        transition: all .4s ease-in-out; }
      &:hover {
        img {
          transform: scale(1.05); } } }
    &__sub {
      font-size: 14px;
      line-height: 20px;
      display: block;
      color: #626B79; }
    &__title {
      font-size: 20px;
      line-height: 28px;
      flex-grow: 2;
      padding-bottom: 16px;
      @include inter-600();
      margin: 0;
      a {
        transition: all .3s ease; }
      &:hover {
        a {
          color: $primary; } } }
    &__auth {
      display: flex;
      span {
        display: block;
        font-size: 16px;
        line-height: 24px;
        color: #626B79;
        @include inter-400(); }
      .date::before {
        content: "•";
        display: inline-block;
        margin: 0 8px; } }
    &__desc {
      overflow: hidden;
      max-height: 72px;
      min-height: 72px;
      flex-grow: 2;
      p {
        @include inter-400(); }
      p:last-child {
        margin: 0; } }
    &__action {
      margin-top: auto;
      padding-top: 24px;
      .btn {
        width: 100%; } }
    &__wrap {
      position: relative;
      z-index: 2;
      display: flex;
      height: 100%;
      flex-direction: column; }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      height: 100%; }
    &::before {
      content: "";
      z-index: 1;
      display: block;
      width: 100%;
      height: calc(100% + 24px);
      position: absolute;
      top: -12px;
      left: 0;
      border-radius: 8px;
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0), 0px 4px 6px -2px rgba(0, 0, 0, 0);
      transition: all .3s ease; }
    &:hover {
      &::before {
        box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05); } }
    &:not(.col-lg-4) {
      padding-left: 12px;
      padding-right: 12px;
      .item__title {
        min-height: 110px; } } }
  &__shead {
    margin-bottom: 24px; }
  &__title {
    @include inter-600(); }
  &__action {
    margin-left: -8px;
    margin-right: -8px;
    .btn,
    .link-more {
      margin-left: 8px;
      margin-right: 8px; } }
  &__more {
    padding-top: 32px;
    text-align: center;
    width: 100%; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  .owl-dots {
    display: block !important; }
  @include maxtablet() {
    .item {
      padding-left: 12px;
      padding-right: 12px;
      margin: 0 0 2px;
      &__img {
        margin: 0 0 12px; }
      &__title {
        margin: 0 0 12px; }
      &__action {
        padding-top: 12px; }
      &__wrap {
        border: 1px solid $border;
        border-radius: 4px;
        padding: 12px; } }
    &__smain {
      position: relative;
      margin-left: -12px;
      margin-right: -12px;
      width: calc(100% + 24px); }
    &__shead {
      text-align: center; }
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin: 0 0 8px; }
    &__action {
      margin-left: 0;
      margin-right: 0;
      .btn,
      .link-more {
        margin-left: 0;
        margin-right: 0; } }
    &__desc {
      p {
        margin: 0 0 8px; }
      .btn {
        width: 100%; } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.block-upebook {
  &__img {
    position: relative;
    text-align: center;
    img {
      display: inline-block;
      max-width: 264px;
      height: auto;
      margin-top: -64px; } }
  &__title {
    margin: 0 0 8px; }
  &__desc {
    margin: 0 0 8px;
    p {
      @include inter-400(); }
    p:last-child() {
      margin: 0; } }
  &__box {
    background: #EAECFB;
    border-radius: 4px;
    min-height: 348px;
    padding: 24px;
    display: flex;
    align-items: center;
    margin-left: 0;
    margin-right: 0;
    position: relative;
    &::before {
      content: "";
      display: block;
      width: 40%;
      height: 100%;
      background-image: url(../images/or-update-pajak.svg);
      background-position: -2px 85%;
      background-size: contain;
      background-repeat: no-repeat;
      position: absolute;
      top: 0;
      left: 0; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__img {
      margin: 0 0 32px;
      img {
        margin-top: 0;
        max-height: 280px;
        max-width: 100%;
        width: auto;
        height: auto; } }
    &__box {
      padding: 24px 12px;
      &::before {
        width: 100%;
        height: 300px; } } } }

.block-upform {
  &__sub {
    @include inter-600(); }
  &__title {
    @include inter-600(); }
  &__img {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      width: auto; } }
  &__box {
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin-left: 16px;
    padding: 32px; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__sub {
      text-align: center; }
    &__title {
      text-align: center;
      font-size: 28px;
      line-height: 36px;
      margin: 0 0 24px; }
    &__box {
      padding: 24px;
      margin-left: 0; } } }

.block-wbb {
  &__title {
    margin: 0 0 12px; }
  &__desc {
    margin: 0 0 12px; }
  &__img {
    img {
      max-width: 100%;
      height: auto; } }
  &__box {
    background: #EAECFB;
    border-radius: 4px;
    padding: 32px; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__img {
      margin-top: 24px; }
    &__box {
      padding: 24px; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.block-wspeakers {
  &__title {
    text-align: center;
    margin: 0 0 32px; }
  .item {
    &__img {
      margin: 0 0 8px;
      img {
        display: block;
        width: 120px;
        height: 120px;
        object-fit: cover;
        object-position: center;
        border-radius: 60px;
        margin: 0 auto; } }
    &__name {
      font-size: 16px;
      line-height: 24px;
      @include inter-600();
      text-align: center; }
    &__title {
      text-align: center; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    .item {
      margin-bottom: 24px;
      &__img {
        img {
          width: 80px;
          height: 80px; } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 8px; } } }

.block-wdetail {
  &__sub {
    display: block;
    margin: 0 0 8px; }
  &__title {
    margin: 0 0 24px; }
  &__points {
    .title {
      display: block;
      margin: 0 0 8px; } }
  &__rundown {
    .date {
      display: block;
      @include inter-600();
      color: #626B79;
      font-size: 20px;
      line-height: 28px; }
    hr {
      margin: 24px 0; }
    .items {
      position: relative; }
    .item {
      &__left {
        display: block;
        max-width: 35%;
        flex: 0 0 35%;
        color: #232933; }
      &__right {
        display: block;
        max-width: 65%;
        flex: 0 0 65%;
        color: #232933;
        span.title {
          @include inter-600();
          font-size: 20px;
          line-height: 28px; } }
      &__wrap {
        display: flex; } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__rundown {
      padding-top: 32px;
      hr {
        margin: 16px 0; }
      .item {
        &__left,
        &__right {
          max-width: 100%;
          flex: 0 0 100%; }
        &__left {
          padding-bottom: 8px; }
        &__right {
          span.title {
            font-size: 16px;
            line-height: 24px; } }
        &__wrap {
          display: block; } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

#webinar_prev_posts {
  &.loading {
    position: relative;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 255, 0.7);
      background-image: url(../images/loading.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 48px;
      z-index: 2; } }
  .item {
    margin-top: 48px; }
  @include maxtablet() {
    .item {
      margin-top: 24px; } } }
