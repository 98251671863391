.masthead {
  &.masthead--npwp {
    .masthead__npwp-form {
      background: #EAECFB;
      padding: 32px;
      border-radius: 8px;
      .title {
        font-size: 20px;
        line-height: 28px;
        @include inter-600();
        color: #232933;
        display: block;
        text-align: center;
        margin: 0 0 16px; }
      form {
        label {
          display: block;
          margin: 0 0 4px;
          @include inter-600(); }
        .form-control {
          border-radius: 6px;
          border: 1px solid #D0D6DD; }
        .form-group {
          margin: 0 0 20px; }
        .fomr-action {
          padding-top: 4px;
          .btn:disabled {
            border: 1px solid #D0D6DD;
            background: #EDF0F2;
            color: #8B95A5;
            opacity: 1; }
          .btn-blue-border {
            background: #FFF;
            &:hover {
              color: $button;
              border-color: $button; } } } }
      .npwp-val-msg {
        background: #FFF;
        display: flex;
        padding: 40px 16px;
        text-align: center;
        .msg {
          color: #626B79;
          text-align: center;
          width: 100%;
          .msg-valid {
            display: none; } } }
      .btn-cn-kp,
      .btn-cn-trial,
      .btn-cn-resubmit {
        display: none; }
      &.npwp-valid {
        .npwp-val-msg {
          padding: 24px 16px; }
        .msg {
          text-align: left;
          .msg-valid {
            display: flex;
            align-items: center;
            padding-bottom: 12px;
            border-bottom: 1px solid #D0D6DD;
            margin: 0 0 12px;
            img {
              display: block;
              height: 58px;
              width: auto; }
            span {
              display: block;
              padding: 12px;
              color: #232933;
              @include inter-600(); } } }
        .text {
          color: #232933; }
        .btn-cn-submit {
          display: none; }
        .btn-cn-trial {
          margin-top: 0;
          display: block; } }
      &.npwp-invalid {
        .text {
          color: #232933; }
        .btn-cn-kp {
          display: block;
          margin-top: 0; }
        .btn-cn-resubmit {
          display: block; }
        .btn-cn-submit {
          display: none; } } }
    @include maxtablet() {
      .masthead__right {
        margin-top: 32px; }
      .masthead__npwp-form {
        padding: 24px; } } } }

.cn-feature {
  &__title {
    text-align: center;
    margin: 0 0 8px; }
  &__sub {
    text-align: center;
    margin: 0 0 8px; }
  &__desc {
    text-align: center;
    margin: 0 0 8px; }
  &__content {
    h3 {
      font-size: 20px;
      line-height: 28px;
      @include inter-600(); } }
  &__action {
    padding-top: 24px;
    margin: 0 -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px;
    .row {
      margin-top: 24px; } }
  &:nth-child(odd) {
    .cn-feature__wrap {
      .row {
        flex-direction: row-reverse; } } }
  @include maxtablet {
    &__action {
      margin: 0;
      .btn {
        margin: 0 0 8px;
        width: 100%; } }
    &__img {
      padding-top: 16px;
      padding-bottom: 24px; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px;
      .row {
        flex-direction: column-reverse; } }
    &:nth-child(odd) {
      .cn-feature__wrap {
        .row {
          flex-direction: column-reverse; } } } } }



.cn-cards {
  &__title {
    text-align: center; }
  .item {
    &__icon {
      margin: 0 0 8px;
      img {
        display: block;
        height: 48px;
        width: 48px;
        object-fit: contain;
        object-position: center; } }
    &__title {
      margin: 0 0 8px;
      @include inter-600(); }
    &__action {
      padding-top: 16px;
      margin-top: auto; }
    &__wrap {
      padding: 24px;
      border: 1px solid #D0D6DD;
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      height: 100%; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet {
    .item {
      margin-bottom: 24px;
      &__wrap {
        padding: 16px; }
      &:last-child {
        margin-bottom: 0; } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }


