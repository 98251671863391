.steps--calendar {
  padding: 64px 0;
  ul.steps-list,
  ol.steps-list {
    padding-left: 0;
    position: relative;
    li {
      padding-left: 60px;
      margin-bottom: 16px;
      position: relative;
      list-style: none;
      counter-increment: li;
      &::before {
        content: '';
        width: 1px;
        height: calc(100% + 16px);
        background: #D0D6DD;
        top: 16px;
        left: 24px;
        display: inline-block;
        position: absolute; }
      &::after {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%);
        border-radius: 29px;
        border: 4px solid #FBF3DD;
        left: 0;
        top: 0;
        position: absolute;
        content: counter(li);
        @include inter-600();
        color: #FFF; }
      &:last-child {
        margin-bottom: 0;
        &::before {
          display: none; } }
      b {
        display: block;
        margin-bottom: 12px; } } }
  @include maxtablet() {
    padding: 32px 0;
    .steps__img {
      display: block;
      padding-left: 0; } } }


.calendar {
  padding: 64px 0;
  position: relative;
  &__action {
    position: absolute;
    top: 32px;
    right: 32px; }
  #calendar {
    transform: translate3d(0, 0, 0);
    width: 100%;
    margin: 0 auto;
    height: auto;
    overflow: hidden;
    padding: 32px;
    border: 1px solid #D0D6DD;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05);
    border-radius: 6px;
    .header {
      height: 50px;
      width: 30%;
      background: #FFF;
      text-align: center;
      position: relative;
      z-index: 100;
      margin-bottom: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      h3 {
        margin: 0;
        padding: 0;
        font-size: 20px;
        line-height: 28px;
        color: #232933;
        @include inter-600();
        letter-spacing: 1px; } }
    .left, .right {
      position: absolute;
      width: 0px;
      height: 0px;
      border-style: solid;
      top: 50%;
      margin-top: -7.5px;
      cursor: pointer; }
    .left {
      border-width: 7.5px 10px 7.5px 0;
      border-color: transparent rgba(160, 159, 160, 1) transparent transparent;
      left: 20px; }
    .right {
      border-width: 7.5px 0 7.5px 10px;
      border-color: transparent transparent transparent rgba(160, 159, 160, 1);
      right: 20px; }
    .week-label {
      border-top: 1px solid #D0D6DD;
      padding-top: 24px; }
    .month {
      opacity: 0;
      &.new {
        -webkit-animation: fadeIn 1s ease-out;
        opacity: 1; }
      &.in {
        &.next {
          -webkit-animation: moveFromTopFadeMonth .4s ease-out;
          -moz-animation: moveFromTopFadeMonth .4s ease-out;
          animation: moveFromTopFadeMonth .4s ease-out;
          opacity: 1; }
        &.prev {
          -webkit-animation: moveFromBottomFadeMonth .4s ease-out;
          -moz-animation: moveFromBottomFadeMonth .4s ease-out;
          animation: moveFromBottomFadeMonth .4s ease-out;
          opacity: 1; } }
      &.out {
        &.next {
          -webkit-animation: moveToTopFadeMonth .4s ease-in;
          -moz-animation: moveToTopFadeMonth .4s ease-in;
          animation: moveToTopFadeMonth .4s ease-in;
          opacity: 1; }
        &.prev {
          -webkit-animation: moveToBottomFadeMonth .4s ease-in;
          -moz-animation: moveToBottomFadeMonth .4s ease-in;
          animation: moveToBottomFadeMonth .4s ease-in;
          opacity: 1; } } }
    .week {
      background: #FFF; }
    .day {
      display: inline-block;
      width: calc(100% / 7);
      padding: 10px;
      text-align: center;
      vertical-align: top;
      cursor: pointer;
      background: #FFF;
      position: relative;
      z-index: 100;
      color: #232933;
      font-size: 32px;
      line-height: 48px;
      border-radius: 8px;
      @include inter-400();
      &.other {
        color: #8B95A5; }
      &.today {
        background: #FBF3DD; }
      .day-events {
        list-style: none;
        margin-top: 3px;
        text-align: center;
        height: 12px;
        line-height: 6px;
        overflow: hidden;
        span {
          vertical-align: top;
          display: inline-block;
          padding: 0;
          margin: 0;
          width: 44px;
          height: 8px;
          border-radius: 12px;
          line-height: 5px;
          margin: 0 1px; } } }
    .day-name {
      font-size: 14px;
      line-height: 20px;
      @include inter-600();
      text-transform: uppercase;
      margin-bottom: 5px;
      color: #626B79;
      letter-spacing: .7px; }
    .day-number {
      padding-top: 12px;
      font-size: 24px;
      letter-spacing: 1.5px; }
    .blue {
      background: rgba(156, 202, 235, 1); }
    .orange {
      background: #E0AB00; }
    .green {
      background: rgba(153, 198, 109, 1); }
    .yellow {
      background: rgba(249, 233, 0, 1); }
    .details {
      position: relative;
      width: 100%;
      height: 75px;
      background: #E0AB00;
      margin-top: 5px;
      border-radius: 4px;
      color: #FFF;
      display: none;
      &.in {
        -webkit-animation: moveFromTopFade .5s ease both;
        -moz-animation: moveFromTopFade .5s ease both;
        animation: moveFromTopFade .5s ease both; }
      &.out {
        -webkit-animation: moveToTopFade .5s ease both;
        -moz-animation: moveToTopFade .5s ease both;
        animation: moveToTopFade .5s ease both;
        .events {
          -webkit-animation: fadeOutShrink .4s ease both;
          -moz-animation: fadeOutShink .4s ease both;
          animation: fadeOutShink .4s ease both; } } }
    .arrow {
      position: absolute;
      top: -5px;
      left: 50%;
      margin-left: -2px;
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent rgba(164, 164, 164, 1) transparent;
      transition: all 0.7s ease; }
    .events {
      height: 75px;
      padding: 7px 0;
      overflow-y: auto;
      overflow-x: hidden;
      &.in {
        -webkit-animation: fadeIn .3s ease both;
        -moz-animation: fadeIn .3s ease both;
        animation: fadeIn .3s ease both;
        -webkit-animation-delay: .3s;
        -moz-animation-delay: .3s;
        animation-delay: .3s; }
      &.out {
        -webkit-animation: fadeOut .3s ease both;
        -moz-animation: fadeOut .3s ease both;
        animation: fadeOut .3s ease both; } }
    .event {
      font-size: 16px;
      line-height: 22px;
      letter-spacing: .5px;
      padding: 2px 16px;
      vertical-align: top;
      &.empty {
        color: #eee; }
      span {
        display: inline-block;
        padding: 0 0 0 7px; } }
    .event-category {
      height: 10px;
      width: 10px;
      display: inline-block;
      margin: 6px 0 0;
      vertical-align: top; }
    .legend {
      display: block;
      width: 100%;
      margin-top: 24px;
      &__label {
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 24px;
        display: block; }
      .item {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        &__date {
          width: 88px;
          height: 88px;
          border-radius: 8px;
          background: #E0AB00;
          color: #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 32px;
          line-height: 48px;
          margin-right: 12px; }
        &__meta {
          min-height: 70px;
          display: flex;
          align-items: space-between;
          flex-direction: column;
          justify-content: space-between;
          align-items: baseline; }
        &__desc {
          display: block; } } }
    .entry {
      position: relative;
      padding: 0 0 0 25px;
      font-size: 13px;
      display: inline-block;
      line-height: 30px;
      background: transparent;
      &:after {
        position: absolute;
        content: '';
        height: 5px;
        width: 5px;
        top: 12px;
        left: 14px; }
      &.blue {
        &:after {
          background: rgba(156, 202, 235, 1); } }
      &.orange {
        &:after {
          background: rgba(247, 167, 0, 1); } }
      &.green {
        &:after {
          background: rgba(153, 198, 109, 1); } }
      &.yellow {
        &:after {
          background: rgba(249, 233, 0, 1); } } } }

  @-webkit-keyframes {
    moveFromTopFade {
      from {
        opacity: .3;
        height: 0px;
        margin-top: 0px;
        -webkit-transform: translateY(-100%); } } }
  @-moz-keyframes {
    moveFromTopFade {
      from {
        height: 0px;
        margin-top: 0px;
        -moz-transform: translateY(-100%); } }
    moveToTopFade {
      to {
        height: 0px;
        -moz-transform: translateY(-100%); } }
    moveToTopFadeMonth {
      to {
        opacity: 0;
        -moz-transform: translateY(-30%); } }
    moveFromTopFadeMonth {
      from {
        opacity: 0;
        -moz-transform: translateY(30%); } }
    moveToBottomFadeMonth {
      to {
        opacity: 0;
        -webkit-transform: translateY(30%); } }
    moveFromBottomFadeMonth {
      from {
        opacity: 0;
        -webkit-transform: translateY(-30%); } }
    fadeIn {
      from {
        opacity: 0; } }
    fadeOut {
      to {
        opacity: 0; } }
    fadeOutShink {
      to {
        opacity: 0;
        padding: 0px;
        height: 0px; } } }
  @keyframes {
    moveFromTopFade {
      from {
        height: 0px;
        margin-top: 0px;
        transform: translateY(-100%); } }
    moveToTopFade {
      to {
        height: 0px;
        transform: translateY(-100%); } }
    moveToTopFadeMonth {
      to {
        opacity: 0;
        -moz-transform: translateY(-30%); } }
    moveFromTopFadeMonth {
      from {
        opacity: 0;
        -moz-transform: translateY(30%); } }
    moveToBottomFadeMonth {
      to {
        opacity: 0;
        -webkit-transform: translateY(30%); } }
    moveFromBottomFadeMonth {
      from {
        opacity: 0;
        -webkit-transform: translateY(-30%); } }
    fadeIn {
      from {
        opacity: 0; } }
    fadeOut {
      to {
        opacity: 0; } }
    fadeOutShink {
      to {
        opacity: 0;
        padding: 0px;
        height: 0px; } } }
  @-webkit-keyframes {
    moveToTopFade {
      to {
        opacity: .3;
        height: 0px;
        margin-top: 0px;
        opacity: 0.3;
        -webkit-transform: translateY(-100%); } }
    moveToTopFadeMonth {
      to {
        opacity: 0;
        -webkit-transform: translateY(-30%) scale(.95); } }
    moveFromTopFadeMonth {
      from {
        opacity: 0;
        -webkit-transform: translateY(30%) scale(.95); } }
    moveToBottomFadeMonth {
      to {
        opacity: 0;
        -webkit-transform: translateY(30%) scale(.95); } }
    moveFromBottomFadeMonth {
      from {
        opacity: 0;
        -webkit-transform: translateY(-30%) scale(.95); } }
    fadeIn {
      from {
        opacity: 0; } }
    fadeOut {
      to {
        opacity: 0; } }
    fadeOutShink {
      to {
        opacity: 0;
        padding: 0px;
        height: 0px; } } }

  @include maxtablet() {
    padding: 32px 0;
    #calendar {
      padding: 26px 21px;
      .header {
        width: 100%;
        height: auto;
        margin-bottom: 12px;
        h3 {
          font-size: 14px;
          line-height: 20px;
          @include inter-400(); } }
      .week-label {
        padding-top: 0;
        border-top: none; }
      .month {
        .week {
          .day {
            .day-number {
              padding-top: 0;
              font-size: 14px;
              line-height: 20px; }
            .day-events {
              text-align: center;
              height: auto;
              span {
                width: 90%;
                height: 4px; } } } } }
      .legend {
        &__label {
          font-size: 14px;
          line-height: 20px; }
        .item {
          align-items: flex-start;
          border-radius: 4px;
          &__date {
            width: 24px;
            height: 24px;
            font-size: 14px;
            line-height: 20px;
            margin-top: 3px; }
          &__meta {
            min-height: 0; } } } } } }

