.feature-block {
  padding: 80px 0;
  .section-head {
    margin-bottom: 92px;
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 20px; }
    &__desc {
      margin-bottom: 28px; }
    @include maxtablet() {
      margin-bottom: 40px;
      &__title {
        font-size: 26px;
        line-height: 1.5em;
        br {
          display: none; } }
      &__desc {
        br {
          display: none; } } } }
  &__items {
    .item {
      padding: 24px;
      margin-bottom: 70px;
      position: relative;
      top: 0;
      transition: all linear .3s;
      &:nth-last-child(-n+3) {
        margin-bottom: 0; }
      &__icon {
        margin-bottom: 36px; }
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 16px; }
      &__desc {
        p {
          margin-bottom: 36px; } }
      &__action {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        bottom: 24px;
        transition: all linear .3s; }
      &:hover {
        position: absolute;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        top: -36px;
        transition: all linear .3s;
        .item__action {
          visibility: visible;
          opacity: 1;
          transition: all linear .3s; } }
      @include maxtablet() {
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 30px!important;
        &__title {
          font-size: 21px; }
        &__desc {
          margin-bottom: 18px; }
        &__action {
          opacity: 1;
          visibility: visible;
          position: relative;
          bottom: 0; }
        &:hover {
          position: relative;
          top: 0;
          box-shadow: none; } } } }

  @include maxtablet() {
    padding: 56px 0; } }



