.contact-headquarter {
  padding: 64px 0;
  &__title {
    text-align: center; }
  &__desc {
    text-align: center;
    max-width: 600px;
    margin: 0 auto 3rem;
    color: #232933; }
  .info--img {
    position: relative;
    z-index: 2;
    .img {
      max-height: 440px;
      max-width: 496px;
      overflow: hidden;
      border-radius: 4px;
      img {
        object-fit: contain;
        width: 100%;
        height: auto; } } }
  .info {
    position: relative;
    z-index: 1;
    &:before {
      content: "";
      z-index: 1;
      display: block;
      position: absolute;
      width: 110%;
      height: 100%;
      top: 0;
      right: 0;
      background: #F2F4F7;
      border-radius: 0.25rem; }
    &__wrap {
      position: relative;
      z-index: 2;
      padding: 64px 64px 64px 45px; }
    &__title {
      margin: 0 0 1rem; }
    &__desc {
      p {
        margin: 0 0 1rem; }
      ul li:not(:last-child) {
        margin: 0 0 1rem; } }
    &__action {
      padding-top: 2.5rem; } }
  @include maxtablet() {
    padding: 32px 10px;
    background: #F2F4F7;
    &__desc {
      margin-bottom: 32px; }
    .info {
      &::before {
        display: none; }
      &__wrap {
        padding: 32px 0 0 0; } } } }
