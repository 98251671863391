.mastheadrvmp--api {
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    .mastheadrvmp__buttons {
      margin-left: 0;
      margin-right: 0;
      .btn {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 12px;
        width: 100%; } } } }

.api-feature {
  &__title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.5px;
    margin-bottom: 28px;
    font-weight: 600; }
  &__desc {
    margin-bottom: 48px;
    p:last-child {
      margin-bottom: 0; } }
  .item {
    margin-bottom: 24px;
    &__icon {
      margin-bottom: 12px;
      img {
        display: block;
        height: 56px;
        width: auto; } }
    &__title {
      font-size: 18px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 12px; }
    &__desc {
      p:last-child {
        margin-bottom: 0; } } }
  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  &__buttons {
    padding-top: 64px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__buttons {
      padding-top: 40px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }

.api-benefit {
  background: #F2F4F7;
  &__sub {
    display: block;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px; }
  &__title {
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.5px;
    margin-bottom: 28px;
    font-weight: 600; }
  &__desc {
    margin-bottom: 48px;
    p:last-child {
      margin-bottom: 0; } }
  &__items {
    padding-top: 64px;
    padding-bottom: 80px;
    .item-img {
      display: flex;
      align-items: center;
      .img {
        position: relative;
        width: 100%;
        display: none;
        img {
          display: block;
          height: 440px;
          width: auto;
          margin: 0 auto; }
        &.show {
          display: block; } } } }
  .item {
    height: 100vh;
    max-height: 600px;
    display: flex;
    align-items: center;
    &__img {
      display: none; }
    &__title {
      font-size: 32px;
      line-height: 40px;
      font-weight: 600;
      margin-bottom: 28px; }
    &__desc {
      margin-bottom: 32px;
      p:last-child {
        margin-bottom: 0; } }
    &__list {
      li {
        margin-bottom: 20px; } }
    &__buttons {
      padding-top: 40px;
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin: 0 8px; } }
    &__wrap {
      padding-top: 80px;
      padding-bottom: 80px; }
    &:first-child {
      .item__wrap {
        padding-top: 0; } }
    &:last-child {
      .item__wrap {
        padding-bottom: 0; } } }
  &__wrap {
    padding-top: 80px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; }
    &__items {
      padding-top: 0;
      padding-bottom: 0;
      .item-img {
        display: none; } }
    .item {
      height: auto;
      max-height: initial;
      &__img {
        display: block;
        margin-bottom: 24px;
        img {
          width: 90%;
          height: auto;
          display: block; } }
      &__title {
        font-size: 20px;
        line-height: 30px;
        margin-bottom: 12px; }
      &__desc {
        margin-bottom: 24px; }
      &__buttons {
        margin-left: 0;
        margin-right: 0;
        .btn {
          margin-left: 0;
          margin-right: 0;
          margin-bottom: 12px;
          width: 100%; } }
      &__wrap {
        padding-top: 32px;
        padding-bottom: 32px; } } } }

.api-block-table {
  .block-table {
    &__sub {
      display: block;
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 12px; }
    &__title {
      text-align: left;
      font-size: 40px;
      line-height: 48px;
      letter-spacing: 0.5px;
      margin-bottom: 28px;
      font-weight: 600; }
    &__desc {
      text-align: left;
      margin-bottom: 48px; } }
  @include maxtablet {
    padding-left: 12px;
    padding-right: 12px;
    .block-table {
      &__title {
        font-size: 24px;
        line-height: 32px; } } } }

.api-solusi {
  &__title {
    text-align: left;
    font-size: 40px;
    line-height: 48px;
    letter-spacing: 0.5px;
    margin-bottom: 28px;
    font-weight: 600; }
  &__desc {
    text-align: left;
    margin-bottom: 40px; }
  &__img {
    img {
      display: block;
      width: 100%;
      height: auto; } }

  &__wrap {
    padding-top: 80px;
    padding-bottom: 80px; }
  @include maxtablet() {
    padding-left: 12px;
    padding-right: 12px;
    &__title {
      font-size: 24px;
      line-height: 32px; }
    &__img {
      padding-top: 48px; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }
