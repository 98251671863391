.block-banner {
  &__img {
    position: relative;
    width: 100%;
    max-width: 442px;
    flex: 0 0 442px;
    img {
      display: block;
      width: 100%;
      height: auto; }
    .btn-play {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
      .icon {
        display: block;
        background: #FFF;
        width: 40px;
        height: 40px;
        border-radius: 20px;
        background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='play-video'%3E%3Cpath id='Vector' fill-rule='evenodd' clip-rule='evenodd' d='M10.7723 4.66807L10.7455 4.65254C9.56002 3.93978 8.63574 3.38464 7.88333 3.02206C7.12863 2.65947 6.46254 2.44595 5.80714 2.51117C5.29865 2.56186 4.80642 2.72112 4.36268 2.97855C3.91893 3.23598 3.53368 3.58576 3.23214 4.005C2.84333 4.54461 2.68445 5.23718 2.60883 6.08192C2.53244 6.92434 2.53244 8.01598 2.53244 9.41587V12.2498C2.53244 13.6497 2.53244 14.7413 2.60883 15.5837C2.68521 16.4285 2.84333 17.121 3.23214 17.6607C3.5336 18.0798 3.91873 18.4295 4.36233 18.687C4.80594 18.9444 5.29802 19.1037 5.80638 19.1545C6.46178 19.2197 7.12863 19.007 7.8841 18.6428C8.63574 18.281 9.56002 17.7259 10.7455 17.0131L10.7723 16.9976L13.0792 15.6125L13.1059 15.5969V15.5962C14.2555 14.9051 15.1531 14.3663 15.81 13.8842C16.4707 13.4004 16.9642 12.9214 17.2247 12.3189C17.4276 11.8505 17.5324 11.3443 17.5324 10.8324C17.5324 10.3206 17.4276 9.8144 17.2247 9.34599C16.965 8.74504 16.4707 8.26521 15.81 7.78073C15.1531 7.29935 14.2555 6.75974 13.1059 6.0695L13.0792 6.05397L10.7723 4.66807Z' fill='%234B61DD'/%3E%3C/g%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 20px;
        background-position: center; }
      .text {
        display: block;
        padding: 0 8px;
        color: #FFF;
        font-size: 14px;
        line-height: 20px;
        &__title {
          font-weight: 600; }
        span {
          display: block; } }
      &__wrap {
        display: flex;
        align-items: center;
        padding: 6px;
        background: #10243E;
        border-radius: 140px;
        @include pulseCustom(255,255,255, pulse-white); } } }
  &__content {
    width: 100%;
    max-width: calc(100% - 442px);
    flex: 0 0 calc(100% - 442px);
    padding-left: 24px; }
  &__action {
    display: flex;
    margin-left: -8px;
    margin-right: -8px;
    align-items: center;
    .btn,
    .link-more {
      margin-left: 8px;
      margin-right: 8px; } }
  &__box {
    display: flex;
    align-items: center;
    padding: 40px;
    background: #F1F5F9;
    border-radius: 16px; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include maxtablet() {
    &__img,
    &__content {
      max-width: 100%;
      flex: 0 0 100%;
      padding: 0; }
    &__img {
      margin-bottom: 24px; }
    &__box {
      padding: 24px;
      flex-direction: column; } } }
