.masthead--promo {
  background: #FFF;
  height: 100vh;
  max-height: 635px;
  position: relative;
  &__video {
    display: flex;
    align-items: center;
    height: 100%;
    &::after {
      z-index: 3;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: linear-gradient(0deg, rgba(25, 25, 25, 0.20) 0%, rgba(25, 25, 25, 0.20) 100%); }
    .item__content {
      z-index: 4;
      position: relative;
      width: 100%;
      .title {
        font-size: 40px;
        line-height: 56px;
        font-weight: 700;
        color: #FFF;
        img {
          display: inline-block;
          width: auto;
          height: 120px;
          margin-right: 16px; }
        span {
          position: absolute;
          opacity: 0;
          left: 0; } }
      .desc {
        color: #FFF;
        p {
          font-size: 16px;
          line-height: 24px; } }
      .action {
        padding-top: 24px;
        margin-left: -8px;
        margin-right: -8px;
        .btn {
          margin-left: 8px;
          margin-right: 8px; }
        .btn-blue-border,
        .btn--border-primary {
          background: #FFF;
          &:hover {
            color: $primary; } }
        .link-down {
          padding-left: 16px;
          padding-right: 16px;
          &::before {
            display: none; }
          &::after {
            content: "";
            display: inline-block;
            vertical-align: middle;
            width: 20px;
            height: 20px;
            background: url(../images/ic-arrow-down-long.svg) no-repeat center;
            background-size: contain;
            margin-left: 8px; }
          &:hover,
          &:focus,
          &.focus,
          &:not(:disabled):not(.disabled):active,
          &:not(:disabled):not(.disabled).active {
            color: $primary;
            text-decoration: none;
            &::after {
              @include bounceDown(); } } } } }
    .item__video {
      z-index: 2;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      object-fit: cover;
      object-position: center; }
    .item__bg {
      z-index: 1;
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      background-size: cover;
      background-position: center; } }


  &__slider {
    height: 100%; }
  &__pagination {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    position: absolute;
    bottom: 32px;
    left: calc(50% - 624px);
    text-align: left;
    max-width: 1248px;
    padding-left: 12px;
    padding-right: 12px;
    .swiper-pagination {
      width: auto; }
    .swiper-pagination-bullet {
      width: 32px;
      height: 6px;
      border-radius: 3px;
      margin: 2px 6px;
      background: #FFF;
      opacity: .7;
      transition: all linear .3s;
      &.swiper-pagination-bullet-active {
        background: #FFF;
        opacity: 1;
        transition: all linear .3s; } } }
  &__navs {
    position: absolute;
    z-index: 2;
    width: 100%;
    left: 0;
    top: calc(50% - 28px);
    padding: 0 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .nav {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #FFF;
      border-radius: 50px;
      padding: 16px;
      z-index: 3;
      cursor: pointer;
      svg {
        path {
          stroke: #D0D6DD;
          transition: all linear .3s; } }
      &:hover {
        svg {
          path {
            stroke: #4B61DD;
            transition: all linear .3s; } } } } }

  .items {
    height: 100%;
    .item {
      height: 100%;
      &__bg {
        height: 100%;
        background: #ff9100; }
      &__bg-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        &-sm {
          display: none; } } } }
  @include maxlarge() {
    &__pagination {
      left: 0;
      max-width: 100%;
      padding: 0 32px;
      .swiper-pagination {
        width: auto;
        left: 0;
        top: 0;
        position: relative; } }
    &__navs {
      left: 0;
      max-width: 100%;
      padding: 0 32px; } }
  @include maxtablet() {
    padding: 0; }

  @include mobilelg() {
    &__video {
      .item__content {
        .action {
          .btn {
            display: block;
            &+ .btn {
              margin-top: 16px; } } } } }
    .items .item {
      &__bg-img {
        display: none;
        &-sm {
          display: block; } } }
    &__pagination {
      .swiper-pagination {
        width: 100%;
        text-align: center; } }
    &__navs {
      display: none; } } }

.ribbon {
  position: relative;
  z-index: 5;
  padding: 0 0 64px 0;
  &__ribbon {
    display: flex;
    justify-content: center;
    padding: 32px 0;
    background: url(../images/ribbon-center.png) repeat-x;
    background-size: contain;
    max-width: 780px;
    position: relative;
    z-index: 4;
    margin: -16.5px auto 0 auto;
    &::before {
      content: '';
      position: absolute;
      width: 93px;
      height: 100%;
      left: -92px;
      top: 0;
      background: url(../images/ribbon-left.png) no-repeat;
      background-size: 100% 100%;
      background-position: top right; }
    &::after {
      content: '';
      position: absolute;
      width: 93px;
      height: 100%;
      right: -92px;
      top: 0;
      background: url(../images/ribbon-right.png) no-repeat;
      background-size: 100% 100%;
      background-position: top left; } }
  &__title {
    margin-bottom: 0;
    color: #FFF;
    display: inline-flex;
    font-weight: 600;
    text-align: center; }

  .items {
    margin-top: 75px;
    .item {
      &__box {
        height: 100%;
        background: #F1F5F9;
        border-radius: 16px;
        overflow: hidden;
        padding: 24px; }
      &__img {
        margin: 0 auto 8px auto;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          width: auto;
          max-height: 160px; } }
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        font-weight: 600; } } }
  &.gold {
    .ribbon__ribbon {
      background-image: url(../images/ribbon-center-gold.webp);
      &::before {
        background-image: url(../images/ribbon-left-gold.webp); }
      &::after {
        background-image: url(../images/ribbon-right-gold.webp); } } }



  @include maxtablet() {
    .items {
      .item {
        height: calc(100% - 24px);
        margin-bottom: 24px; } } }
  @include mobile() {
    &__ribbon {
      padding: 18px 0 14px 0;
      max-width: auto;
      width: calc(100% - 144px);
      margin: -8px 70px 0;
      &::before {
        width: 70px;
        left: -70px; }
      &::after {
        width: 70px;
        right: -70px; } } }
  @include mobilelg() {
    padding-bottom: 32px;
    &__title {
      font-size: 16px;
      line-height: 24px; }
    &__ribbon {
      padding: 18px 0 14px 0;
      max-width: 289px;
      margin: -8px auto 0 auto; }
    .items {
      margin-top: 29px; } } }

.testimonial-gartner {
  padding: 64px 0;
  background: #F1F5F9;
  &__left {
    max-width: 430px; }
  &__title {
    text-align: center;
    margin-bottom: 40px; }
  &__list {
    .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 24px;
      color: #232933; } }
  &__action {
    padding-top: 40px;
    margin-top: 40px;
    border-top: 1px solid #D0D6DD;
    .title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      margin-bottom: 24px;
      color: #232933; } }
  &__icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
    margin-bottom: 32px;
    .icon {
      height: 32px;
      img {
        height: 32px;
        width: auto; } } }
  &__testimoni {
    .items {
      .item {
        &__box {
          background: #FFF;
          border-radius: 16px;
          overflow: hidden;
          display: flex;
          align-items: stretch; }
        &__img {
          width: 230px;
          flex: 0 0 230px;
          img {
            height: 100%;
            object-fit: cover; } }
        &__content {
          width: calc( 100% - 230px );
          flex: 0 0 calc( 100% - 230px );
          padding: 24px;
          display: flex;
          justify-content: space-between;
          flex-direction: column; }
        &__bottom {
          margin-top: auto;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
          position: relative; }
        &__left {
          .name {
            font-weight: 600; } }
        &__right {
          position: absolute;
          right: 0;
          bottom: 0;
          max-width: 125px;
          color: #232933;
          .bold {
            font-family: Permanent Marker;
            font-size: 36px;
            line-height: normal; }
          .text {
            font-size: 14px;
            line-height: 20px; }
          &::before {
            content: '';
            display: inline-flex;
            width: 80px;
            height: 80px;
            background: url(../images/arrow-testimonial.png) no-repeat center;
            background-size: contain;
            position: absolute;
            left: -80px;
            top: -30px; } } } } }
  &__control {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start; }
  &__pagination {
    display: flex;
    justify-content: flex-start;
    .swiper-pagination {
      width: auto;
      position: relative; }
    .swiper-pagination-bullet {
      width: 32px;
      height: 6px;
      border-radius: 3px;
      margin: 2px 6px;
      background: #8B95A5;
      opacity: 0.5!important;
      transition: all linear .3s;
      &.swiper-pagination-bullet-active {
        background: #8B95A5;
        opacity: 1!important;
        transition: all linear .3s; } } }
  &__navs {
    display: flex;
    gap: 24px;
    .nav {
      width: 56px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #F8F9FB;
      border-radius: 50px;
      padding: 16px;
      cursor: pointer;
      svg {
        path {
          stroke: #D0D6DD;
          transition: all linear .3s; } }
      &:hover {
        background: #fff;
        transition: all linear .3s;
        svg {
          path {
            stroke: #4B61DD;
            transition: all linear .3s; } } } } }
  @include xsdesktop() {
    &__testimoni {
      .items {
        .item {
          &__right {
            position: relative;
            &::before {
              width: 60px;
              height: 60px;
              left: -60px;
              top: -20px; } } } } } }
  @include maxtablet() {
    &__left {
      max-width: 100%;
      margin-bottom: 40px; }
    &__action {
      text-align: center; } }
  @include mobilelg() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 24px; }
    &__testimoni {
      .items {
        margin-top: 40px;
        .item {
          &__box {
            flex-direction: column; }
          &__img {
            max-height: 343px;
            width: 100%;
            img {
              height: 100%;
              width: 100%; } }
          &__content {
            flex: 0 0 100%;
            width: 100%; } } } } } }

.benefit-block {
  padding: 64px 0;

  &__title {
    text-align: center;
    margin-bottom: 24px; }
  &__action {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
    a {
      width: fit-content; } }

  .items {
    margin-top: 75px;
    .item {
      &__box {
        height: calc(100% - 24px);
        background: #F1F5F9;
        border-radius: 16px;
        overflow: hidden;
        padding: 24px;
        margin-bottom: 24px; }
      &__img {
        margin: 0 auto 8px auto;
        display: flex;
        justify-content: center;
        img {
          height: 100%;
          width: auto;
          max-height: 160px; } }
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        font-weight: 600; } } }
  @include mobilelg() {
    padding: 32px 0; } }

.sticky-button {
  padding: 16px;
  position: fixed;
  background: #FFF;
  z-index: 11;
  left: calc(50% - (238px/2));
  bottom: 40px;
  border-radius: 44px;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);
  a {
    margin-bottom: 0!important; }
  .btn {
    border-radius: 20px; } }
