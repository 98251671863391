
.wrap.inapp {
  .btn {
    font-size: 14px;
    line-height: 20px;
    border: 1px solid transparent;
    border-radius: 6px; }
  .btn-purple {
    background: #4B61DD;
    border-color: #4B61DD;
    color: #FFF;
    &:hover {
      background: darken(#4B61DD, 10%); } }
  .btn-white {
    background: #FFF;
    border-color: #EAECFB;
    color: #4B61DD;
    &:hover {
      color: #FFF;
      background: darken(#4B61DD, 10%); } }
  label {
    font-size: 14px;
    line-height: 20px; }
  .form-control {
    height: 36px;
    font-size: 14px;
    line-height: 20px; }
  .form-group {
    margin-bottom: 20px; } }

.masthead-inapp {
  font-family: "Roboto",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  &__djp {
    background: #1C1D1E;
    padding: 10px 0;
    color: #FFF;
    text-align: center;
    img {
      display: inline-block;
      vertical-align: middle;
      height: 20px;
      width: auto;
      margin-right: 10px; }
    span {
      display: inline-block;
      vertical-align: middle;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500; } }
  &__logo {
    margin-bottom: 28px;
    img {
      display: block;
      height: 40px;
      width: auto; } }
  &__title {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 28px; }
  &__desc {
    max-width: 496px;
    margin-bottom: 40px;
    p {
      font-size: 14px;
      line-height: 20px;
      color: #777777;
      &:last-child {
        margin-bottom: 0; } } }
  &__buttons {
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; } }

.howjurnal--in-klikpajak {
  font-family: "Roboto",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  .section-head {
    margin-bottom: 40px;
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      text-align: center; }
    &__buttons {
      text-align: center; } }
  .accordion {
    margin-bottom: 0;
    .card {
      border: 1px solid transparent;
      margin-bottom: 4;
      transition: all .4s ease;
      &__title {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600; }
      &__header {
        padding-top: 18px;
        padding-bottom: 18px;
        border-bottom: 1px solid $border;
        &:after {
          top: 16px; } }
      &__body {
        border: 0;
        margin: 0;
        padding-top: 0;
        p {
          font-size: 14px;
          line-height: 20px;
          &:last-child {
            margin-bottom: 0; } } }
      &.open {
        border: 1px solid #009BDE;
        box-shadow: 0px 0px 0px 1px rgba(0,155,222,1);
        margin-top: 8px;
        .card__header {
          border-bottom: 1px solid transparent; }
        .card__body {
          box-shadow: none; } } } }
  .howjurnal {
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } } }

.block-clients--in-klikpajak {
  font-family: "Roboto",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background: transparent;
  padding: 0;
  .block-clients {
    &__title {
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 32px; }
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      .item {
        width: 100%;
        max-width: 16.666666%;
        flex: 0 0 16.666666%;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6px 12px;
        img {
          display: block;
          width: auto;
          height: auto;
          max-width: 100%;
          max-height: 100%; } } }
    &__buttons {
      padding-top: 24px;
      text-align: center; }
    &__wrap {
      padding-top: 64px;
      padding-bottom: 64px; } }
  @include maxtablet() {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 12px;
    padding-right: 12px;
    .block-clients {
      &__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        br {
          display: none; } }
      &__list {
        .item {
          max-width: 33.333333%;
          flex: 0 0 33.333333%;
          height: 64px;
          padding: 12px 16px; } }
      &__wrap {
        padding-top: 64px;
        padding-bottom: 64px; } } } }

.block-form--in-klikpajak {
  font-family: "Roboto",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  background: #F2F4F7;
  .block-form__title {
    text-align: center;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px; }
  .block-form__box {
    max-width: 392px;
    margin: 0 auto;
    background: #FFF;
    border-radius: 6px;
    padding: 32px; }
  .block-form__wrap {
    padding-top: 64px;
    padding-bottom: 64px; } }

.block-cta--in-klikpajak {
  font-family: "Roboto",-apple-system,BlinkMacSystemFont,Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  .block-cta {
    &__line {
      display: block;
      width: 48px;
      height: 6px;
      background: #F96D01;
      margin: 0 auto 32px; }
    &__title {
      text-align: center;
      margin-bottom: 28px; }
    &__buttons {
      text-align: center;
      margin-left: -8px;
      margin-right: -8px;
      .btn {
        margin-left: 8px;
        margin-right: 8px; } }
    &__wrap {
      padding-top: 40px;
      padding-bottom: 64px; } } }

.pricing-testi {
  padding-top: 24px;
  padding-bottom: 64px;
  &__img-custom {
    position: relative;
    max-width: 260px; }
  &__block-img {
    position: relative;
    width: 100%;
    padding-top: 100%;
    img {
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      object-position: center;
      border-radius: 8px; } }
  .testi {
    color: #FFF;
    .logo {
      margin-bottom: 32px;
      img {
        display: block;
        height: 32px;
        width: auto; } }
    .quote {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
      padding-right: 40px;
      span {
        display: block;
        font-size: 12px;
        line-height: 16px;
        &.name {
          font-weight: 500; } } }
    .more {
      padding-top: 32px; }
    .link-more-white {
      font-size: 14px;
      line-height: 20px;
      &:after {
        height: 6px;
        width: 20px;
        top: calc(50% - 3px); } } }
  &__wrap {
    padding-top: 32px;
    padding-bottom: 32px;
    position: relative;
    &> .row {
      position: relative;
      z-index: 2; }
    &::before {
      content: "";
      z-index: 1;
      display: block;
      width: 92%;
      height: 100%;
      background: #10253E;
      border-radius: 4px;
      position: absolute;
      top: 0;
      right: 0; } } }

.footertop-inapp {
  background: #143A72;
  border-radius: 0 0 4px 4px;
  &__title {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
    color: #FFF;
    margin-bottom: 24px; }
  &--in-klikpajak {
    background: #1C1D1E; }
  &__buttons {
    .btn + .btn {
      margin-left: 12px; } }
  .btn-yellow {
    background: #F8E51E;
    color: #293B85;
    &:hover {
      background: darken(#F8E51E, 10%); } } }

