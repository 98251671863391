.section-value {
  &__outline {
    text-align: center;
    margin: 0 0 8px; }
  &__title {
    margin: 0 0 24px;
    text-align: center; }
  &__desc {
    text-align: center;
    margin: 0 0 8px;
    &+ .section-value__items {
      padding-top: 16px; } }
  &__items {
    margin-bottom: -24px;
    .item {
      position: relative;
      margin: 0 0 24px;
      &__icon {
        margin: 0 0 8px;
        img {
          height: 48px;
          width: auto;
          display: block; } }
      &__title {
        font-size: 20px;
        line-height: 28px;
        font-weight: 600;
        margin: 0 0 8px; }
      &__desc {
        p:last-child() {
          margin: 0; } }
      &__wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        border: 1px solid $border;
        border-radius: 16px;
        padding: 24px; } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  &--bigimg {
    .section-value__items {
      .item {
        &__icon {
          img {
            height: 245px;
            margin-left: auto;
            margin-right: auto; } }
        &__wrap {
          background: #F1F5F9;
          border: 0; } } } }
  &--noborder {
    .section-value__items {
      .item {
        &__wrap {
          border: 0; } } } }
  &.bg-gray {
    background: #F1F5F9;
    .section-value__items .item__wrap {
      background: #FFF; } }

  @include maxtablet() {
    &__title {
      br {
        display: none; } }
    &__items {
      .item {
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0; } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

