.section-masthead {
  &__subtitle {
    font-weight: 600;
    display: block;
    span {
      display: inline-block;
      margin-left: 4px;
      &.badge {
        border-radius: 12px;
        background: #F96D01;
        padding: 0 6px;
        @include inter-400();
        font-size: 14px;
        line-height: 20px;
        &--orange {
          background: #F96D01; } } } }
  &__title {
    margin: 0 0 8px;
    font-size: 40px;
    line-height: 56px;
    font-weight: 700; }
  &__desc {
    p:last-child {
      margin: 0; } }
  &__action {
    padding-top: 24px;
    margin-left: -8px;
    margin-right: -8px;
    .btn {
      margin-left: 8px;
      margin-right: 8px; } }
  &__footer {
    padding-top: 24px;
    .title {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
      color: #626B79;
      margin: 0 0 8px;
      .logos {
        margin-left: -8px;
        margin-right: -8px; }
      .logo {
        display: inline-block;
        vertical-align: top;
        padding-left: 8px;
        padding-right: 8px;
        img {
          display: block;
          max-height: 56px;
          width: auto;
          height: auto; } } } }
  &__img {
    img {
      display: block;
      max-width: 100%;
      height: auto;
      width: auto; } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__title {
      font-size: 32px;
      line-height: 40px;
      br {
        display: none; } }
    &__action {
      margin-left: 0;
      margin-right: 0;
      .btn {
        display: block;
        margin: 0 0 16px;
        &:last-child {
          margin: 0; } } }
    &__img {
      padding-top: 24px; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }
