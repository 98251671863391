.top-banner--alt {
  background: #FFF6E0!important;
  margin-top: 115px!important;
  .tb__close {
    position: fixed;
    right: 0;
    top: 8px;
    padding: 8px;
    z-index: 4;
    cursor: pointer;
    span {
      font-size: 24px;
      line-height: 24px;
      color: #FFF;
      border-radius: 8px;
      display: block;
      width: 16px;
      height: 16px;
      text-align: center; } }
  @include maxtablet() {
    margin-top: 0!important;
    position: fixed;
    z-index: 100;
    .tb__close {
      top: 6px;
      right: 2px; } }
  @include mobilelg() {
    .tb__close {
      top: 10px;
      right: -2px; } } }


.top-banner-sticky {
  z-index: 879;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: none;
  text-decoration: none;
  color: #FFF;
  width: 100%;
  background: #0080d9;
  opacity: 0;
  // background: #F7F4FF
  &:hover {
    color: #FFF;
    text-decoration: none; }
  .tb__logo {
    display: none;
    width: 100%;
    max-width: 13%;
    flex: 0 0 13%;
    img {
      display: block;
      height: 24px;
      width: auto; } }
  .tb__text {
    display: flex;
    width: 100%;
    flex: 0 0 97%;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    gap: 32px;
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5)
    span.sub,
    span.sub-sm {
      display: none; }
    span.title {
      display: block;
      font-size: 15px;
      line-height: 24px;
      @include inter-400();
      b {
        @include inter-400(); }
      br {
        display: none; } }
    span.title-sm {
      display: none;
      font-size: 28px;
      line-height: 1.5em;
      @include inter-600(); } }
  .tb__action {
    display: block;
    width: 100%;
    max-width: 25%;
    flex: 0 0 25%;
    text-align: right;
    margin-left: auto;
    span.link {
      // text-decoration: underline
      // font-size: 13px
      // line-height: 1.5em
      // color: $primary
      display: inline-block;
      text-decoration: underline;
      color: #FFF;
      position: relative;
      font-size: 13px;
      line-height: 1.5em;
      padding-right: 24px;
      // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5)
      &:after {
        content: "";
        display: block;
        width: 20px;
        height: 20px;
        background: url(../images/ic-arrow-right-long-white.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: -5px;
        top: 0; }
      &:hover {
        color: #FFF;
        text-decoration: underline; }
      &:after {
        @include bounce(); } } }
  .tb__wrap {
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: space-between; }
  .tb__close {
    position: absolute;
    right: 12px;
    top: 0;
    padding: 8px;
    span {
      font-size: 16px;
      line-height: 16px;
      background: #FFF;
      color: #000;
      border-radius: 8px;
      display: block;
      width: 16px;
      height: 16px;
      text-align: center; } }
  @include maxtablet() {
    display: block;
    .tb__logo {
      display: none; }
    .tb__action {
      // display: none
      display: flex;
      justify-content: center;
      max-width: 100%;
      width: 100%;
      flex: 0 0 100%;
      text-align: center;
      span.link {
        font-size: 11px;
        line-height: 18px; } }
    .tb__text {
      max-width: 100%;
      flex: 0 0 100%;
      span.sub {
        display: none;
        font-size: 10px;
        line-height: 12px; }
      span.sub-sm {
        display: block;
        font-size: 12px;
        line-height: 16px; }
      span.title {
        display: none; }
      span.title-sm {
        display: block;
        font-size: 13px;
        line-height: 20px;
        padding-right: 20px;
        text-align: center;
        @include inter-400(); } }
    .tb__close {
      right: 0;
      top: calc( 50% - 15px );
      span {
        font-size: 16px;
        line-height: 14px;
        background: #FFF;
        color: #000;
        border-radius: 7px;
        display: block;
        width: 14px;
        height: 14px;
        text-align: center; } }
    .tb__wrap {
      flex-wrap: wrap;
      height: 48px;
      padding: 4px 0;
      max-width: 100%;
      flex: 0 0 100%;
      .tb__text {
        max-width: 100%; } } } }

.top-banner-bot {
  display: block;
  text-decoration: none;
  color: #FFF;
  width: 100%;
  // background: linear-gradient(180deg, #40c3ff 50%, #0080d9 100%)
  background: #0080d9;
  position: relative;
  margin-bottom: 0;
  &:hover {
    color:  #FFF;
    text-decoration: none; }
  .tb__logo {
    display: none;
    width: 100%;
    max-width: 16.666667%;
    flex: 0 0 16.666667%;
    img {
      display: block;
      height: 64px;
      width: auto; } }
  .tb__text {
    display: block;
    width: 100%;
    max-width: 65%;
    flex: 0 0 65%;
    text-align: left;
    padding-left: 0;
    padding-right: 0;
    // text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5)
    span.sub {
      display: block;
      font-size: 18px;
      line-height: 1.5em;
      @include inter-500(); }
    span.sub-sm {
      display: none; }
    span.title {
      display: block;
      font-size: 21px;
      line-height: 28px;
      @include inter-400();
      margin: 0 0 12px;
      b {
        @include inter-600(); } }
    span.link {
      display: inline-block;
      text-decoration: underline;
      color:  #FFF;
      position: relative;
      padding-right: 24px;
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url(../images/ic-arrow-right-long-white.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        right: -8px;
        top: 0; }
      &:hover {
        color:  #FFF;
        text-decoration: underline; }
      &:after {
        @include bounce(); } } }

  .tb__img {
    z-index: 1;
    display: block;
    position: absolute;
    width: 548px;
    height: 100%;
    top: 0;
    left: 50%;
    // right: 0
    background-size: auto 100%;
    background-repeat: no-repeat;
    background-position: right bottom; }
  .tb__wrap {
    z-index: 2;
    position: relative;
    padding-top: 16px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    min-height: 144px;
    justify-content: space-between;
    gap: 32px; }
  @include maxtablet() {
    background: #FFF6E0;
    margin-bottom: 0;
    display: none;
    .tb__logo {
      display: none; }
    .tb__img {
      display: none; }
    .tb__text {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      span.sub {
        display: none;
        font-size: 12px;
        line-height: 1.5em; }
      span.sub-sm {
        display: block;
        font-size: 16px;
        line-height: 1.5em; }
      span.title {
        font-size: 18px;
        line-height: 1.5em;
        margin-bottom: 8px; }
      span.link {
        font-size: 14px;
        line-height: 1.5em; } }
    .tb__wrap {
      padding-top: 16px;
      padding-bottom: 16px; } } }

.top-banner--alt {
  .top-banner-sticky {
    background: #FFF6E0;
    z-index: 20;
    .tb__wrap {
      height: 64px;
      .tb__text {
        padding: 6px 0;
        text-align: left;
        color: #232933;
        .title {
          font-size: 20px;
          line-height: 28px;
          @include inter-600();
          br {
            display: none; } } } } }


  .top-banner-bot {
    background: #FFF6E0;
    display: flex;
    align-items: center;
    justify-content: center;
    .tb__wrap {
      padding-top: 0;
      padding-bottom: 0;
      position: relative;
      // &::before
      //   content: ''
      //   display: inline-block
      //   width: 173px
      //   height: 100%
      //   background: url(../images/ornament-bg-banner.png) no-repeat
      //   background-position: left center
      //   background-size: contain
      //   position: absolute
      //   left: -30px
      //   top: 0
      .tb__img {
        position: relative;
        width: 165px;
        height: 100%;
        padding-top: 0;
        padding-bottom: 0;
        top: unset;
        left: unset;
        img {
          object-fit: contain; } }
      .tb__text {
        text-align: left;
        padding: 12px 0;
        color: #232933;
        .title {
          font-size: 32px;
          line-height: 48px;
          margin-bottom: 8px;
          @include inter-600(); } } } }

  &.layout_one {
    .top-banner-sticky {
      .tb__wrap {
        min-height: 64px;
        gap: 57px;
        height: auto;
        .tb__img {
          display: inline-flex;
          min-height: 40px;
          img {
            max-height: 40px;
            height: 100%;
            width: 78px;
            object-fit: cover; } }
        .tb__text {
          width: auto;
          flex: auto;
          justify-content: flex-start;
          gap: 0;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding: 6px 0;
          .title-sm,
          .meta-sm {
            display: none; }
          .title {
            font-size: 16px;
            line-height: 24px; }
          .meta {
            font-size: 16px;
            line-height: 24px;
            position: relative;
            &::before {
              content: '';
              display: none; }
            small {
              font-size: 14px;
              line-height: 20px;
              margin-left: 8px; } }
          .action {
            display: none; } }
        .tb__action {
          padding-right: 22px;
          flex: 0 0 220px;
          .link-more {
            font-size: 16px;
            line-height: 24px; } } } }
    .top-banner-bot {
      position: relative;
      .tb__wrap {
        min-height: auto;
        .tb__img {
          img {
            max-height: 140px;
            height: 140px;
            width: auto; } }
        .tb__text {
          text-align: left;
          .title {
            margin-bottom: 0;
            font-size: 32px;
            line-height: 48px;
            @include inter-600(); }
          .meta {
            font-size: 16px;
            line-height: 24px;
            p {
              margin-bottom: 0; }
            small {
              font-size: 14px;
              line-height: 20px; } }
          .meta-sm,
          .action {
            display: none; } } }
      .tb__close {
        position: absolute;
        top: -5px;
        span {
          color: #000; } } } }
  &.layout_one.gold {
    .link-more {
      color: #FFF;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14);
      &::after {
        background-image: url(../images/ic-arrow-right-w.svg);
        @include bounce(); } }
    .top-banner-sticky {
      background: linear-gradient(4deg, #AB8255 -20.48%, #D4B580 46.17%, #D3B47F 112.82%);
      .tb__wrap {
        .tb__text {
          color: #FFF; }
        .tb__action {
          flex: 0 0 220px;
          max-width: 220px; } } }

    .top-banner-bot {
      background: linear-gradient(4deg, #AB8255 -20.48%, #D4B580 46.17%, #D3B47F 112.82%);
      &::before {
        content: '';
        left: 0;
        top: 0;
        height: 100%;
        width: 20%;
        position: absolute;
        background: url(../images/sb-ornament-left.webp) no-repeat center;
        background-size: cover; }
      &::after {
        content: '';
        right: 0;
        top: 0;
        height: 100%;
        width: 8%;
        position: absolute;
        background: url(../images/sb-ornament-right.webp) no-repeat center;
        background-size: cover; }
      .tb__wrap {
        .tb__text {
          color: #FFF;
          text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.14); } } } }
  &.layout_one.tentubisa {
    .top-banner-sticky {
      .tb__wrap {
        gap: 0; }
      .tb__img {
        max-width: 132px;
        flex: 0 0 132px;
        img {
          max-height: 60px;
          width: auto; } }
      .tb__text {
        padding: 0 40px 0;
        max-width: calc(80% - 132px);
        flex: 0 0 calc(80% - 132px);
        .title {
          font-size: 16px;
          line-height: 24px; }
        img {
          max-height: 20px;
          width: auto; } }
      .tb__action {
        max-width: 20%;
        flex: 20%;
        padding: 0;
        text-align: right;
        .link-more::after {
            @include bounce(); } } }
    .top-banner-bot {
      .tb__wrap {
        gap: 0;
        .tb__img {
          max-width: 300px;
          flex: 0 0 300px;
          align-self: flex-end; }
        .tb__text {
          max-width: calc(85% - 300px);
          flex: 0 0 calc(85% - 300px);
          padding-left: 40px;
          padding-right: 40px;
          .title {
            font-size: 20px;
            line-height: 28px;
            margin: 0 0 4px; } }
        .tb__action {
          max-width: 15%;
          flex: 0 0 15%;
          text-align: right;
          .link-more::after {
            @include bounce(); } } } } }
  &.layout_one.mcon {
    .top-banner-sticky {
      background: #704bec;
      .tb__wrap {
        gap: 0;
        .tb__img {
          max-width: 190px;
          flex: 0 0 190px;
          img {
            max-height: 60px;
            width: auto; } }
        .tb__text {
          color: #FFF;
          max-width: calc(80% - 190px);
          flex: 0 0 calc(80% - 190px); }
        .tb__action {
          max-width: 20%;
          flex: 20%;
          padding: 0;
          .link-more {
            display: inline-block;
            color: #232933;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            padding: 8px 16px;
            background-color: #ffed51;
            border-radius: 4px;
            &::after {
              filter: grayscale(100%) brightness(.5); }
            &:hover {
              background-color: darken(#ffed51, 10%);
              text-decoration: none; } } } } }
    .top-banner-bot {
      background: #704bec;
      color: #FFF;
      &::after,
      &::before {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(../images/banner-tbmcon-l.webp);
        background-size: auto 100%;
        background-position: left top;
        background-repeat: no-repeat; }
      &::after {
        background-image: url(../images/banner-tbmcon-r.webp);
        background-position: right top; }
      .tb__wrap {
        gap: 0;
        .tb__img {
          max-width: 300px;
          flex: 0 0 300px; }
        .tb__text {
          max-width: calc(82% - 300px);
          flex: 0 0 calc(82% - 300px);
          padding-left: 24px;
          color: #FFF;
          .title {
            font-size: 22px;
            line-height: 150%; } }
        .tb__action {
          max-width: 18%;
          flex: 0 0 18%;
          text-align: right;
          .link-more {
            display: inline-block;
            color: #232933;
            font-size: 16px;
            line-height: 24px;
            font-weight: 600;
            padding: 8px 16px;
            background-color: #ffed51;
            border-radius: 4px;
            &::after {
              filter: grayscale(100%) brightness(.5); }
            &:hover {
              background-color: darken(#ffed51, 10%);
              text-decoration: none; } } } } } }

  @include sdekstop() {
    &.layout_one.tentubisa {
      .top-banner-sticky {
        .tb__wrap {
          min-height: 60px; }
        .tb__img {
          display: none; }
        .tb__text {
          max-width: 80%;
          flex: 0 0 80%;
          padding-left: 0; }
        .tb__action {
          max-width: 20%;
          flex: 0 0 20%; } }
      .top-banner-bot {
        .tb__wrap {
          .tb__img {
            display: none; }
          .tb__text {
            max-width: 80%;
            flex: 0 0 80%;
            padding-left: 0; }
          .tb__action {
            max-width: 20%;
            flex: 0 0 20%; } } } }
    &.layout_one.mcon {
      margin-top: 104px !important;
      .top-banner-sticky {
        background: #704bec !important;
        padding: 0;
        display: flex;
        align-items: center;
        .tb__wrap {
          .tb__img {
            display: none; }
          .tb__text {
            color: #FFF;
            padding-left: 12px;
            max-width: calc(100% - 260px);
            flex: 0 0 calc(100% - 260px); }
          .tb__action {
            max-width: 260px;
            flex: 0 0 260px;
            margin-left: 0;
            .link-more {
              color: #FFF;
              padding: 0;
              background: transparent;
              &::after {
                filter: invert(100%) brightness(1000%); } } } } }
      .top-banner-bot {
        .tb__wrap {
          flex-wrap: wrap;
          padding-top: 16px;
          padding-bottom: 16px;
          .tb__img {
            display: none; }
          .tb__text {
            padding: 0 !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center;
            .action {
              display: none; } }
          .tb__action {
            padding: 8px 0 0 !important;
            display: block !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center !important;
            padding: 0;
            padding: 0 0 16px; } } } } }


  @include xsdesktop() {
    &.layout_one {
      margin-top: 104px!important;
      .top-banner-sticky {
        .tb__wrap {
          .tb__img {
            display: none; }
          .tb__text {
            flex-wrap: wrap;
            .meta {
              padding-left: 0;
              margin-left: 0;
              &::before {
                display: none; } } } } }

      .top-banner-bot {
        .tb__wrap {
          max-width: 100%;
          margin: 0;
          gap: 0;
          .tb__text {
            padding-left: 32px; }
          .tb__img {
            flex: 0 0 150px; }
          .tb__action {
            padding-right: 22px; } } } }
    &.layout_one.tentubisa {
      .top-banner-sticky {
        .tb__wrap {
          min-height: 60px; }
        .tb__img {
          display: none; }
        .tb__text {
          max-width: 80%;
          flex: 0 0 80%;
          padding-left: 0; }
        .tb__action {
          max-width: 20%;
          flex: 0 0 20%; } }
      .top-banner-bot {
        .tb__wrap {
          min-height: 144px;
          .tb__img {
            display: none; }
          .tb__text {
            max-width: 80%;
            flex: 0 0 80%; }
          .tb__action {
            max-width: 20%;
            flex: 0 0 20%; } }
        .tb__close {
          display: none; } } }
    &.layout_one.mcon {
      margin-top: 104px !important;
      .top-banner-sticky {
        background: #704bec !important;
        padding: 0;
        display: flex;
        align-items: center;
        .tb__wrap {
          .tb__img {
            display: none; }
          .tb__text {
            color: #FFF;
            padding-left: 12px;
            max-width: calc(100% - 260px);
            flex: 0 0 calc(100% - 260px); }
          .tb__action {
            max-width: 260px;
            flex: 0 0 260px;
            margin-left: 0;
            .link-more {
              color: #FFF;
              padding: 0;
              background: transparent;
              &::after {
                filter: invert(100%) brightness(1000%); } } } } }
      .top-banner-bot {
        .tb__wrap {
          flex-wrap: wrap;
          padding-top: 16px;
          padding-bottom: 16px;
          .tb__img {
            display: none; }
          .tb__text {
            padding: 0 !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center;
            .action {
              display: none; } }
          .tb__action {
            padding: 8px 0 0 !important;
            display: block !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center !important;
            padding: 0;
            padding: 0 0 16px; } } } } }


  @include maxtablet() {
    .top-banner-sticky {
      opacity: 1;
      // transform: translateY(-100px)
      padding: 8px 16px;
      min-height: 64px;
      display: flex;
      .tb__wrap {
        height: auto;
        padding: 0;
        .tb__text {
          text-align: left;
          max-width: 100%;
          padding: 0;
          .title {
            font-size: 16px;
            line-height: 24px; }
          .meta {
            display: none; } }
        .tb__action {
          max-width: 25%;
          .btn {
            width: auto;
            margin-bottom: 0; } } }
      .tb__close {
        top: 0; } }
    .top-banner-bot {
      margin-top: 0;
      z-index: 100;
      .tb__wrap {
        display: block;
        min-height: auto;
        padding-top: 16px;
        padding-bottom: 16px;
        &::before {
          display: none; }
        .tb__img {
          display: none; }
        .tb__text,
        .tb__action {
          display: block;
          text-align: center;
          .btn {
            width: auto;
            display: inline-block; } }
        .tb__text {
          text-align: center;
          padding-top: 0;
          .title {
            font-size: 20px;
            line-height: 28px; }
          .meta {
            display: none; } } } }
    &.layout_one {
      position: relative;
      margin-top: 40px!important;
      z-index: 9;
      .top-banner-sticky {
        transform: translateY(-100%);
        transition: transform linear .3s;
        .tb__wrap {
          gap: 0;
          align-self: center;
          .tb__img {
            display: flex; }
          .tb__action {
            flex: 0 0 225px;
            max-width: none; }
          .tb__text {
            padding: 0 0 0 32px;
            .title,
            .meta {
              display: none; }
            .title-sm {
              display: flex;
              font-size: 16px;
              line-height: 24px;
              @include inter-700(); }
            .meta-sm {
              display: flex;
              font-size: 14px;
              line-height: 20px;
              position: relative;
              &::before {
                content: '';
                display: none; } } } } }
      .top-banner-bot {
        .tb__wrap {
          display: flex;
          padding: 0 12px;
          .tb__img {
            display: flex;
            margin-right: 32px; }
          .tb__text {
            padding: 12px 24px 12px 0;
            flex: 0 0 calc(100% - 160px);
            .meta {
              display: block; }
            .action {
              display: block; } }
          .tb__action {
            display: none; } } } }
    &.layout_one.tentubisa {
      margin-top: 97px !important;
      .top-banner-sticky {
        padding: 0 16px;
        .tb__img {
          display: none; }
        .tb__text {
          padding-left: 24px;
          max-width: 65%;
          flex: 0 0 65%; }
        .tb__action {
          max-width: 35%;
          flex: 0 0 35%;
          padding-right: 48px;
          justify-content: flex-end; } }
      .top-banner-bot {
        .tb__wrap {
          .tb__text {
            max-width: 100%;
            flex: 0 0 100%;
            padding-left: 28px;
            padding-right: 28px;
            .meta {
              display: none; }
            .title-sm,
            .meta-sm {
              display: block; }
            .action {
              margin-top: 8px; } } } } }
    &.layout_one.mcon {
      margin-top: 104px !important;
      .top-banner-sticky {
        background: #704bec !important;
        padding: 0;
        display: flex;
        align-items: center;
        .tb__wrap {
          .tb__img {
            display: none; }
          .tb__text {
            color: #FFF;
            padding-left: 12px;
            max-width: calc(100% - 260px);
            flex: 0 0 calc(100% - 260px); }
          .tb__action {
            max-width: 260px;
            flex: 0 0 260px;
            margin-left: 0;
            .link-more {
              color: #FFF;
              padding: 0;
              background: transparent;
              &::after {
                filter: invert(100%) brightness(1000%); } } } } }
      .top-banner-bot {
        .tb__wrap {
          flex-wrap: wrap;
          padding-top: 16px;
          padding-bottom: 16px;
          .tb__img {
            display: none; }
          .tb__text {
            padding: 0 !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center;
            .action {
              display: none; } }
          .tb__action {
            padding: 8px 0 0 !important;
            display: block !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center !important;
            padding: 0;
            padding: 0 0 16px; } } } } }

  @include mobilelg() {
    .top-banner-sticky {
      z-index: 9;
      .tb__wrap {
        .tb__text {
          max-width: 90%;
          font-size: 14px;
          line-height: 20px;
          .title,
          .tb__action span {
            font-size: 14px;
            line-height: 20px; } } } }
    &.layout_one {
      margin-top: 40px!important;
      .top-banner-sticky {
        padding: 8px;
        .tb__wrap {
          gap: 0;
          align-self: center;
          .tb__img {
            display: none; }
          .tb__action {
            max-width: 100%;
            flex: 0 0 100%;
            display: none; }
          .tb__text {
            padding: 0;
            text-align: center;
            justify-content: flex-start;
            align-items: center;
            max-width: 100%;
            .title-sm {
              display: flex;
              font-size: 16px;
              line-height: 24px;
              @include inter-700(); }
            .meta-sm {
              font-size: 14px;
              line-height: 20px;
              &::before {
                content: '';
                display: none; } }
            .action {
              display: block; } }
          .tb__action {
            justify-content: flex-start;
            .link-more {
              padding: 4px 0; } } } }
      .top-banner-bot {
        .tb__wrap {
          padding: 20px 8px;
          .tb__img {
            display: none; }
          .tb__text {
            flex: 0 0 100%;
            padding: 0;
            .title,
            .meta-sm,
            .action {
              text-align: center; }
            .title {
              display: block;
              font-size: 20px;
              line-height: 28px;
              @include inter-600(); }
            .meta {
              display: none; }
            .meta-sm {
              display: block;
              font-size: 16px;
              line-height: 24px;
              small {
                font-size: 14px;
                line-height: 20px; } } } } } }
    &.layout_one.tentubisa {
      .top-banner-sticky {
        .tb__wrap {
          min-height: 96px; }
        .tb__text {
          max-width: 100%;
          flex: 0 0 100%; } }
      .top-banner-bot {
        .tb__wrap {
          .tb__text {
            padding-left: 0;
            padding-right: 0; } } } }
    &.layout_one.mcon {
      margin-top: 104px !important;
      .top-banner-sticky {
        background: #704bec !important;
        padding: 0;
        display: flex;
        align-items: center;
        .tb__wrap {
          flex-wrap: wrap;
          padding: 6px 0;
          min-height: 96px;
          .tb__img {
            display: none; }
          .tb__text {
            color: #FFF;
            padding: 0;
            max-width: 100%;
            flex: 0 0 100%;
            .action {
              display: none; } }
          .tb__action {
            display: block;
            max-width: 100%;
            flex: 0 0 100%;
            margin-left: 0;
            .link-more {
              color: #FFF;
              padding: 0;
              background: transparent;
              &::after {
                filter: invert(100%) brightness(1000%); } } } } }
      .top-banner-bot {
        .tb__wrap {
          flex-wrap: wrap;
          padding-top: 16px;
          padding-bottom: 16px;
          .tb__img {
            display: none; }
          .tb__text {
            padding: 0 !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center;
            .title {
              font-size: 18px;
              line-height: 1.5em; }
            .action {
              display: none; } }
          .tb__action {
            padding: 8px 0 0 !important;
            display: block !important;
            max-width: 100% !important;
            flex: 0 0 100% !important;
            text-align: center !important;
            padding: 0;
            padding: 0 0 16px; } } } } } }


body.has-tb {
  .masthead {
    padding-top: 0; }
  &.single {
    .masthead {
      padding-top: 53px; } }
  &.tbs-show {
    .top-banner-sticky {
      opacity: 1;
      display: block; }
    header {
      top: 0;
      .header__top {
        transition: all .4s ease;
        opacity: 1;
        visibility: visible; }
      &.fixed {
        transform: translateY(40px);
        @include maxtablet() {
          transform: translateY(0); }
        .header__top {
          height: 0;
          opacity: 0;
          padding: 0;
          visibility: hidden; } } }
    &.tbs-show-top {
      header.fixed {
        top: -40px;
        .header__top {
          height: auto;
          opacity: 1;
          padding: 10px 0;
          visibility: visible; } } } }

  &.has-tb--alt {
    main {
      margin-top: 0; }
    .masthead {
      margin-top: 64px; }
    &.tbs-show {
      header {
        &.fixed {
          transform: translateY(64px); } }
      &.tbs-show-top {
        header {
          &.fixed {
            top: -64px; } } } }
    &.layout_one {
      &.tbs-show {
        header {
          &.fixed {
            transform: translateY(60px); } }
        &.tbs-show-top {
          header {
            &.fixed {
              top: -60px; } } } } }
    @include xsdesktop() {
      &.layout_one {
        &.tbs-show {
          &.tbs-show-top {
            header {
              &.fixed {
                top: 0;
                .header__top {
                  top: -100px; } } } } } } }
    @include maxtablet() {
      margin-top: 56px;
      &.layout_one {
        header {
          .header__top {
            top: 0; }
          .header__left {
            top: 41px; } } }
      header {
        .header__top {
          top: 64px; }
        .header__left {
          top: 105px; }
        &.fixed {
          top: -41px;
          transform: translateY(0)!important;
          transition: all linear .3s; }
        &.sticky {
          top: 0;
          transform: translateY(0)!important;
          transition: all linear .3s;
          .header__top {
            opacity: 1;
            visibility: visible;
            height: auto;
            padding: 10px 0; } } }
      &.tbs-show {
        &.tbs-show-top {
          header {
            &.fixed {
              top: 0; } }
          &.layout_one {
            header.fixed {
              .header__top {
                top: 64px; } } } }
        .top-banner-sticky {
          background: #FFF6E0;
          transform: translateY(0); }
        &.layout_one {
          header {
            .header__top {
              top: 0; }
            .header__left {
              top: 105px; } } } }
      &.menu-open,
      &.menu-open.tbs-show,
      &.menu-open.tbs-show-top {
        &.layout_one {
          header {
            .header__left {
              top: 0; }
            .header__main {
              margin-top: 56px; } } } } }
    @include mobilelg() {
      &.tbs-show {
        &.tbs-show-top {
          &.layout_one {
            header.fixed {
              .header__top {
                top: 96px; } } } }
        header {
          &.fixed {
            transform: translateY(64px); } }
        &.layout_one {
          header {
            .header__left {
              top: 137px; } } } } } }

  @include xsdesktop {
    .masthead {
      margin-top: 64px; } }

  @include maxtablet() {
    margin-top: 110px;
    .masthead {
      margin-top: 161px; }
    &.single {
      .masthead {
        padding-top: 123px; } }
    header {
      .header__left {
        top: 48px; } }
    &.tbs-show {
      &.tbs-show-top {
        header.fixed {
          top: 0; } }
      &.menu-open {
        header.fixed {
          transform: translateY(0)!important; } } }
    &.menu-open {
      .top-banner-sticky {
        visibility: hidden; }
      header {
        z-index: 999;
        .header__top {
          top: 0!important;
          transition: top linear .3s; }
        .header__left {
          top: 40px;
          transition: top linear .3s;
          z-index: 10; }
        .header__main {
          .main-menu {
            height: calc( 100vh - 240px );
            overflow-x: hidden;
            overflow-y: auto;
            .main-menu__sub {
              height: calc( 100vh - 240px );
              top: 77px; } } }
        &.fixed {
          top: 0;
          transition: all linear .3s;
          .header__top {
            height: auto;
            opacity: 1;
            visibility: visible;
            padding: 10px 0; } } }
      &.tbs-show {
        header.fixed {
          transform: translateY(0); } } } } }
