.block-middle {
  padding: 64px 0;
  &__img {
    text-align: center;
    margin-bottom: 24px;
    img {
      height: 365px;
      width: auto;
      display: inline-block; } }
  &__title {
    @include inter-600();
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px; }
  &__desc {
    text-align: center;
    margin-bottom: 24px; }
  &__action {
    text-align: center;
    a {
      margin-right: 32px;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } }
    &__img {
      img {
        height: auto; } }
    &__action {
      a {
        margin-right: 0;
        margin-bottom: 24px;
        width: 100%;
        &.link-more {
          width: auto; }
        &:last-child {
          margin-bottom: 0; } } } } }
