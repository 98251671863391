.block-kpsc {
  background: #F2F4F7;
  form {
    .btn {
      padding-left: 12px;
      padding-right: 12px; } }
  &__title {
    display: block;
    font-size: 32px;
    line-height: 48px;
    text-align: center;
    @include inter-600();
    margin: 0 0 24px; }

  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  .bootstrap-select {
    .dropdown-menu {
      width: 100%!important;
      min-width: 100%!important;
      .dropdown-item {
        white-space: normal; } } }

  @include maxtablet() {
    .col-lg-2 {
      margin-top: 16px; }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.block-kpftrs {
  .item {
    margin: 0 0 24px;
    &__img {
      margin: 0 0 8px;
      img {
        display: block;
        height: 52px;
        width: auto; } }
    &__title {
      margin: 0 0 8px;
      font-size: 20px;
      line-height: 28px;
      @include inter-600(); }
    &__desc {
      p:last-child() {
        margin: 0; } }
    &__wrap {
      display: flex;
      height: 100%;
      flex-direction: column;
      border: 1px solid $border;
      border-radius: 8px;
      padding: 24px; } }
  .items {
    margin-bottom: -24px; }
  .items.nb {
    .item__img {
      img {
        height: 40px; } }
    .item__wrap {
      border-color: transparent; } }
  &__title {
    text-align: center;
    margin: 0 0 8px; }
  &__desc {
    text-align: center;
    margin: 0 auto 24px;
    max-width: 690px; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }

  @include maxtablet() {
    .item {
      margin-bottom: 12px;
      &__img {
        position: absolute;
        top: 12px;
        left: 12px;
        img {
          width: 32px;
          height: auto; } }
      &__wrap {
        position: relative;
        padding: 12px 12px 12px 52px; } }
    .items {
      margin-bottom: -24px; }
    .items.nb {
      .item__wrap {
        border-color: $border; } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.compare-cards--kp {
  .compare-cards__desc {
    margin: 0 auto 24px; }
  .compare-cards__compare {
    .item--highlight {
      .item__head {
        background: linear-gradient(340deg, #FF7C1F 9.7%, #FF9100 47.12%, #FFC333 84.53%);
        border-bottom: #DB8000; }
      .item__wrap {
        border-color: #DB8000; } }
    .compare-nav .nav-link.nav-link--highlight.active {
      border-color: #DB8000; } } }

.block-kpposts {
  .item {
    margin: 0 0 24px;
    &__img {
      position: relative;
      width: 100%;
      padding-top: 55.357%;
      margin-bottom: 24px;
      img {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center; } }
    &__title {
      margin: 0 0 16px;
      padding: 0 24px;
      font-size: 20px;
      line-height: 28px;
      @include inter-600();
      a {
        transition: all .3s ease; }
      a:hover {
        color: $primary; } }
    &__desc {
      padding: 0 24px;
      .list-title {
        @include inter-600(); }
      ul {
        @include inter-400();
        padding-left: 24px;
        list-style-type: "-  "; } }
    &__action {
      padding: 24px;
      margin-top: auto; }
    &__wrap {
      display: flex;
      flex-direction: column;
      border-radius: 4px;
      overflow: hidden;
      height: 100%;
      border: 1px solid $border; } }
  .items {
    margin-bottom: -24px; }
  &__title {
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 24px;
    text-align: center; }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.block-kpsingle {
  &__title {
    text-align: center;
    font-size: 32px;
    line-height: 48px;
    margin: 0 0 24px; }
  &__box {
    display: flex;
    border: 1px solid $border;
    .box__img,
    .box__content {
      display: block;
      width: 100%;
      max-width: 50%;
      flex: 0 0 50%; }
    .box__img {
      img {
        display: block;
        width: 100%;
        height: auto; } }
    .box__content {
      padding: 24px;
      .item {
        position: relative;
        padding-left: 32px;
        margin: 0 0 16px;
        &__icon {
          position: absolute;
          top: 0;
          left: 0; }
        &__title {
          @include inter-600();
          display: block;

          margin: 0; }
        a {
          color: $blue;
          &:hover {
            text-decoration: underline; } }
        ul {
          padding-left: 20px;
          list-style-type: "-  "; } } }
    .title {
      font-size: 20px;
      line-height: 28px;
      @include inter-600; }
    .box__map {
      position: relative;
      .title {
        display: block;
        margin: 0 0 8px; }
      iframe {
        display: block;
        width: 100%;
        height: 300px; } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    .box__img,
    .box__content {
      display: block;
      width: 100%;
      max-width: 100%;
      flex: 0 0 100px; }
    &__box {
      display: block; } } }
