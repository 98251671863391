.fitur-blocks--solution {
  .section-head {
    padding-bottom: 0;
    &__content {
      margin-bottom: 40px; }
    &__action {
      .btn {
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 16px; } } }

  .fitur-block {
    padding-top: 64px;
    padding-bottom: 64px;
    ul.custom-list > li {
      margin-bottom: 12px; }
    &__action {
      padding-top: 24px;
      a,.btn {
        margin-right: 14px;
        &:last-child {
          margin-right: 0; } } }
    .block-img {
      img {
        height: auto; } }
    &:nth-child(even) {
      .row {
        flex-direction: row-reverse; } }
    &__title,h3 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 28px; } }
  @include maxtablet() {
    .section-head {
      padding-left: 12px;
      padding-right: 12px;
      .btn {
        margin-left: 0;
        margin-right: 0; }
      &__title {
        font-size: 24px;
        line-height: 32px; } }
    .fitur-block {
      padding: 24px 12px;
      .block-img {
        margin-bottom: 18px;
        img {
          height: auto; } }
      &__title,h3 {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 18px; }
      &__action {
        a,.btn {
          display: block;
          width: 100%;
          margin-bottom: 14px;
          margin-right: 0;
          &:last-child {
            margin-bottom: 0; } } } } } }

.testimonial-small--solution {
  .testimonial-small__wrap {
    padding: 0 0 48px 0; } }

.industry-cta--solution {
  padding-top: 80px; }
