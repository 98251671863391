.items-block {
  padding: 64px 0;
  .section__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 24px; }
  .item {
    padding: 24px;
    border: 1px solid #D0D6DD;
    border-radius: 4px;
    height: 100%;
    &__icon {
      margin-bottom: 8px;
      img {
        height: 52px;
        width: auto; } }
    &__title {
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px; }
    &__desc {
      p {
        color: #232933;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } } }
  @include maxtablet() {
    padding: 32px 0;
    .section__title {
      font-size: 28px;
      line-height: 36px;
      br {
        display: none; } }

    .item {
      height: calc(100% - 16px);
      margin-bottom: 16px; } } }



