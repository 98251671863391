/* ------------------------------------------------------------------------------------------------ */
/* General */
/* ------------------------------------------------------------------------------------------------ */

main {
  margin-top: 115px;
  &.no-header {
    margin-top: 0; }
  @include mobile {
    margin-top: 100px; } }

.container.container-sm {
  @media (min-width: 1200px) {
    max-width: 770px; } }

.row {
  margin-left: -12px;
  margin-right: -12px; }

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  padding-left: 12px;
  padding-right: 12px; }

@media (min-width: 1200px) {
  .container {
    max-width: 1248px; } }

a {
  color: #4B61DD;
  &:hover {
    text-decoration: none;
    color: #2740ce; } }

.text-danger {
  color: #C02A34; }

.section {
  &__title {
    @include inter-600();
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 8px;
    text-align: center; }
  &__desc {
    margin-bottom: 24px;
    text-align: center;
    p {
      margin-bottom: 24px;
      &:last-child {
        margin-bottom: 0; } } }
  &__action {
    text-align: center;
    a,.btn {
      margin-right: 14px;
      &:last-child {
        margin-right: 0; } } }
  @include maxtablet() {
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__action {
      a,.btn {
        margin-right: 0;
        margin-bottom: 16px;
        width: 100%;
        &:last-child {
          margin-bottom: 0; } } } } }

.btn {
  position: relative;
  font-weight: 500;
  border: 0;
  border-radius: 6px;
  text-align: center;
  transition: all .3s ease;
  padding: 7px 16px;
  font-size: 16px;
  line-height: 24px;
  &:focus,
  &:active:focus,
  &.active:focus {
    outline: none !important;
    box-shadow: none !important; }
  .icon {
    display: inline-block;
    vertical-align: middle;
    margin: 2px 8px;
    position: relative;
    top: -1px; }
  &.btn-icon-left {
    .icon {
      margin-left: 0; } }
  &.btn-icon-right {
    .icon {
      margin-right: 0; } }
  &-blue-border {
    background: transparent;
    color: $button;
    border: 1px solid $button;
    border-radius: 6px;
    font-weight: 500;
    box-shadow: none;
    &.cta-waba {
      &::before {
        background: url('../images/ic-waba-blue.svg') no-repeat center; } }
    span.arrow-button {
      width: 20px;
      height: 20px;
      display: inline-block;
      vertical-align: middle;
      background: url('../images/ic-arrow-button.svg') no-repeat center;
      background-size: contain;
      margin-left: 4px; }
    &:hover {
      color: #FFF;
      background: $button;
      border-color: $button;
      &.cta-waba {
        &::before {
          background: url('../images/ic-waba-white.svg') no-repeat center; } }
      span.arrow-button {
        width: 20px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        background: url('../images/ic-arrow-button-white.svg') no-repeat center;
        background-size: contain;
        margin-left: 4px;
        @include bounce(); } } }
  &.btn-primary {
    background: $button;
    color: #FFF;
    box-shadow: 0 16px 4px 0 rgba(103,110,144,0.05), 0 8px 8px 0 rgba(103,110,144,0.05), 0 4px 4px 0 rgba(103,110,144,0.05), 0 2px 2px 0 rgba(103,110,144,0.05);
    &:hover,
    &:active,
    &.active {
      background: darken($button, 10%); }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: darken($button, 10%); }
    &.cta-waba {
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-waba-white.svg') no-repeat center;
        background-size: contain; }
      img {
        display: none!important; } } }
  &.btn-primary-border {
    background: transparent;
    color: $primary;
    border: 1px solid $button;
    line-height: 24px;
    &:hover,
    &:active,
    &.active {
      color: #FFF;
      background: darken($button, 10%);
      border-color: darken($button, 10%);
      &.cta-waba {
        padding-left: 16px;
        position: relative;
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          margin-top: -5px;
          margin-right: 8px;
          background: url('../images/ic-waba-white.svg') no-repeat center;
          background-size: contain; }
        img {
          display: none!important; } } }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      color: #FFF;
      background: darken($button, 10%);
      border-color: darken($button, 10%); }
    &.cta-waba {
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-waba-orange.svg') no-repeat center;
        background-size: contain; }
      img {
        display: none!important; } } }
  &.btn-white {
    background: #FFFFFF;
    color: #153F7D;
    &:hover,
    &:active,
    &.active {
      background: $primary;
      color: #FFFFFF; }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: $primary;
      color: #FFFFFF; } }
  &.btn-border-white {
    border: 1px solid #FFF;
    border-radius: 6px;
    background: transparent;
    color: #FFF;
    line-height: 24px;
    &:hover {
      background: $button;
      color: #FFF; } }
  &.btn-black {
    font-size: 16px;
    line-height: 24px;
    background: #232933;
    color: #FFF;
    &:hover,
    &:active,
    &.active {
      background: #232933;
      color: #FFFFFF; }
    &:not(:disabled):not(.disabled):active:focus,
    &:not(:disabled):not(.disabled).active:focus {
      background: #232933;
      color: #FFFFFF; } }
  &.btn-link {
    font-weight: normal;
    color: #1357FF;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
      color: lighten($link, 20%); } }
  &.btn-lg {
    padding: 12px 20px;
    min-width: 200px; }
  &.btn-blue {
    background: $button;
    border: 1px solid $button;
    // box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75)
    box-shadow: none;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFF;
    &.cta-waba {
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-waba-white.svg') no-repeat center;
        background-size: contain; }
      img {
        display: none!important; } }
    &:hover {
      background: darken($button, 10%); }
    &.btn-arrow {
      &:after {
        content: '';
        display: inline-flex;
        margin-left: 8px;
        width: 20px;
        height: 20px;
        background: url(../images/ic-arrow-right-w.svg) no-repeat center;
        background-size: contain;
        position: relative;
        top: 5px;
        margin-top: -3px; }
      &:hover {
        &:after {
          @include bounce(); } } } }

  &.btn-border-blue {
    border: 1px solid $button;
    border-radius: 6px;
    background: transparent;
    color: $button;
    line-height: 24px;
    &.cta-waba {
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-waba-blue.svg') no-repeat center;
        background-size: contain; }
      img {
        display: none!important; } }
    &:hover {
      background: $button;
      color: #FFF;
      &.cta-waba {
        padding-left: 16px;
        position: relative;
        &::before {
          content: '';
          width: 24px;
          height: 24px;
          display: inline-block;
          vertical-align: middle;
          margin-top: -5px;
          margin-right: 8px;
          background: url('../images/ic-waba-white.svg') no-repeat center;
          background-size: contain; } }
      img {
        display: none!important; } } }
  &.btn-mekari {
    border-radius: 6px;
    background: $button;
    color: #FFF;
    &.cta-waba {
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-waba-white.svg') no-repeat center;
        background-size: contain; }
      img {
        display: none!important; } }
    &:hover {
      background: darken($button, 10%); } }
  &.btn-mekari-border {
    border-radius: 6px;
    background: #FFF;
    border: 1px solid $button;
    color: $button;
    &.cta-waba {
      padding-left: 16px;
      position: relative;
      &::before {
        content: '';
        width: 24px;
        height: 24px;
        display: inline-block;
        vertical-align: middle;
        margin-top: -5px;
        margin-right: 8px;
        background: url('../images/ic-waba-purple.svg') no-repeat center;
        background-size: contain; }
      img {
        display: none!important; } }
    &:hover {
      background: $button;
      color: #FFF;
      &.cta-waba {
        &::before {
          background: url('../images/ic-waba-white.svg') no-repeat center; } } } }
  &.btn-blue-play {
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      margin-right: 8px;
      width: 20px;
      height: 20px;
      background-image: url(../images/ic-play-blue.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      vertical-align: middle;
      position: relative;
      top: -1px; }
    &:hover::before {
      background-image: url(../images/ic-play-white.svg); } }
  &.btn-blue-yt {
    position: relative;
    &::before {
      content: "";
      display: inline-block;
      margin-right: 8px;
      width: 20px;
      height: 20px;
      background-image: url(../images/ic-btn-youtube.svg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      vertical-align: middle;
      position: relative;
      top: -1px; }
    &:hover::before {
      background-image: url(../images/ic-btn-youtube-white.svg); } }
  &.btn-blue-arrow {
    padding-left: 16px;
    padding-right: 16px;
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../images/ic-arrow-right-w.svg) no-repeat center;
      background-size: contain;
      vertical-align: middle;
      margin-left: 8px; } }
  &.btn-white-arrow {
    background: #FFF;
    color: $blue;
    &:after {
      content: "";
      display: inline-block;
      width: 20px;
      height: 20px;
      background: url(../images/ic-arrow-right.svg) no-repeat center;
      background-size: contain;
      vertical-align: middle;
      margin-left: 8px; }
    &:hover {
      &::after {
        @include bounce(); } } } }

.cta-waba {
  pointer-events: none;
  &.show {
    pointer-events: auto; } }

.btn-more {
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 15px;
    height: 14px;
    background-image: url(../images/arrow-more.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    left: 90%;
    top: calc(50% - 7px);
    opacity: 0;
    transition: all .5s; }
  &:hover {
    &:after {
      transform: translateX(5px);
      left: calc(100% + 5px);
      opacity: 1; } } }

.link-more {
  color: $button;
  position: relative;
  display: inline-block;
  position: relative;
  &:after {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    background: url(../images/ic-arrow-right.svg) no-repeat center;
    background-size: contain;
    margin-left: 8px;
    position: relative;
    top: -1px; }
  &:hover {
    color: $button;
    text-decoration: underline;
    &:after {
      @include bounce(); } } }

.link-more-white {
  color: #FFF;
  position: relative;
  &:after {
    content: "";
    display: block;
    width: 24px;
    height: 8px;
    background: url(../images/ic-arrow-right-long-white.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    right: -32px;
    top: calc(50% - 4px); }
  &:hover {
    color: #FFF;
    text-decoration: underline;
    &:after {
      @include bounce(); } } }

.link-external {
  position: relative;
  display: inline-block;
  color: #4B61DD;
  &::after {
    content: "";
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    margin-left: 8px;
    width: 20px;
    height: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg id='newtab'%3E%3Cpath id='Vector' d='M10.8333 9.9668L17.6667 3.13346M18.3333 6.4668V2.4668L14.3333 2.4668M9.16667 2.4668L7.5 2.4668C3.33333 2.4668 1.66666 4.13346 1.66666 8.30013L1.66666 13.3001C1.66666 17.4668 3.33333 19.1335 7.5 19.1335H12.5C16.6667 19.1335 18.3333 17.4668 18.3333 13.3001V11.6335' stroke='%234B61DD' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
  &:hover {
    color: #4B61DD;
    text-decoration: underline; } }

.link-arrow {
  color: #4A26C4;
  line-height: 24px;
  &:after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 16px;
    background-image: url(../images/arrow-more.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: calc(50% - 8px);
    right: 0;
    margin-left: 10px;
    transition: all .5s;
    position: relative;
    top: 3px; }
  &:hover {
    color: #4A26C4;
    text-decoration: none;
    &:after {
      transform: translateX(5px); } } }

.btn-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -8px;
  margin-right: -8px;
  .btn,
  .link-more {
    margin-left: 8px !important;
    margin-right: 8px !important; }
  @include mobile() {
    .btn, .link-more {
      display: block;
      width: 100%;
      margin-bottom: 16px;
      text-align: center;
      &:last-child {
        margin-bottom: 0; } } } }

@include mobile {
  .text-left-sm {
    text-align: left !important; }
  .text-right-sm {
    text-align: right !important; }
  .text-center-sm {
    text-align: center !important; } }

.block__action {
  display: flex;
  flex-wrap: wrap;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: -12px;
  min-width: 390px;
  &.text-center {
    justify-content: center; }
  .btn,
  .link-more {
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 12px; }
  .link-more {
    line-height: 40px; }
  @include mobile {
    min-width: 100%; } }

.section-title {
  @extend %mb-60;
  .title {
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 20px;
    font-weight: bold; }
  .sub {
    margin: 0 auto;
    &.sub-sm {
      max-width: 570px; } }

  @include maxtablet {
    .title {
      font-size: 26px;
      line-height: 1.5em; } } }

.owl-carousel {
  margin: 0; }
.owl-dots {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  padding: 8px 0;
  .owl-dot {
    width: 32px;
    height: 4px;
    background: #8B95A5;
    margin: 0 6px;
    border-radius: 2px;
    display: block;
    &.active {
      background: #4B61DD; }
    @include mobile {
      margin: 0 10px; } }
  @include mobile {
    margin: 24px 0 0; } }

.slider-dots-bar {
  .owl-dots {
    line-height: 20px;
    margin: 24px 0 0;
    .owl-dot {
      vertical-align: middle;
      width: 32px;
      height: 4px;
      border-radius: 2px;
      margin: 0 6px;
      background: #8B95A5;
      border-color: #8B95A5;
      &.active {
        background: $blue;
        border-color: $blue; } } } }

.banner-wrap {
  .img-banner {
    overflow: hidden;
    border-radius: 6px;
    min-height: 380px;
    display: flex;
    align-items: center;
    position: relative;
    &.ads {
      justify-content: center; }
    .bg {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: 1; }
    .content {
      position: relative;
      z-index: 2;
      width: 50%;
      padding: 30px 30px 30px 0;
      .title {
        font-size: 36px;
        line-height: 48px;
        font-weight: bold; } }
    @include mobile {
      // background-position: top left
      flex-wrap: wrap;
      min-height: 300px;
      .bg {
        position: relative;
        width: 100%;
        padding-top: 50%;
        background-position: top left; }
      .content {
        margin: 0;
        width: 100%;
        padding: 20px;
        text-align: center;
        background-color: #eef3f4;
        .title {
          font-size: 21px;
          line-height: 32px; } } } } }

.rate {
  text-align: left;
  margin-bottom: 20px;
  u {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../images/icon-star.svg);
    background-size: contain;
    & + u {
      margin-left: 1px; }
    &:last-child {
      margin-right: 0; }
    &.rated {
      background-image: url(../images/icon-star-y.svg); } } }

* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px; }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); }
  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    outline: 1px solid none;
    border-radius: 2.5px; } }

.page-number,
.page-numbers {
  text-align: center;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 0 15px;
      a,
      > span {
        display: block;
        color: #777;
        &.current {
          color: #4A26C4; } }
      &.active,
      &:hover {
        a {
          color: #4A26C4; } }
      &.disabled {
        a, span {
          pointer-events: none;
          opacity: .5; }
        &:hover {
          a, span {
            color: #777; } } } } }
  @include mobile {
    ul {
      li {
        margin: 0 5px; } } } }

// MODAL
.modal-backdrop.show {
  opacity: .9; }
.modal {
  &.fade {
    .modal-dialog {
      transform: scale(0.2); } }
  &.show {
    .modal-dialog {
      transform: scale(1); } }

  .modal-custom {
    max-width: 770px; }
  .modal-content {
    border-radius: 10px;
    border: 0;
    box-shadow: 0 20px 60px 0 rgba(0,0,0,0.5); }
  .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    opacity: 1;
    transition: opacity .5s ease;
    text-align: center;
    font-size: 30px;
    line-height: 30px;
    border-radius: 10px;
    z-index: 5;
    color: #777;
    display: block;
    width: 30px;
    height: 30px;
    background-image: url(../images/icon-close.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    &:hover {
      opacity: .5; } }

  .title {
    font-size: 26px;
    line-height: 36px;
    font-weight: 500;
    b {
      font-weight: bold; } }

  .sub {
    font-weight: normal;
    text-align: center; }

  .modal-body {
    padding: 30px;
    &.modal-body-custom {
      padding: 60px 100px; } }
  @include maxtablet {
    .modal-custom {
      max-width: 100%;
      margin: 1rem; }
    .btn-close {
      top: -45px;
      right: 0;
      color: #FFF; }
    .title {
      font-size: 21px;
      line-height: 32px; }
    .modal-body.modal-body-custom {
      padding: 30px 15px; } } }

.page-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100vh;
  margin-top: -100px;
  background-color: #EDF6FA;
  color: $primary;
  h1 {
    font-size: 25em;
    line-height: 1.2em; } }

.back-to-top {
  display: none;
  position: fixed;
  bottom: 25px;
  right: 15px;
  text-align: center;
  z-index: 100;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  background: $primary;
  border-radius: 4px;
  color: #fff !important;
  text-decoration: none !important;
  transition: all 0.5s;
  overflow: hidden;
  &.h {
    bottom: 0;
    opacity: 0;
    visibility: hidden; }
  .fa {
    font-size: 30px;
    line-height: 35px;
    height: 40px;
    width: 40px;
    text-align: center;
    display: inline-block;
    position: relative;
    background: $primary;
    color: #fff; } }

.m-0 {
  margin: 0 !important; }

.mb-30 {
  margin-bottom: 30px !important; }

// .link-more
//   color: $button
//   position: relative
//   font-size: 16px
//   &:after
//     content: ""
//     display: block
//     width: 20px
//     height: 20px
//     background: url(../images/ic-arrow-right.svg) no-repeat center
//     background-size: contain
//     position: absolute
//     right: -27px
//     top: calc(50% - 10px)
//   &:hover
//     color: $button
//     text-decoration: underline
//     &:after
//       +bounce()
//   +mobile
//     padding: 8px 0
//     display: inline-block

.link-more-white {
  color: #FFF;
  position: relative;
  font-size: 16px;
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    background: url(../images/ic-arrow-right-w.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    right: -27px;
    top: calc(50% - 10px); }
  &:hover {
    color: #FFF;
    text-decoration: underline;
    &:after {
      @include bounce(); } }
  @include mobile {
    padding: 8px 0;
    display: inline-block; } }

.section-head {
  margin-bottom: 3rem;
  &:last-child {
    margin-bottom: 0; }
  &__subtitle {
    font-size: 14px;
    line-height: 23px; }
  &__title {
    font-size: 32px;
    line-height: 48px;
    @include inter-600();
    margin-bottom: 8px; }
  &__desc {
    &:not(:last-child) {
      margin-bottom: 24px; } }

  &__action {
    a {
      margin-left: 12px;
      &:first-child {
        margin-left: 0; }
      @include mobile {
        width: 100%;
        margin-bottom: 12px;
        margin-left: 0; } } }
  @include maxtablet() {
    &__title {
      font-size: 28px;
      line-height: 36px; } } }

.custom-list-title {
  display: block;
  margin: 0 0 8px; }

ul.custom-list {
  display: block;
  width: 100%;
  &> li {
    position: relative;
    display: block;
    padding-left: 36px;
    margin-bottom: 16px;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(../images/ic-check.svg) no-repeat center/contain;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 2px; }
    .title {
      font-weight: 600;
      display: block;
      margin: 0 0 4px; }
    p {
      margin-bottom: 0; }
    &.lock::before {
      background: url(../images/ic-lock.svg) no-repeat center/contain; }
    &.cross::before {
      background: url(../images/ic-cross.svg) no-repeat center/contain; } }

  &.alt > li:before {
    background: url(../images/ic-check-green.svg) no-repeat center;
    background-size: contain; }
  &.red > li:before {
    background: url(../images/ic-check-red.svg) no-repeat center;
    background-size: contain; }
  &.cross > li:before {
    background: url(../images/ic-cross.svg) no-repeat center;
    background-size: contain; }
  &.dark {
    &> li {
      margin-bottom: 28px;
      h4 {
        font-size: 1rem;
        line-height: 1.5rem;
        margin-bottom: 4px; }
      &::before {
        background: url(../images/ic-check-dark.svg) no-repeat center; } } } }

.custom-list-alt {
  .item {
    position: relative;
    display: block;
    padding-left: 36px;
    margin-bottom: 16px;
    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background: url(../images/ic-check.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      left: 0;
      top: 2px; } } }

.accordion {
  position: relative;
  margin-bottom: 32px;
  .card {
    position: relative;
    margin-bottom: 4px;
    &__header {
      position: relative;
      display: block;
      padding: 20px 56px 20px 20px;
      cursor: pointer;
      border-bottom: 1px solid #E2E2E2;
      transition: all .3s ease-in-out;
      &:after {
        content: "";
        display: block;
        width: 24px;
        height: 24px;
        background: url(../images/ic-chevron-down.svg) no-repeat center;
        background-size: contain;
        position: absolute;
        top: 20px;
        right: 20px;
        transform: rotate(180deg);
        transition: all .5s ease-in-out; }
      &.collapsed:after {
        transform: none; } }
    &__title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 700;
      margin: 0;
      padding: 0; }
    &__body {
      background: #FFF;
      border-radius: 4px;
      box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
      padding: 24px 20px;
      margin-top: 12px; } }

  // &--faq
  //   margin-bottom: 0
  //   .card
  //     margin-bottom: 16px
  //     background: #FFF
  //     border-radius: 4px
  //     overflow: hidden
  //     border: 1px solid $border
  //     // transition: all .5s ease-in-out
  //     &.open
  //       margin-bottom: 24px
  //       background: #FFF
  //       box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1)
  //     &__header
  //       padding: 20px 50px 20px 16px
  //       border: 0
  //       .card__title
  //         max-width: 560px
  //         font-size: 16px
  //         line-height: 24px
  //         +inter-600()
  //       &:after
  //         top: 18px
  //         right: 16px
  //         background: url(../images/ic-caret-down.svg) no-repeat center
  //         background-size: contain
  //         transform: rotate(180deg)
  //       &.collapsed
  //         .card__title
  //           +inter-400()
  //         &:after
  //           content: ""
  //           background: url(../images/ic-caret-down.svg) no-repeat center
  //           background-size: contain
  //     &__body
  //       border-radius: 0
  //       box-shadow: none
  //       padding: 0 50px 20px 16px
  //       margin: 0
  //       +inter-400()
  //       p
  //         max-width: 560px
  //         +inter-400()
  //       p:last-child
  //         margin-bottom: 0
  //         a
  //           color: $link!important
  //           &:hover
  //             color: $link!important
  //             text-decoration: underline!important
  //       a
  //         color: $link!important
  //         &:hover
  //           color: $link!important
  //           text-decoration: underline!important
  //     &:last-child
  //       margin-bottom: 0

  &--auto {
    .card {
      position: relative;
      background: #FFF;
      border-radius: 4px;
      .progress {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        background: #FFF; }
      &__body {
        position: relative;
        box-shadow: none;
        background: transparent;
        padding-top: 0;
        margin-top: 0; }
      &.open {
        box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
        background: #FFF;
        margin-top: 4px;
        .card__header {
          border-color: transparent;
          padding-bottom: 12px; }
        .progress {
          opacity: 1;
          rect {
            @include dash2(10s); } } } } }

  &--auto-image {
    transition: all .5s ease-in-out;
    img {
      display: block;
      width: 100%;
      height: auto; }
    &.img-out {
      transform: scale(0.9);
      opacity: 0; }
    &.img-in {
      transform: scale(1);
      opacity: 1; } }

  &--dots {
    margin: 0;
    position: relative;
    .card {
      border: 0;
      position: relative;
      background: transparent;
      padding: 20px 0;
      margin: 0 0 0 40px;
      transition: all .4s ease;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 24px;
        left: -32px;
        height: calc(100% + 24px);
        width: 1px;
        background: $border;
        transition: all .4s ease; }
      span.dot {
        display: block;
        position: absolute;
        width: 16px;
        height: 16px;
        border-radius: 8px;
        background: $border;
        border: 2px solid #FFFFFF;
        top: 24px;
        left: -40px;
        transition: all .4s ease; }
      &:last-child {
        margin-bottom: 0;
        &:before {
          background: #FFFFFF; } }
      &__header {
        padding: 0;
        border: 0;
        background: transparent;
        transition: all .4s ease;
        border-radius: 8px 8px 0 0;
        &:after {
          display: none; } }
      &__title {
        color: #777;
        transition: all .4s ease; }
      &__body {
        border: 0;
        box-shadow: none;
        background: transparent;
        margin: 0;
        padding: 0 0 24px;
        transition: all .4s ease;
        border-radius: 0 0 8px 8px;
        p {
          margin-bottom: 12px; }
        p:last-of-type() {
          margin: 0; } }
      &__img {
        display: none; }
      &.open {
        border-radius: 16px;
        span.dot {
          background: $blue;
          box-shadow: 0px 0px 8px rgba(19, 87, 255, 0.5); }
        &:before {
          top: 44px; }
        span.dot {
          top: 44px; }
        .card__header {
          padding: 20px 24px 8px;
          background: #F2F4F7; }
        .card__title {
          color: #212121; }
        .card__body {
          padding: 0 24px 24px;
          background: #F2F4F7; } } }
    @include maxtablet {
      .card__img {
        display: block;
        padding-top: 16px;
        img {
          display: block;
          max-width: 90%;
          height: auto; } } } } }

.accordion--images {
  .img {
    display: none;
    img {
      display: block;
      width: 100%;
      height: auto; }
    &.active {
      display: block; } }
  @include maxtablet() {
    display: none; } }

.faq-block {
  padding-top: 64px;
  padding-bottom: 64px;
  background: #FFFFFF;
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 16px; }
  .section-head {
    margin-bottom: 32px; }
  .accordion {
    margin-bottom: 0; }
  &.bg-gray {
    background: #F2F4F7; }
  &--alt {
    .accordion--faq {
      .card {
        margin-bottom: 8px; }
      .card__header {
        padding: 20px 56px 12px 24px;
        &::after {
          top: 20px; }
        &.collapsed {
          padding-bottom: 20px; } }
      .card__body {
        padding-bottom: 20px; } } }

  .accordion {
    position: relative;
    .card {
      position: relative;
      &__header {
        position: relative;
        display: block;
        padding: 20px 56px 20px 20px;
        cursor: pointer;
        // border-bottom: 1px solid #E2E2E2
        transition: all .3s ease-in-out;
        &:after,
        &:before {
          content: "";
          display: block;
          width: 14px;
          height: 2px;
          position: absolute;
          left: 29px;
          top: 36px;
          background: #232933;
          transition: all .5s ease-in-out; }
        &:before {
          transform: rotate(0); } }
      &__title {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        margin: 0;
        padding: 0;
        &:hover {
          color: $text; } }
      &__body {
        background: #FFF;
        border-radius: 4px;
        box-shadow: 0px 2px 4px rgba(180, 188, 208, 0.75);
        padding: 24px 20px;
        margin-top: 12px; } }
    &--faq {
      .card {
        position: relative;
        z-index: 1;
        margin: 0;
        background: #FFF;
        border-radius: 6px;
        overflow: hidden;
        // border-bottom: 1px solid #EDF0F2
        border: 1px solid transparent;
        transition: all .3s ease-in-out;
        &::after {
          content: "";
          display: block;
          width: 100%;
          border-bottom: 1px solid #EDF0F2;
          position: absolute;
          bottom: 0;
          left: 0;
          opacity: 1;
          transition: all .3s ease-in-out; }
        &.open {
          border-radius: 6px;
          box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.10);
          border: 1px solid #8B95A5;
          z-index: 2;
          &::after {
            opacity: 0; } }
        &__header {
          padding: 24px 24px 16px 64px;
          border: 0;
          &:after,
          &:before {
            left: 29px;
            top: 36px; }
          &.collapsed {
            padding-bottom: 24px;
            &::before {
              transform: rotate(90deg); } } }
        &__body {
          border-radius: 0;
          box-shadow: none;
          padding: 0 24px 24px 64px;
          margin: 0; } } } }

  @include maxtablet() {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 4px;
    padding-right: 4px; } }

.pagination-row {
  display: block;
  padding: 20px 0 40px; }

.pagination {
  display: flex;
  justify-content: center; }

.page-item {
  display: block;
  min-width: auto;
  line-height: 24px;
  text-align: center;
  margin: 0 5px;
  padding: 0;
  color: #545465;
  &.active {
    font-weight: 400;
    .page-link {
      color: #FFF;
      background: #005FBF; } }
  &.prev {
    margin-right: 16px; }
  &.next {
    margin-left: 16px; } }

.page-link {
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #545465;
  border: 0;
  padding: 6px 12px;
  border-radius: 2px;
  &:focus {
    outline: none;
    box-shadow: none; }
  .svg {
    display: block;
    height: 24px;
    width: auto; }
  &:hover {
    color: $blue;
    background: transparent;
    .svg path {
      stroke: $blue; } } }

.play-btn {
  width: 75px;
  height: 75px;
  background: #2B2A35;
  border-radius: 50%;
  position: absolute;
  display: block;
  top: calc(50% - 37px);
  left: calc(50% - 37px);
  @include mobilesm {
    width: 50px;
    height: 50px;
    top: calc(50% - 25px);
    left: calc(50% - 25px); }
  &:hover {
    background: black; }
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-40%) translateY(-50%);
    transform: translateX(-40%) translateY(-50%);
    transform-origin: center center;
    width: 0;
    height: 0;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 25px solid #FFF;
    z-index: 2;
    -webkit-transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: all 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
    @include mobilesm {
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 15px solid #FFF; } }
  &:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 150%;
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
    -webkit-animation: pulsate1 2s;
    animation: pulsate1 2s;
    -webkit-animation-direction: forwards;
    animation-direction: forwards;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: steps;
    animation-timing-function: steps;
    opacity: 1;
    border-radius: 50%;
    border: 0px solid rgba(0, 0, 0, 0.75);
    top: -26%;
    left: -26%;
    background: rgba(0, 0, 0, 0); }
  span {
    color: transparent; } }

@keyframes pulsate1 {
  0% {
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
    opacity: 0;
    background: rgba(0, 0, 0, 1); }
  100% {
    -webkit-transform: scale(1, 1);
    transform: scale(1);
    opacity: 1;
    box-shadow: none; } }

// ========================
//    NEW STYLE - REVAMP
// ========================

ul.list-check {
  list-style: none;
  .title {
    display: block;
    font-weight: 600;
    margin-bottom: 4px;
    &:last-child {
      margin-bottom: 0; } }
  li {
    padding-left: 36px;
    margin-bottom: 24px;
    position: relative;
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 24px;
      height: 24px;
      top: 2px;
      left: 0;
      background: url(../images/ic-check-green.svg) no-repeat center;
      background-size: contain; }
    &:last-child {
      margin-bottom: 0; } } }

.ic-wa {
  z-index: 101;
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 18px;
  right: 18px;
  // background-color: $mekarios
  // border-radius: 30px
  // padding: 12px
  img {
    z-index: 2;
    position: relative;
    display: block;
    height: 64px;
    width: 64px;
    min-width: 64px; }
  span {
    z-index: 1;
    position: relative;
    display: block;
    width: 100%;
    height: 30px;
    background-color: #25d366;
    font-size: 12px;
    line-height: 14px;
    // padding: 8px 12px 8px 12px
    margin-left: -10px;
    border-radius: 0 20px 20px 0;
    color: #FFF;
    transition: all .3s ease;
    opacity: 0;
    font-size: 0;
    padding: 8px 0;
    max-width: 0; }
  &:hover {
    color: #FFF;
    text-decoration: none;
    span {
      font-size: 12px;
      padding: 8px 12px;
      opacity: 1;
      max-width: 124px; } } }
.cta-waba:not(.show) {
  pointer-events: none !important; }
#masking {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  z-index: 999999; }

.hidden {
  display: none!important; }

.hidden-sm {
  display: flex!important;
  @include maxtablet() {
    display: none!important; } }

.hidden-sm-b {
  display: block!important;
  @include maxtablet() {
    display: none!important; } }

.hidden-sm-ib {
  display: inline-block!important;
  @include maxtablet() {
    display: none!important; } }

.show-sm {
  display: none!important;
  @include maxtablet() {
    display: block!important; } }

.show-sm-ib {
  display: none!important;
  @include maxtablet() {
    display: inline-block!important; } }

.show-sm-f {
  display: none!important;
  @include maxtablet() {
    display: flex!important; } }

.link-show {
  color: #1357FF;
  position: relative;
  cursor: pointer;
  span {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../images/ic-chev-down.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      right: -32px;
      top: calc(50% - 12px); } }
  &:hover {
    color: #1357FF;
    text-decoration: underline; } }

.link-close {
  color: #1357FF;
  position: relative;
  cursor: pointer;
  span {
    position: relative;
    &:after {
      content: "";
      display: block;
      width: 24px;
      height: 24px;
      background: url(../images/ic-chev-up.svg) no-repeat center;
      background-size: contain;
      position: absolute;
      right: -32px;
      top: calc(50% - 12px); } }
  &:hover {
    color: #1357FF;
    text-decoration: underline; } }

body.sb {
  .header.fixed {
    .header__wrap {
      top: 40px; }
    .header__sticky {
      top: -59px;
      position: relative; } }
  main {
    margin-top: 245px; }
  @include maxtablet {
    .header.fixed {
      .header__wrap {
        top: 0; }
      .header__left {
        top: 86px; }
      .header__sticky {
        top: -70px; } }
    main {
      margin-top: 215px; } } }

.btn-play--blue {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  .bg {
    z-index: 2;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-image: url(../images/ic-play-white.svg);
    background-size: 22px;
    background-color: #4B61DD;
    background-repeat: no-repeat;
    background-position: 53% 33%;
    position: absolute;
    top: calc(50% - 50px);
    left: calc(50% - 50px);
    @include pulseBlue(); }
  span {
    z-index: 4;
    display: block;
    width: 100%;
    font-size: 10px;
    line-height: 16px;
    color: #FFF;
    position: absolute;
    bottom: 25%;
    left: 0;
    text-align: center; } }

.media {
  display: block;
  position: relative;
  width: 100%;
  &--video {
    position: relative; }
  &--img {
    // padding-left: 40px
    img {
      display: block;
      width: 100%;
      height: auto; } }
  &__cover {
    display: block;
    width: auto;
    margin: 0 auto; }
  &__play {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background: url(../images/ic-play-circle-alt.svg) no-repeat center;
    background-size: contain;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 40px);
    @include pulse(); } }

.hidden-sm {
  display: flex!important;
  @include maxtablet() {
    display: none!important; } }

.hidden-sm-b {
  display: block!important;
  @include maxtablet() {
    display: none!important; } }

.hidden-sm-ib {
  display: inline-block!important;
  @include maxtablet() {
    display: none!important; } }

.show-sm {
  display: none!important;
  @include maxtablet() {
    display: block!important; } }

.show-sm-ib {
  display: none!important;
  @include maxtablet() {
    display: inline-block!important; } }

.bg-gray,
.bg-grey {
  background: #F2F4F7; }

.badge {
  display: inline-block;
  color: #FFF;
  font-size: 12px;
  line-height: 20px;
  padding: 0 8px;
  background: #C02A34;
  border-radius: 4px;
  &--orange {
    background: #F96D01; } }

.block-cta {
  &__icon {
    margin: 0 0 24px; }
  &__title {
    margin: 0 0 16px;
    @include inter-600(); }
  &__desc {
    margin: 0 0 24px;
    @include inter-400(); }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px;
    text-align: center;
    max-width: 600px;
    margin: 0 auto; }

  @include maxtablet() {
    &__title {
      font-size: 28px;
      line-height: 36px; }
    &__wrap {
      padding: 32px 20px; } } }

.modal-trial {
  width: 100%;
  &__content {
    padding: 24px;
    text-align: center;
    color: #232933; }
  &__action {
    padding: 0 24px 24px;
    .btn {
      width: 100%;
      text-align: center; } }
  .modal-close {
    position: absolute;
    top: 12px;
    right: 12px;
    width: 24px;
    height: 24px;
    background: url('../images/icons/ic-cancel-blue.svg') no-repeat;
    background-position: center;
    background-size: contain; }
  .modal-dialog {
    display: flex;
    align-items: center;
    max-width: 340px;
    min-height: calc(100vh - 96px);
    margin-top: 48px;
    margin-bottom: 48px;
    margin-left: auto;
    margin-right: auto; }
  .modal-body {
    padding: 0; }
  .modal-content {
    border-radius: 0;
    padding: 0;
    margin-left: 16px;
    margin-right: 16px; } }

.scroll-top {
  display: block;
  background-color: #4B61DD;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50px;
  position: fixed;
  bottom: 98px;
  right: 27px;
  transition: background-color .3s, opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  text-align: center;
  cursor: pointer;
  &::after {
    content: '';
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle;
    background: url(../images/ic-chev-up-white.svg);
    background-size: contain;
    margin: 10% auto; }
  &.alt {
    bottom: 27px; }
  &.show {
    opacity: 1;
    visibility: visible; } }

.lity-wrap {
  top: 80px;
  bottom: auto; }
.lity-iframe .lity-container {
  max-width: 70%; }

.breadcrumb {
  padding: 16px 0;
  margin: 0;
  background: transparent;
  border-top: 1px solid $border;
  &__wrap {
    font-size: 12px;
    line-height: 16px;
    a {
      color: #4B61DD;
      text-decoration: underline;
      display: inline-block;
      &:hover {
        color: darken(#4B61DD, 20%); }
      span.home {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        font-size: 0;
        line-height: 0;
        background: url(../images/icon-klikpajak.svg) no-repeat center;
        background-size: contain; } } } }

.modal {
  .modal-inquiry {
    max-width: 1224px;
    border-radius: 4px;
    margin: 32px auto;
    min-height: calc(100% - 64px);
    .btn-close {
      background: url(../images/ic-cancel.svg) no-repeat center; }
    .modal-content {
      border-radius: 4px;
      background: #F1F5F9; }
    .modal-body {
      padding: 64px 92px;
      display: flex;
      &__left {
        width: 100%;
        max-width: 53.8462%;
        flex: 0 0 53.8462%;
        padding-left: 16px;
        padding-right: 16px; }
      &__right {
        width: 100%;
        max-width: 46.154%;
        flex: 0 0 46.154%;
        padding-left: 16px;
        padding-right: 16px; }
      &__img {
        margin: 0 0 32px;
        img {
          display: block;
          width: 100%;
          height: auto; } }
      &__testi {
        padding: 32px;
        border: 1px solid #D0D6DD;
        border-radius: 6px;
        background: #FFF;
        .title {
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
          margin: 0 0 12px; }
        .quote {
          p:last-child {
            margin: 0; } }
        .meta {
          display: flex;
          padding-top: 24px;
          .author {
            display: flex;
            width: 100%;
            max-width: calc(100% - 150px);
            flex: 0 0 calc(100% - 150px);
            .img {
              display: block;
              width: 48px;
              height: 48px;
              object-fit: cover;
              object-position: center;
              border-radius: 24px; }
            .info {
              display: block;
              width: calc(100% - 48px);
              padding-left: 16px;
              span {
                display: block;
                color: #232933; }
              .name {
                font-weight: 600; } } }
          .logo {
            width: 100%;
            max-width: 150px;
            flex: 0 0 150px;
            img {
              display: block;
              height: 48px;
              width: auto;
              margin-left: auto; } } } }

      &__logo {
        text-align: center;
        margin: 0 0 32px;
        .items {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          .item {
            display: block;
            padding: 0 6px;
            img {
              height: 56px;
              width: auto; } } } }

      &__form {
        h3 {
          text-align: center;
          font-size: 20px;
          line-height: 28px; }
        form {
          background: #FFF;
          border-radius: 6px;
          padding: 32px;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12); } } } }

  @include maxtablet() {
    .modal-inquiry {
      margin: 64px 16px;
      .btn-close {
        top: 6px;
        right: 6px; }
      .modal-body {
        padding: 44px 16px;
        flex-direction: column;
        &__left {
          order: 2;
          max-width: 100%;
          padding: 32px 0 0;
          display: flex;
          flex-direction: column; }
        &__right {
          order: 1;
          max-width: 100%;
          padding: 0; }
        &__form {
          h3 {
            font-size: 20px;
            line-height: 28px; }
          form {
            padding: 16px; } }
        &__img {
          order: 1; }
        &__logo {
          order: 3;
          margin: 0;
          .items .item img {
            height: 48px;
            width: auto; } }
        &__testi {
          order: 2;
          margin-bottom: 32px;
          .meta {
            @include mobile {
              .author {
                max-width: 100%;
                flex: 0 0 100%; }
              .logo {
                display: none; } } } } } } } }
