.propose {
  padding: 64px 0;
  &.bg-gray {
    background: #F1F5F9; }
  &.propose--alt {
    .item {
      &__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
        @include inter-600(); } } }
  &__title {
    text-align: center; }
  &__desc {
    text-align: center;
    margin-bottom: 24px; }
  &__action {
    margin-top: 32px;
    text-align: center;
    a {
      margin-right: 16px;
      &:last-child {
        margin-right: 0; } } }
  .item {
    border: 1px solid #D0D6DD;
    border-radius: 4px;
    padding: 24px;
    height: 100%;
    background: #FFF;
    &__icon {
      margin-bottom: 8px;
      img {
        height: 52px;
        width: auto; } }
    &__title {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 8px;
      @include inter-600(); }
    &__desc {
      color: #232933;
      p {
        margin-bottom: 0; }
      strong {
        font-size: 20px;
        line-height: 28px;
        @include inter-600();
        margin-top: 8px;
        display: inline-block; } } }


  @include maxtablet() {
    padding: 32px 0;
    .item {
      margin-bottom: 24px;
      height: calc(100% - 24px); } }

  @include mobilelg() {
    &__title,
    &__desc {
      text-align: left; }
    &__action {
      a {
        margin-right: 0;
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }

    &.propose--center {
      .item {
        text-align: center; } } } }
