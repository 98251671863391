.section-tab {
  &__title {
    text-align: center; }
  &__nav {
    ul.nav {
      display: flex;
      align-items: center;
      justify-content: center;
      .nav-item {
        display: block;
        position: relative;
        width: 100%;
        max-width: 28%;
        flex: 0 0 28%; }
      .nav-link {
        display: flex;
        padding: 24px 24px 20px;
        align-items: center;
        border-bottom: 4px solid transparent;
        img {
          display: block;
          width: 56px;
          height: 56px;
          object-fit: contain;
          object-position: center;
          filter: grayscale(1) invert(61%) sepia(18%) saturate(250%) hue-rotate(178deg) brightness(92%) contrast(96%); }
        span {
          display: block;
          width: 100%;
          padding-left: 16px; }
        &.active {
          font-weight: 600;
          border-bottom-color: #4B61DD;
          img {
            filter: none; } } } } }
  &__content {
    background: #EAECFB;
    padding-top: 64px;
    padding-bottom: 64px;
    .tab-pane {
      &__head {
        display: none; }
      &__body.collapse {
        display: block; }
      &__desc {
        padding-right: 40px;
        ul.custom-list {
          li:last-child {
            margin: 0; } } }
      &__action {
        padding-top: 24px;
        margin-left: -8px;
        margin-right: -8px;
        .btn {
          margin-left: 8px;
          margin-right: 8px; } }
      &__img {
        img {
          display: block;
          max-width: 100%;
          width: auto;
          height: auto; } } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__title {
      br {
        display: none; } }
    &__nav {
      display: none; }
    &__content {
      padding-top: 0;
      padding-bottom: 0;
      background: #FFF;
      .tab-pane {
        display: block;
        padding: 0 15px;
        &__head {
          display: flex;
          position: relative;
          align-items: center;
          padding: 12px 12px 21px;
          border-bottom: 1px solid #D0D6DD;
          img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            object-position: center; }
          span {
            display: block;
            width: 100%;
            max-width: calc(100% - 44px);
            flex: 0 0 calc(100% - 44px);
            font-weight: 600;
            padding: 0 8px; }
          &::after {
            content: "";
            display: block;
            width: 24px;
            height: 24px;
            background: url(../images/ic-caret-down.svg) no-repeat center;
            background-size: contain;
            transform: rotate(180deg);
            transition: all .3s ease; }
          &.collapsed::after {
            transform: none;
            transition: all .3s ease; } }
        &__body.collapse:not(.show) {
          display: none; }
        &__body {
          &> .container {
            padding-top: 24px;
            padding-bottom: 24px; } }
        &__desc {
          padding-right: 0; }
        &__action {
          margin-left: 0;
          margin-right: 0;
          .btn {
            display: block;
            margin: 0 0 16px;
            &:last-child {
              margin: 0; } } }
        &__img {
          padding-top: 24px; } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

