/* ------------------------------------------------------------------------------------------------ */
/* Form */
/* ------------------------------------------------------------------------------------------------ */

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px white inset !important; }

.form-group {
  margin-bottom: 20px;
  > label {
    font-size: 15px;
    line-height: 26px;
    font-weight: 500;
    margin-bottom: 5px;
    display: block; }
  input[type=radio],input[type=checkbox] {
    position: relative;
    top: 2px;
    margin-right: 4px; }
  &.choice {
    input[type=radio],input[type=checkbox] {
      display: inline-block;
      width: 25px;
      margin-right: 5px;
      vertical-align: top;
      width: 15px;
      height: 15px; }
    & > label {
      display: inline-block;
      width: calc(100% - 35px);
      vertical-align: top;
      line-height: 1.5; } } }
.form-group-hidden {
  display: none; }

.form-group-inline {
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-left: 8px;
    padding-right: 8px; } }




.form-action {
  padding-top: 16px;
  position: relative; }

.form-control {
  color: $secondary;
  height: 40px;
  border: 1px solid #e2e2e2;
  border-radius: 4px;
  &:hover,
  &:focus,
  &:active:focus {
    box-shadow: 0px 3px 4px -2px rgba(0, 0, 0, 0.1); } }

.form-choice {
  position: relative;
  margin-bottom: 4px;
  display: inline-block;
  margin-right: 10px;
  label {
    font-weight: 400;
    display: inline-block; }
  input[type="text"],
  input[type="email"],
  input[type="number"] {
    width: 50%;
    margin-left: 16px;
    @include mobile {
      width: calc(100% - 16px); } } }
.depend {
  display: block;
  font-size: 12px;
  line-height: 16px;
  color: #777;
  margin-top: 8px;
  margin-left: 16px; }

select.select {
  display: block;
  height: 38px;
  width: 100%;
  border: 1px solid $border;
  border-radius: 4px;
  opacity: 0.1; }

div.dropdown.bootstrap-select:not(.select-btn) {
  width: 100%;
  .dropdown-toggle {
    width: 100%;
    color: $secondary;
    line-height: 38px;
    padding: 0 30px 0 12px;
    border: 1px solid #e2e2e2;
    background: #FFF;
    font-weight: 400;
    &.bs-placeholder {
      color: #777; }
    &:focus {
      outline: none !important; }
    &:before {
      display: none; }
    &:after {
      content: "" !important;
      display: block;
      width: 16px;
      height: 16px;
      position: absolute;
      top: calc(50% - 8px);
      right: 12px;
      border: none;
      background-image: url(../images/arrow-down.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain; }
    .filter-option {
      position: relative;
      padding: 0; } }

  &.transparent {
    .dropdown-toggle {
      background: transparent;
      color: #fff; } } }
.bootstrap-select .dropdown-menu li a span.text {
  font-size: 15px; }
.dropdown-item.active, .dropdown-item:active {
  background-color: darken(#f8f9fa, 10%);
  color: #2B2A35; }

.form-box {
  background: #FFFFFF;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 16px 4px 0 rgba(103,110,144,0.05), 0 8px 8px 0 rgba(103,110,144,0.05), 0 4px 4px 0 rgba(103,110,144,0.05), 0 2px 2px 0 rgba(103,110,144,0.05);
  label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #212121; } }

.form-tax-calendar {
  input[type="text"],input[type="email"] {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    padding: 5px 12px!important; }
  select {
    border: 1px solid #E2E2E2;
    border-radius: 4px;
    padding: 7px !important; }
  .gform_footer {
    text-align: center;
    input[type="submit"] {
      position: relative;
      font-size: 15px;
      line-height: 26px;
      font-weight: 500;
      padding: 7px 20px;
      border: 0;
      border-radius: 4px;
      text-align: center;
      transition: all .3s ease;
      background: #005FBF;
      border: 1px solid #005FBF;
      box-shadow: 0px 4px 16px rgba(180, 188, 208, 0.75);
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #FFF;
      &:hover {
        background: darken(#005FBF, 10%); }
      &:focus,
      &:active:focus,
      &.active:focus {
        outline: none !important;
        box-shadow: none !important; } } } }

.custom-control {
  padding: 0;
  .custom-control-label {
    padding: 9px 0 9px 25px;
    &:after,
    &:before {
      top: 12px; }
    &:before {
      background: #FFF;
      border: 1px solid #e2e2e2; } } }

.input-group-text {
  color: #212121;
  background: #F2F4F7; }

div.dropdown.bootstrap-select:not(.select-btn) {
  &.gfield_select {
    .dropdown-toggle {
      color: #495057;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      padding-left: 12px;
      padding-right: 42px;
      line-height: 40px;
      &:after {
        right: 12px; } }
    .dropdown-item.active, .dropdown-item:active {
      color: #495057; } } }

.form-search {
  .input-search {
    position: relative;
    input {
      padding-left: 44px; }
    &::before {
      content: '';
      width: 44px;
      height: 100%;
      display: inline-block;
      position: absolute;
      left: 0;
      top: 0;
      background: url('../images/ic-search.svg') no-repeat center;
      background-size: 20px; } }
  @include maxtablet() {
    .btn {
      display: block;
      width: 100%; } } }

// ERROR
.form-group.error .form-control {
  border-color: #C0392B; }

.form-group.error .form-control {
  border-color: #C0392B; }

.form-group.error small.error {
  color: #C0392B; }

.form-group.error .bootstrap-select .select-control {
  border-color: #C0392B; }

.form-group.success {
  position: relative; }

.form-group.success .form-control {
  border-color: #179E63; }

.form-group.success::after {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  background: url("../images/ic-check.svg") no-repeat center;
  background-size: contain;
  display: inline-block;
  right: 10px;
  bottom: 11px; }

.form-group.success .dropdown.bootstrap-select button.select-control {
  border-color: #179E63; }

.form-check {
  padding: 0;
  .form-check-input {
    position: relative;
    margin: 0; }
  .form-check-tooltip {
    width: 20px;
    height: 20px;
    display: block; } }

.captcha-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  small {
    &.error {
      display: block;
      width: 100%; } } }
.captcha-canvas {
  pointer-events: none;
  margin-right: 8px;
  display: flex;
  align-items: center;
  .captcha {
    background: #f6f6f7;
    border-radius: 6px;
    height: 40px; } }
.captcha-refresh {
  width: 20px;
  height: 20px;
  background: url(../images/ic-refresh.webp) no-repeat center;
  background-size: contain;
  display: inline-block;
  cursor: pointer;
  opacity: 0.8;
  margin-right: 24px; }
.captcha-field {
  padding: 8px 16px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  max-width: 150px;
  @media screen and ( max-width:  480px) {
    flex: 1;
    @media screen and ( max-width:  320px) {
      flex: 100%; } } }
