.section-testimonial {
  &__title {
    text-align: center; }
  &__items {
    .item {
      &__left {
        min-height: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 50%;
        flex: 0 0 50%;
        background: #F1F5F9;
        padding: 32px;
        border-radius: 4px 0 0 4px;
        .title {
          display: block;
          font-size: 20px;
          line-height: 28px;
          font-weight: 600;
          margin: 0 0 12px; }
        .quote {
          margin: 0 0 16px; }
        .author {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &__left {
            display: flex;
            .photo {
              display: inline-flex;
              flex: 48px;
              width: 48px;
              height: 48px;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              overflow: hidden;
              margin-right: 16px; }
            .meta {
              display: inline-block;
              width: calc( 100% -  16px);
              flex: calc( 100% -  16px); }
            .name {
              font-weight: 600; }
            span {
              display: block; } }
          &__right {
            .logo {
              display: block;
              max-height: 48px;
              width: auto; } } } }
      &__right {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 300px;
        width: 100%;
        max-width: 50%;
        flex: 0 0 50%;
        background: #373F4C;
        padding: 32px;
        color: #FFF;
        border-radius: 0 4px 4px 0;
        .title {
          display: block;
          font-size: 52px;
          line-height: 56px;
          font-weight: 700;
          margin: 0 0 16px; }
        .desc {
          display: block;
          margin: 0 0 16px; } }
      &__wrap {
        display: flex;
        width: 100%; } } }
  &__wrap {
    padding-top: 64px;
    padding-bottom: 64px; }
  @include maxtablet() {
    &__title {
      br {
        display: none; } }
    &__items {
      .item {
        &__left {
          max-width: 100%;
          flex: 0 0 100%;
          border-radius: 4px 4px 0 0;
          padding: 24px;
          .author {
            display: block;
            .logo {
              margin-top: 16px; } } }
        &__right {
          max-width: 100%;
          flex: 0 0 100%;
          border-radius: 0 0 4px 4px;
          padding: 24px; }
        &__wrap {
          flex-direction: column; } } }
    &__wrap {
      padding-top: 32px;
      padding-bottom: 32px; } } }

.section-testimonial--alt {
  .item {
    &__left,
    &__right {
      max-width: 50%;
      flex: 0 0 50%; }
    &__left {
      background: #EDF0F2; }
    &__right {
      background: #373F4C; } }
  @include maxtablet() {
    .item {
      &__left,
      &__right {
        max-width: 100%;
        flex: 0 0 100%; } } } }
