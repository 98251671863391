.not-found {
  margin-top: 99px;
  padding: 64px 0;
  color: #232933;
  text-align: left;
  p {
    font-size: 16px;
    line-height: 24px; }
  h1 {
    @include inter-700();
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 16px;
    color: #232933; }
  .row {
    .col-lg-6 {
      margin-left: 64px;
      padding-right: 105px; } }
  &__title, {
    @include inter-700();
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 16px;
    color: #232933; }
  &__desc {
    margin-bottom: 24px;
    p {
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0; } } }
  &__search {
    form {
      .form-group {
        position: relative;
        label {
          display: block;
          @include inter-600();
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 4px; }
        input.form-control {
          padding: 8px 8px 8px 44px;
          border-radius: 6px;
          &::placeholder {
            color: #8B95A5; } }
        &::before {
          content: '';
          width: 20px;
          height: 20px;
          display: inline-flex;
          background: url(../images/ic-search-2.svg) no-repeat center center;
          background-size: contain;
          position: absolute;
          left: 14px;
          bottom: 12px;
          opacity: 0.5; } } } }

  @include maxtablet-l() {
    .row {
      .col-lg-6 {
        margin-left: 32px;
        padding-right: 12px; } } }
  @include maxtablet() {
    margin-top: 56px;
    padding: 32px 0;
    h1 {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px; }
    &__title {
      font-size: 32px;
      line-height: 40px;
      margin-bottom: 16px; }
    .row {
      flex-direction: column-reverse;
      .col-lg-6 {
        margin-left: 0;
        padding-right: 15px; } }
    &__img {
      margin-top: 64px;
      img {
        display: block;
        margin: 0 auto; } } }
  @include mobilelg() {
    &__search {
      form {
        .from-group {
          &:before {
            top: unset;
            bottom: 12px; } } } } } }

.cards-block {
  padding: 64px 0;
  .card {
    height: 100%;
    border: none;
    &__img {
      margin-bottom: 24px;
      img {
        height: 216px;
        width: 100%;
        object-fit: cover;
        border-radius: 4px; } }
    &__title {
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 12px; }
    &__desc {
      margin-bottom: 24px; } }
  @include maxtablet() {
    padding: 32px 0;
    .card {
      height: calc(100% - 24px);
      margin-bottom: 24px; } } }
