.masthead__slider {
  max-height: 500px;
  overflow: hidden;
  display: flex;
  flex-direction: row-reverse;
  .logo-marquee {
    &__group {
      display: inline-block;
      position: relative;
      width: calc(100% / 4);
      &::before {
        width: 100%;
        height: 20px;
        background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%); }
      &::after {
        width: 100%;
        height: 20px;
        background: linear-gradient(to top, rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        bottom: 0!important;
        top: unset!important; }
      &:nth-child(2) {
        margin-top: 20%; }
      &:nth-child(3) {
        margin-top: 35%; }
      &:nth-child(4) {
        margin-top: 50%; }
      &:nth-child(even) {
        .logo-marquee__list {
          animation-direction: reverse; } } }
    &__list {
      flex-direction: column;
      animation: scroll-y 30s linear infinite;
      width: 100%;
      gap: 0;
      animation-duration: 45s;
      min-width: 100%;
      .item {
        width: 100%;
        height: auto;
        max-width: 100%;
        max-height: auto;
        padding-left: 0;
        padding-right: 0;
        img {
          width: 100%;
          height: auto;
          max-height: 100%; } } } }

  @include maxtablet() {
    max-height: 299px;
    .logo-marquee {
      &__group {
        &:first-child {
          margin-top: 0; } } } } }

.client-testimonial {
  padding: 64px 0;
  background: #F2F4F7;
  .testi-big {
    margin-top: 128px;
    margin-bottom: 32px;
    padding: 32px;
    background: #FFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px 0px 0px 4px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    &__media {
      width: 400px;
      height: 490px;
      flex: 0 0 400px;
      max-width: 400px;
      margin-top: -140px;
      position: relative;
      margin-right: 78px;
      img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: contain;
        object-position: bottom; } }
    &__quote {
      margin-bottom: 24px;
      p {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }
    &__meta {
      display: flex;
      justify-content: space-between;
      align-items: center;
      b,span {
        display: block; } }
    &__logo {
      width: auto;
      height: 50px;
      object-fit: contain; } }
  .testi-sm {
    background: #FFF;
    border-radius: 4px 0px 0px 4px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    padding: 32px;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__quote {
      margin-bottom: 24px;
      p {
        margin-bottom: 16px;
        &:last-child {
          margin-bottom: 0; } } }
    &__meta {
      display: flex;
      align-items: center; }
    &__img {
      height: 105px;
      width: auto;
      margin-right: 16px; }
    &__info {
      b,span {
        display: block; }
      span {
        margin-bottom: 4px; } } }
  @include maxtablet() {
    padding: 32px 0;
    .testi-big {
      flex-direction: column;
      padding: 24px 12px;
      margin-top: 85px;
      margin-bottom: 24px;
      &__media {
        margin-right: 0;
        margin-bottom: 8px;
        margin-top: -90px;
        height: 292px; }
      &__meta {
        flex-direction: column;
        align-items: flex-start; }
      &__info {
        margin-bottom: 12px; } }
    .testi-sm {
      padding: 24px 12px;
      margin-bottom: 24px;
      height: calc(100% - 24px);
      &__img {
        display: none; } } } }


.card-testi {
  padding: 64px 0;
  .card__wrap {
    margin-top: 32px; }
  &__filter {
    padding-top: 16px;
    form {
      display: flex; }
    .form-group {
      margin: 0;
      display: block;
      width: 100%;
      max-width: 35%;
      flex: 0 0 35%;
      padding-left: 8px;
      padding-right: 8px; }
    .form-action {
      padding-top: 0;
      display: block;
      width: 100%;
      max-width: 30%;
      flex: 0 0 30%;
      padding-left: 8px;
      padding-right: 8px;
      .btn {
        display: block;
        width: 100%; } } }
  .card {
    border-radius: 4px;
    overflow: hidden;
    border: 0;
    height: calc(100% - 24px);
    margin-bottom: 24px;
    &__img-box {
      display: block;
      width: 100%;
      padding-top: 56%;
      position: relative;
      img {
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center; } }
    &__content {
      padding: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      height: 100%; }
    &__icon {
      position: absolute;
      width: 24px;
      height: 24px;
      object-fit: contain;
      top: 25px;
      left: 24px; }
    &__title {
      padding-left: 32px;
      @include inter-600();
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 16px;
      &.no-icon {
        padding-left: 0; } }
    &__desc {
      margin-top: auto;
      p {
        min-height: 96px; } }
    &__info {
      margin-bottom: 4px;
      b,span {
        display: block; } }
    &__logo {
      height: 50px;
      width: auto;
      object-position: left center;
      object-fit: contain; }
    &__action {
      margin-top: 26px;
      visibility: hidden;
      opacity: 0;
      transition: all linear .3s; }
    &:hover {
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
      .card__action {
        visibility: visible;
        opacity: 1;
        transition: all linear .3s; } } }
  @include maxtablet() {
    padding: 32px 0;
    &__filter {
      padding-top: 8px;
      form {
        display: block; }
      .form-group {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: 8px;
        padding: 0; }
      .form-action {
        margin-top: 8px;
        max-width: 100%;
        flex: 0 0 100%;
        padding: 0; } }
    .card {
      border: 1px solid #D0D6DD;
      &__icon {
        left: 12px;
        top: 13px; }
      &__content {
        padding: 12px 12px 24px 12px; }
      &__action {
        visibility: visible;
        opacity: 1; }
      &:hover {
        box-shadow: none; } } } }
