#wrap {
  margin: auto;
  // overflow: hidden
  .section {
    @include transition(all .8s 1.7s); }
  .loadpage {
    background: #F9FAFB;
    @include center;
    @include absfull;
    position: fixed;
    z-index: 101;
    @include transition(opacity .5s,visibility .5s,transform 1.2s );
    overflow: hidden;
    opacity: 1;
    visibility: hidden;
    transform: translate(0,-100%);
    .img {
      margin: auto; } }

  .loadingimg {
    background: #FFFFFF;
    @include center;
    @include absfull;
    position: fixed;
    z-index: 101;
    opacity: 1;
    @include transition(opacity .5s 1.5s,visibility .5s 1.5s,transform .5s );
    overflow: hidden;
    &:before {
      content: "";
      @include absfull;
      background: $primary;
      transition: transform 1.2s cubic-bezier(0.23, 0.8, 0.32, 1), opacity .5s 1.5s;
      transform: translate(0, -100%); }
    img {
      display: block;
      margin: auto;
      transition: all .5s cubic-bezier(0.23, 0.8, 0.32, 1);
      transition-delay: 1s;
      transform: translate(0,100%);
      opacity: 0; }
    .img {
      margin: auto;
      position: relative;
      overflow: hidden;
      .icon {
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 40px); } } }

  &.opened {
    .loadpage {
      .img {
        margin: auto; } }
    .loadingimg {
      opacity: 0;
      visibility: hidden;
      transform: translate(0 , 100%);
      @include transition(opacity .3s 1.3s,visibility .3s 1.3s,transform .3s 2.3s);
      &:before {
        animation-name: loadimage;
        animation-duration: 2s;
        animation-fill-mode: forwards;
        animation-timing-function: cubic-bezier(.785, .135, .15, .86); }
      img {
        transform: translate(0,100%); } }
    .section {
 } }      //opacity:
  &.state {
    .loadingimg {
      transition: all 0s;

      opacity: 1;
      visibility: visible;
      transform: translate(-100%,0);
      @include transition(opacity .5s .5s,visibility .5s .5s,transform .5s);
      &:before {
        animation: none; }
      img {
        transform: translate(0,100%);
        opacity: 0; } }
    header {
      transform: translateY(-100px);
      background: none;
      transition-delay: 1s;
      &.blue {
        .wrp {
          background: none; } } } }
  &.loading {
    .loadingimg {
      transform: translate(0,0);
      @include transition(transform .5s);
      img {
        opacity: 1;
        transform: translate(0,0);
        transition-delay: 1s;
        transition: all 1s 1s cubic-bezier(0.23, 1, 0.32, 1); } } }
  &.loading-page {
    .loadpage {
      visibility: visible;
      transform: translate(0,0); }
    .loadingimg {
      opacity: 0;
      visibility: hidden;
      transform: translate(100%,0);
      @include transition(opacity .5s 1.5s,visibility .5s 1.5s,transform .5s 2.5s); } }
  &.process {}

  &.opened-page {
    //opacity: 1
    //transform: translate(0,0)
    //transition: all 1.2s
    .loadpage {
      opacity: 0;
      visibility: hidden;
      transform: translate(0,-100%); }
    .loadingimg {
      opacity: 0;
      visibility: hidden;
      transform: translate(100%,0);
      @include transition(opacity .5s 1.5s,visibility .5s 1.5s,transform .5s 2.5s); } } }




@include keyframes(loadimage) {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translate(0,100%); }
  50% {
    transform: translate(0,0); }
  100% {
    transform: translate(0,-100%); } }

@include keyframes(flip) {
  0% {
    opacity: 1;
    visibility: visible;
    transform: translate(0,0); }
  100% {
    opacity: 0;
    visibility: hidden; } }



.step-in {
  opacity: 0;
  transition: opacity .5s ease; }

.slide-left {
  transition: opacity 1s 0s linear, -webkit-transform 1s 0s ease;
  transition: opacity 1s 0s linear, transform 1.5s 0s ease;
  -webkit-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  opacity: 0;
  &.animate-in {
    opacity: 1;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); } }

.slide-right {
  transition: opacity 1s 0s linear, -webkit-transform 1s 0s ease;
  transition: opacity 1s 0s linear, transform 1.5s 0s ease;
  -webkit-transform: translateX(100px);
  -ms-transform: translateX(100px);
  transform: translateX(100px);
  opacity: 0;
  &.animate-in {
    opacity: 1;
    -webkit-transform: translate(0);
    -ms-transform: translate(0);
    transform: translate(0); } }


.slide-up {
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  transition: opacity .5s linear, -webkit-transform .5s ease;
  transition: opacity .5s linear, transform .5s ease;
  &.long {
    transition: opacity 1s linear, -webkit-transform 1s ease; }
  transition: opacity 1s linear, transform 1s ease;
  @for $item from 1 through 15 {
    &[data-delay="#{$item}"] {
      -webkit-transition-delay: #{$item*500}ms;
      -moz-transition-delay: #{$item*500}ms;
      -o-transition-delay: #{$item*500}ms;
      -ms-transition-delay: #{$item*500}ms;
      transition-delay: #{$item*500}ms; } }
  &.animate-in {
    opacity: 1;
    transform: translateY(0); } }

.slide-down {
  opacity: 0;
  -webkit-transform: translateY(-30px);
  -ms-transform: translateY(-30px);
  transform: translateY(-30px);
  transition: opacity .5s linear, -webkit-transform .5s ease;
  transition: opacity .5s linear, transform .5s ease;
  &.long {
    transition: opacity 1s linear, -webkit-transform 1s ease; }
  transition: opacity 1s linear, transform 1s ease;
  @for $item from 1 through 15 {
    &[data-delay="#{$item}"] {
      -webkit-transition-delay: #{$item*500}ms;
      -moz-transition-delay: #{$item*500}ms;
      -o-transition-delay: #{$item*500}ms;
      -ms-transition-delay: #{$item*500}ms;
      transition-delay: #{$item*500}ms; } }
  &.animate-in {
    opacity: 1;
    transform: translateY(0); } }

.banner-up {
  opacity: 0;
  -webkit-transform: translateY(30px);
  -ms-transform: translateY(30px);
  transform: translateY(30px);
  transition: opacity .5s linear, -webkit-transform .5s ease;
  transition: opacity .5s linear, transform .5s ease;
  .text {
    * {
      opacity: 0;
      -webkit-transform: translateY(30px);
      -ms-transform: translateY(30px);
      transform: translateY(30px);
      transition: opacity .5s linear, -webkit-transform .5s ease;
      transition: opacity .5s linear, transform .5s ease;
      @for $item from 1 through 15 {
        &:nth-child(#{$item}) {
          -webkit-transition-delay: #{$item*200}ms;
          -moz-transition-delay: #{$item*200}ms;
          -o-transition-delay: #{$item*200}ms;
          -ms-transition-delay: #{$item*200}ms;
          transition-delay: #{$item*200}ms; } } } }
  &.animate-in {
    opacity: 1;
    transform: translateY(0);
    .text {
      * {
        opacity: 1;
        transform: translateY(0); } } } }

.list-up {
  .item-up,.col-sm-4,.panel,.col-md-3,&> * {
    opacity: 0;
    -webkit-transform: translateY(30px);
    -ms-transform: translateY(30px);
    transform: translateY(30px);
    transition: opacity .5s linear, -webkit-transform .5s ease;
    transition: opacity .5s linear, transform .5s ease;
    @for $item from 1 through 20 {
      &:nth-child(#{$item}) {
        -webkit-transition-delay: #{$item*200}ms;
        -moz-transition-delay: #{$item*200}ms;
        -o-transition-delay: #{$item*200}ms;
        -ms-transition-delay: #{$item*200}ms;
        transition-delay: #{$item*200}ms; } } }
  &.left:not(.animate-in) {
    &> * {
      -webkit-transform: translate(-30px,0);
      -ms-transform: translate(-30px,0);
      transform: translate(-30px,0); } }
  &.animate-in {
    .item-up,.col-sm-4,.col-md-3,&> * {
      opacity: 1;
      -webkit-transform: translate(0,0);
      -ms-transform: translate(0,0);
      transform: translate(0,0); } } }


.list-each {
  @include minltablet {
    .col-sm-4 {
      opacity: 0;
      transform: translateY(60px);
      transition: all .5s;
      &:nth-child(1) {
        left: 33%;
        transition: opacity 0.5s, transform 0.5s, left 0.5s 0.5s; }
      &:nth-child(3) {
        left: -33%;
        transition: opacity 0.5s, transform 0.5s, left 0.5s 0.5s; } }
    &.animate-in {
      .col-sm-4 {
        opacity: 1;
        transform: none;
        left: 0 !important; } } } }


.waypoint-reveal .step-up,.waypoint-reveal.step-up {
  opacity: 1;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }


.anim.boxs {
  .wraper {
    .box {
      transform: scaleY(0);
      transform-origin: 0 100%;

      transition: all 1s;

      &:after {
        transform: scaleX(0);
        transform-origin: 0 0;

        transition: all 1s;
        transition-delay: .5s; }
      .map,.img {
        transform: scaleY(0);
        transform-origin: 0 0;

        transition: all 1s;
        transition-delay: 1s; } } }
  &.animate-in {
    .box {
      transform: none;
      &:after,.map,.img {
        transform: none; } } } }


.anim-img {
  &:after {
    background: $secondary;
    content: ' ';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transform-origin: left top;
    transform: scale(.05, 0);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.785, .135, .15, .86);
    animation-delay: 1s; }
  > * {
    visibility: hidden;
    animation-duration: 2.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(.785, .135, .15, .86); }
  &.animate-in {
    &:after {
      animation-name: slide-bg-2; }
    &> * {
      animation-name: show-img-1; } } }

@mixin pulse($r, $g, $b, $name) {
  box-shadow: 0 0 0 rgba($r, $g, $b, 0.4);
  animation: $name 2s infinite;

  @keyframes #{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.4); }
    70% {
      box-shadow: 0 0 0 14px rgba($r, $g, $b, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0); } } }

@mixin bounce() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounce2;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@mixin progressBar($duration) {
  animation: progressBar $duration linear; }
@keyframes progressBar {
  0% {
    max-width: 0%; }
  100% {
    max-width: 100%; } }

@keyframes d-headline {
  0% {
    max-width: 0%; }
  10% {
    max-width: 0%; }
  100% {
    max-width: 100%; } }

@mixin pulse() {
  box-shadow: 0 0 0 rgba(0,0,0, 0.4);
  animation: pulse 2s infinite; }

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.4); }
  70% {
    box-shadow: 0 0 0 14px rgba(0,0,0, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0,0,0, 0); } }

@mixin pulseBig() {
  box-shadow: 0 0 0 rgba(255,255,255, 1);
  animation: pulseBig 2s infinite; }

@keyframes pulseBig {
  0% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4); }
  70% {
    box-shadow: 0 0 0 30px rgba(255,255,255, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(255,255,255, 0); } }

@mixin pulseBlue() {
  box-shadow: 0 0 0 rgba(75, 97, 221, 1);
  animation: pulseBlue 2s infinite; }

@keyframes pulseBlue {
  0% {
    box-shadow: 0 0 0 0 rgba(75, 97, 221, 0.4); }
  70% {
    box-shadow: 0 0 0 30px rgba(75, 97, 221, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(75, 97, 221, 0); } }

@mixin pulse2() {
  box-shadow: 0 0 0 rgba(0,95,191, 0.8);
  animation: pulse2 3s infinite; }

@keyframes pulse2 {
  0% {
    box-shadow: 0 0 0 0 rgba(0,95,191, 0.8); }
  70% {
    box-shadow: 0 0 0 18px rgba(0,95,191, 0); }
  100% {
    box-shadow: 0 0 0 0 rgba(0,95,191, 0); } }

@mixin pulseCustom($r, $g, $b, $name) {
  box-shadow: 0 0 0 rgba($r, $g, $b, 0.4);
  animation: $name 2s infinite;

  @keyframes #{$name} {
    0% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0.4); }
    70% {
      box-shadow: 0 0 0 14px rgba($r, $g, $b, 0); }
    100% {
      box-shadow: 0 0 0 0 rgba($r, $g, $b, 0); } } }

@keyframes scroll-x {
  from {
    transform: translateX(0); }
  to {
    transform: translateX(calc(-100% - 32px)); } }

@keyframes scroll-y {
  from {
    transform: translateY(0); }
  to {
    transform: translateY(calc(-100% - 32px)); } }

@mixin bounceRevert() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounceRev;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounceRev {
  0% {
    transform: scale(1,1) translateX(0); }
  10% {
    transform: scale(1.1,.9) translateX(0); }
  30% {
    transform: scale(.9,1.1) translateX(4px); }
  50% {
    transform: scale(1.05,.95) translateX(-6px); }
  57% {
    transform: scale(1,1) translateX(2px); }
  64% {
    transform: scale(1,1) translateX(0); }
  100% {
    transform: scale(1,1) translateX(0); } }

@mixin bounceDown() {
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-name: bounceDown;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1); }

@keyframes bounceDown {
  0% {
    transform: scale(1,1) translateY(0); }
  10% {
    transform: scale(1.1,.9) translateY(0); }
  30% {
    transform: scale(.9,1.1) translateY(-4px); }
  50% {
    transform: scale(1.05,.95) translateY(6px); }
  57% {
    transform: scale(1,1) translateY(-2px); }
  64% {
    transform: scale(1,1) translateY(0); }
  100% {
    transform: scale(1,1) translateY(0); } }
